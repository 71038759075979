import {Component, InjectionToken, Injector, Input, Type, ViewChild} from '@angular/core';
import {ComponentType} from "@angular/cdk/overlay";
import {CdkPortalOutlet, ComponentPortal} from '@angular/cdk/portal';

export const REQUEST_FORM_COMPONENT_DATA = new InjectionToken<{}>('REQUEST_FORM_COMPONENT_DATA');


@Component({
    selector: 'app-dynamic-host',
    templateUrl: './dynamic-host.component.html',
    styleUrls: ['dynamic-host.component.scss']
})
export class DynamicHostComponent {

    @Input() componentType: ComponentType<any>;
    @Input() data: any;

    portal: ComponentPortal<any>;

    private _portalOutlet!: CdkPortalOutlet;

    @ViewChild(CdkPortalOutlet) set portalOutlet(portalOutlet: CdkPortalOutlet) {
        if (portalOutlet) {
            this._portalOutlet = portalOutlet;
            this.handlePortalAttached();
        }
    }

    private handlePortalAttached() {
        if (this._portalOutlet?.attachedRef) {
            const componentInstance = (this._portalOutlet.attachedRef as any).instance;
            console.log('Created component instance:', componentInstance);
        }
    }

    constructor(private injector: Injector,) {
    }

    ngOnInit() {
        this.portal = new ComponentPortal(this.componentType, null, this.createInjector(this.data));
    }

    createInjector(dataToPass): Injector {
        // DEPRECATED
        //const injectorTokens = new WeakMap();
        //injectorTokens.set(INFO_BOTTOM_COMPONENT_DATA, dataToPass);
        //return new PortalInjector(this.injector, injectorTokens);
        return Injector.create({
            parent: this.injector,
            providers: [
                { provide: REQUEST_FORM_COMPONENT_DATA, useValue: dataToPass }
            ]
        });
    }
}
