/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddSpesaStudenteRequestDTO } from '../model/addSpesaStudenteRequestDTO';
import { BudgetStudenteInfoView } from '../model/budgetStudenteInfoView';
import { BudgetStudenteInfoViewImpl } from '../model/budgetStudenteInfoViewImpl';
import { ChiaveFlussoEnum } from '../model/chiaveFlussoEnum';
import { DettagliDocumentiAllegatiRequestDTO } from '../model/dettagliDocumentiAllegatiRequestDTO';
import { EsitoRichiestaApprovazione } from '../model/esitoRichiestaApprovazione';
import { EsitoRichiestaApprovazioneByCoordinatore } from '../model/esitoRichiestaApprovazioneByCoordinatore';
import { EsitoRichiestaApprovazioneByGruppoPTA } from '../model/esitoRichiestaApprovazioneByGruppoPTA';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GetZipAllegatiRichiesteDiSpesaDTO } from '../model/getZipAllegatiRichiesteDiSpesaDTO';
import { PageSpesaStudentePerApprovMassivaInfoView } from '../model/pageSpesaStudentePerApprovMassivaInfoView';
import { SpesaStudenteStatus } from '../model/spesaStudenteStatus';
import { SpesaStudenteType } from '../model/spesaStudenteType';
import { TipoFondoDiRiferimento } from '../model/tipoFondoDiRiferimento';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SpeseStudentiCicloService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette ad un coordinatore di accettare o rifiutare una richiesta di spesa di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaSpesaStudenteRequestByCoordinatoreForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneByCoordinatore, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public accettaRifiutaSpesaStudenteRequestByCoordinatoreForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneByCoordinatore, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestByCoordinatoreForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneByCoordinatore, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestByCoordinatoreForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneByCoordinatore, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaSpesaStudenteRequestByCoordinatore.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling accettaRifiutaSpesaStudenteRequestByCoordinatore.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/${encodeURIComponent(String(codice_spesa))}/coordinatore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un membro del Gruppo PTA di accettare o rifiutare una richiesta di spesa di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaSpesaStudenteRequestByGruppoPTAForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneByGruppoPTA, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public accettaRifiutaSpesaStudenteRequestByGruppoPTAForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneByGruppoPTA, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestByGruppoPTAForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneByGruppoPTA, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestByGruppoPTAForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneByGruppoPTA, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaSpesaStudenteRequestByGruppoPTA.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling accettaRifiutaSpesaStudenteRequestByGruppoPTA.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/${encodeURIComponent(String(codice_spesa))}/gruppo-pta`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore di accettare o rifiutare una richiesta di spesa di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaSpesaStudenteRequestBySupervisoreForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public accettaRifiutaSpesaStudenteRequestBySupervisoreForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestBySupervisoreForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestBySupervisoreForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaSpesaStudenteRequestBySupervisore.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling accettaRifiutaSpesaStudenteRequestBySupervisore.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/${encodeURIComponent(String(codice_spesa))}/supervisore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge una richiesta di spesa sul budget di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param addSpesaStudenteRequestDTO 
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addSpesaStudenteRequestForm(id_utente: string, addSpesaStudenteRequestDTO?: AddSpesaStudenteRequestDTO, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public addSpesaStudenteRequestForm(id_utente: string, addSpesaStudenteRequestDTO?: AddSpesaStudenteRequestDTO, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public addSpesaStudenteRequestForm(id_utente: string, addSpesaStudenteRequestDTO?: AddSpesaStudenteRequestDTO, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public addSpesaStudenteRequestForm(id_utente: string, addSpesaStudenteRequestDTO?: AddSpesaStudenteRequestDTO, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addSpesaStudenteRequest.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (addSpesaStudenteRequestDTO !== undefined) {
            formParams = formParams.append('addSpesaStudenteRequestDTO', new Blob([JSON.stringify(addSpesaStudenteRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/budget/spesa`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un Direttore di Dipartimento di autorizzare o rifiutare una richiesta di spesa di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public autorizzaSpesaStudenteRequestByDirettoreDiDipartimentoForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public autorizzaSpesaStudenteRequestByDirettoreDiDipartimentoForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public autorizzaSpesaStudenteRequestByDirettoreDiDipartimentoForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public autorizzaSpesaStudenteRequestByDirettoreDiDipartimentoForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling autorizzaSpesaStudenteRequestByDirettoreDiDipartimento.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling autorizzaSpesaStudenteRequestByDirettoreDiDipartimento.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/${encodeURIComponent(String(codice_spesa))}/direttore-di-dipartimento/autorizza`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato presente in una richiesta di spesa di uno studente.
     * 
     * @param codice_spesa 
     * @param idAllegato id dell&#x27;allegato
     * @param idUtente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoSpesaStudente(codice_spesa: string, idAllegato: string, idUtente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoSpesaStudente(codice_spesa: string, idAllegato: string, idUtente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoSpesaStudente(codice_spesa: string, idAllegato: string, idUtente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoSpesaStudente(codice_spesa: string, idAllegato: string, idUtente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling getAllegatoSpesaStudente.');
        }

        if (idAllegato === null || idAllegato === undefined) {
            throw new Error('Required parameter idAllegato was null or undefined when calling getAllegatoSpesaStudente.');
        }

        if (idUtente === null || idUtente === undefined) {
            throw new Error('Required parameter idUtente was null or undefined when calling getAllegatoSpesaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idAllegato !== undefined && idAllegato !== null) {
            queryParameters = queryParameters.set('idAllegato', <any>idAllegato);
        }
        if (idUtente !== undefined && idUtente !== null) {
            queryParameters = queryParameters.set('idUtente', <any>idUtente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/${encodeURIComponent(String(codice_spesa))}/allegati`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore di accettare o rifiutare una richiesta di spesa di uno studente.
     * 
     * @param codice_spesa codice univoco della spesa richiesta
     * @param idUtente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDettaglioSpesaStudente(codice_spesa: string, idUtente: string, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoViewImpl>;
    public getDettaglioSpesaStudente(codice_spesa: string, idUtente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoViewImpl>>;
    public getDettaglioSpesaStudente(codice_spesa: string, idUtente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoViewImpl>>;
    public getDettaglioSpesaStudente(codice_spesa: string, idUtente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling getDettaglioSpesaStudente.');
        }

        if (idUtente === null || idUtente === undefined) {
            throw new Error('Required parameter idUtente was null or undefined when calling getDettaglioSpesaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idUtente !== undefined && idUtente !== null) {
            queryParameters = queryParameters.set('idUtente', <any>idUtente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BudgetStudenteInfoViewImpl>('get',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/${encodeURIComponent(String(codice_spesa))}/dettaglio`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;allegato di una richiesta di spesa studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZipAllegatiRichiestaDiSpesaStudente(id_utente: string, codice_spesa: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getZipAllegatiRichiestaDiSpesaStudente(id_utente: string, codice_spesa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getZipAllegatiRichiestaDiSpesaStudente(id_utente: string, codice_spesa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getZipAllegatiRichiestaDiSpesaStudente(id_utente: string, codice_spesa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getZipAllegatiRichiestaDiSpesaStudente.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling getZipAllegatiRichiestaDiSpesaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/${encodeURIComponent(String(codice_spesa))}/get-zip-allegati-richiesta`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Consente di scaricare un file zip contenente gli allegati delle richieste di spesa a partire dalla lista di codici spesa in input.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZipAllegatiRichiesteDiSpesa(body: GetZipAllegatiRichiesteDiSpesaDTO, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getZipAllegatiRichiesteDiSpesa(body: GetZipAllegatiRichiesteDiSpesaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getZipAllegatiRichiesteDiSpesa(body: GetZipAllegatiRichiesteDiSpesaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getZipAllegatiRichiesteDiSpesa(body: GetZipAllegatiRichiesteDiSpesaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getZipAllegatiRichiesteDiSpesa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/zip-allegati-spese`,
            {
             responseType: "blob" as "json",
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista paginata delle spese degli studenti con i filtri applicati.
     * 
     * @param cfStudente 
     * @param chiaveFlusso 
     * @param codiceSpesa 
     * @param codiciSpesa 
     * @param descrizioneFondoDiRiferimento 
     * @param descrizioneNoteSpesa 
     * @param emailStudente 
     * @param hasPermessiApprovativiCosupervisore 
     * @param matricolaStudente 
     * @param nominativoStudente 
     * @param statiSpesa 
     * @param tipiFondoDiRiferimento 
     * @param tipologiaSpesa 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSpeseStudentiCicloForm(cfStudente?: string, chiaveFlusso?: ChiaveFlussoEnum, codiceSpesa?: string, codiciSpesa?: Array<string>, descrizioneFondoDiRiferimento?: string, descrizioneNoteSpesa?: string, emailStudente?: string, hasPermessiApprovativiCosupervisore?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiSpesa?: Array<SpesaStudenteStatus>, tipiFondoDiRiferimento?: Array<TipoFondoDiRiferimento>, tipologiaSpesa?: SpesaStudenteType, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageSpesaStudentePerApprovMassivaInfoView>;
    public searchSpeseStudentiCicloForm(cfStudente?: string, chiaveFlusso?: ChiaveFlussoEnum, codiceSpesa?: string, codiciSpesa?: Array<string>, descrizioneFondoDiRiferimento?: string, descrizioneNoteSpesa?: string, emailStudente?: string, hasPermessiApprovativiCosupervisore?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiSpesa?: Array<SpesaStudenteStatus>, tipiFondoDiRiferimento?: Array<TipoFondoDiRiferimento>, tipologiaSpesa?: SpesaStudenteType, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSpesaStudentePerApprovMassivaInfoView>>;
    public searchSpeseStudentiCicloForm(cfStudente?: string, chiaveFlusso?: ChiaveFlussoEnum, codiceSpesa?: string, codiciSpesa?: Array<string>, descrizioneFondoDiRiferimento?: string, descrizioneNoteSpesa?: string, emailStudente?: string, hasPermessiApprovativiCosupervisore?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiSpesa?: Array<SpesaStudenteStatus>, tipiFondoDiRiferimento?: Array<TipoFondoDiRiferimento>, tipologiaSpesa?: SpesaStudenteType, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSpesaStudentePerApprovMassivaInfoView>>;
    public searchSpeseStudentiCicloForm(cfStudente?: string, chiaveFlusso?: ChiaveFlussoEnum, codiceSpesa?: string, codiciSpesa?: Array<string>, descrizioneFondoDiRiferimento?: string, descrizioneNoteSpesa?: string, emailStudente?: string, hasPermessiApprovativiCosupervisore?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiSpesa?: Array<SpesaStudenteStatus>, tipiFondoDiRiferimento?: Array<TipoFondoDiRiferimento>, tipologiaSpesa?: SpesaStudenteType, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (cfStudente !== undefined) {
            formParams = formParams.append('cfStudente', <any>cfStudente) as any || formParams;
        }
        if (chiaveFlusso !== undefined) {
            formParams = formParams.append('chiaveFlusso', <any>chiaveFlusso) as any || formParams;
        }
        if (codiceSpesa !== undefined) {
            formParams = formParams.append('codiceSpesa', <any>codiceSpesa) as any || formParams;
        }
        if (codiciSpesa) {
            codiciSpesa.forEach((element) => {
                formParams = formParams.append('codiciSpesa', <any>element) as any || formParams;
            })
        }
        if (descrizioneFondoDiRiferimento !== undefined) {
            formParams = formParams.append('descrizioneFondoDiRiferimento', <any>descrizioneFondoDiRiferimento) as any || formParams;
        }
        if (descrizioneNoteSpesa !== undefined) {
            formParams = formParams.append('descrizioneNoteSpesa', <any>descrizioneNoteSpesa) as any || formParams;
        }
        if (emailStudente !== undefined) {
            formParams = formParams.append('emailStudente', <any>emailStudente) as any || formParams;
        }
        if (hasPermessiApprovativiCosupervisore !== undefined) {
            formParams = formParams.append('hasPermessiApprovativiCosupervisore', <any>hasPermessiApprovativiCosupervisore) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }
        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (statiSpesa) {
            statiSpesa.forEach((element) => {
                formParams = formParams.append('statiSpesa', <any>element) as any || formParams;
            })
        }
        if (tipiFondoDiRiferimento) {
            tipiFondoDiRiferimento.forEach((element) => {
                formParams = formParams.append('tipiFondoDiRiferimento', <any>element) as any || formParams;
            })
        }
        if (tipologiaSpesa !== undefined) {
            formParams = formParams.append('tipologiaSpesa', <any>tipologiaSpesa) as any || formParams;
        }

        return this.httpClient.request<PageSpesaStudentePerApprovMassivaInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un Direttore di Dipartimento di validare o rifiutare una richiesta di spesa di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaSpesaStudenteRequestByDirettoreDiDipartimentoForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public validaSpesaStudenteRequestByDirettoreDiDipartimentoForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public validaSpesaStudenteRequestByDirettoreDiDipartimentoForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public validaSpesaStudenteRequestByDirettoreDiDipartimentoForm(id_utente: string, codice_spesa: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling validaSpesaStudenteRequestByDirettoreDiDipartimento.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling validaSpesaStudenteRequestByDirettoreDiDipartimento.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/budget/spesa/${encodeURIComponent(String(codice_spesa))}/direttore-di-dipartimento/valida`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
