import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {
    AddDeliberaDTO,
    AttivitaOffertaFormativaInfoView,
    AttivitaTrasversaleStatus,
    AuthorityType,
    CorsoPianoDiStudiPerExportViewImpl,
    DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService,
    PageDeliberaInfoViewImpl, TipoPeriodoEnum, UnitaDiMisuraAttivita
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, startWith, Subscription, switchMap, take, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {
    formGroupConfigInterface,
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApprovazioneTrasversaliMassivaDataI} from '../approvazione-trasversale-steps-interface';
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get, orderBy} from "lodash";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {buildFormConfigForAddNewDelibera} from "../../../gestione-documenti/gestione-documenti-utils";
import {optionFile} from "../../../../../shared/costants/app-constants";
import * as fs from "file-saver";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";

export interface TrasversaliCompileInfo extends CorsoPianoDiStudiPerExportViewImpl{
    studenteUIMapped: {
        utenteNome?: string;
        utenteCognome?: string;
    };
    id: string;
}


@Component({
    selector: 'app-info-approvazione-trasversali-stepper',
    templateUrl: './compila-info-approvazione-trasversali.component.html',
    styleUrls: ['./compila-info-approvazione-trasversali.component.scss']
})
export class CompilaInfoApprovazioneTrasversaliComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit {

    loading: boolean;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    corso: AttivitaOffertaFormativaInfoView = undefined;
    form: FormGroup;
    attivitaSelected: CorsoPianoDiStudiPerExportViewImpl[];
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';
    withDelibera: boolean;
    trasversaliCompileInfos: TrasversaliCompileInfo[];
    files: Map<string, File>;
    private formControlsSub: Subscription;
    formArray: FormArray;

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                protected cicloConfigurationService: CicloConfigurationService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneTrasversaliMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneTrasversaliMassivaDataI) => {
            this.withDelibera = inputOutputData?.showWithDelibera;
            this.initForm(inputOutputData?.deliberaCollegio?.id);
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshData){
                this.canGoNext$.next(false);
            }
            // if trasversali selected changes reset delibera form
            if(inputOutputData.attivitaSelected !== this.attivitaSelected){
                this.attivitaSelected = inputOutputData?.attivitaSelected;
                this.form.get('deliberaCollegio').setValue(inputOutputData.deliberaCollegio?.id);
                if(this.currentProfile === AuthorityType.COSUPERVISORE || this.currentProfile === AuthorityType.SUPERVISORE) {
                    this.canGoNext$.next(this.formArray?.valid);
                    this.trasversaliCompileInfos = this.buildCompileInfoConfiguration(this.attivitaSelected || []);
                }
            }
        });
    }

    ngAfterViewInit() {
        if(this.currentProfile !== AuthorityType.COSUPERVISORE && this.currentProfile !== AuthorityType.SUPERVISORE){
            this.getDelibereRequest();
        }
    }

    private buildCompileInfoConfiguration(trasversaliInfoViews:  CorsoPianoDiStudiPerExportViewImpl[]): TrasversaliCompileInfo[] {
        this.files = new Map<string, File>();
        const trasversali = trasversaliInfoViews
            ?.map(att => ({
                ...att,
                id: att.idAttivitaPresunta ?? att.idAttivitaProposta ?? att.idAttivitaTrasversale,
                studenteUIMapped: {
                    utenteNome: att?.utenteNome,
                    utenteCognome: att?.utenteCognome,
                    nome: att?.utenteNome,
                    cognome: att?.utenteCognome,
                    utenteMail: att?.utenteMail?.toLowerCase(),
                    studenteCodiceFiscale: att?.studenteCodiceFiscale?.toUpperCase(),
                    codiceFiscale: att?.studenteCodiceFiscale?.toUpperCase(),
                    studenteMatricola: att?.studenteMatricola,
                    urlImmagineProfilo:  att?.urlImmagineProfilo,
                    utenteTelefono: att?.utenteTelefono,
                },
            }));
        this.formArray = this.fb.array([]);
        trasversali?.forEach(att => this.formArray.push(this.fb.group({
            file: [undefined, att.statoAttivitaTrasversale === AttivitaTrasversaleStatus.RICHIESTASUPERAMENTOCONDOCUMENTOSUPERVISORE
                || att.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESADOCUMENTOSUPERVISORE ? Validators.required : undefined],
            ore: undefined,
            cfu: [att.cfuPrevisti, Validators.required],
            data: [moment().toDate(), Validators.required],
            nota: undefined,
            id: att.id,
        })));
        this.canGoNext$.next(this.formArray.valid);
        this.formControlsSub?.unsubscribe();
        this.formControlsSub = this.formArray.valueChanges.pipe(startWith(this.formArray.value)).subscribe(fa => {
            this.canGoNext$.next(this.formArray.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$.getValue(),
                compileInfo: fa,
            });
        });
        return trasversali ?? [];
    }

    downloadFile(filename: string, idPeriodo: string) {
        fs.saveAs(this.files.get(idPeriodo), filename);
    }

    uploadFile(file: File, idTrasv: string) {
        const extension = file?.name?.substring(file?.name?.lastIndexOf('.')+1);
        const renamedFile = new File([file], idTrasv + '.' + extension, {type: file.type});
        this.files.set(idTrasv, renamedFile);
        this.formArray.at(this.trasversaliCompileInfos.findIndex(ci => ci.id === idTrasv))
            .get('file').setValue(idTrasv);
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            compileInfoFiles: this.files,
        });

    }

    deleteFile(idTrasv: string) {
        this.files.delete(idTrasv);
        this.formArray.at(this.trasversaliCompileInfos.findIndex(ci => ci.id === idTrasv))
            .get('file').setValue(undefined);
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
        });
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    private initForm(deliberaCollegio?: string) {
        this.form = this.fb.group({
            deliberaCollegio: [deliberaCollegio, (!this.withDelibera && this.currentProfile !== AuthorityType.COSUPERVISORE && this.currentProfile !== AuthorityType.SUPERVISORE)
                ? Validators.required : undefined]
        });
        this.canGoNext$.next(this.form.valid && (
            (this.currentProfile !== AuthorityType.COSUPERVISORE && this.currentProfile !== AuthorityType.SUPERVISORE)
            || !this.formArray?.invalid
        ));
        this.form.get('deliberaCollegio').valueChanges.subscribe(deliberaId => {
            this.canGoNext$.next(
                (deliberaId && this.currentProfile !== AuthorityType.COSUPERVISORE && this.currentProfile !== AuthorityType.SUPERVISORE)
                || this.formArray?.valid
            );
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                deliberaCollegio: this.delibere?.find(d => d.id === deliberaId)
            });
        })
    }


    openAddNewDeliberaDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'common.add_delibera',
            //message: get(translation, 'mobility.approve_message', null),
            icon: {
                show: true,
                name: 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.addNewDeliberaRequest(form, form.getRawValue(), dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: buildFormConfigForAddNewDelibera(),
            valueForm: {
                fileDelibera: undefined,
                dataDelibera: undefined,
                numeroDelibera: undefined,
                fileNotaTrasmissione: undefined,
                nota: undefined,

            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }


    private addNewDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>) {
        console.log(rawValue)
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera,
            note: rawValue.nota
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera, rawValue.fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_added', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                this.delibere.push(addedDelibera);
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
                this.form?.get('deliberaCollegio')?.patchValue(addedDelibera.id);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.addNewDeliberaRequest(form, rawValue, dialogRef), 'dialog.cancel',
                    err?.error?.message);
            }
        });

    }

    private getDelibereRequest() {
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.searchDelibereForm()),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (delibere: PageDeliberaInfoViewImpl) => {
                this.delibere = delibere?.content || [];
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.getDelibereRequest(), 'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    formatDeliberaDate(dateFromBE: string): string {
        return moment(dateFromBE).format(this.outputFormatData);
    }


    protected readonly AuthorityType = AuthorityType;
    protected readonly optionFile = optionFile;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
    protected readonly AttivitaTrasversaleStatus = AttivitaTrasversaleStatus;

    getFormGroupByAttId(id: string) {
        return this.formArray.at(this.trasversaliCompileInfos?.findIndex(ci => ci.id === id));
    }

    protected readonly UnitaDiMisuraAttivita = UnitaDiMisuraAttivita;
}
