import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, Observable, of, takeUntil} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {MediaService} from '../../../api-clients/generated/services';
import {AbstractDefaultComponent} from '../abstracts/abstract-default-component/abstract-default-component';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ImageManagerService {
    checkCurrentAvatar$ = new BehaviorSubject(this.getCurrentAvatar());
    private currentAvatar: SafeUrl | undefined;


    constructor(private mediaService: MediaService,
                private sanitizer: DomSanitizer) {
    }

    getCurrentAvatar(): SafeUrl {
        return this.currentAvatar;
    }

    setCurrentAvatar(image: SafeUrl): void {
        this.currentAvatar = image;
        this.checkCurrentAvatar$.next(image);
    }

    removeCurrentAvatar(): void {
        this.currentAvatar = undefined;
        this.checkCurrentAvatar$.next(undefined);
    }

    getProfileImage(nomeFile?: string, codiceFiscaleUserTarget?: string): Observable<Blob | boolean> {
        if (!!nomeFile && !!codiceFiscaleUserTarget) {
            return this.mediaService.getImmagineProfiloForm(codiceFiscaleUserTarget, nomeFile)
                .pipe(
                    tap((value) => {
                        if (!!value) {
                            const objectURL = URL.createObjectURL(value);
                            const image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                            this.setCurrentAvatar(image);
                        }
                    }),
                    catchError(() => of(true))
                );
        } else {
            return of(true);
        }
    }
}
