/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum di tutti gli stati gestiti
 */
export type StatoGenericoEnum = 'INSERITO' | 'RIFIUTATA' | 'RIFIUTATO' | 'APPROVATA_PARZIALE' | 'APPROVATO_PARZIALE' | 'APPROVATA' | 'APPROVATO' | 'APPROVATO_PTA' | 'APPROVATO_PTA_ATENEO_MOBILITA_RICHIESTE' | 'RICHIESTA_CONCLUSIONE' | 'RICHIESTA_CONCLUSIONE_RIFIUTATA' | 'RICHIESTA_CONCLUSIONE_APPROVATA_PARZIALMENTE' | 'CONCLUSO' | 'SOSPESO' | 'ANNULLATO' | 'APPROVATA_DIRETTORE_DI_DIPARTIMENTO' | 'AUTORIZZATO_DIRETTORE_DI_DIPARTIMENTO' | 'VALIDATO_DIRETTORE_DI_DIPARTIMENTO' | 'DA_APPROVARE' | 'RICHIESTA_LIQUIDAZIONE' | 'RICHIESTA_LIQUIDAZIONE_RIFIUTATA' | 'LIQUIDATA' | 'RICHIESTA_APPROVAZIONE';

export const StatoGenericoEnum = {
    INSERITO: 'INSERITO' as StatoGenericoEnum,
    RIFIUTATA: 'RIFIUTATA' as StatoGenericoEnum,
    RIFIUTATO: 'RIFIUTATO' as StatoGenericoEnum,
    APPROVATAPARZIALE: 'APPROVATA_PARZIALE' as StatoGenericoEnum,
    APPROVATOPARZIALE: 'APPROVATO_PARZIALE' as StatoGenericoEnum,
    APPROVATA: 'APPROVATA' as StatoGenericoEnum,
    APPROVATO: 'APPROVATO' as StatoGenericoEnum,
    APPROVATOPTA: 'APPROVATO_PTA' as StatoGenericoEnum,
    APPROVATOPTAATENEOMOBILITARICHIESTE: 'APPROVATO_PTA_ATENEO_MOBILITA_RICHIESTE' as StatoGenericoEnum,
    RICHIESTACONCLUSIONE: 'RICHIESTA_CONCLUSIONE' as StatoGenericoEnum,
    RICHIESTACONCLUSIONERIFIUTATA: 'RICHIESTA_CONCLUSIONE_RIFIUTATA' as StatoGenericoEnum,
    RICHIESTACONCLUSIONEAPPROVATAPARZIALMENTE: 'RICHIESTA_CONCLUSIONE_APPROVATA_PARZIALMENTE' as StatoGenericoEnum,
    CONCLUSO: 'CONCLUSO' as StatoGenericoEnum,
    SOSPESO: 'SOSPESO' as StatoGenericoEnum,
    ANNULLATO: 'ANNULLATO' as StatoGenericoEnum,
    APPROVATADIRETTOREDIDIPARTIMENTO: 'APPROVATA_DIRETTORE_DI_DIPARTIMENTO' as StatoGenericoEnum,
    AUTORIZZATODIRETTOREDIDIPARTIMENTO: 'AUTORIZZATO_DIRETTORE_DI_DIPARTIMENTO' as StatoGenericoEnum,
    VALIDATODIRETTOREDIDIPARTIMENTO: 'VALIDATO_DIRETTORE_DI_DIPARTIMENTO' as StatoGenericoEnum,
    DAAPPROVARE: 'DA_APPROVARE' as StatoGenericoEnum,
    RICHIESTALIQUIDAZIONE: 'RICHIESTA_LIQUIDAZIONE' as StatoGenericoEnum,
    RICHIESTALIQUIDAZIONERIFIUTATA: 'RICHIESTA_LIQUIDAZIONE_RIFIUTATA' as StatoGenericoEnum,
    LIQUIDATA: 'LIQUIDATA' as StatoGenericoEnum,
    RICHIESTAAPPROVAZIONE: 'RICHIESTA_APPROVAZIONE' as StatoGenericoEnum
};