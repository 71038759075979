import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AuthorityType, ChiaveFlussoEnum, ChiaveOperazioneEnum, DeliberaInfoView,
    EsitoRichiestaApprovazioneMobilitaSEByCoordinatore,
    MediaService,
    MissioneStatus, MobilitaStudentiCicloService, NuovaConfigurazioneOperazione,
    PagePeriodoDiMobilitaStudenteInfoView,
    PeriodiDiMobilitaService,
    PeriodoDiMobilitaStudenteInfoView,
    PeriodoDiMobilitaStudenteStatus,
    RichiestaDiRetribuzioneMobilitaStatus,
    StudentiCicloService,
    TipoPeriodoEnum,
    UserCodiceFiscaleNomeCognomeView
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {
    BehaviorSubject,
    catchError,
    finalize,
    map,
    Observable,
    ObservedValueOf,
    of,
    OperatorFunction,
    take,
    takeUntil
} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {get, isEmpty} from "lodash";
import * as moment from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {
    getLanguagePathByMobilitaStatus,
    getLanguagePathByRetribuzioneMobilitaStatus, openFileInBlankWindow
} from "../../../../../shared/utils/utils";
import {ApprovazioneMobilitaMassivaDataI} from "../approvazione-mobilita-steps-interface";
import {MobilitaFilters} from "../../../../../shared/service/mobilita-filter-manager.service";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    MobilitaFilterManagerRequestService
} from "../../../requests/approvazione-periodi-di-mobilita-ricerca/mobilita-sidebar-filter-container/mobilita-filter-manager-request.service";
import {
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoManagerService} from "../../../../../shared/service/dialog-info-manager.service";
import {APP_DEFAULT_PAGE_SIZE, LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {HttpResponse} from "@angular/common/http";
import {StudentDetailsService} from "../../../student-details/student-details.service";
import {AttachmentsInfoDialogService} from "../../../../../shared/service/attachments-info-dialog.service";
import {flussoStatusToLabelFn} from "../../../../../shared/pipe/flusso-status-to-label.pipe";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";


@Component({
    selector: 'app-scegli-periodi-mobilita-stepper',
    templateUrl: './scegli-periodi-mobilita.component.html',
    styleUrls: ['./scegli-periodi-mobilita.component.scss']
})
export class ScegliPeriodiMobilitaComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    loading: boolean;
    periodsConfiguration: GenericTableConfigurationModel;
    private corso: any;
    private pageSize: number;
    private fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    showTopbar: boolean;
    defStatus: PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus.APPROVATOPARZIALE;
    idRigheSolaLettura: string[] = [];
    disabledPeriodsCodiciMobilita: string[];
    operationConfig: NuovaConfigurazioneOperazione;

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                private filterManagerService: MobilitaFilterManagerRequestService,
                private logOutService: LogoutService,
                private appInitService: AppInitService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private cicloConfigurationService: CicloConfigurationService,
                private mobilitaStudentiCicloService: MobilitaStudentiCicloService,
                private dialog: MatDialog,
                private router: Router,
                private dialogInfoManagerService: DialogInfoManagerService,
                private localStorageService: LocalStorageService,
                private attachmentsInfoDialogService: AttachmentsInfoDialogService,
                private studentDetailsService: StudentDetailsService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operationConfig = this.cicloConfigurationService.getOperationCorsoConfig(
            ChiaveFlussoEnum.RichiestaMobilita,
            this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
            undefined,
            true,
        ) as NuovaConfigurazioneOperazione;
        this.isFirstTime = true;
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.getDataAndFilterSubscribe();
        this.operazioneMassivaData$?.subscribe(inputOutputData => {
            // when confirm step forces refresh get the data and subscribe to filters
            if (inputOutputData.refreshPeriodi) {
                console.log('REFRESHING STUDENTS IN SCEGLI PERIODI')
                this.periodsConfiguration = undefined;
                this.idRigheSolaLettura = [];
                this.resetRefreshPeriodi();
                this.canGoNext$.next(false);
                this.getListaPeriodiRequest$(false, this.getMainFormGroup().getRawValue());
            }
        });
    }

    getListaPeriodiRequest$(isFirstTime?: boolean, filters?: MobilitaFilters, page: number = 0, size: number = this.localStorageService.pageSize): void {
        this.getPeriodiFormObs$(isFirstTime, filters, page, size).subscribe({
            next: (value) => {
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {
                        this.logOutService.goToHome()
                    },
                    () => this.getListaPeriodiRequest$(isFirstTime, filters, page, size),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getPeriodiFormObs$(isFirstTime?: boolean, filters?: MobilitaFilters, page: number = 0, size: number = this.localStorageService.pageSize): Observable<PagePeriodoDiMobilitaStudenteInfoView> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.periodiDiMobilitaService.getPeriodiDiMobilitaStudenteForm(
            !!filters?.annoAccademico ? filters.annoAccademico : undefined,
            undefined,
            !!filters?.codiceMobilita ? filters.codiceMobilita : undefined,
            !!filters?.codiceNazioneStruttura ? filters.codiceNazioneStruttura : undefined,
            !!filters?.codiceTipoInterventoSupporto ? filters.codiceTipoInterventoSupporto : undefined,
            !!filters?.codiceTipologiaMobilita ? filters.codiceTipologiaMobilita : undefined,
            !!filters?.dataUltimaModifica ? moment(filters.dataUltimaModifica).format('DD/MM/YYYY HH:mm') as unknown as Date : undefined,
            !!filters?.dataFineNull ? filters.dataFineNull : undefined,
            this.currentProfile === AuthorityType.COSUPERVISORE ? true : undefined,
            undefined,
            undefined,
            !!filters?.nominativoStrutturaEsteraOspitante ? filters.nominativoStrutturaEsteraOspitante : undefined,
            !!filters?.nominativoStudente ? filters.nominativoStudente : undefined,
            !!filters?.codiceTipologiaBonus ? filters.codiceTipologiaBonus : undefined,
            undefined,
            this.operationConfig.stati_in?.map(si => si.stato) as PeriodoDiMobilitaStudenteStatus[],
            !!filters?.periodoA ? filters.periodoA : undefined,
            !!filters?.periodoDa ? filters.periodoDa : undefined,
            filters?.richiesteDiRetribuzioneStatus?.length > 0 ? filters?.richiesteDiRetribuzioneStatus : undefined,
            !!filters?.statoSvolgimento ? filters?.statoSvolgimento : undefined,
            TipoPeriodoEnum.SOGGIORNOESTERO,
            page, size, undefined
        ).pipe(
            tap((pagePeriodi) => {
                this.periodsConfiguration = this.buildListaPeriodiConfiguration(pagePeriodi, size,
                    page, filters?.richiesteDiRetribuzioneStatus?.length > 0 ? filters?.richiesteDiRetribuzioneStatus : undefined);
                this.currentFilterListChipLabels = this.getListChipsLabel();
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }



    buildListaPeriodiConfiguration(pagePeriodi: PagePeriodoDiMobilitaStudenteInfoView,
                                   pageSize: number = APP_DEFAULT_PAGE_SIZE,
                                   page?: number,
                                   filterStatiRetribuzione?: RichiestaDiRetribuzioneMobilitaStatus[]): GenericTableConfigurationModel {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const disabledPeriodsCodiciMobilita = pagePeriodi?.content
            ?.filter(p => p.codiceMobilitaPadre)
            ?.map(p => p.codiceMobilita) ?? [];
        this.idRigheSolaLettura = [...(this.idRigheSolaLettura ?? []), ...disabledPeriodsCodiciMobilita];
        let periodiDiMobilitaStudenteForUI = pagePeriodi?.content?.map((periodo: PeriodoDiMobilitaStudenteInfoView) =>
            ({
                ...periodo,
                strutturaEsteraOspitanteDescrizione: (periodo?.strutturaEsteraOspitante?.descrizione || periodo?.strutturaEsteraNonCodificata?.descrizione)
                    + ' (' + periodo?.nazioneStrutturaEstera?.codice + ')',
                tipologiaMobilitaDescrizione: periodo?.tipologiaMobilita?.descrizione,
                tipologiaBonusDescrizione: periodo?.tipologiaBonus?.descrizioneTipologiaBonus,
                dataRichiestaFormatted: moment(periodo?.dataRichiesta).format('DD/MM/YYYY HH:mm'),
                dataInizio: !!periodo?.dataInizio ? moment(periodo?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                dataFine: !!periodo?.dataFine ? moment(periodo?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                studenteUIMapped: [{
                    ...periodo?.studenteCiclo,
                    nome: periodo?.studenteCiclo?.utente?.nome,
                    cognome: periodo?.studenteCiclo?.utente?.cognome,
                    codiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale
                }],
                codiceMobilitaFormatted: periodo.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO ?
                    (periodo.codiceMobilita + (periodo.codiceMobilitaPadre ? (' (' + get(translation, 'mobility.associated_research_code', null) + ' ' + periodo.codiceMobilitaPadre + ')') : '')) :
                    (periodo.codiceMobilita + (periodo.codiceMobilitaFiglio ? (' (' + get(translation, 'mobility.associated_mobility_code', null) + ' ' + periodo.codiceMobilitaFiglio + ')') : '')),
                richiesteRetribuzione: periodo.richiesteDiRetribuzioneMobilita
                    ?.filter(val => !filterStatiRetribuzione || filterStatiRetribuzione.includes(val.stato))
                    ?.map(periodoRetribuzione => ({
                    ...periodoRetribuzione,
                    periodiFormatted: periodoRetribuzione.periodi?.sort(
                        (p1, p2) =>
                            moment(p1.dataInizio, 'YYYY-MM-DD').unix() - moment(p2.dataInizio, 'YYYY-MM-DD').unix()
                    )?.map(p =>
                        get(translation, 'common.from', null) + ' ' +
                        moment(p?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' +
                        get(translation, 'common.to', null) + ' ' +
                        moment(p?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY'))?.join(', ')
                }))
            }));
        return {
            configuration: {
                pageBE: page,
                data: periodiDiMobilitaStudenteForUI,
                totalElements: pagePeriodi?.totalElements,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 30, 40, 50],
                isPaginatedBE: true,
                sticky: true,
                selection: {
                    isSelectable: true,
                    selectedColumnKeyToShow: 'codiceMobilita',
                    selectedColumnKeyPrimaryKey: 'codiceMobilita',
                },
                disabledCssClass: 'generic-table-disabled-row',
                highlightRowWhen: (element) => (this.idRigheSolaLettura?.includes(element.codiceMobilita)
                    && !element.codiceMobilitaPadre) ? 'generic-table-removed-row' : undefined,
                disabledRowTooltip: (element) => !element.codiceMobilitaPadre ?
                    'massive_operations.refused_request' : 'massive_operations.op_must_be_executed_on_associated_period',
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.mobility_code',
                        colonnaKey: 'codiceMobilitaFormatted',
                        flex: 11,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.mobility_type',
                        colonnaKey: 'tipologiaMobilitaDescrizione',
                        flex: 11,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'mobility.student',
                        colonnaKey: 'studenteUIMapped',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS_FROM_CONFIG,
                        nomeColonna: 'mobility.state',
                        colonnaKey: 'stato',
                        flex: 10,
                        statusTypeChiaveFlusso: ChiaveFlussoEnum.RichiestaMobilita,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.start_date',
                        colonnaKey: 'dataInizio',
                        flex: 9,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.end_date',
                        colonnaKey: 'dataFine',
                        flex: 9,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.foreign_university',
                        colonnaKey: 'strutturaEsteraOspitanteDescrizione',
                        flex: 18,
                    },
                    {
                        nomeColonna: ' ',
                        colonnaKey: 'action',
                        flex: 9,
                        tipo: TipoColonnaEnum.ACTION_MENU,
                        valueCheck: 'l',
                        button: [
                            {
                                nameIconButton: 'event',
                                click: TipoClickEnum.GO_TO_MOBILITA_STUDENTE,
                                tooltip: 'common.go_to_mobility',
                                color: 'accent',
                                valueShow: 'stato'
                            },
                            {
                                nameIconButton: 'visibility',
                                click: TipoClickEnum.SHOW_REQUEST_ATTACHMENTS,
                                tooltip: 'mobility.show_request_attachments',
                                color: 'accent',
                                valueShow: 'stato'
                            },
                            {
                                nameIconButton: 'close',
                                click: TipoClickEnum.REFUSE_REQUEST,
                                tooltip: 'mobility.refuse',
                                valueShow: 'stato',
                                show: (_, period) => !period.codiceMobilitaPadre && !this.idRigheSolaLettura?.includes(period.codiceMobilita),
                                color: 'accent'
                            }
                        ]
                    }
                ],
                css: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data',
            }
        };
    }


    tableClickAction($event: ClickEvent) {
        switch ($event?.tipoClick) {
            case TipoClickEnum.REFUSE_REQUEST:
                this.tryOpenRefuseDialog($event);
                break;
            case TipoClickEnum.CHIP_DETAIL:
                this.openInfoStudent($event.value?.utente);
                break;
            case TipoClickEnum.GO_TO_MOBILITA_STUDENTE:
                const periodoMobilita: PeriodoDiMobilitaStudenteInfoView = $event?.value;
                const url = this.router.serializeUrl(this.router.createUrlTree([PathEnum.STUDENTS, periodoMobilita?.studenteCiclo?.utente?.id, PathEnum.MOBILITA], {
                    fragment: periodoMobilita?.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO ? 'mobilita' : 'ricerca'
                }));
                window.open(url, '_blank');
                break;
            case TipoClickEnum.SHOW_REQUEST_ATTACHMENTS:
                const period = $event.value as PeriodoDiMobilitaStudenteInfoView;
                this.attachmentsInfoDialogService.openMobilityRequestAttachmentsDialog(
                    $event.value,
                    (idAllegato, nomeFile, delibera) =>
                        this.downloadPeriodDocument(idAllegato, nomeFile, delibera, period.codiceMobilita, period.studenteCiclo?.utente?.id));
                break;
        }
    }

    downloadPeriodDocument(idAllegato: string, nomeFile: string, delibera: DeliberaInfoView, codiceMobilita: string, idUtente: string) {
        if (delibera) {
            this.fuseConfirmationService.openDialogDelibera({
                delibera: delibera,
                codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
            });
        } else {
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.showLoader();
            this.mobilitaStudentiCicloService.getAllegatoPeriodoDiMobilitaStudente(codiceMobilita, idAllegato, idUtente, 'response').pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                    this.fuseConfirmationService.hideLoader();
                })
            ).subscribe({
                next: (fileResponse: HttpResponse<Blob>) => {
                    const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                    //const fileNameToDownload = makeFilename(fileName);
                    openFileInBlankWindow(fileResponse?.body, fileName);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'student.file_download_error', null),
                        type: SnackbarTypes.Warning,
                    });
                }
            });
        }
    }

    private openInfoStudent(studente: UserCodiceFiscaleNomeCognomeView) {
        if (!!studente.urlImmagineProfilo && !!studente.codiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente.codiceFiscale, studente.urlImmagineProfilo)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.dialogInfoManagerService.openStudenteInfoDialogUCFNCAV(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.dialogInfoManagerService.openStudenteInfoDialogUCFNCAV(studente);
        }
    }


    private tryOpenRefuseDialog(event: ClickEvent) {
        if(this.operazioneMassivaData$.getValue().periodiSelected?.find(
            s => s.codiceMobilita === event.value.codiceMobilita)){
            this.openDeselectFirstDialog();
        } else {
            this.openRefuseDialog(event.value)
        }
    }

    private openDeselectFirstDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'dialog.attention', null),
            message: get(translation, 'common.deselect_first', null),
            icon: {
                name: 'mat_solid:error_outline',
                color: 'error'
            },
            onBackdrop: {
                show: true,
                backdrop: false
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
            ]
        });
    }

    private openRefuseDialog(periodo: PeriodoDiMobilitaStudenteInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'mobility.refuse_request',
            message: get(translation, 'mobility.refuse_message', null),
            icon: {
                show: true,
                name: 'mat_outline:close',
                color: 'error'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.refuseRequest(form, periodo, form.getRawValue().motivo, dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'motivo',
                    transloco: 'budget.refuse_reason',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
            ],
            valueForm: {
                motivo: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private refuseRequest(form: FormGroup, periodo: PeriodoDiMobilitaStudenteInfoView, motivazioneRifiuto: string, dialogRef: MatDialogRef<GenericDialogComponent>) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let request;
        const documents = this.cicloConfigurationService.prepareDocsSubmitObjectFromNuoveConfig(
            ChiaveFlussoEnum.RichiestaMobilita,
            this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.RifiutoCoordinatore : ChiaveOperazioneEnum.RifiutoSupervisore,
            form.getRawValue(),
        );
        const esito: EsitoRichiestaApprovazioneMobilitaSEByCoordinatore = {
            approvato: false,
            motivazione_rifiuto: motivazioneRifiuto,
            dettagli_documenti_allegati: documents?.specifications,
        }
        request = this.currentProfile === AuthorityType.COORDINATORE ?
            this.mobilitaStudentiCicloService.accettaRifiutaPeriodoDiMobilitaSEByCoordinoreForm(
                periodo?.studenteCiclo?.utente?.id, periodo?.codiceMobilita, esito, documents?.files) :
            this.mobilitaStudentiCicloService.accettaRifiutaPeriodoDiMobilitaSEBySupervisoreForm(
                periodo?.studenteCiclo?.utente?.id, periodo?.codiceMobilita, esito, documents?.files);
        this.fuseConfirmationService.showLoader();
        form.disable();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => request),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
                form.enable();
            })
        ).subscribe({
            next: (periodi: PeriodoDiMobilitaStudenteInfoView[]) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'mobility.op_saved', null),
                    type: SnackbarTypes.Success,
                });
                this.idRigheSolaLettura = [...(this.idRigheSolaLettura ?? []), periodo.codiceMobilita];
                dialogRef.close();
            },
            error: (err) => {
                console.log(err)
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'mobility.error_during_op', null),
                    error: err, type: SnackbarTypes.Error,
                });
            }
        });
    }



    onPeriodsDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            periodiSelected: $event.selectedRows.length > 0 ? $event.selectedRows.map(row => row.data) as PeriodoDiMobilitaStudenteInfoView[] : undefined,
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.localStorageService.pageSize = $event.pageSize;
        this.getListaPeriodiRequest$(false, this.getMainFormGroup()?.value, page, this.localStorageService.pageSize);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    catchErrorInPeriodsList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.filterManagerService?.mainFormGroup?.getRawValue())) {
            const entries: [string, any][] = Object.entries(this.filterManagerService?.mainFormGroup?.getRawValue());
            const filteredEntries = entries?.filter(item => item.every(value => !!value))?.filter(item =>
                item[0] !== 'stato' && item[0] !== 'showOnlyApprovableRequests');
            return filteredEntries.map(([key, value]) => {
                const translatedKey: string = get(translation, this.fieldsLabelMap.get(key), key);
                if (key === 'codiceNazioneStruttura') {
                    value = this.filterManagerService.selectValues?.nazioniEstere?.find(n => n.codice === value)?.descrizione;
                } else if (key === 'codiceTipologiaMobilita') {
                    value = this.filterManagerService.selectValues?.tipologieMobilita?.find(n => n.codice === value)?.descrizione;
                } else if (key === 'codiceTipologiaBonus') {
                    value = this.filterManagerService.selectValues?.tipologieBonus?.find(n => n.numeroTipologiaBonus === value)?.descrizioneTipologiaBonus;
                } else if (key === 'codiceTipoInterventoSupporto') {
                    value = this.filterManagerService.selectValues?.tipiInterventoSupporto?.find(n => n.codice === value)?.descrizione;
                } else if (key === 'dataFineNull' || key === 'haverichiesteDiRetribuzioneStatus') {
                    return translatedKey;
                } else if (key === 'richiesteDiRetribuzioneStatus') {
                    value = value?.length > 0 ? value.map(v => get(translation, getLanguagePathByRetribuzioneMobilitaStatus(v))).join(', ') : '';
                } else if (key === 'dataUltimaModifica') {
                    value = moment(value, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
                } else if (key === 'stato') {
                    value = flussoStatusToLabelFn(this.cicloConfigurationService, value, ChiaveFlussoEnum.RichiestaMobilita);
                }
                return translatedKey + ': ' + value;
            });
        } else {
            return [];
        }
    }


    private resetRefreshPeriodi() {
        console.log(this.operazioneMassivaData$.getValue())
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshPeriodi: false
        })
    }

    private getDataAndFilterSubscribe() {
        if (this.isFirstTime) {
            this.getListaPeriodiRequest$(this.isFirstTime, this.getMainFormGroup().getRawValue());
        }
        this.filterManagerService.appliedFiltersObsevable$().pipe(
            filter(Boolean),
            switchMap(value => {
                if (!this.isFirstTime) {
                    return this.getPeriodiFormObs$(this.isFirstTime, this.getMainFormGroup().getRawValue(), 0, this.localStorageService.pageSize).pipe(
                        this.catchErrorInPeriodsList$(this.getPeriodiFormObs$(this.isFirstTime,  this.filterManagerService.filterApplied, 0, this.localStorageService.pageSize))
                    );
                }
                return of(null);
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.filterManagerService.filterApplied$.next(this.getMainFormGroup().getRawValue());
                    this.filterManagerService.filterApplied = this.getMainFormGroup().getRawValue();
                }
            }
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    openFilters() {
        this.filterManagerService.openDrawer.next(true)
    }

    resetFilters(): void {
        this.getMainFormGroup()?.reset();
        this.filterManagerService.filterApplied = undefined;
        this.filterManagerService?.checkApplyFilterClick$.next(true);
    }

    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;
    protected readonly MissioneStatus = MissioneStatus;
    protected readonly AuthorityType = AuthorityType;
    protected readonly ChiaveFlussoEnum = ChiaveFlussoEnum;
}
