import {Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ClickEvent} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    DeliberaInfoViewImpl,
    MobilitaStudentiCicloService, ModalitaDiFirmaType,
    PeriodiDiMobilitaService,
    PeriodoDiMobilitaStudenteInfoView,
    TipoPeriodoEnum
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, startWith, Subscription, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {AbstractControl, FormGroup} from "@angular/forms";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {FormComponent} from "../../../../../layout/common/generic-components/form/form.component";
import {
    CicloConfigurationService,
    DocumentDataType,
    isDocumentNotSignedWithSignRequiredCondition, isDocumentSigned
} from "../../../../../shared/service/ciclo-configuration.service";
import {ApprovazioneConclusioneMobilitaMassivaDataI} from "../approvazione-conclusione-mobilita-steps-interface";
import {ButtonType} from "../../../../../shared/components/custom-button/custom-button.component";
import {HttpResponse} from "@angular/common/http";
import {openFileInBlankWindow} from "../../../../../shared/utils/utils";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {SignableDocumentsData} from "../../../../../shared/components/document/document.component";


export interface PeriodiMobilitaCompileInfo {
    codiceMobilita: string;
    utenteId: string;
    studenteUIMapped: any;
}


@Component({
    selector: 'app-info-approvazione-conclusione-mobilita-stepper',
    templateUrl: './compila-info-approvazione-conclusione-mobilita.component.html',
    styleUrls: ['./compila-info-approvazione-conclusione-mobilita.component.scss']
})
export class CompilaInfoApprovazioneConclusioneMobilitaComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    periodiSelected: PeriodoDiMobilitaStudenteInfoView[];
    documentsFormValues: DocumentDataType[];
    _docsFormComponents: FormComponent[];
    docsFormComponentSubs: Subscription[] = [];
    @ViewChildren(FormComponent) formComponentsQL: QueryList<FormComponent>;
    documentsFormConfigs: { formConfig: formGroupConfigInterface[], utenteId: string, codiceMobilita: string }[];
    periodiCompileInfos: PeriodiMobilitaCompileInfo[];

    //formSelectValues: MobilitaRequestSelectFormValuesDTO;
    someDocNeedSign: boolean;

    ngAfterViewInit() {
        this.formComponentsQL.changes
            ?.pipe(startWith(this.formComponentsQL))
            ?.subscribe((ql: QueryList<FormComponent>) => {
                this._docsFormComponents = ql.toArray();
                this.subscribeToDocsFormsValueChange();
            });
    }

    private subscribeToDocsFormsValueChange() {
        const someNotSignedDocExists = this.documentsFormValues
            ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
        this.canGoNext$.next(this._docsFormComponents?.every(fc => fc.formGroup?.valid) && !someNotSignedDocExists);
        this.docsFormComponentSubs?.forEach(fcs => fcs.unsubscribe());
        const documentiAllegati = [];
        this.docsFormComponentSubs = this._docsFormComponents?.map(fc =>
            (fc?.formGroup?.valueChanges
                ?.pipe(startWith(fc?.formGroup?.value))
                .subscribe(docsFormValue => {
                    const someNotSignedDocExists = this.documentsFormValues
                        ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
                    this.canGoNext$.next(this._docsFormComponents?.every(fc => fc.formGroup?.valid) && !someNotSignedDocExists);
                    const documents = this.cicloConfigurationService.prepareDocsSubmitObjectFromNuoveConfig(
                        ChiaveFlussoEnum.RichiestaMobilita,
                        this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneConclusioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneConclusioneSupervisore,
                        docsFormValue,
                    );
                    let existingDocs = documentiAllegati?.find(d => d.codiceMobilita === fc.formGroup?.value?.codiceMobilita && d.id_utente === fc.formGroup?.value?.id_utente);
                    if(existingDocs){
                        existingDocs.documents = (documents ?? {});
                    } else {
                        documentiAllegati.push({
                            codiceMobilita: fc.formGroup?.value?.codiceMobilita,
                            id_utente: fc.formGroup?.value?.id_utente,
                            documents: (documents ?? {}),
                        });
                    }
                    this.operazioneMassivaData$.next({
                        ...this.operazioneMassivaData$?.getValue(),
                        documentiAllegati: documentiAllegati?.map(da => ({
                            codiceMobilita: da?.codiceMobilita,
                            id_utente: da?.id_utente,
                            ...(da?.documents ?? {})
                        }))
                    });
                })));


    }



    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private mobilitaStudentiCicloService: MobilitaStudentiCicloService,
                private cicloConfigurationService: CicloConfigurationService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneConclusioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshPeriodi){
                this.canGoNext$.next(false);
            }
            // if periodi selected changes reset delibera form
            if (inputOutputData.periodiSelected !== this.periodiSelected) {
                this.periodiSelected = inputOutputData?.periodiSelected;
                this._docsFormComponents?.forEach(fc => fc?.formGroup?.reset({emitEvent: false}));
                this.buildCompileInfoComponent();
                this.periodiCompileInfos = this.buildPeriodiCompileInfoConfiguration(this.periodiSelected || []);
            }
        });
        // get form select values
        //this.getFormSelectValues();
    }

    private buildPeriodiCompileInfoConfiguration(periodo: PeriodoDiMobilitaStudenteInfoView[]): PeriodiMobilitaCompileInfo[] {
        const periodi = periodo
            ?.map(periodo => ({
                codiceMobilita: periodo.codiceMobilita,
                utenteId: periodo?.studenteCiclo?.utente?.id,
                studenteUIMapped: {
                    utenteNome: periodo?.studenteCiclo?.utente?.nome,
                    utenteCognome: periodo?.studenteCiclo?.utente?.cognome,
                    nome: periodo?.studenteCiclo?.utente?.nome,
                    cognome: periodo?.studenteCiclo?.utente?.cognome,
                    utenteMail: periodo?.studenteCiclo?.utente?.mail?.toLowerCase(),
                    studenteCodiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale,
                    codiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale,
                    studenteMatricola: periodo?.studenteCiclo?.matricola,
                    urlImmagineProfilo: periodo?.studenteCiclo?.utente?.urlImmagineProfilo,
                    utenteTelefono: periodo?.studenteCiclo?.utente?.telefono
                },

            }));
        return periodi ? [...periodi] : undefined;
    }


    private buildCompileInfoComponent() {
        this.documentsFormConfigs = this.periodiSelected?.map(period => ({
            formConfig: [
                {
                    show: false,
                    name: 'codiceMobilita',
                    transloco: 'codiceMobilita',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
                {
                    show: false,
                    name: 'id_utente',
                    transloco: 'id_utente',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
                ...this.cicloConfigurationService.getDocsFormConfigFromNuoveConfig(
                    ChiaveFlussoEnum.RichiestaMobilita,
                    this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneConclusioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneConclusioneSupervisore,
                    (filename, idAllegato) => this.downloadDocument(period.codiceMobilita, idAllegato, period.studenteCiclo?.utente?.id),
                    undefined,
                    period.allegatiPeriodoDiMobilita,
                )
            ],
            utenteId: period?.studenteCiclo?.utente?.id,
            codiceMobilita: period.codiceMobilita,
        }));

        this.documentsFormValues = this.periodiSelected?.map(period => ({
            codiceMobilita: period.codiceMobilita,
            id_utente: period?.studenteCiclo?.utente?.id,
            ...this.cicloConfigurationService.getDocsFormValuesFromNuoveConfig(
                ChiaveFlussoEnum.RichiestaMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneConclusioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneConclusioneSupervisore,
                period.allegatiPeriodoDiMobilita,
                undefined,
                period?.studenteCiclo?.utente?.id,
            )
        }));

        this.someDocNeedSign =
            !!this.documentsFormConfigs?.find(fc =>
                fc?.formConfig?.find(fci =>
                    fci.signModes?.filter(mode => mode !== ModalitaDiFirmaType.CARICAMENTOFILE)?.length > 0)
            ) && !!this.documentsFormValues?.find(fv =>
                Object.values(fv?.signableDocumentsData ?? {})
                    ?.find((docSignData: SignableDocumentsData) => !isDocumentSigned(docSignData))
            );
    }


    downloadDocument(codiceMobilita: string, idAllegato: string, idStudente: string) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.mobilitaStudentiCicloService.getAllegatoPeriodoDiMobilitaStudente(codiceMobilita, idAllegato, idStudente, 'response').pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                //const fileNameToDownload = makeFilename(fileName);
                openFileInBlankWindow(fileResponse?.body, fileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });

    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    // private getFormSelectValues() {
    //     this.fuseConfirmationService.showLoader();
    //     this.appInitService.selectedInService.pipe(
    //         filter(Boolean),
    //         take(1),
    //         switchMap(() => this.periodiDiMobilitaService.getMobilitaRequestSelectFormValues()),
    //         tap(selectValues => this.formSelectValues = selectValues),
    //         takeUntil(this.destroy$),
    //         finalize(() => {
    //             this.fuseConfirmationService.hideLoader();
    //         })
    //     ).subscribe({
    //         next: () => {
    //         },
    //         error: (err) => {
    //             this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
    //                 () => {},() => this.getFormSelectValues(),
    //                 'dialog.cancel',
    //                 err?.error?.message);
    //         }
    //     });
    // }

    tableClickAction($event: ClickEvent) {

    }

    clearDateField(fieldCtrl: AbstractControl) {
        fieldCtrl.setValue(undefined);
        fieldCtrl.setErrors(null);
    }

    getDocumentsFormValue(utenteId: string, codiceMobilita: string) {
        return this.documentsFormValues?.find(dfv => dfv.codiceMobilita === codiceMobilita && dfv.id_utente === utenteId);
    }

    getDocumentsFormConfig(utenteId: string, codiceMobilita: string) {
        return this.documentsFormConfigs?.find(dfc => dfc.codiceMobilita === codiceMobilita && dfc.utenteId === utenteId)?.formConfig;
    }


    hasSomeDocToFill(){
        return this.documentsFormConfigs?.find(d => d.formConfig?.length > 2);
    }



    protected readonly ButtonType = ButtonType;
    protected readonly ChiaveFlussoEnum = ChiaveFlussoEnum;
    protected readonly ChiaveOperazioneEnum = ChiaveOperazioneEnum;
    protected readonly AuthorityType = AuthorityType;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
}
