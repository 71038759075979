/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di missione. Valori previsti: *CONVEGNO*, *SEMINARIO*, *MOBILITA_E_RICERCA* 
 */
export type MissioneType = 'CONVEGNO' | 'SEMINARIO' | 'MOBILITA_E_RICERCA';

export const MissioneType = {
    CONVEGNO: 'CONVEGNO' as MissioneType,
    SEMINARIO: 'SEMINARIO' as MissioneType,
    MOBILITAERICERCA: 'MOBILITA_E_RICERCA' as MissioneType
};