/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserFilterForm { 
    /**
     * codice corso di studi
     */
    codiceCorsoDiStudiEsse3?: string;
    /**
     * codice fiscale
     */
    codiceFiscale?: string;
    /**
     * cognome
     */
    cognome?: string;
    /**
     * email
     */
    email?: string;
    /**
     * nome
     */
    nome?: string;
    /**
     * nominativo
     */
    nominativo?: string;
    /**
     * numero ciclo di dottorato
     */
    numeroCiclo?: number;
    /**
     * ruolo
     */
    ruolo?: Array<UserFilterForm.RuoloEnum>;
}
export namespace UserFilterForm {
    export type RuoloEnum = 'ADMIN' | 'DOCENTE' | 'PERSONALE_PTA' | 'ESTERNO';
    export const RuoloEnum = {
        ADMIN: 'ADMIN' as RuoloEnum,
        DOCENTE: 'DOCENTE' as RuoloEnum,
        PERSONALEPTA: 'PERSONALE_PTA' as RuoloEnum,
        ESTERNO: 'ESTERNO' as RuoloEnum
    };
}