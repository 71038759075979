import {Component, InjectionToken, Injector, Input, OnInit, TemplateRef, Type, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
    AbstractDefaultComponent
} from "../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum, DeliberaInfoView, SpesaStudentePerApprovMassivaInfoView,
    TipoPeriodoEnum
} from "../../../../api-clients/generated/services";
import {getOperazioneDescrizione} from "./operazioni-massive-utils";
import {MatStepper} from "@angular/material/stepper";
import {
    getStepsByOperazione,
    OperazioneMassivaDataI,
    OperazioneMassivaI,
    OperazioneMassivaStepConfig,
    OperazioneMassivaTipo
} from './operazioni-massive-config';
import {ScegliCorsoComponent} from "./superamento-corso-steps/scegli-corso/scegli-corso.component";
import {CicloConfigurationService} from "../../../shared/service/ciclo-configuration.service";
import {GenericTableConfigurationModel} from "../../../shared/components/table/model/generic-table-model";
import {Translation} from "@ngneat/transloco";
import {GenericOperationMassivaDataI} from "./generic-operation-steps/generic-operation-steps-interface";
import {ScegliRichiesteComponent} from "./generic-operation-steps/scegli-richieste/scegli-richieste.component";
import {Router} from "@angular/router";
import {AttachmentsInfoDialogService} from "../../../shared/service/attachments-info-dialog.service";
import {ConfirmOperationComponent} from "./generic-operation-steps/confirm-operation/confirm-operation.component";
import {ComponentType} from "@angular/cdk/overlay";
import {
    CompilaInfoRichiesteComponent
} from "./generic-operation-steps/compila-info-richieste/compila-info-richieste.component";

export const OP_TYPE_CHOICE_ENABLED = new InjectionToken<boolean>('PICK_CONFIG_FROM_FIRST_PRESENTATION');


export const CAN_GO_AHEAD$ = new InjectionToken<BehaviorSubject<boolean>>('CAN_GO_AHEAD$');
export const OPERAZIONE_MASSIVA_DATA$ = new InjectionToken<BehaviorSubject<OperazioneMassivaDataI>>('OPERAZIONE_MASSIVA_DATA$');
export const CURRENT_PROFILE = new InjectionToken<AuthorityType>('CURRENT_PROFILE');
export const PERIOD_TYPE$ = new InjectionToken<TipoPeriodoEnum>('PERIOD_TYPE$');
export const CHIAVE_FLUSSO$ = new InjectionToken<ChiaveFlussoEnum>('CHIAVE_FLUSSO$');
export const CHIAVE_OPERAZIONE$ = new InjectionToken<ChiaveOperazioneEnum>('CHIAVE_OPERAZIONE$');
export const GET_DATA_SERVICE$ = new InjectionToken<(filters: any, size: number, page: number, opMassivaComp: ScegliRichiesteComponent | CompilaInfoRichiesteComponent, requestsId?: string[]) => Observable<any>>('GET_DATA_SERVICE$');
export const GET_TABLE_CONFIGURATION$ = new InjectionToken<(pageData: any, cicloConfigurationService: CicloConfigurationService, size?: number, page?: number, idRigheDaDisabilitare?: string[]) => GenericTableConfigurationModel>('GET_TABLE_CONFIGURATION$');
export const GET_REQUEST_PAGE_URL$ = new InjectionToken<(request: any, router: Router) => string>('GET_REQUEST_PAGE_URL$');
export const OPEN_REQUEST_ATTACHMENTS_DIALOG$ = new InjectionToken<(request: any, attachmentsInfoDialogService: AttachmentsInfoDialogService) => void>('OPEN_REQUEST_ATTACHMENTS_DIALOG$');
export const GET_REQUEST_IDENTITY_FIELD_NAME$ = new InjectionToken<string>('GET_REQUEST_IDENTITY_FIELD_NAME$');
export const GET_REFUSE_REQUEST$ = new InjectionToken<(request: any, opMassivaComp: ScegliRichiesteComponent, cicloConfigurationService: CicloConfigurationService, form: FormGroup) => Observable<any>>('GET_REFUSE_REQUEST$');
export const GET_APPLIED_FILTERS_LABELS$ = new InjectionToken<(filterManagerService: any, fieldsLabelMap: Map<string, string>, translation: Translation) => Array<string>>('GET_APPLIED_FILTERS_LABELS$');
export const DOWNLOAD_REQUEST_DOCUMENT$ = new InjectionToken<(filename: string, idAllegato: string, request: any, opMassivaComp: any) => Observable<any>>('DOWNLOAD_REQUEST_DOCUMENT$');
export const GET_REQUEST_ATTACHMENTS_FIELD_NAME$ = new InjectionToken<string>('GET_REQUEST_ATTACHMENTS_FIELD_NAME$');
export const GET_REQUEST_FORMGROUP$ = new InjectionToken<(fb: FormBuilder, requestsSelected: any[], formArray: FormArray, request?: any) => FormGroup | undefined>('GET_REQUEST_FORMGROUP$');
export const EXECUTE_OPERATION_SERVICE$ = new InjectionToken<(opMassivaData: GenericOperationMassivaDataI, opMassivaComp: ConfirmOperationComponent, cicloConfigurationService: CicloConfigurationService) => Observable<any>>('EXECUTE_OPERATION_SERVICE$');
export const GET_REQUEST_TITLE$ = new InjectionToken<(request?: any) => string>('GET_REQUEST_TITLE$');
export const GET_ESITO_TITLE$ = new InjectionToken<(esito: any, requestsResults?: any[]) => string>('GET_ESITO_TITLE$');
export const REQUEST_FORM_COMPONENT$ = new InjectionToken<ComponentType<any> | undefined>('REQUEST_FORM_COMPONENT$');
export const GET_FILTER_SERVICE_TYPE$ = new InjectionToken<Type<any>>('GET_FILTER_SERVICE_TYPE$');

@Component({
    selector: 'app-operazioni-massive',
    templateUrl: './operazioni-massive.component.html',
    styleUrls: ['./operazioni-massive.component.scss']
})
export class OperazioniMassiveComponent extends AbstractDefaultComponent implements OnInit {

    @ViewChild('stepper') stepper: MatStepper;
    @Input() operazioneMassivaTipo: OperazioneMassivaTipo;
    @Input() tornaAllaListaTmpl: TemplateRef<any>;
    @Input() currentProfile: AuthorityType;
    @Input() operazione: ChiaveOperazioneEnum;
    @ViewChild(ScegliCorsoComponent) scegliCorsoComponent: ScegliCorsoComponent;

    loading: boolean = false;
    title: string;
    subtitle: string;
    sottoruoli: string[];
    ctrlSelectFormControl: FormControl = new FormControl(null);
    selectedOperazioneTipo: OperazioneMassivaTipo;
    operazioniDisponibili: OperazioneMassivaI[];
    currentRuolo: AuthorityType;
    currentSubroleCanAccess: boolean;
    isSubroleSelected: boolean;
    mustChooseSubrole: boolean;
    protected readonly AuthorityType = AuthorityType;
    steps: OperazioneMassivaStepConfig[];
    operazioneFormGroup: FormGroup;

    protected readonly CAN_GO_AHEAD$ = CAN_GO_AHEAD$;
    protected readonly OPERAZIONE_MASSIVA_DATA$ = OPERAZIONE_MASSIVA_DATA$;
    protected readonly getOperazioneDescrizione = getOperazioneDescrizione;

    constructor(
        private fb: FormBuilder,
        private injector: Injector,
        private cicloConfigurationService: CicloConfigurationService
    ) {
        super();

        this.operazioneFormGroup = this.fb.group({
            operazione: ['', Validators.required],
        });
        this.operazioneFormGroup?.get('operazione')?.valueChanges?.subscribe(operazione => {
            this.steps = getStepsByOperazione(operazione, this.injector, this.currentProfile, this.cicloConfigurationService, this.operazione)
        });

    }

    ngOnInit(): void {

        this.operazioneFormGroup.get('operazione').setValue(this.operazioneMassivaTipo);
    }


    changeRuolo() {
        this.steps = [];
        this.operazioneFormGroup.get('operazione').setValue(this.operazioneMassivaTipo);
        this.selectedOperazioneTipo = this.operazioneMassivaTipo;
    }

    resetStep() {
        this.steps = []
    }
}



