import {ConfigCol, ConfigColTopHeader} from "../../../../shared/service/excel.service";
import {
    AggiornamentoPianoDiStudiInfoView,
    AttivitaOffertaFormativaInfoView, UnitaDiMisuraAttivita,
} from "../../../../../api-clients/generated/services";
import {Translation} from "@ngneat/transloco";
import {get, sortBy, trim} from "lodash";
import {assign, cloneDeep, maxBy} from "lodash-es";
import {
    AnnoRiferimentoValues,
    AttivitaCategoriaAssociationInfoView,
    CategoriaOffertaFormativaInfoView,
    ConfigurazioneCategoriaPerAnni,
    ElementoOffertaFormativaType,
    OffertaFormativaInfoViewImpl
} from "api-clients/generated/services";
import {
    TrainingOfferCategoryByTypeDataUI,
    TrainingOfferCategoryByYearDataUI,
    TrainingOfferYearFragments
} from "./training-offer.component";
import {GenericTableConfigurationModel} from "../../../../shared/components/table/model/generic-table-model";
import {annoRiferimentoFromRomanNumeral, annoRiferimentoToRomanNumeral} from "../../../../shared/utils/utils";
import {
    TrainingOfferCategoryDataUI
} from "../../training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component";
import {CicloConfigurationService} from "../../../../shared/service/ciclo-configuration.service";


function mergeDocentiAndExtra(a: AttivitaOffertaFormativaInfoView) {
    const mergedDocenti = cloneDeep(a?.docenti || []).concat(a?.docentiExtraOffertaFormativa || []);
    if(mergedDocenti?.length == 0){
        mergedDocenti.push({
            nome: '',
            cognome: '',
            codiceFiscale: ''
        });
    }
    return mergedDocenti;
}

function getOrganizzatori(a: AttivitaOffertaFormativaInfoView) {
    const organizers = cloneDeep(a?.organizzatori || []);
    if(organizers?.length == 0){
        organizers.push('');
    }
    return organizers;
}

function getPercorsiDiStudi(a: AttivitaOffertaFormativaInfoView) {
    const perc = cloneDeep(a?.percorsiDiStudi || []);
    if(perc?.length == 0){
        perc.push({
            descrizionePercorsoDiStudi: ''
        });
    }
    return perc;
}

export function mapAttivitaForExcel(attivita: (AttivitaOffertaFormativaInfoView & {categoryDenomination: string})[], tipoAttivita: ElementoOffertaFormativaType, translation: Translation): any[] {
     const mappedAttivita = attivita
        ?.filter(a => a.tipo === tipoAttivita)
        ?.map(a => ({
            ...a,
            ...assign({}, ...mergeDocentiAndExtra(a)?.map((d, index) => ({
                ['docente_' + index + '_cf']: d.codiceFiscale,
                ['docente_' + index + '_cognome']: d.cognome,
                ['docente_' + index + '_nome']: d.nome,
            }))),
            ...assign({}, ...getOrganizzatori(a)?.map((o, index) => ({
                ['organizer_' + index]: o,
            }))),
            ...assign({}, ...getPercorsiDiStudi(a)?.map((p, index) => ({
                ['curriculum_' + index]: p.descrizionePercorsoDiStudi,
            }))),
            primoAnno: a.primoAnno ? get(translation, 'common.yes') : get(translation, 'common.no'),
            secondoAnno: a.secondoAnno ? get(translation, 'common.yes') : get(translation, 'common.no'),
            terzoAnno: a.terzoAnno ? get(translation, 'common.yes') : get(translation, 'common.no'),
            verificaFinale: a.verificaFinale ? get(translation, 'common.yes') : get(translation, 'common.no'),
            presenzaDocumentoDottorando: a.presenzaDocumentoDottorando ? get(translation, 'common.yes') : get(translation, 'common.no'),
            presenzaDocumentoSupervisore: a.presenzaDocumentoSupervisore ? get(translation, 'common.yes') : get(translation, 'common.no'),
            presenzaDeliberaCoordinatore: a.presenzaDeliberaCoordinatore ? get(translation, 'common.yes') : get(translation, 'common.no'),
            isObbligatorio: a.isObbligatorio ? get(translation, 'common.yes') : get(translation, 'common.no'),
        }));
     return sortBy(mappedAttivita, [
         a => trim(a.categoryDenomination)?.toLowerCase(),
         a => trim(a.denominazione)?.toLowerCase(),
     ], ['asc', 'asc']);
}

export function getExcelTopHeaderConfigurations(configCols: ConfigCol[], translation: Translation): ConfigColTopHeader[] {
    let docenteIndex = 1;
    return configCols.map(col => {
        let translatedHeader = '';
        if(col.key.endsWith('_cognome')) {
            translatedHeader = get(translation, 'common.professor', null)?.toUpperCase() + ' ' + docenteIndex;
            docenteIndex += 1;
        }
        if(col.key.endsWith('secondoAnno')) {
            translatedHeader = get(translation, 'common.years', null)?.toUpperCase();
        }
        return {
            translatedHeader: translatedHeader
        };
    })
}


// corsi

export function getExcelConfigurationsForCorsi(attivita: (AttivitaOffertaFormativaInfoView & {categoryDenomination: string})[],
                                               translation: Translation,
                                               cicloConfigurationService: CicloConfigurationService): ConfigCol[] {
    const filteredActivities = attivita
        ?.filter(a => a.tipo === ElementoOffertaFormativaType.CORSO);
    const curriculumsColumns =
        getPercorsiDiStudi(maxBy(filteredActivities, a => getPercorsiDiStudi(a)?.length))
            ?.map((p, index) => ({
                key: 'curriculum_' + index,
                translatedHeader: get(translation, 'cycle_doctorate.any_reference_curriculum', null) + ' ' + (index + 1),
                width: 22
            }));
    const docentiColumns =
        mergeDocentiAndExtra(maxBy(filteredActivities, a => mergeDocentiAndExtra(a)?.length))
            ?.flatMap((p, index) => [
                {
                    key: 'docente_' + index + '_cf',
                    translocoHeader: 'common.fiscal_code',
                    width: 20
                },
                {
                    key: 'docente_' + index + '_cognome',
                    translocoHeader: 'auth.surname',
                    width: 20
                },
                {
                    key: 'docente_' + index + '_nome',
                    translocoHeader: 'auth.name',
                    width: 20
                }
            ]);
    return [
        {
            key: 'categoryDenomination',
            translocoHeader: 'support.category',
            width: 40
        },
        {
            key: 'denominazione',
            translocoHeader: 'cycle_doctorate.course_title',
            width: 50
        },
        {
            key: 'descrizione',
            translocoHeader: 'common.description',
            width: 70,
        },
        ...(cicloConfigurationService.activityUnitOfMeasure === UnitaDiMisuraAttivita.CFU ? [{
            key: 'ore',
            translocoHeader: 'total_hour_table.column_total_hour',
            width: 7,
        }] : []),
        {
            key: 'cfu',
            translocoHeader: cicloConfigurationService.activityUnitOfMeasureLabel,
            width: 7,
        },
        {
            key: 'primoAnno',
            translatedHeader: 'I',
            width: 10
        },
        {
            key: 'secondoAnno',
            translatedHeader: 'II',
            width: 10,
        },
        {
            key: 'terzoAnno',
            translatedHeader: 'III',
            width: 10,
        },
        ...(curriculumsColumns || [
            {
                key: 'curriculum_0',
                translatedHeader: get(translation, 'cycle_doctorate.any_reference_curriculum', null) + ' ' + 1,
                width: 22
            }
        ]),
        {
            key: 'isObbligatorio',
            translocoHeader: 'cycle_doctorate.mandatory',
            width: 10,
        },
        {
            key: 'verificaFinale',
            translocoHeader: 'cycle_doctorate.final_check',
            width: 10,
        },
        {
            key: 'ssd',
            translocoHeader: 'cycle_doctorate.ssd',
            width: 10,
        },
        {
            key: 'periodoErogazione',
            translocoHeader: 'cycle_doctorate.disbursement_period',
            width: 20,
        },
        ...(docentiColumns || []), // this includes both docenti and docentiExtra
    ];
}


// extra

export function getExcelConfigurationsForExtra(attivita: (AttivitaOffertaFormativaInfoView & {categoryDenomination: string})[],
                                               translation: Translation,
                                               cicloConfigurationService: CicloConfigurationService): ConfigCol[] {
    const filteredActivities = attivita
        ?.filter(a => a.tipo === ElementoOffertaFormativaType.ATTIVITAEXTRA);
    const curriculumsColums =
        getPercorsiDiStudi(maxBy(filteredActivities, a => getPercorsiDiStudi(a)?.length))
            ?.map((p, index) => ({
                key: 'curriculum_' + index,
                translatedHeader: get(translation, 'cycle_doctorate.any_reference_curriculum', null) + ' ' + (index + 1),
                width: 22
            }));
    const organizersColums =
        getOrganizzatori(maxBy(filteredActivities, a => getOrganizzatori(a)?.length))
            ?.flatMap((p, index) => [
                {
                    key: 'organizer_' + index,
                    translatedHeader: get(translation, 'cycle_doctorate.organizer_single', null) + ' ' + (index + 1),
                    width: 20
                },
            ]);
    return [
        {
            key: 'categoryDenomination',
            translocoHeader: 'support.category',
            width: 40
        },
        {
            key: 'denominazione',
            translocoHeader: 'cycle_doctorate.activity_denomination',
            width: 50
        },
        ...(cicloConfigurationService.activityUnitOfMeasure === UnitaDiMisuraAttivita.CFU ? [{
            key: 'ore',
            translocoHeader: 'total_hour_table.column_total_hour',
            width: 7,
        }] : []),
        {
            key: 'cfu',
            translocoHeader: cicloConfigurationService.activityUnitOfMeasureLabel,
            width: 7,
        },
        {
            key: 'primoAnno',
            translatedHeader: 'I',
            width: 10
        },
        {
            key: 'secondoAnno',
            translatedHeader: 'II',
            width: 10,
        },
        {
            key: 'terzoAnno',
            translatedHeader: 'III',
            width: 10,
        },
        ...(curriculumsColums || [
            {
                key: 'curriculum_0',
                translatedHeader: get(translation, 'cycle_doctorate.any_reference_curriculum', null) + ' ' + 1,
                width: 22
            }
        ]),
        {
            key: 'descrizione',
            translocoHeader: 'common.description',
            width: 100,
        },
        {
            key: 'isObbligatorio',
            translocoHeader: 'cycle_doctorate.mandatory',
            width: 10,
        },
        ...(organizersColums || [
            {
                key: 'organizer_0',
                translatedHeader: get(translation, 'cycle_doctorate.organizer_single', null) + ' ' + 1,
                width: 20
            },
        ]),
    ];
}

// transversal

export function getExcelConfigurationsForTransversal(cicloConfigurationService: CicloConfigurationService): ConfigCol[] {
    return [
        {
            key: 'categoryDenomination',
            translocoHeader: 'support.category',
            width: 40
        },
        {
            key: 'denominazione',
            translocoHeader: 'cycle_doctorate.activity_typology',
            width: 40
        },
        {
            key: 'descrizioneCfu',
            translocoHeader: cicloConfigurationService.activityUnitOfMeasureLabel,
            width: 20,
        },
        {
            key: 'primoAnno',
            translatedHeader: 'I',
            width: 10
        },
        {
            key: 'secondoAnno',
            translatedHeader: 'II',
            width: 10,
        },
        {
            key: 'terzoAnno',
            translatedHeader: 'III',
            width: 10,
        },
        {
            key: 'descrizione',
            translocoHeader: 'common.description',
            width: 70,
        },
        {
            key: 'isObbligatorio',
            translocoHeader: 'cycle_doctorate.mandatory',
            width: 10,
        },
        {
            key: 'presenzaDocumentoDottorando',
            translocoHeader: 'cycle_doctorate.student_document_required',
            width: 20,
        },
        {
            key: 'tipoDocumentoDottorando',
            translocoHeader: 'cycle_doctorate.student_document',
            width: 25,
        },
        {
            key: 'presenzaDocumentoSupervisore',
            translocoHeader: 'cycle_doctorate.supervisor_document_required',
            width: 20,
        },
        {
            key: 'tipoDocumentoSupervisore',
            translocoHeader: 'cycle_doctorate.supervisor_document',
            width: 25,
        },
        {
            key: 'presenzaDeliberaCoordinatore',
            translocoHeader: 'cycle_doctorate.coordinator_required',
            width: 20,
        },
    ];
}





export function annoRiferimentoToCheckActivityInCategoryYearCallback(year: AnnoRiferimentoValues): (c: AttivitaCategoriaAssociationInfoView) => boolean {
    switch (year) {
        case AnnoRiferimentoValues.PRIMO:
            return (c) => c.primoAnno;
        case AnnoRiferimentoValues.SECONDO:
            return (c) => c.secondoAnno;
        case AnnoRiferimentoValues.TERZO:
            return (c) => c.terzoAnno;
    }
}

// utils functions
export const getOfferCategoriesByYear = (offerta: OffertaFormativaInfoViewImpl,
                                         year: AnnoRiferimentoValues,
                                         archivedMode?: boolean) => {
    const yearLabel = annoRiferimentoToRomanNumeral(year);
    const checkActivityInCategoryYearCallback = annoRiferimentoToCheckActivityInCategoryYearCallback(year);
    let categories = offerta.categorie
        ?.filter(cat => archivedMode || !cat.isArchiviata)
        ?.map(value => {
            const filteredActivities = value.attivitaCategoriaAssociations?.filter(a => checkActivityInCategoryYearCallback(a)) //standard activities
                    ?.filter(a => (archivedMode && (a.attivitaOffertaFormativa?.isArchiviato || a?.isArchiviato)) ||
                        (!archivedMode && !a.attivitaOffertaFormativa?.isArchiviato && !a?.isArchiviato)); // filtering by archived/non archived according toggle
            return {
                ...value,
                attivitaCategoriaAssociations: filteredActivities,
                numberOfActivitiesAcrossAllYears: value?.attivitaCategoriaAssociations?.length ?? 0,
                numberOfShowedActivitiesForTypeAndYear: filteredActivities?.length ?? 0,
                year: yearLabel
            }
    });
    categories = sortBy(categories, [
        category => category.denominazione?.toLowerCase()?.trim(),
    ], ['asc', 'asc']);
    return categories;
};

export const getOfferCategoriesByAllYears = (offerta: OffertaFormativaInfoViewImpl,
                                         archivedMode?: boolean) => {
    let categories = offerta.categorie
        ?.filter(cat => archivedMode || !cat.isArchiviata)
        ?.map(value => {
        const filteredActivities = value.attivitaCategoriaAssociations
            ?.filter(a => (archivedMode && (a.attivitaOffertaFormativa?.isArchiviato || a?.isArchiviato)) ||
                (!archivedMode && !a.attivitaOffertaFormativa?.isArchiviato && !a?.isArchiviato)); // filtering by archived/non archived according toggle
        return {
            ...value,
            attivitaCategoriaAssociations: filteredActivities,
            numberOfActivitiesAcrossAllYears: value?.attivitaCategoriaAssociations?.length ?? 0,
            year: undefined
        }
    });
    categories = sortBy(categories, [
        category => category.denominazione?.toLowerCase()?.trim(),
    ], ['asc', 'asc']);
    return categories;
};


function annoRiferimentoToCheckCategoryConfigurationYearCallback(year: AnnoRiferimentoValues): (config: ConfigurazioneCategoriaPerAnni) => boolean {
    switch (year) {
        case AnnoRiferimentoValues.PRIMO:
            return (c) => c.primo_anno;
        case AnnoRiferimentoValues.SECONDO:
            return (c) => c.secondo_anno;
        case AnnoRiferimentoValues.TERZO:
            return (c) => c.terzo_anno;
    }
}

export const createOfferCategoriesByActivityTypeConfigurations = (
    categoriesForThisYear: (TrainingOfferCategoryDataUI)[],
    t: Translation,
    year: AnnoRiferimentoValues,
    createTableConfigurationForCourses: (translation: Translation, category?: TrainingOfferCategoryDataUI) => GenericTableConfigurationModel,
    createTableConfigurationForExtraActivities: (inputList?: TrainingOfferCategoryDataUI) => GenericTableConfigurationModel,
    createTableConfigurationForTransversalActivities: (inputList?: TrainingOfferCategoryDataUI) => GenericTableConfigurationModel,
    draftForPickingSelectedActivities?: AggiornamentoPianoDiStudiInfoView,
): TrainingOfferCategoryByTypeDataUI[] => {
    const checkConfigurationYearCallback = year ? annoRiferimentoToCheckCategoryConfigurationYearCallback(year) : undefined;
    const categoriesOfCorsi = getOfferCategoriesByType(categoriesForThisYear, ElementoOffertaFormativaType.CORSO);
    const categoriesOfExtra = getOfferCategoriesByType(categoriesForThisYear, ElementoOffertaFormativaType.ATTIVITAEXTRA);
    const categoriesOfTrasversal = getOfferCategoriesByType(categoriesForThisYear, ElementoOffertaFormativaType.ATTIVITATRASVERSALE);
    const data = [
        {
            activityType: ElementoOffertaFormativaType.CORSO,
            activityLabel: 'common.didactic_activities',
            fragmentLabel: 'attivita_didattiche_programmate',
            categoriesConfiguration: categoriesOfCorsi?.map(category => ({
                ...category,
                activitiesTableConfiguration: createTableConfigurationForCourses(t, category),
                configurazioneForThisYear: year ? category.configurazione?.configurazioni?.find(checkConfigurationYearCallback) : undefined,
            } as TrainingOfferCategoryDataUI)),
        },
        {
            activityType: ElementoOffertaFormativaType.ATTIVITAEXTRA,
            activityLabel: 'student.extra_activities',
            fragmentLabel: 'altre_attivita_didattiche',
            categoriesConfiguration: categoriesOfExtra?.map(category => ({
                ...category,
                activitiesTableConfiguration: createTableConfigurationForExtraActivities(category),
                configurazioneForThisYear: year ? category.configurazione?.configurazioni?.find(checkConfigurationYearCallback) : undefined,
            } as TrainingOfferCategoryDataUI)),
        },
        {
            activityType: ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
            activityLabel: 'student.transversal_activities',
            fragmentLabel: 'attivita_formative_di_ricerca',
            categoriesConfiguration: categoriesOfTrasversal?.map(category => ({
                ...category,
                activitiesTableConfiguration: createTableConfigurationForTransversalActivities(category),
                configurazioneForThisYear: year ? category.configurazione?.configurazioni?.find(checkConfigurationYearCallback) : undefined,
            } as TrainingOfferCategoryDataUI)),
        }
    ];
    /*// for all years compute the number of activities
    if(!year){
        data.forEach(typeConf => {
            typeConf.categoriesConfiguration.forEach(cat => {
                cat.numberOfActivitiesForYears = Object.values(AnnoRiferimentoValues).map(anno => {
                    return {
                        year: annoRiferimentoToRomanNumeral(anno),
                        numberOfActivities: cat.attivitaCategoriaAssociations?.filter(att =>
                            annoRiferimentoToCheckActivityInCategoryYearCallback(anno)(att))?.length ?? 0
                    }
                })
            })
        });
    }*/
    return data;
};

export function annoRiferimentoToTrainingOfferYearFragment(year: AnnoRiferimentoValues): TrainingOfferYearFragments {
    switch (year) {
        case AnnoRiferimentoValues.PRIMO:
            return TrainingOfferYearFragments.FIRST;
        case AnnoRiferimentoValues.SECONDO:
            return TrainingOfferYearFragments.SECOND;
        case AnnoRiferimentoValues.TERZO:
            return TrainingOfferYearFragments.THIRD;
    }
}

export const getOfferCategoriesByType = (list: Array<CategoriaOffertaFormativaInfoView>,
                                        type: ElementoOffertaFormativaType): (CategoriaOffertaFormativaInfoView)[] | [] =>
    list?.filter(item => item.tipoAttivitaContenute === type) || [];


function countCFUForCategory(trainingOfferCategoryDataUI: TrainingOfferCategoryDataUI, configForThisYear: ConfigurazioneCategoriaPerAnni) {
    return trainingOfferCategoryDataUI?.attivitaCategoriaAssociations
            ?.filter(a => a.primoAnno && configForThisYear?.primo_anno || a.secondoAnno && configForThisYear?.secondo_anno || a.terzoAnno && configForThisYear?.terzo_anno)
            ?.map(a => a.attivitaOffertaFormativa?.cfu ?? 0)
            ?.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
            ?? 0;
}


function countNumAttForCategory(trainingOfferCategoryDataUI: TrainingOfferCategoryDataUI, configForThisYear?: ConfigurazioneCategoriaPerAnni) {
    return trainingOfferCategoryDataUI?.attivitaCategoriaAssociations
        ?.filter(a => a.primoAnno && configForThisYear?.primo_anno || a.secondoAnno && configForThisYear?.secondo_anno || a.terzoAnno && configForThisYear?.terzo_anno)
        ?.length ?? 0;
}

function getTotaleCFUOfOfferOverConfYears(configForThisYear: ConfigurazioneCategoriaPerAnni, categoryConfig: TrainingOfferCategoryDataUI, categoriesByYear: TrainingOfferCategoryByYearDataUI[]) {
    const typeIndex = categoryConfig?.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO ? 0 : (categoryConfig?.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA ? 1 : 2);
    return countCFUForCategory(
        categoriesByYear?.[0]?.categoriesByActivityType?.[typeIndex]?.categoriesConfiguration?.find(c => c.id === categoryConfig?.id),
        configForThisYear
    );
}


function getNumeroAttivitaOfOfferOverConfYears(configForThisYear: ConfigurazioneCategoriaPerAnni, categoryConfig: TrainingOfferCategoryDataUI, categoriesByYear: TrainingOfferCategoryByYearDataUI[]) {
    const typeIndex = categoryConfig?.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO ? 0 : (categoryConfig?.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA ? 1 : 2);
    return countNumAttForCategory(
        categoriesByYear?.[0]?.categoriesByActivityType?.[typeIndex]?.categoriesConfiguration?.find(c => c.id === categoryConfig?.id),
        configForThisYear
    );
}


export function getOfferCategoriesCompliance(categoryConfig: TrainingOfferCategoryDataUI, categoriesByYear: TrainingOfferCategoryByYearDataUI[], type: ElementoOffertaFormativaType) {
    const checkConfigurationYearCallback = annoRiferimentoToCheckCategoryConfigurationYearCallback(annoRiferimentoFromRomanNumeral(categoryConfig.year));
    const configForThisYear = categoryConfig.configurazione?.configurazioni?.find(checkConfigurationYearCallback);

    if(type !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE) {
        // check if cfu configuration for the category is met
        const totCfu = getTotaleCFUOfOfferOverConfYears(configForThisYear, categoryConfig, categoriesByYear);
        const cfuCompliance = !configForThisYear || configForThisYear?.flag_aggiunta_attivita_presunte || configForThisYear?.flag_aggiunta_attivita_proposte
            || totCfu >= configForThisYear?.numero_minimo_cfu;
        // check if num of mandatory activities configuration for the category is met
        const totAtt = getNumeroAttivitaOfOfferOverConfYears(configForThisYear, categoryConfig, categoriesByYear);
        const activityNumberCompliance = !configForThisYear || configForThisYear?.flag_aggiunta_attivita_presunte || configForThisYear?.flag_aggiunta_attivita_proposte
            || totAtt >= configForThisYear?.numero_attivita_obbligatorie;
        return {cfuCompliance, activityNumberCompliance, totCfu, totAtt};
    } else {
        const isPresunteOrProposteEnabled = categoryConfig.configurazioneForThisYear?.flag_aggiunta_attivita_proposte || categoryConfig.configurazioneForThisYear?.flag_aggiunta_attivita_presunte;
        const totAtt = getNumeroAttivitaOfOfferOverConfYears(configForThisYear, categoryConfig, categoriesByYear);
        const hasAtLeastOneTypology = totAtt > 0;
        const cfuCompliance = !configForThisYear || isPresunteOrProposteEnabled || hasAtLeastOneTypology || configForThisYear?.numero_minimo_cfu == 0;
        const activityNumberCompliance = !configForThisYear || isPresunteOrProposteEnabled || hasAtLeastOneTypology || configForThisYear?.numero_attivita_obbligatorie == 0;
        return {cfuCompliance, activityNumberCompliance, totCfu:0, totAtt:totAtt};
    }


}
