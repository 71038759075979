/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { LoginForm } from '../model/loginForm';
import { LoginResponse } from '../model/loginResponse';
import { SecretResponseDTO } from '../model/secretResponseDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette ad un utente esterno di eseguira la login interna su keycloak.
     * 
     * @param body 
     * @param Dottorandi_Recaptcha_Token Token reCAPTCHA
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginInternaUtente(body: LoginForm, Dottorandi_Recaptcha_Token: string, observe?: 'body', reportProgress?: boolean): Observable<LoginResponse>;
    public loginInternaUtente(body: LoginForm, Dottorandi_Recaptcha_Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginResponse>>;
    public loginInternaUtente(body: LoginForm, Dottorandi_Recaptcha_Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginResponse>>;
    public loginInternaUtente(body: LoginForm, Dottorandi_Recaptcha_Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling loginInternaUtente.');
        }

        if (Dottorandi_Recaptcha_Token === null || Dottorandi_Recaptcha_Token === undefined) {
            throw new Error('Required parameter Dottorandi_Recaptcha_Token was null or undefined when calling loginInternaUtente.');
        }

        let headers = this.defaultHeaders;
        if (Dottorandi_Recaptcha_Token !== undefined && Dottorandi_Recaptcha_Token !== null) {
            headers = headers.set('Dottorandi-Recaptcha-Token', String(Dottorandi_Recaptcha_Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LoginResponse>('post',`${this.basePath}/api/v1/auth/login-interna`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Esegue la logout dell&#x27;utente, invalidandone la sessione.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logoutUser(observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public logoutUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public logoutUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public logoutUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/auth/logout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di settare il secret di sessione utilizzato per il crypt delle comunicazioni.
     * 
     * @param index 
     * @param secret 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setSessionSecretForm(index: string, secret: string, observe?: 'body', reportProgress?: boolean): Observable<SecretResponseDTO>;
    public setSessionSecretForm(index: string, secret: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SecretResponseDTO>>;
    public setSessionSecretForm(index: string, secret: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SecretResponseDTO>>;
    public setSessionSecretForm(index: string, secret: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (index === null || index === undefined) {
            throw new Error('Required parameter index was null or undefined when calling setSessionSecret.');
        }

        if (secret === null || secret === undefined) {
            throw new Error('Required parameter secret was null or undefined when calling setSessionSecret.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (index !== undefined) {
            formParams = formParams.append('index', <any>index) as any || formParams;
        }
        if (secret !== undefined) {
            formParams = formParams.append('secret', <any>secret) as any || formParams;
        }

        return this.httpClient.request<SecretResponseDTO>('post',`${this.basePath}/api/v1/auth/secret`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
