/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum di tutti i colori gestiti
 */
export type ColoreEnum = 'VERDE' | 'ROSSO' | 'GRIGIO' | 'GIALLO' | 'BLU' | 'ARANCIONE';

export const ColoreEnum = {
    VERDE: 'VERDE' as ColoreEnum,
    ROSSO: 'ROSSO' as ColoreEnum,
    GRIGIO: 'GRIGIO' as ColoreEnum,
    GIALLO: 'GIALLO' as ColoreEnum,
    BLU: 'BLU' as ColoreEnum,
    ARANCIONE: 'ARANCIONE' as ColoreEnum
};