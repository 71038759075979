/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stato supervisori utente. Valori previsti: *ASSENTI*, *PRESENZA_INTERNO*, *ASSENZA_INTERNO*, *PRESENZA_INTERNO_MANUALE*
 */
export type StatoSupervisoriEnum = 'ASSENTI' | 'PRESENZA_INTERNO' | 'ASSENZA_INTERNO' | 'PRESENZA_INTERNO_MANUALE';

export const StatoSupervisoriEnum = {
    ASSENTI: 'ASSENTI' as StatoSupervisoriEnum,
    PRESENZAINTERNO: 'PRESENZA_INTERNO' as StatoSupervisoriEnum,
    ASSENZAINTERNO: 'ASSENZA_INTERNO' as StatoSupervisoriEnum,
    PRESENZAINTERNOMANUALE: 'PRESENZA_INTERNO_MANUALE' as StatoSupervisoriEnum
};