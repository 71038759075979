<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.approvazione_mobilita.hint_choose_periods')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col gap-4 items-center mt-2">

            <div class="flex flex-col md:flex-row gap-4 justify-end w-full items-center"
                 *ngIf="!!periodsConfiguration">
                <div class="flex flex-col md:flex-row gap-4 justify-end w-full items-center">
                    <button mat-flat-button
                            class="max-w-60"
                            color="primary"
                            [disabled]="showMiniLoader"
                            (click)="openFilters()">
                        <mat-spinner *ngIf="showMiniLoader" [diameter]="20" color="accent" class="mr-2"></mat-spinner>
                        <mat-icon class="mr-2" *ngIf="!showMiniLoader">manage_search</mat-icon>
                        {{ t('menu_filter.advanced_search') }}
                    </button>
                </div>

            </div>

            <div class="flex flex-col flex-auto w-full h-full overflow-auto p-2 gap-4"
                 *ngIf="!!periodsConfiguration">

                <div class="flex flex-row justify-between w-full">

                    <div class="mt-0.5 px-2 py-4 flex flex-row gap-4">
                        <!-- TESTO FILTRI APPLICATI -->
                        <div class=" text-2xl font-semibold mt-[0.35rem]">{{t('students_list.applied_filters')}}</div>
                        <button mat-flat-button
                                *ngIf="currentFilterListChipLabels?.length > 0"
                                (click)="resetFilters()"
                                [color]="'accent'">
                            <mat-icon class="mr-2">delete</mat-icon>
                            <span>{{t('menu_filter.reset')}}</span>
                        </button>

                    </div>


                </div>

                <div class="w-full flex flex-row justify-center items-center">
                    <!-- CHIPS -->
                    <div class="w-full flex items-center">

                        <div class="flex flex-col gap-1.5">

                            <mat-chip-list class="w-full">
                                <div class="w-full flex flex-row gap-1.5 p-2 flex-wrap">

                                    <mat-chip color="primary" class="justify-between text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600"
                                              *ngFor="let statoIn of operationConfig?.stati_in">
                                        {{(statoIn.stato | flussoStatusToLabel: ChiaveFlussoEnum.RichiestaMobilita) | titlecase}}
                                    </mat-chip>
                                    <mat-chip *ngIf="currentProfile === AuthorityType.COSUPERVISORE"
                                              color="primary" class="justify-between text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600">
                                        {{t('menu_filter.only_approvable_by_me') | titlecase}}
                                    </mat-chip>

                                    <ng-container *ngFor="let chip of currentFilterListChipLabels">
                                        <mat-chip color="primary" class="justify-between height-unset">
                                            {{chip | removeUnderscores}}
                                        </mat-chip>
                                    </ng-container>
                                </div>
                            </mat-chip-list>
                        </div>
                    </div>

                </div>

                <div class="w-full p-y-2.5" *ngIf="periodsConfiguration">
                    <app-generic-table *ngIf="!!periodsConfiguration"
                                       [configuration]="periodsConfiguration"
                                       [idRigheSolaLettura]="idRigheSolaLettura"
                                       (tableDataEmitter)="onPeriodsDataTableChanged($event)"
                                       (clickAction)="tableClickAction($event)"
                                       (pageAction)="pageAction($event)">
                    </app-generic-table>
                </div>
            </div>

        </div>



    </ng-template>



</ng-container>
