/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi widget
 */
export type ChiaveWidgetEnum = 'piani_formativi' | 'registro_attivita' | 'missioni' | 'spese' | 'richieste_modifica_budget' | 'autorizzazione_mobilita' | 'mobilita' | 'superamenti_anno' | 'inviti_esterni' | 'attivita_didattiche_rogrammate' | 'attivita_formative_di_ricerca' | 'altre_attivita_didattiche';

export const ChiaveWidgetEnum = {
    PianiFormativi: 'piani_formativi' as ChiaveWidgetEnum,
    RegistroAttivita: 'registro_attivita' as ChiaveWidgetEnum,
    Missioni: 'missioni' as ChiaveWidgetEnum,
    Spese: 'spese' as ChiaveWidgetEnum,
    RichiesteModificaBudget: 'richieste_modifica_budget' as ChiaveWidgetEnum,
    AutorizzazioneMobilita: 'autorizzazione_mobilita' as ChiaveWidgetEnum,
    Mobilita: 'mobilita' as ChiaveWidgetEnum,
    SuperamentiAnno: 'superamenti_anno' as ChiaveWidgetEnum,
    InvitiEsterni: 'inviti_esterni' as ChiaveWidgetEnum,
    AttivitaDidatticheRogrammate: 'attivita_didattiche_rogrammate' as ChiaveWidgetEnum,
    AttivitaFormativeDiRicerca: 'attivita_formative_di_ricerca' as ChiaveWidgetEnum,
    AltreAttivitaDidattiche: 'altre_attivita_didattiche' as ChiaveWidgetEnum
};