import {Component, Inject, OnInit} from '@angular/core';
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    EsitoOperazione,
    EsitoSingolaOperazioneInOperazioneMassiva, OperazioniMassiveService
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, Observable, takeUntil, tap} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {GenericOperationMassivaDataI} from "../generic-operation-steps-interface";
import {
    CAN_GO_AHEAD$,
    CHIAVE_FLUSSO$,
    CHIAVE_OPERAZIONE$,
    CURRENT_PROFILE,
    EXECUTE_OPERATION_SERVICE$, GET_ESITO_TITLE$,
    GET_REQUEST_IDENTITY_FIELD_NAME$, GET_REQUEST_TITLE$,
    OPERAZIONE_MASSIVA_DATA$
} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";


@Component({
    selector: 'app-confirm-operation-stepper',
    templateUrl: './confirm-operation.component.html',
    styleUrls: ['./confirm-operation.component.scss']
})
export class ConfirmOperationComponent extends AbstractDefaultComponent implements OnInit {

    operationMassivaData: GenericOperationMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaOperazioneInOperazioneMassiva> | undefined;
    resultsRequestsSelected: any[];

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                public operazioniMassiveService: OperazioniMassiveService,
                public cicloConfigurationService: CicloConfigurationService,
                @Inject(CHIAVE_FLUSSO$) protected chiaveFlusso: ChiaveFlussoEnum,
                @Inject(CHIAVE_OPERAZIONE$) protected chiaveOperazione: ChiaveOperazioneEnum,
                @Inject(GET_REQUEST_IDENTITY_FIELD_NAME$) protected getRequestIdentityFieldName: string,
                @Inject(EXECUTE_OPERATION_SERVICE$) protected executeOperationService$: (opMassivaData: GenericOperationMassivaDataI, opMassivaComp: ConfirmOperationComponent, cicloConfigurationService: CicloConfigurationService) => Observable<any>,
                @Inject(GET_ESITO_TITLE$) protected getEsitoTitle: (esito: any, resultsRequestsSelected?: any) => string,
                @Inject(GET_REQUEST_TITLE$) protected getRequestTitle: (request?: any) => string,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<GenericOperationMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: GenericOperationMassivaDataI) => {
            this.operationMassivaData = inputOutputData;
            // when there are studenti selected reset succeed and error flags
            if(this.operationMassivaData?.requestsSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        this.executeOperationService$(
            this.operationMassivaData,
            this,
            this.cicloConfigurationService,
        ).pipe(
            takeUntil(this.destroy$),
            tap((requestResponse: any) => {
                this.resultsRequestsSelected  = cloneDeep(this.operationMassivaData?.requestsSelected);
                // forcing the refresh of studenti
                this.operazioneMassivaData$.next({
                    requestsSelected: undefined,
                    refreshRequests: true,
                    requestsDocumentsValues: undefined,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (requestResponse: any) => {
                    this.operationSucceed = true;
                    this.operationEsiti = requestResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    protected readonly EsitoOperazione = EsitoOperazione;

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }




}
