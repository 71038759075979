import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AttivitaOffertaFormativaInfoView,
    AuthorityType,
    CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO, ChiaveFlussoEnum, ChiaveOperazioneEnum, DeliberaInfoView,
    DettaglioCalcoloImportoMobilita, MobilitaStudentiCicloService,
    PeriodiDiMobilitaService,
    PeriodoDiMobilitaStudenteInfoView,
    RichiestaDiRetribuzioneMobilitaInfoView,
    SpesaStudentePerApprovMassivaInfoView, SpeseStudentiCicloService,
    StudenteCicloIdAndUtenteInfoView,
    StudentiCicloService, TemplateCalcoloMaggiorazioneMobilitaEnum,
    TipologiaMobilitaInfoView,
    TipoPeriodoEnum
} from "../../../../../../api-clients/generated/services";
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    finalize,
    startWith,
    Subscription,
    switchMap,
    take,
    takeUntil
} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApprovazioneValidazioneMobilitaMassivaDataI} from '../approvazione-validazione-mobilita-steps-interface';
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get} from "lodash";
import {filter, tap} from "rxjs/operators";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {makeFilenameFromFE} from "../../../../../shared/utils/utils";
import {HttpResponse} from "@angular/common/http";
import {CicloCorsoRuoloInterface} from "../../../../../shared/interface/CicloCorsoRuoloInterface";
import * as fs from "file-saver";
import {FormComponent} from "../../../../../layout/common/generic-components/form/form.component";
import {CicloConfigurationService, DocumentDataType} from "../../../../../shared/service/ciclo-configuration.service";
import {StudentDetailsService} from "../../../student-details/student-details.service";
import {MatDatepicker} from "@angular/material/datepicker";
import {Moment} from "moment/moment";


@Component({
    selector: 'app-info-approvazione-richieste-validazione-stepper',
    templateUrl: './compila-info-approvazione-richieste-validazione.component.html',
    styleUrls: ['./compila-info-approvazione-richieste-validazione.component.scss']
})
export class CompilaInfoApprovazioneRichiesteValidazioneComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    richiesteCompileInfoConf: GenericTableConfigurationModel;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    corso: AttivitaOffertaFormativaInfoView = undefined;
    richiesteSelected: (RichiestaDiRetribuzioneMobilitaInfoView & {
        periodoMobilita: PeriodoDiMobilitaStudenteInfoView;
    })[];
    outputFormatData = 'DD/MM/YYYY';
    form: FormGroup;
    mobilityTypologies: Array<TipologiaMobilitaInfoView>;
    periodiFormArray: FormArray;
    currentPeriodFormArrayIndex: number;
    currentValidationFormArrayIndex: number;
    datesFilters: Map<number, Map<number, (d: Date | null) => boolean>>;
    sottoperiodiValueChangeSub = new Map<number, Subscription>();
    anteprimaRetribuzione = new Map<number, DettaglioCalcoloImportoMobilita>();
    loadingAnteprimaRetribuzione = new Map<number, boolean>();
    erroreAnteprimaRetribuzione = new Map<number, any>();
    documentsFormConfig: formGroupConfigInterface[];
    documentsFormValue: DocumentDataType;
    _docsFormComponent: FormComponent;
    docsFormComponentSub: Subscription;
    templateMaggiorazione: TemplateCalcoloMaggiorazioneMobilitaEnum;
    @ViewChild(FormComponent) set docsFormComponent(docsFormComp: FormComponent) {
        this._docsFormComponent = docsFormComp;
        // subscribe to document form value change
        this.subscribeToDocsFormValueChange();
    };

    private subscribeToDocsFormValueChange() {
        this.canGoNext$.next(this._docsFormComponent.formGroup.valid && this.periodiFormArray.valid);
        this.docsFormComponentSub?.unsubscribe();
        this.docsFormComponentSub = this._docsFormComponent?.formGroup?.valueChanges
            ?.pipe(startWith(this._docsFormComponent?.formGroup?.value)).subscribe(docsFormValue => {
            this.canGoNext$.next(this._docsFormComponent.formGroup.valid && this.periodiFormArray.valid);
            const documents = this.cicloConfigurationService.prepareDocsSubmitObject(
                ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                docsFormValue,
            );
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                //deliberaCollegio: this.delibere?.find(d => d.id === deliberaId),
                documentiAllegati: documents
            });
        });
    }
    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private fb: FormBuilder,
                private studentiCicloService: StudentiCicloService,
                private mobilitaStudentiCicloService: MobilitaStudentiCicloService,
                private speseStudentiCicloService: SpeseStudentiCicloService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                private cicloConfigurationService: CicloConfigurationService,
                private studentDetailsService: StudentDetailsService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneValidazioneMobilitaMassivaDataI>,) {
        super()
        this.datesFilters = new Map<number, Map<number, (d: (Date | null)) => boolean>>();
        this.templateMaggiorazione = this.cicloConfigurationService.getFlussoCicloConfig(
            ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita
        )?.template_calcolo_maggiorazione_mobilita;
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshData){
                this.canGoNext$.next(false);
                this.periodiFormArray = undefined;
            }
            this.buildCompileInfoComponent();
            // if richieste selected changes reset details form
            if(inputOutputData.richiesteValidazioneSelected !== this.richiesteSelected){
                this.richiesteSelected = inputOutputData?.richiesteValidazioneSelected;
                this._docsFormComponent?.formGroup?.reset({emitEvent: false});
                this.buildPeriodiDetailsFormGroup(this.richiesteSelected);
            }
        });
        this.typeRequest();
    }

   private buildCompileInfoComponent() {
        if(!!this.documentsFormConfig){
            return
        }
        this.documentsFormConfig =  [
            ...this.cicloConfigurationService.getDocsFormConfig(
                ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                (fileUrl) => this.downloadDocument(fileUrl),
                undefined,
            )
        ];
        this.documentsFormValue = {
            ...this.cicloConfigurationService.getDocsFormValues(
                ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                [],
            )
        };
    }

    clearDateField(fieldCtrl: AbstractControl) {
        fieldCtrl.setValue(undefined);
        fieldCtrl.setErrors(null);
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    private buildPeriodiDetailsFormGroup(selectedPeriods: (RichiestaDiRetribuzioneMobilitaInfoView & { periodoMobilita?: PeriodoDiMobilitaStudenteInfoView})[]) {
        this.periodiFormArray = this.fb.array(selectedPeriods?.map(period =>
            this.getPeriodoDetailsForm(period)));
        this.subscribeToSottoperiodiChange();
        // subscribe to form group change
        this.periodiFormArray.valueChanges?.pipe(startWith(this.periodiFormArray.value)).subscribe(fa => {
            console.log('details value changed', fa)
            this.canGoNext$.next(this.periodiFormArray.valid && this._docsFormComponent?.formGroup?.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                compileInfo: fa?.map(value => ({
                    ...value,
                }))
            });
        })
    }

    private getPeriodoDetailsForm(validation: RichiestaDiRetribuzioneMobilitaInfoView & { periodoMobilita?: PeriodoDiMobilitaStudenteInfoView} ) {
        let sottoperiodiFormGroups = validation?.periodi?.sort(
            (r1, r2) => moment(r1.dataInizio, 'YYYY-MM-DD').unix() - moment(r2.dataInizio, 'YYYY-MM-DD').unix()
        )?.map(val =>
            this.fb.group({
                dataInizio: [moment(val.dataInizio, 'YYYY-MM-DD').toDate(), Validators.required],
                dataFine:  [moment(val.dataFine, 'YYYY-MM-DD').toDate(), Validators.required],
            })
        );
        if(!sottoperiodiFormGroups || sottoperiodiFormGroups.length == 0) {
            sottoperiodiFormGroups = [
                this.fb.group({
                    dataInizio: [validation?.periodoMobilita?.dataInizio ? moment(validation?.periodoMobilita?.dataInizio, 'DD/MM/YYYY').toDate() : undefined, Validators.required],
                    dataFine:  [undefined as Date, Validators.required],
                })
            ]
        }
        const formGroup = this.fb.group({
            nota: undefined,
            sottoperiodi: this.fb.array(sottoperiodiFormGroups),
            id_richiesta_di_retribuzione_mobilita: validation.id,
        });
        if(this.templateMaggiorazione === TemplateCalcoloMaggiorazioneMobilitaEnum.MENSILEEMINIMO15GIORNITRASCORSI){
            const month =  moment(validation?.periodi?.[0]?.dataInizio, 'YYYY-MM-DD').startOf('month');
            const ctrl = this.fb.control(month.toDate(), Validators.required);
            (formGroup as FormGroup).addControl('mese', ctrl);
            formGroup.get('mese')?.valueChanges.subscribe(month => {
                (formGroup.get('sottoperiodi') as FormArray).clear();
                (formGroup.get('sottoperiodi') as FormArray).push(this.fb.group({
                    dataInizio: [moment(month).startOf('month').toDate(), Validators.required],
                    dataFine: [moment(month).endOf('month').toDate(), Validators.required],
                }));
            })
        }
        // this.subscribeToSottoperiodiChange();
        return formGroup;
    }



    buildRichiesteCompileInfoConfiguration(validazioni: (RichiestaDiRetribuzioneMobilitaInfoView & { studenteCiclo: StudenteCicloIdAndUtenteInfoView })[]): GenericTableConfigurationModel {
        const activeLang = this.translocoService.getActiveLang()
        const translation = this.translocoService.getTranslation().get(activeLang);
        return {
            configuration: {
                data: validazioni?.map(validazione => ({
                    ...validazione,
                    studenteUIMapped: [{
                        ...validazione?.studenteCiclo,
                        nome: validazione?.studenteCiclo?.utente?.nome,
                        cognome: validazione?.studenteCiclo?.utente?.cognome,
                        codiceFiscale: validazione?.studenteCiclo?.utente?.codiceFiscale
                    }],
                    id: validazione.id
                })),
                sticky: true,
                totalElements: validazioni?.length,
                isPaginatedBE: false,
                messaggioDatiAssenti: 'custom_table.no_data',
                hidePaginator: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        show: () => false,
                        nomeColonna: 'id',
                        colonnaKey: 'id',
                        hideColonna: true,
                        flex: 0,
                        formControl: {
                            type: TypeDialogFormEnum.TEXT,
                            show: false,
                            name: 'id',
                        }
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'common.dottorando',
                        colonnaKey: 'studenteForChip',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.INPUT_DATE,
                        nomeColonna: 'common.start_date',
                        colonnaKey: 'dataInizio',
                        flex: 15,
                        formControl: {
                            type: TypeDialogFormEnum.DATA,
                            show: true,
                            name: 'dataInizio',
                            required: true,
                        }
                    },
                    {
                        tipo: TipoColonnaEnum.INPUT_DATE,
                        nomeColonna: 'common.end_date',
                        colonnaKey: 'dataFine',
                        flex: 15,
                        formControl: {
                            type: TypeDialogFormEnum.DATA,
                            show: true,
                            name: 'dataFine',
                            required: false,
                        }
                    },
                    {
                        tipo: TipoColonnaEnum.INPUT_SELECT,
                        nomeColonna: 'mobility.mobility_type',
                        colonnaKey: 'mobilityType',
                        flex: 15,
                        formControl: {
                            type: TypeDialogFormEnum.SELECT,
                            show: true,
                            name: 'mobilityType',
                            required: false,
                        },
                        selectOptions: this.mobilityTypologies.map(t => ({
                            value: t.codice,
                            display: t.descrizione
                        }))
                    },
                    {
                        tipo: TipoColonnaEnum.INPUT_BOOLEAN,
                        nomeColonna: 'mobility.erasmus',
                        colonnaKey: 'erasmus',
                        flex: 8,
                        formControl: {
                            type: TypeDialogFormEnum.BOOLEAN,
                            show: true,
                            name: 'erasmus',
                            required: false,
                        }
                    },
                    {
                        tipo: TipoColonnaEnum.INPUT_TEXT,
                        nomeColonna: 'mobility.place_doctor_europeaus',
                        colonnaKey: 'sedeEuropeaus',
                        flex: 15,
                        formControl: {
                            type: TypeDialogFormEnum.TEXT,
                            show: true,
                            name: 'sedeEuropeaus',
                            required: false,
                        }
                    },
                ],
            }
        };
    }


    tableClickAction($event: ClickEvent) {
        if($event.tipoClick === TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST) {
            this.downloadAllegatiRequest($event.value);
        }
    }


    private downloadAllegatiRequest(spesa: SpesaStudentePerApprovMassivaInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.speseStudentiCicloService.getZipAllegatiRichiestaDiSpesaStudente(spesa?.budgetStudente?.studenteCiclo?.utente?.id, spesa?.codiceSpesa, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                //const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                const fileTypeForName = 'ALLEGATI_RICHIESTA_DI_SPESA_' + spesa?.codiceSpesa;
                const studentCicloCorsoRuolo: CicloCorsoRuoloInterface = {
                    codiceCorsoStudi: spesa?.budgetStudente?.studenteCiclo?.codiceCorsoDiStudiEsse3,
                    ciclo:  spesa?.budgetStudente?.studenteCiclo?.numeroCicloDottorato + '',
                    ruolo: AuthorityType.STUDENTE,
                    denominazioneCorsoStudi: ''
                }
                const outputFileName = makeFilenameFromFE(studentCicloCorsoRuolo, '.zip', fileTypeForName);
                fs.saveAs(fileResponse?.body, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err)
            }
        });
    }

    private typeRequest() {
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.getMobilitaRequestSelectFormValues()),
            tap((selectValues) => {
                this.mobilityTypologies = selectValues.tipologieMobilita;
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: () => {
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.typeRequest(), 'dialog.cancel',
                    err?.error?.message);
            }
        });
    }


    validazioniFormArray(periodoFormGroup: FormGroup): FormArray {
        return periodoFormGroup?.get("sottoperiodi") as FormArray;
    }

    removeSottoperiodo(periodoFormGroup: FormGroup, validationIndex: number) {
        this.validazioniFormArray(periodoFormGroup).removeAt(validationIndex);
    }

    addSottoperiodo(periodoFormGroup: FormGroup) {
        this.validazioniFormArray(periodoFormGroup).push(this.fb.group({
            dataInizio: [undefined, Validators.required],
            dataFine:  [undefined, Validators.required],
        }));
    }

    datepickerOpened(periodIndex: number, validationIndex: number) {
        this.currentPeriodFormArrayIndex = periodIndex;
        this.currentValidationFormArrayIndex = validationIndex;
    }

    private setDatesFilters(formGroup: FormGroup, periodIndex: number) {
        const map = new Map<number, (d: Date | null) => boolean >();
        //console.log('setting datefilers for sottoperiodi', this.formGroup?.value?.sottoperiodi)
        formGroup?.value?.sottoperiodi?.forEach((s, sottoperiodoIndex) => {
            this.validazioniFormArray(formGroup)?.at(sottoperiodoIndex)?.get('dataInizio')?.setErrors(null, {emitEvent: false});
            this.validazioniFormArray(formGroup)?.at(sottoperiodoIndex)?.get('dataFine')?.setErrors(null, {emitEvent: false});
            map.set(sottoperiodoIndex, this.createDateFilterForSottoperiodoWithIndex(sottoperiodoIndex, formGroup))
        });
        // updating validity
        formGroup?.value?.sottoperiodi?.forEach((s, sottoperiodoIndex) => {
            this.validazioniFormArray(formGroup)?.at(sottoperiodoIndex)?.get('dataInizio')?.updateValueAndValidity({emitEvent: false});
            this.validazioniFormArray(formGroup)?.at(sottoperiodoIndex)?.get('dataFine')?.updateValueAndValidity({emitEvent: false});
        });
        this.datesFilters.set(periodIndex, map);
    }

    private subscribeToSottoperiodiChange() {
        this.periodiFormArray.value.forEach((periodValue, periodIndex) => {
                const periodFormGroup = this.periodiFormArray.at(periodIndex) as FormGroup;
                this.datesFilters.clear();
                this.setDatesFilters(periodFormGroup, periodIndex);
                const tipoMob = this.richiesteSelected[periodIndex]?.periodoMobilita?.tipologiaMobilita?.codice;
                if(tipoMob !== 'V1' && tipoMob !== 'V2' && tipoMob !== 'V3' &&
                    !(this.richiesteSelected[periodIndex]?.periodoMobilita?.tipoPeriodoEnum === TipoPeriodoEnum.RICERCA && this.richiesteSelected[periodIndex]?.periodoMobilita?.nazioneStrutturaEstera?.codice === 'IT')) {
                    this.getAnteprimaRetribuzioneRequest(periodFormGroup?.value?.sottoperiodi, periodIndex);
                    // subscribe to sottoperiodi changes to get preview of retribution for added subperiods
                    this.sottoperiodiValueChangeSub.get(periodIndex)?.unsubscribe();
                    this.sottoperiodiValueChangeSub.set(periodIndex, periodFormGroup?.get('sottoperiodi')?.valueChanges?.pipe(
                        debounceTime(500),
                        distinctUntilChanged()
                    ).subscribe(sottoperiodi => {
                        //console.log('get anteprima retribuzuine request')
                        this.getAnteprimaRetribuzioneRequest(sottoperiodi, periodIndex);
                        this.setDatesFilters(periodFormGroup, periodIndex)
                    }));
                }
            });
    }

    private getAnteprimaRetribuzioneRequest(sottoperiodi: any, periodIndex: number) {
        this.loadingAnteprimaRetribuzione.set(periodIndex, true);
        const requestBody: CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO = {
            periodi: sottoperiodi?.map(p => ({dataInizio: moment(p.dataInizio).format(this.outputFormatData), dataFine: moment(p.dataFine).format(this.outputFormatData)})),
        }
        const period = this.richiesteSelected[periodIndex]?.periodoMobilita;
        const richiestaRetribuzioneToEdit = this.richiesteSelected[periodIndex];
        const idUtente = this.richiesteSelected[periodIndex]?.periodoMobilita?.studenteCiclo?.utente?.id;
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            takeUntil(this.destroy$),
            switchMap(_ => this.mobilitaStudentiCicloService.calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita(requestBody, idUtente, period?.codiceMobilita, richiestaRetribuzioneToEdit?.id)),
            finalize(() => this.loadingAnteprimaRetribuzione.set(periodIndex, false)),
        ).subscribe({
            next: (anteprimaRetribuzione: DettaglioCalcoloImportoMobilita) => {
                this.anteprimaRetribuzione.set(periodIndex, anteprimaRetribuzione);
                this.erroreAnteprimaRetribuzione.set(periodIndex, undefined);
            },
            error: (err) => {
                this.anteprimaRetribuzione.set(periodIndex, undefined);
                this.erroreAnteprimaRetribuzione.set(periodIndex, err);
            }
        });
    }


    private createDateFilterForSottoperiodoWithIndex(sottoperiodoIndex, formGroup: FormGroup) {
        return (d: Date | null): boolean => {
            // checking collisions with SOME periods of OTHER, NOT REFUSED retributions requests
/*            const otherRetributionsCollisions = this.data?.period?.richiesteDiRetribuzioneMobilita?.
            filter((r, index) => r.id !== this.data?.richiestaRetribuzioneToEdit?.id)?.
            filter((r) => r.stato !== RichiestaDiRetribuzioneMobilitaStatus.RIFIUTATA)?.
            filter(richiesta => richiesta?.periodi?.some(p => moment(p.dataInizio).isSameOrBefore(d, 'day') && moment(p.dataFine).isSameOrAfter(d, 'day')));*/
            // checking collisions with OTHER periods of the CURRENT retribution request
            const currentRetributionCollisions = this.validazioniFormArray(formGroup)?.controls?.
            filter((fa, index) => index !== sottoperiodoIndex)?.
            map(ctrl => ctrl.value).filter(p => moment(p.dataInizio).isSameOrBefore(d, 'day') && moment(p.dataFine).isSameOrAfter(d, 'day'));
            // checking collisions with SOME periods of OTHER, NOT REFUSED/CANCELED periods
           /* const otherPeriodsCollisions = this.data?.existingPeriods?.
            filter(p =>
                p.codiceMobilita !== this.data?.period?.codiceMobilita
                && p.codiceMobilita !== this.data?.period?.codiceMobilitaFiglio
                && p.codiceMobilita !== this.data?.period?.codiceMobilitaPadre)?.
            filter(p => p.stato !== PeriodoDiMobilitaStudenteStatus.RIFIUTATO && p.stato !== PeriodoDiMobilitaStudenteStatus.ANNULLATO)?.
            filter(p => moment(p.dataInizio).isSameOrBefore(d, 'day') && moment(p.dataFine).isSameOrAfter(d, 'day'));*/
            if(this.templateMaggiorazione === TemplateCalcoloMaggiorazioneMobilitaEnum.MENSILEEMINIMO15GIORNITRASCORSI
                && !moment(d).isSame(moment(formGroup.get('mese')?.value), 'month')){
                return false;
            }
            const valid =  (currentRetributionCollisions?.length || 0) == 0;
            //console.log('checking date', d, 'for sottoperiodo with index', sottoperiodoIndex, 'otherRetributionsCollisions',
            // otherRetributionsCollisions, 'currentRetributionCollisions', currentRetributionCollisions, 'otherPeriodsCollisions', otherPeriodsCollisions, 'valid', valid);
            return valid;
        };
    }

    downloadDocument(urlFile: string, deliberaData?: DeliberaInfoView) {
        if(deliberaData) {
            this.fuseConfirmationService.openDialogDelibera({
                delibera: deliberaData,
                codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
            });
        }

    }

    chosenMonthHandler(datepicker: MatDatepicker<Moment>, normalizedMonth: Moment, fieldCtrl: FormControl) {
        fieldCtrl.setValue(moment(normalizedMonth).toDate())
        datepicker.close();
    }

    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
    protected readonly TemplateCalcoloMaggiorazioneMobilitaEnum = TemplateCalcoloMaggiorazioneMobilitaEnum;
}
