import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    AnnoRiferimentoValues,
    SpesaStudentePerApprovMassivaInfoView, TipoFondoDiRiferimento
} from "../../../../../../api-clients/generated/services";
import {REQUEST_FORM_COMPONENT_DATA} from "../../generic-operation-steps/dynamic-host-component/dynamic-host.component";
import {max, min, sum} from "lodash";
import {
    RipartizioneImportoSpesaI
} from "../../../student-details/budget/dialog-pta-approve-spesa/dialog-pta-approve-spesa.component";
import {round} from "lodash-es";
import {CustomValidators} from "../../../../../shared/validators/custom-validators";

@Component({
    selector: 'app-approvazione-spesa-pta-form',
    templateUrl: './approvazione-spesa-pta-form.component.html',
    styleUrls: ['./approvazione-spesa-pta-form.component.scss']
})
export class ApprovazioneSpesaPtaFormComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() request: SpesaStudentePerApprovMassivaInfoView;
    @Input() requestsSelected: SpesaStudentePerApprovMassivaInfoView[];
    @Input() requestsFormValuesFormArray: FormArray;

    budgetResiduo: number;

    constructor(@Optional() @Inject(REQUEST_FORM_COMPONENT_DATA) public injectedData:
                {  form: FormGroup, request: SpesaStudentePerApprovMassivaInfoView , requestsSelected: SpesaStudentePerApprovMassivaInfoView[], requestsFormValuesFormArray: FormArray }) {
        this.form = this.form || this.injectedData?.form;
        this.request = this.request || this.injectedData?.request;
        this.requestsSelected = this.requestsSelected || this.injectedData?.requestsSelected;
        this.requestsFormValuesFormArray = this.requestsFormValuesFormArray || this.injectedData?.requestsFormValuesFormArray;

    }

    ngOnInit(): void {
        this.budgetResiduo = this.request.annoRiferimento === AnnoRiferimentoValues.PRIMO ? this.request.budgetStudente.budgetResiduoPrimoAnno :
            (this.request.annoRiferimento === AnnoRiferimentoValues.SECONDO ? this.request.budgetStudente.budgetSecondoAnno :
                this.request.budgetStudente?.budgetResiduoTerzoAnno);

    }



    getTipoFondo() {
        return [TipoFondoDiRiferimento.BUDGETDOTTORANDO, TipoFondoDiRiferimento.ALTRIFONDI, TipoFondoDiRiferimento.BUDGETDOTTORANDOPIUALTRIFONDI];
    }



    protected readonly TipoFondoDiRiferimento = TipoFondoDiRiferimento;
    protected readonly max = max;
    protected readonly AnnoRiferimentoValues = AnnoRiferimentoValues;
    protected readonly getSumImportiForOtherRequestsOfSameStudentSpesaPta = getSumImportiForOtherRequestsOfSameStudentSpesaPta;
}


export function  setEnableStatesSpesaPta(formGroupSpesa: FormGroup, tipoFondoRiferimento?: TipoFondoDiRiferimento) {
    const value = tipoFondoRiferimento ?? formGroupSpesa?.get('tipoFondoRiferimento')?.value;
    if(value !== TipoFondoDiRiferimento.BUDGETDOTTORANDO){
        formGroupSpesa.get('descrizioneFondo').enable();
        // if(this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneFondi) === FunzionalitaStatus.ABILITATA){
        //     this.form.get('fondoRiferimento')?.enable();
        // }
        formGroupSpesa.get('importoSpesaAltriFondi')?.enable();
    } else {
        formGroupSpesa.get('descrizioneFondo').disable();
        formGroupSpesa.get('fondoRiferimento')?.disable();
        formGroupSpesa.get('importoSpesaAltriFondi')?.disable();
    }
    if(value === TipoFondoDiRiferimento.ALTRIFONDI){
        formGroupSpesa.get('importoSpesaPrimoAnno')?.disable();
        formGroupSpesa.get('importoSpesaSecondoAnno')?.disable();
        formGroupSpesa.get('importoSpesaTerzoAnno')?.disable();
    } else {
        formGroupSpesa.get('importoSpesaPrimoAnno')?.enable();
        formGroupSpesa.get('importoSpesaSecondoAnno')?.enable();
        formGroupSpesa.get('importoSpesaTerzoAnno')?.enable();
    }
}

export function getSumImportiForOtherRequestsOfSameStudentSpesaPta(spesa: SpesaStudentePerApprovMassivaInfoView,
                                                           annoToCount: AnnoRiferimentoValues,
                                                           requestsSelected: SpesaStudentePerApprovMassivaInfoView[],
                                                           requestsFormValuesFormArray: FormArray) {
    const requests = requestsSelected
        ?.map((otherSpesa, index) => {
            if(spesa.codiceSpesa !== otherSpesa.codiceSpesa && spesa.budgetStudente?.studenteCiclo?.utente?.id === otherSpesa.budgetStudente?.studenteCiclo?.utente?.id) {
                if (annoToCount === AnnoRiferimentoValues.PRIMO)
                    return requestsFormValuesFormArray?.at(index)?.get('requestForm')?.value?.importoSpesaPrimoAnno ?? 0;
                else if (annoToCount === AnnoRiferimentoValues.SECONDO)
                    return requestsFormValuesFormArray?.at(index)?.get('requestForm')?.value?.importoSpesaSecondoAnno ?? 0;
                else (annoToCount === AnnoRiferimentoValues.TERZO)
                return requestsFormValuesFormArray?.at(index)?.get('requestForm')?.value?.importoSpesaTerzoAnno ?? 0;
            } else {
                return 0;
            }
        }) ?? [];
    return sum(requests);
}


export function getRipartizioneImportoSpesaPta(importoApprovato: number,
    tipoFondoRiferimento: TipoFondoDiRiferimento,
    requestsSelected: SpesaStudentePerApprovMassivaInfoView[],
    requestsFormValuesFormArray: FormArray,
    spesa?: SpesaStudentePerApprovMassivaInfoView): RipartizioneImportoSpesaI {
    if (!importoApprovato) {
        return {
            importoSpesaPrimoAnno: 0,
            importoSpesaSecondoAnno: 0,
            importoSpesaTerzoAnno: 0,
            importoSpesaAltriFondi: 0,
        }
    } else {
        const importoSpesaPrimoAnno = tipoFondoRiferimento === TipoFondoDiRiferimento.ALTRIFONDI ? 0 : min([
            importoApprovato,
            max([0, spesa?.budgetStudente?.budgetResiduoPrimoAnno-getSumImportiForOtherRequestsOfSameStudentSpesaPta(spesa, AnnoRiferimentoValues.PRIMO, requestsSelected, requestsFormValuesFormArray)])
        ]);
        const importoSpesaSecondoAnno = tipoFondoRiferimento === TipoFondoDiRiferimento.ALTRIFONDI ? 0 : min([
            (importoApprovato - importoSpesaPrimoAnno),
            max([0, spesa?.budgetStudente?.budgetResiduoSecondoAnno-getSumImportiForOtherRequestsOfSameStudentSpesaPta(spesa, AnnoRiferimentoValues.SECONDO, requestsSelected, requestsFormValuesFormArray)])
        ]);
        const importoSpesaTerzoAnno = tipoFondoRiferimento === TipoFondoDiRiferimento.ALTRIFONDI ? 0 : min([
            (importoApprovato - importoSpesaPrimoAnno - importoSpesaSecondoAnno),
            max([0, spesa?.budgetStudente?.budgetResiduoTerzoAnno-getSumImportiForOtherRequestsOfSameStudentSpesaPta(spesa, AnnoRiferimentoValues.TERZO, requestsSelected, requestsFormValuesFormArray)])
        ]);
        const importoSpesaAltriFondi = tipoFondoRiferimento === TipoFondoDiRiferimento.BUDGETDOTTORANDO ? 0 :
            (importoApprovato - importoSpesaPrimoAnno - importoSpesaSecondoAnno - importoSpesaTerzoAnno);
        return {
            importoSpesaPrimoAnno,
            importoSpesaSecondoAnno,
            importoSpesaTerzoAnno,
            importoSpesaAltriFondi,
        }
    }
}

export function getRequestFormGroupForApprovazioneSpesaPta(fb: FormBuilder, request: SpesaStudentePerApprovMassivaInfoView, requestsSelected: SpesaStudentePerApprovMassivaInfoView[], formArray: FormArray) {
    const ripartizioneImporto = getRipartizioneImportoSpesaPta(request?.importoApprovato ?? request?.importoRichiesto, request?.tipoFondoRichiesta, requestsSelected, formArray, request);
    const formGroup =  fb.group({
        importoSpesa: [request.importoApprovato ?? request.importoRichiesto, [Validators.required, Validators.min(0.1)]],
        importoSpesaPrimoAnno: [ripartizioneImporto.importoSpesaPrimoAnno,
            [Validators.required, (control: AbstractControl) => Validators.max(max([0, round(request.budgetStudente?.budgetResiduoPrimoAnno-getSumImportiForOtherRequestsOfSameStudentSpesaPta(request, AnnoRiferimentoValues.PRIMO, requestsSelected, formArray), 2)]))(control)]],
        importoSpesaSecondoAnno: [ripartizioneImporto.importoSpesaSecondoAnno,
            [Validators.required, (control: AbstractControl) => Validators.max(max([0, round(request.budgetStudente?.budgetResiduoSecondoAnno-getSumImportiForOtherRequestsOfSameStudentSpesaPta(request, AnnoRiferimentoValues.SECONDO, requestsSelected, formArray), 2)]))(control)]],
        importoSpesaTerzoAnno: [ripartizioneImporto.importoSpesaTerzoAnno,
            [Validators.required, (control: AbstractControl) => Validators.max(max([0, round(request.budgetStudente?.budgetResiduoTerzoAnno-getSumImportiForOtherRequestsOfSameStudentSpesaPta(request, AnnoRiferimentoValues.TERZO, requestsSelected, formArray), 2)]))(control)]],
        importoSpesaAltriFondi: [ripartizioneImporto.importoSpesaAltriFondi, Validators.required],
        tipoFondoRiferimento: [request?.tipoFondoRichiesta, [Validators.required]],
        // fondoRiferimento: [undefined /* this.data?.spesa?.fondoRiferimentoRichiesta*/, [Validators.required]] ,
        descrizioneFondo: [request?.descrizioneFondoRichiesta, [Validators.required]],
    }, {validators: [CustomValidators.RipartizioneImportoSpesaValidator()]});
    setEnableStatesSpesaPta(formGroup, formGroup.get('tipoFondoRiferimento').value);
    formGroup.get('importoSpesa').valueChanges
        .subscribe((value) => {
            formGroup.patchValue(getRipartizioneImportoSpesaPta(value, formGroup.get('tipoFondoRiferimento').value, requestsSelected, formArray, request));
        });
    formGroup.get('tipoFondoRiferimento').valueChanges.subscribe((value) => {
        setEnableStatesSpesaPta(formGroup, value);
        formGroup.patchValue(getRipartizioneImportoSpesaPta(formGroup.get('importoSpesa').value, value, requestsSelected, formArray, request));
    });
    return formGroup;
}
