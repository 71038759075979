import {
    cannotRemoveTrasversalFromPlan,
    getCfuOttentutiFromApprovedPlan,
    getCfuPrevisti,
    getDataSuperamentoValueFromApprovedPlan,
    getMotivazioneRifiutoValueFromApprovedPlan,
    getNotaSuperamentoValueFromApprovedPlan,
    getStatoAttivitaTrasversaleFromApprovedPlan,
    getSuperatoValueFromApprovedPlan
} from '../../study-plan-utils';
import {get, sortBy, trim} from 'lodash';
import {
    AggiornamentoPianoDiStudiInfoView,
    AnnoRiferimentoValues,
    AttivitaCategoriaAssociationInfoView,
    AttivitaTrasversaleStatus,
    CategoriaInAggiornamentoInPianoDiStudiInfoView,
    CategoriaInPianoDiStudiInfoView,
    DatiCorsoOffertaFormativa,
    OffertaFormativaInfoViewImpl,
    PianoDiStudiInfoViewImpl
} from '../../../../../../../api-clients/generated/services';
import {
    ConfigurazioneColonna,
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from '../../../../../../shared/components/table/model/generic-table-model';
import {
    createPercorsoDiStudiListString,
    getPresumedNumbers,
    getPresumedNumbersForDraft
} from './configurazione-corsi-nuova-presentazione';
import {
    TrainingOfferCategoryDataUI
} from 'app/modules/landing/training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component';
import {Translation} from "@ngneat/transloco";
import {
    annoRiferimentoToCheckActivityInCategoryYearCallback
} from "../../../../cycle/training-offer/training-offer-utils";
import {annoRiferimentoFromRomanNumeral} from "../../../../../../shared/utils/utils";
import {CicloConfigurationService} from "../../../../../../shared/service/ciclo-configuration.service";

export function buildConfigurationForTransversalActivitiesForNewPresentationReadMode(
    cicloConfigurationService: CicloConfigurationService,
    translation: Translation,
    category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoInPianoDiStudiInfoView,
    year: AnnoRiferimentoValues,
    approvedPlan?: PianoDiStudiInfoViewImpl,
    showingOfferta?: boolean): GenericTableConfigurationModel {

    const coursesPlan = category.corsi;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbersForDraft(category);

    let tableData = coursesPlan?.map(item => ({
        ...(item?.datiCorsoOffertaFormativa ?? item),
        isArchiviato: item?.datiCorsoOffertaFormativa.isArchiviato || item?.datiCorsoOffertaFormativa.isAssociazioneArchiviata,
    }));

    tableData = tableData?.map(element => ({
        ...element,
        readonly: true,
        includedInPreviousPlan: !!approvedPlan.categorie
            ?.find(c => c.idCategoriaOffertaFormativa === category.idCategoriaOffertaFormativa)?.corsi
            ?.find(a => a?.idCorsoInOffertaFormativa === element.idCorsoInOffertaFormativa && a?.annoInsegnamento === year),
        idAttivitaTrasversale: element?.idAttivitaTrasversale,
        statoAttivitaTrasversale: getStatoAttivitaTrasversaleFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa),
        superato: getSuperatoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        notaSuperamento: getNotaSuperamentoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        dataSuperamento: getDataSuperamentoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        tipoDocumentoDottorandoFormatted: element?.tipoDocumentoDottorando || '-',
        tipoDocumentoSupervisoreFormatted: element?.tipoDocumentoSupervisore || '-',
        idCorsoInOffertaFormativa: element.idCorsoInOffertaFormativa,
        id: element.id ?? element.idAttivitaProposta ?? element.idAttivitaPresunta,
        denominazione: element.denominazione ?? element.denominazioneAttivitaPresunta,
        denominazioneFormatted: (element.idAttivitaPresunta && !element.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : element.denominazione,
        idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
        cfuPrevisti: getCfuPrevisti(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        cfuOttenuti: getCfuOttentutiFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        motivazioneRifiuto: getMotivazioneRifiutoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
    }));

    tableData = sortBy(tableData, [
        attivita => !attivita.idAttivitaProposta,
        attivita => !attivita.idAttivitaPresunta,
        attivita => trim(attivita?.denominazioneFormatted?.toLowerCase()),
    ], ['asc', 'asc', 'asc',]);


    const myConfig = {
        configuration: {
            data: tableData,
            totalElements: tableData?.length,
            isPaginatedBE: false,
            sticky: true,
            selection: showingOfferta ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'denominazione',
                selectedColumnKeyPrimaryKey: 'id',
            } : undefined,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_typology',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 20,
                    alsoShowChipIf: (activity) => {
                        if(activity.idAttivitaProposta){
                            return {
                                chipText: 'study_plan_status.proposed_activity',
                                iconName: 'school',
                                classes: 'text-blue-600'
                            };
                        } else if (activity.idAttivitaPresunta){
                            return {
                                chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                iconName: 'question_mark',
                                classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                            };
                        } else if (activity.cfuPrevisti == 0){
                            return {
                                chipText: 'study_plan_status.transversal_to_fill',
                                iconName: 'cancel',
                                classes: 'text-red-600 icon-size-5'
                            };
                        } else {
                            return undefined;
                        }
                    },

                    // showAlsoIconIf: (activity) => isCorsoArchivedRemovableForTable(activity) ? {
                    //     name: 'warning_amber',
                    //     tooltipText: 'study_plan_status.archived_to_remove',
                    //     color: 'warn'
                    // } : undefined,
                },
                {
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: cicloConfigurationService.activityUnitOfMeasureLabel,
                    colonnaKey: 'cfuPrevisti',
                    flex: 5,
                },

/*                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'student.passed',
                    colonnaKey: 'superato',
                    flex: 16,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },*/
            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };
    (myConfig.configuration.configurazioneTabella as ConfigurazioneColonna[])?.push(
        {
            tipo: TipoColonnaEnum.STRING,
            nomeColonna: 'cycle_doctorate.student_document',
            colonnaKey: 'tipoDocumentoDottorandoFormatted',
            flex: 20,
        },
        {
            tipo: TipoColonnaEnum.STRING,
            nomeColonna: 'cycle_doctorate.supervisor_document',
            colonnaKey: 'tipoDocumentoSupervisoreFormatted',
            flex: 20,
        },
        {
            tipo: TipoColonnaEnum.BOOLEAN,
            nomeColonna: 'cycle_doctorate.coordinator_required',
            colonnaKey: 'presenzaDeliberaCoordinatore',
            convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
            flex: 8,
        },
        {
            tipo: TipoColonnaEnum.CHIP_STATUS,
            nomeColonna: 'common.status',
            colonnaKey: 'statoAttivitaTrasversale',
            flex: 18,
            statusType: AttivitaTrasversaleStatus,
            show: (_, activity) => (activity.includedInPreviousPlan || activity.idAttivitaProposta) && (!activity.idAttivitaPresunta || activity.denominazione),
        },
        {
            tipo: TipoColonnaEnum.BOOLEAN,
            nomeColonna: 'cycle_doctorate.mandatory',
            colonnaKey: 'isObbligatorio',
            flex: 8,
            show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
            convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
        },
        {
            tipo: TipoColonnaEnum.BOOLEAN,
            nomeColonna: 'study_plan_status.archived_activity',
            colonnaKey: 'isArchiviato',
            show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
            flex: 8,
            convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
        },
        {
            tipo: TipoColonnaEnum.ACTION,
            nomeColonna: 'common.detail',
            colonnaKey: 'descrizione',
            flex: 10,
            button: [{
                nameIconButton: 'info',
                click: TipoClickEnum.SHOW,
                tooltip: 'common.show_detail',
                color: 'accent'
            }]
        },
    );

    return myConfig as GenericTableConfigurationModel;
}


export function buildConfigurationForTransversalActivitiesForNewPresentationEditMode(
    cicloConfigurationService: CicloConfigurationService,
    translation: Translation,
    category: TrainingOfferCategoryDataUI,
    draft?: AggiornamentoPianoDiStudiInfoView,
    approvedPlan?: PianoDiStudiInfoViewImpl,
    showingOfferta?: boolean): GenericTableConfigurationModel {

    const activities = category.attivitaCategoriaAssociations;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbers(category);

    let tableData: any = activities?.map(item => ({
        ...item?.attivitaOffertaFormativa,
        // activity can be archived globally or in category-activity association
        isArchiviato: item.isArchiviato || item?.attivitaOffertaFormativa.isArchiviato,
        isArchiviatoActivity: item?.attivitaOffertaFormativa.isArchiviato,
        isArchiviatoAssociazione: item.isArchiviato
    }));

    tableData = tableData?.map(element =>
        ({
            ...element,
            readonly: true,
            curriculumRiferimento: createPercorsoDiStudiListString(element?.percorsiDiStudi),
            tipoDocumentoDottorandoFormatted: element?.tipoDocumentoDottorando || '-',
            tipoDocumentoSupervisoreFormatted: element?.tipoDocumentoSupervisore || '-',
            id: element.id ?? (element as DatiCorsoOffertaFormativa).idAttivitaProposta ?? (element as DatiCorsoOffertaFormativa).idAttivitaPresunta,
            denominazione: element.denominazione ?? element.denominazioneAttivitaPresunta,
            denominazioneFormatted: (element.idAttivitaPresunta && !element.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : element.denominazione,
            includedInPreviousPlan: !!approvedPlan.categorie
                ?.find(c => c.idCategoriaOffertaFormativa === category.id)?.corsi
                ?.find(a => a?.idCorsoInOffertaFormativa === element.id && a?.annoInsegnamento === annoRiferimentoFromRomanNumeral(category.year)),
            statoAttivitaTrasversale: getStatoAttivitaTrasversaleFromApprovedPlan(element, approvedPlan, category.id),
            superato:  getSuperatoValueFromApprovedPlan(element, approvedPlan, category.id, true),
            notaSuperamento: getNotaSuperamentoValueFromApprovedPlan(element, approvedPlan, category.id, true),
            dataSuperamento: getDataSuperamentoValueFromApprovedPlan(element, approvedPlan, category.id, true),
            idCategoriaOffertaFormativa: category.id,
            cfuPrevisti: getCfuPrevisti(element, approvedPlan, category.id, true),
            cfuOttenuti: getCfuOttentutiFromApprovedPlan(element, approvedPlan, category.id, true),
            motivazioneRifiuto: getMotivazioneRifiutoValueFromApprovedPlan(element, approvedPlan, category.id, true),
        })
    );

    // showing archived only if they are in the current plan
    // tableData = tableData.filter(a => !a.isArchiviato || a.includedInPreviousPlan);

    tableData = sortBy(tableData, [
        attivita => !(attivita as DatiCorsoOffertaFormativa).idAttivitaProposta,
        attivita => !(attivita as DatiCorsoOffertaFormativa).idAttivitaPresunta,
        attivita => trim(attivita?.denominazioneFormatted?.toLowerCase()),
    ], ['asc', 'asc', 'asc', ]);

    const myConfig = {
        configuration: {
            data: tableData,
            totalElements: tableData?.length,
            isPaginatedBE: false,
            sticky: true,
           /* selection: showingOfferta ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'denominazione',
                selectedColumnKeyPrimaryKey: 'id',
                selectByDefaultIf: (activity) => {
                    if (activity.isObbligatorio && !approvedPlan.sottomissionePianoNonValidoSbloccata){
                        return 'study_plan_status.cant_remove_because_mandatory';
                    } else if (cannotRemoveTrasversalFromPlan(activity)){
                        return 'study_plan_status.cant_remove_because_superato';
                    } else if(activity.idAttivitaProposta){
                        return 'study_plan_status.cant_remove_because_proposed_activity';
                    } else if (activity.idAttivitaPresunta){
                        return'study_plan_status.cant_remove_because_presumed_activity';
                    }
                }
            } : undefined,*/
            disabledCssClass: 'generic-table-disabled-row',
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_typology',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 25,
                    alsoShowChipIf: (activity) => {
                        if(activity.idAttivitaProposta){
                            return {
                                chipText: 'study_plan_status.proposed_activity',
                                iconName: 'school',
                                classes: 'text-blue-600'
                            };
                        } else if (activity.idAttivitaPresunta){
                            return {
                                chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                iconName: 'question_mark',
                                classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                            };
                        } else if (activity.cfu == 0){
                            return {
                                chipText: 'study_plan_status.transversal_to_fill',
                                iconName: 'cancel',
                                classes: 'text-red-600 icon-size-5'
                            };
                        } else {
                            return undefined;
                        }
                    },
                    /*showAlsoIconIf: (activity) => isCorsoArchivedRemovableForTable(activity) ? {
                        name: 'warning_amber',
                        tooltipText: 'study_plan_status.archived_to_remove',
                        color: 'warn'
                    } : undefined,*/
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: cicloConfigurationService.activityUnitOfMeasureLabel,
                    colonnaKey: 'cfu',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.student_document',
                    colonnaKey: 'tipoDocumentoDottorandoFormatted',
                    flex: 18,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.supervisor_document',
                    colonnaKey: 'tipoDocumentoSupervisoreFormatted',
                    flex: 18,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.coordinator_required',
                    colonnaKey: 'presenzaDeliberaCoordinatore',
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'statoAttivitaTrasversale',
                    flex: 18,
                    statusType: AttivitaTrasversaleStatus
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.mandatory',
                    colonnaKey: 'isObbligatorio',
                    flex: 8,
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    flex: 8,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 8,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.EDIT,
                            tooltip: 'common.edit',
                            valueShow: 'id',
                            show: (_, activity) => (activity.idAttivitaPresunta || activity.idAttivitaProposta) && !cannotRemoveTrasversalFromPlan(activity) && !category.isArchiviata,
                            color: 'accent',
                        },
                        {
                            nameIconButton: 'done',
                            click: TipoClickEnum.CONFERMA,
                            tooltip: 'study_plan_status.fill_presumed',
                            valueShow: 'id',
                            show: (_, activity) => (activity.idAttivitaPresunta && !activity.denominazione),
                            color: 'accent',
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.DELETE,
                            valueShow: 'id',
                            show: (_, activity) => (activity.idAttivitaPresunta || activity.idAttivitaProposta) && !cannotRemoveTrasversalFromPlan(activity),
                            tooltip: 'common.delete',
                            color: 'accent',
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.MODIFICA_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.edit_transversal_activity',
                            valueShow: ['isArchiviato'],
                            show: (value, activity) => !activity.isArchiviato && !cannotRemoveTrasversalFromPlan(activity) && !activity.idAttivitaPresunta && !activity.idAttivitaProposta && !category.isArchiviata,
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.RIMUOVI_ATTIVITA_TRASVERSALE,
                            valueShow: 'id',
                            show: (value, activity) => !cannotRemoveTrasversalFromPlan(activity) && !activity.idAttivitaPresunta && !activity.idAttivitaProposta,
                            tooltip: 'study_plan_status.remove_transversal_activity',
                            color: 'accent'
                        },
                    ]
                }
            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };

    return myConfig as GenericTableConfigurationModel;
}



export function buildConfigurationForTransversalTipologiesForNewPresentationEditMode(
    offertaFormativa: OffertaFormativaInfoViewImpl,
    category: TrainingOfferCategoryDataUI,
    approvedPlan?: PianoDiStudiInfoViewImpl,
    draft?: AggiornamentoPianoDiStudiInfoView): GenericTableConfigurationModel {

   const activitiesTipologies = getTransversalTypologiesForYearAndCategory(category, offertaFormativa, approvedPlan);

    let tableData: any = activitiesTipologies?.map(item => ({
        ...item?.attivitaOffertaFormativa,
    }));

    tableData = tableData?.map(item =>
        ({
            ...item,
            cfuOrDescCFU: item?.cfu || item?.descrizioneCfu,
            tipoDocumentoDottorandoFormatted: item?.tipoDocumentoDottorando || '-',
            tipoDocumentoSupervisoreFormatted: item?.tipoDocumentoSupervisore || '-',
        })
    );

    tableData = sortBy(tableData, [
        attivita => trim(attivita?.denominazione?.toLowerCase()),
    ], ['asc',]);

    const myConfig = {
        configuration: {
            data: tableData,
            totalElements: tableData?.length,
            isPaginatedBE: false,
            sticky: true,
            disabledCssClass: 'generic-table-disabled-row',
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_typology',
                    colonnaKey: 'denominazione',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'dialog_add_edit_categories.is_desc_cfu',
                    colonnaKey: 'cfuOrDescCFU',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.student_document',
                    colonnaKey: 'tipoDocumentoDottorandoFormatted',
                    flex: 18,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.supervisor_document',
                    colonnaKey: 'tipoDocumentoSupervisoreFormatted',
                    flex: 18,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.coordinator_required',
                    colonnaKey: 'presenzaDeliberaCoordinatore',
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.mandatory',
                    colonnaKey: 'isObbligatorio',
                    flex: 8,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                },
                {
                    tipo: TipoColonnaEnum.ACTION,
                    nomeColonna: 'common.actions',
                    colonnaKey: 'descrizione',
                    flex: 8,
                    button: [
                        {
                            nameIconButton: 'add',
                            click: TipoClickEnum.ADD_TRANSVERSAL_TO_PLAN,
                            tooltip: 'student.add_activity',
                            color: 'primary'
                        },
                    ]
                },
            ],
            hidePaginator: false,
            pageSizeOptions: [10, 20, 30, 40, 50],
            pageSize: 10,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };

    return myConfig as GenericTableConfigurationModel;
}

export function getTransversalTypologiesForYearAndCategory(category: TrainingOfferCategoryDataUI, offertaFormativa: OffertaFormativaInfoViewImpl, currentPianoInfoView: PianoDiStudiInfoViewImpl) {
    const alreadyShowedIdsCorso = [];
    const activities = offertaFormativa?.categorie
        ?.find(c => c.id === category.id)
        ?.attivitaCategoriaAssociations
        ?.filter(a => annoRiferimentoToCheckActivityInCategoryYearCallback(annoRiferimentoFromRomanNumeral(category.year))(a));
    return activities?.filter(a => (!a.isArchiviato && !a.attivitaOffertaFormativa?.isArchiviato))
        ?.filter(a => {
            // taking activity only one time
            const show = !alreadyShowedIdsCorso.includes(a.attivitaOffertaFormativa?.id);
            if (show) {
                alreadyShowedIdsCorso.push(a.attivitaOffertaFormativa?.id);
            } else {
                const alreadyAddedActivity = activities.find(aToFind => aToFind.attivitaOffertaFormativa?.id === a.attivitaOffertaFormativa?.id);
                alreadyAddedActivity.isArchiviato = false;
            }
            return show;
        })
        ?.sort((item1, item2) =>
            item1.attivitaOffertaFormativa?.denominazione?.localeCompare(item2.attivitaOffertaFormativa?.denominazione));
}

export function inLastApprovedPlan(category: TrainingOfferCategoryDataUI, a: AttivitaCategoriaAssociationInfoView, currentPianoInfoView: PianoDiStudiInfoViewImpl) {
    return !!currentPianoInfoView.categorie
        ?.find(c => c.idCategoriaOffertaFormativa === category.id)?.corsi
        ?.find(att => att?.idCorsoInOffertaFormativa === a.attivitaOffertaFormativa?.id
            && att?.annoInsegnamento ===  annoRiferimentoFromRomanNumeral(category.year));
}
