import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
    GenericTableConfigurationModel,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AttivitaOffertaFormativaInfoView,
    StudenteCicloUtenteViewImpl
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {FormGroup} from "@angular/forms";
import {SuperamentoCorsoMassivoDataI} from '../superamento-corso-steps-interface';
import {FormComponent} from "../../../../../layout/common/generic-components/form/form.component";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";


@Component({
    selector: 'app-info-superamento-stepper',
    templateUrl: './compila-info-superamento.component.html',
    styleUrls: ['./compila-info-superamento.component.scss']
})
export class CompilaInfoSuperamentoComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    studentsListConfiguration: GenericTableConfigurationModel;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    corso: AttivitaOffertaFormativaInfoView = undefined;
    private minValCFUErrorMessage: any;
    private maxValCFUErrorMessage: any;
    private dataMaxSuperamentoCorso: Date = moment().toDate();
    private selectedStudents: StudenteCicloUtenteViewImpl[];
    customNotesFormGroup: FormGroup = new FormGroup<any>({});
    formComponent: FormComponent;
    @ViewChild(FormComponent) set tabsGroup(form: FormComponent)  {
        if(form) {
            this.canGoNext$.next(form.isValid());
            this.formComponent = form;
        }
    }

    constructor(private translocoService: TranslocoService,
                private cicloConfigurationService: CicloConfigurationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<SuperamentoCorsoMassivoDataI>,) {
        super();
        this.translocoService.selectTranslate('form.cfu_max', { activityUnitOfMeasure: this.translocoService.translate(this.cicloConfigurationService.activityUnitOfMeasureLabel) }).subscribe(value => this.maxValCFUErrorMessage = value);
        this.translocoService.selectTranslate('form.cfu_min', { activityUnitOfMeasure: this.translocoService.translate(this.cicloConfigurationService.activityUnitOfMeasureLabel) }).subscribe(value => this.minValCFUErrorMessage = value);
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: SuperamentoCorsoMassivoDataI) => {
            // when course changes reset the info forms
            if(this.corso?.id !== inputOutputData?.corsoSelected?.id) {
                this.corso = inputOutputData?.corsoSelected;
                this.initFormConfig();
                this.studentsListConfiguration = undefined;
                this.canGoNext$.next(false);
            }
            // when confirm step force refresh set completed flag to false
            if(inputOutputData.refreshStudents){
                this.canGoNext$.next(false);
            }
            this.selectedStudents = inputOutputData?.studentiSelected;
        });
        this.customNotesFormGroup?.valueChanges?.subscribe((value) => {
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$.getValue(),
                noteSuperamentoCustom: this.customNotesFormGroup.get(['child', 'input_fields'])?.value
            });
        })
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    buildListaStudentiConfiguration(studenti: StudenteCicloUtenteViewImpl[]): GenericTableConfigurationModel {
        return {
            configuration: {
                data: studenti?.map(studenteContent => ({
                    ...studenteContent,
                    id: studenteContent?.idUtente,
                    studente: studenteContent.cognome?.toUpperCase() + ' ' + studenteContent.nome?.toUpperCase(),
                    supervisore: (studenteContent?.supervisore?.cognome?.toUpperCase() || studenteContent?.supervisore?.nome?.toUpperCase() ) ?
                        ((studenteContent?.supervisore?.cognome?.toUpperCase() || '') + ' ' + (studenteContent?.supervisore?.nome?.toUpperCase() || '')).toUpperCase() : '',

                    tipologiaBorsa: (studenteContent?.tipoBorsaPerCiclo?.descrizioneTipoBorsa),
                    curriculum: (studenteContent?.percorsoDiStudi?.descrizionePercorsoDiStudi),
                    //dataIscrizione: this.formatDay(studenteContent?.dataIscrizione),
                    nota: this.operazioneMassivaData$.getValue()?.notaSuperamento || '',
                    emailAteneo: studenteContent?.emailAteneo?.toLowerCase()
                })),
                sticky: true,
                totalElements: studenti.length,
                isPaginatedBE: false,
                messaggioDatiAssenti: 'custom_table.no_data',
                hidePaginator: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        show: () => false,
                        nomeColonna: 'id',
                        colonnaKey: 'id',
                        hideColonna: true,
                        flex: 0,
                        formControl: {
                            type: TypeDialogFormEnum.TEXT,
                            show: false,
                            name: 'id',
                        }
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.student',
                        colonnaKey: 'studente',
                        flex: 15,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'menu_filter.enrollment_number',
                        colonnaKey: 'matricola',
                        hideColonna: !this.cicloConfigurationService.showMatricola,
                        flex: 10,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.university_email',
                        colonnaKey: 'emailAteneo',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.INPUT_TEXT,
                        nomeColonna: 'common.nota',
                        colonnaKey: 'nota',
                        flex: 60,
                        formControl: {
                            type: TypeDialogFormEnum.TEXTAREA,
                            show: true,
                            name: 'nota',
                        }
                    },
                ],
            }
        };
    }

    formValueChanged(formGroup: FormGroup) {
        console.log('FORM VALUE CHANGED', formGroup.valid)
        this.canGoNext$.next(formGroup.valid);
        this.operazioneMassivaData$.next({
            corsoSelected: this.operazioneMassivaData$.getValue()?.corsoSelected,
            studentiSelected: this.operazioneMassivaData$.getValue()?.studentiSelected,
            dataSuperamento: formGroup.getRawValue()?.data_superamento,
            cfuOttenuti: formGroup.getRawValue()?.cfu_ottenuti,
            notaSuperamento: formGroup.getRawValue()?.nota_superamento,
            noteSuperamentoCustom: this.customNotesFormGroup.get(['child', 'input_fields'])?.value
        });
    }

    private initFormConfig() {
        console.log(this.corso)
        this.formConfig = [
            {
                show: true,
                name: 'data_superamento',
                dateMax: this.dataMaxSuperamentoCorso,
                required: true,
                transloco: 'common.day',
                type: TypeDialogFormEnum.DATA,
            },
            {
                show: true,
                name: 'cfu_ottenuti',
                required: true,
                max: {number: this.corso?.cfu, text: this.maxValCFUErrorMessage},
                min: {number: 0.1, text: this.minValCFUErrorMessage},
                onlyPositiveWithOneDecimal: true,
                transloco: `${this.cicloConfigurationService.activityUnitOfMeasureLabel}_obtained`,
                type: TypeDialogFormEnum.NUMBER,
            },
            {
                show: true,
                name: 'nota_superamento',
                required: false,
                transloco: 'common.nota',
                type: TypeDialogFormEnum.TEXTAREA
            },
        ];
        this.valueForm = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            data_superamento: (moment().format('DD/MM/YYYY')),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cfu_ottenuti: this.corso?.cfu,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            nota_superamento: ''
        };
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            dataSuperamento: this.valueForm.data_superamento,
            cfuOttenuti: this.valueForm.cfu_ottenuti,
            notaSuperamento: this.valueForm.nota_superamento,
        });
    }

    toggleStudentsList() {
        if(!this.studentsListConfiguration) {
            this.studentsListConfiguration = this.buildListaStudentiConfiguration(this.selectedStudents)
        } else {
            this.studentsListConfiguration = undefined;
        }
    }
}
