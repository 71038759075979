import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthorityType} from "../../../api-clients/generated/services";
import {LocalStorageService} from "../../shared/service/local-storage.service";
import {PathEnum} from "../../app.routing";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";
import {AppInitService} from "../../shared/service/app-init.service";


@Injectable({
    providedIn: 'root'
})
export class ManualsGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private appInitService: AppInitService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const ruoli = this.appInitService.userAmministrazioneInfoCicli?.roles;
        const canActivate = ManualsGuard.accessCondition(ruoli);
        if(!canActivate) {
            this._router.navigate([PathEnum.HOME]);
        }
        return canActivate;
    }

    static accessCondition(ruoli: AuthorityType[]) {
        return !!ruoli?.find(r => r === AuthorityType.SUPERADMIN);
    }


}
