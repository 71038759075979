/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di un periodo di mobilità studente. Valori previsti: *INSERITO*, *APPROVATO_PARZIALE*, *APPROVATO*, *APPROVATO_PTA_ATENEO_MOBILITA_RICHIESTE*, *RIFIUTATO*, *SOSPESO*, *ANNULLATO*,*RICHIESTA_CONCLUSIONE*,*RICHIESTA_CONCLUSIONE_RIFIUTATA*,*RICHIESTA_CONCLUSIONE_APPROVATA_PARZIALMENTE*,*CONCLUSO*
 */
export type PeriodoDiMobilitaStudenteStatus = 'INSERITO' | 'APPROVATO_PARZIALE' | 'APPROVATO' | 'APPROVATO_PTA_ATENEO_MOBILITA_RICHIESTE' | 'RIFIUTATO' | 'SOSPESO' | 'ANNULLATO' | 'RICHIESTA_CONCLUSIONE' | 'RICHIESTA_CONCLUSIONE_RIFIUTATA' | 'RICHIESTA_CONCLUSIONE_APPROVATA_PARZIALMENTE' | 'CONCLUSO';

export const PeriodoDiMobilitaStudenteStatus = {
    INSERITO: 'INSERITO' as PeriodoDiMobilitaStudenteStatus,
    APPROVATOPARZIALE: 'APPROVATO_PARZIALE' as PeriodoDiMobilitaStudenteStatus,
    APPROVATO: 'APPROVATO' as PeriodoDiMobilitaStudenteStatus,
    APPROVATOPTAATENEOMOBILITARICHIESTE: 'APPROVATO_PTA_ATENEO_MOBILITA_RICHIESTE' as PeriodoDiMobilitaStudenteStatus,
    RIFIUTATO: 'RIFIUTATO' as PeriodoDiMobilitaStudenteStatus,
    SOSPESO: 'SOSPESO' as PeriodoDiMobilitaStudenteStatus,
    ANNULLATO: 'ANNULLATO' as PeriodoDiMobilitaStudenteStatus,
    RICHIESTACONCLUSIONE: 'RICHIESTA_CONCLUSIONE' as PeriodoDiMobilitaStudenteStatus,
    RICHIESTACONCLUSIONERIFIUTATA: 'RICHIESTA_CONCLUSIONE_RIFIUTATA' as PeriodoDiMobilitaStudenteStatus,
    RICHIESTACONCLUSIONEAPPROVATAPARZIALMENTE: 'RICHIESTA_CONCLUSIONE_APPROVATA_PARZIALMENTE' as PeriodoDiMobilitaStudenteStatus,
    CONCLUSO: 'CONCLUSO' as PeriodoDiMobilitaStudenteStatus
};