import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {
    AttivitaOffertaFormativaInfoView,
    AttivitaTrasversaleStatus,
    AuthorityType,
    CorsoPianoDiStudiPerExportViewImpl,
    TipoPeriodoEnum
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, startWith, Subscription} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {MatDialog} from "@angular/material/dialog";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {optionFile} from "../../../../../shared/costants/app-constants";
import * as fs from "file-saver";
import {ApprovazioneExtraMassivaDataI} from "../approvazione-extra-steps-interface";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";

export interface ExtraCompileInfo extends CorsoPianoDiStudiPerExportViewImpl{
    studenteUIMapped: {
        utenteNome?: string;
        utenteCognome?: string;
    };
    id: string;
    idCategoria: string;
}


@Component({
    selector: 'app-info-approvazione-extra-stepper',
    templateUrl: './compila-info-extra.component.html',
    styleUrls: ['./compila-info-extra.component.scss']
})
export class CompilaInfoExtraComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit {

    loading: boolean;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    corso: AttivitaOffertaFormativaInfoView = undefined;
    form: FormGroup;
    attivitaSelected: CorsoPianoDiStudiPerExportViewImpl[];
    outputFormatData = 'DD/MM/YYYY';
    withDelibera: boolean;
    extraCompileInfos: ExtraCompileInfo[];
    files: Map<string, File>;
    private formControlsSub: Subscription;
    formArray: FormArray;

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                protected cicloConfigurationService: CicloConfigurationService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneExtraMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.canGoNext$.next(true);
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneExtraMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshData){
                this.canGoNext$.next(false);
            }
            if(inputOutputData.attivitaSelected !== this.attivitaSelected){
                this.attivitaSelected = inputOutputData?.attivitaSelected;
                this.canGoNext$.next(true);
                this.extraCompileInfos = this.buildCompileInfoConfiguration(this.attivitaSelected || []);
            }
        });
    }

    ngAfterViewInit() {
    }

    private buildCompileInfoConfiguration(extraInfoViews:  CorsoPianoDiStudiPerExportViewImpl[]): ExtraCompileInfo[] {
        this.files = new Map<string, File>();
        const extra = extraInfoViews
            ?.map(att => ({
                ...att,
                id: att.idCorsoInOffertaFormativa ?? att.idAttivitaProposta ?? att.idAttivitaPresunta,
                idCategoria: att.idCategoriaOffertaFormativa,
                studenteUIMapped: {
                    utenteNome: att?.utenteNome,
                    utenteCognome: att?.utenteCognome,
                    nome: att?.utenteNome,
                    cognome: att?.utenteCognome,
                    utenteMail: att?.utenteMail?.toLowerCase(),
                    studenteCodiceFiscale: att?.studenteCodiceFiscale?.toUpperCase(),
                    codiceFiscale: att?.studenteCodiceFiscale?.toUpperCase(),
                    studenteMatricola: att?.studenteMatricola,
                    urlImmagineProfilo:  att?.urlImmagineProfilo,
                    utenteTelefono: att?.utenteTelefono,
                },
            }));
        this.formArray = this.fb.array([]);
        extra?.forEach(att => this.formArray.push(this.fb.group({
            file: [undefined],
            cfu: [att.cfuPrevisti, Validators.required],
            data: [moment().toDate(), Validators.required],
            nota: undefined,
            id: att.id,
            idCategoria: att.idCategoriaOffertaFormativa,
        })));
        this.formControlsSub?.unsubscribe();
        this.formControlsSub = this.formArray.valueChanges.pipe(startWith(this.formArray.value)).subscribe(fa => {
            this.canGoNext$.next(this.formArray.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$.getValue(),
                compileInfo: fa,
            });
        });
        return extra ?? [];
    }

    downloadFile(filename: string, idPeriodo: string) {
        fs.saveAs(this.files.get(idPeriodo), filename);
    }

    uploadFile(file: File, idExtra: string) {
        const extension = file?.name?.substring(file?.name?.lastIndexOf('.')+1);
        const renamedFile = new File([file], idExtra + '.' + extension, {type: file.type});
        this.files.set(idExtra, renamedFile);
        this.formArray.at(this.extraCompileInfos.findIndex(ci => ci.id === idExtra))
            .get('file').setValue(idExtra);
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            compileInfoFiles: this.files,
        });

    }

    deleteFile(idExtra: string) {
        this.files.delete(idExtra);
        this.formArray.at(this.extraCompileInfos.findIndex(ci => ci.id === idExtra))
            .get('file').setValue(undefined);
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
        });
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }


    protected readonly AuthorityType = AuthorityType;
    protected readonly optionFile = optionFile;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
    protected readonly AttivitaTrasversaleStatus = AttivitaTrasversaleStatus;

    getFormGroupByAttId(idExtra: string, idCategory: string) {
        return this.formArray.at(this.extraCompileInfos?.findIndex(ci => ci.id === idExtra && ci.idCategoria === idCategory));
    }

}
