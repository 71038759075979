<ng-container *transloco="let t">

    <mat-form-field class="w-full" appearance="outline"
                    *ngIf="form?.get('importoApprovato') as fieldCtrl">
        <mat-label>{{t('budget.req_amount')}}</mat-label>
        <input matInput
               currencyMask
               [options]="{ prefix: '',  suffix: ' €',
                                           allowNegative: false,
                                           thousands: '.', decimal: ',', align: 'left' }"
               [placeholder]="t('budget.req_amount')" [formControl]="fieldCtrl"
        >
        <mat-error *ngIf="fieldCtrl.hasError('required')">
            {{t('form.required')}}
        </mat-error>
        <mat-error *ngIf="fieldCtrl.hasError('max')">
            {{t('budget.overbudget_error') + ' ' + ('(' + budgetResiduo + ')')}}
        </mat-error>
        <mat-error *ngIf="fieldCtrl.hasError('min')">
            {{t('budget.min_budget_error') + ' ' + ('(' + (0.1 | currencyEuro) + ')')}}
        </mat-error>
    </mat-form-field>

</ng-container>
