/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddRichiestaDiRetrubuzioneMobilitaDTO } from '../model/addRichiestaDiRetrubuzioneMobilitaDTO';
import { AnnullamentoPeriodoDiMobilitaDTO } from '../model/annullamentoPeriodoDiMobilitaDTO';
import { CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO } from '../model/calcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO';
import { DettagliDocumentiAllegatiRequestDTO } from '../model/dettagliDocumentiAllegatiRequestDTO';
import { DettaglioCalcoloImportoMobilita } from '../model/dettaglioCalcoloImportoMobilita';
import { EsitoRichiestaApprovazione } from '../model/esitoRichiestaApprovazione';
import { EsitoRichiestaApprovazioneMobilitaPR } from '../model/esitoRichiestaApprovazioneMobilitaPR';
import { EsitoRichiestaApprovazioneMobilitaPRByCoordinatore } from '../model/esitoRichiestaApprovazioneMobilitaPRByCoordinatore';
import { EsitoRichiestaApprovazioneMobilitaSE } from '../model/esitoRichiestaApprovazioneMobilitaSE';
import { EsitoRichiestaApprovazioneMobilitaSEByCoordinatore } from '../model/esitoRichiestaApprovazioneMobilitaSEByCoordinatore';
import { EsitoRichiestaConclusioneMobilita } from '../model/esitoRichiestaConclusioneMobilita';
import { EsitoRichiestaRetribuzioneMobilita } from '../model/esitoRichiestaRetribuzioneMobilita';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { PeriodoDiMobilitaStudenteInfoView } from '../model/periodoDiMobilitaStudenteInfoView';
import { PeriodoDiRicercaStudenteRequestDTO } from '../model/periodoDiRicercaStudenteRequestDTO';
import { PeriodoDiSoggiornoEsteroStudenteRequestDTO } from '../model/periodoDiSoggiornoEsteroStudenteRequestDTO';
import { RichiestaDiConclusioneMobilitaDTO } from '../model/richiestaDiConclusioneMobilitaDTO';
import { StoricoAutorizzazioneMobilitaStudenteInfoView } from '../model/storicoAutorizzazioneMobilitaStudenteInfoView';
import { UpdateDeliberaPeriodoDiMobilitaRequest } from '../model/updateDeliberaPeriodoDiMobilitaRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MobilitaStudentiCicloService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette ad un PTA Ateneo Mobilita di accettare o rifiutare un periodo di ricerca di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaPeriodoDiMobilitaByPtaAteneoMobilitaForm(id_utente: string, codice_mobilita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public accettaRifiutaPeriodoDiMobilitaByPtaAteneoMobilitaForm(id_utente: string, codice_mobilita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaByPtaAteneoMobilitaForm(id_utente: string, codice_mobilita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaByPtaAteneoMobilitaForm(id_utente: string, codice_mobilita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaPeriodoDiMobilitaByPtaAteneoMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling accettaRifiutaPeriodoDiMobilitaByPtaAteneoMobilita.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/pta-ateneo-mobilita`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un coordinatore di accettare o rifiutare un periodo di ricerca di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaPeriodoDiMobilitaPRByCoordinoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaPRByCoordinatore, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public accettaRifiutaPeriodoDiMobilitaPRByCoordinoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaPRByCoordinatore, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaPRByCoordinoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaPRByCoordinatore, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaPRByCoordinoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaPRByCoordinatore, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRByCoordinore.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRByCoordinore.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/ricerca/${encodeURIComponent(String(codice_mobilita))}/coordinatore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore di accettare o rifiutare un periodo di ricerca di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaPeriodoDiMobilitaPRBySupervisoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaPR, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public accettaRifiutaPeriodoDiMobilitaPRBySupervisoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaPR, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaPRBySupervisoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaPR, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaPRBySupervisoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaPR, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRBySupervisore.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRBySupervisore.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/ricerca/${encodeURIComponent(String(codice_mobilita))}/supervisore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un coordinatore di accettare o rifiutare un periodo di mobilità (Soggiorno Estero) di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaPeriodoDiMobilitaSEByCoordinoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaSEByCoordinatore, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public accettaRifiutaPeriodoDiMobilitaSEByCoordinoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaSEByCoordinatore, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaSEByCoordinoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaSEByCoordinatore, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaSEByCoordinoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaSEByCoordinatore, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEByCoordinore.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEByCoordinore.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/soggiorno-estero/${encodeURIComponent(String(codice_mobilita))}/coordinatore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore di accettare o rifiutare un periodo di mobilità (Soggiorno Estero) di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaPeriodoDiMobilitaSEBySupervisoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaSE, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public accettaRifiutaPeriodoDiMobilitaSEBySupervisoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaSE, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaSEBySupervisoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaSE, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaSEBySupervisoreForm(id_utente: string, codice_mobilita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazioneMobilitaSE, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEBySupervisore.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEBySupervisore.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/soggiorno-estero/${encodeURIComponent(String(codice_mobilita))}/supervisore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette lo studente di richiedere un periodio di mobilità di tipo &#x27;Ricerca&#x27;.
     * 
     * @param id_utente id dell&#x27;utente
     * @param files 
     * @param requestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMobilitaPeriodoDiRicercaStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: PeriodoDiRicercaStudenteRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public addMobilitaPeriodoDiRicercaStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: PeriodoDiRicercaStudenteRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public addMobilitaPeriodoDiRicercaStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: PeriodoDiRicercaStudenteRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public addMobilitaPeriodoDiRicercaStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: PeriodoDiRicercaStudenteRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addMobilitaPeriodoDiRicercaStudente.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }
        if (requestDTO !== undefined) {
            formParams = formParams.append('requestDTO', new Blob([JSON.stringify(requestDTO)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/ricerca`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette lo studente di richiedere un periodio di mobilità di tipo &#x27;Soggiorno Estero&#x27;.
     * 
     * @param id_utente id dell&#x27;utente
     * @param files 
     * @param requestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMobilitaPeriodoDiSoggiornoEsteroStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: PeriodoDiSoggiornoEsteroStudenteRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public addMobilitaPeriodoDiSoggiornoEsteroStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: PeriodoDiSoggiornoEsteroStudenteRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public addMobilitaPeriodoDiSoggiornoEsteroStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: PeriodoDiSoggiornoEsteroStudenteRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public addMobilitaPeriodoDiSoggiornoEsteroStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: PeriodoDiSoggiornoEsteroStudenteRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addMobilitaPeriodoDiSoggiornoEsteroStudente.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }
        if (requestDTO !== undefined) {
            formParams = formParams.append('requestDTO', new Blob([JSON.stringify(requestDTO)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/soggiorno-estero`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di annullare un periodo di mobilità di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param annullamentoPeriodoDiMobilitaDTO 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public annullamentoPeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, annullamentoPeriodoDiMobilitaDTO?: AnnullamentoPeriodoDiMobilitaDTO, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public annullamentoPeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, annullamentoPeriodoDiMobilitaDTO?: AnnullamentoPeriodoDiMobilitaDTO, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public annullamentoPeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, annullamentoPeriodoDiMobilitaDTO?: AnnullamentoPeriodoDiMobilitaDTO, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public annullamentoPeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, annullamentoPeriodoDiMobilitaDTO?: AnnullamentoPeriodoDiMobilitaDTO, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling annullamentoPeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling annullamentoPeriodoDiMobilita.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (annullamentoPeriodoDiMobilitaDTO !== undefined) {
            formParams = formParams.append('annullamentoPeriodoDiMobilitaDTO', new Blob([JSON.stringify(annullamentoPeriodoDiMobilitaDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/annullamento`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di calcolare l&#x27;importo di una richiesta di retribuzione su un periodo di mobilità in base ai periodi forniti.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param id_richiesta_di_retribuzione_mobilita codice univoco della richiesta di retribuzione mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita(body: CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<DettaglioCalcoloImportoMobilita>;
    public calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita(body: CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DettaglioCalcoloImportoMobilita>>;
    public calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita(body: CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DettaglioCalcoloImportoMobilita>>;
    public calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita(body: CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DettaglioCalcoloImportoMobilita>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/calcola-dettaglio-importo`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato presente in un periodo di mobilità o ricerca di uno studente.
     * 
     * @param codice_mobilita 
     * @param idAllegato id dell&#x27;allegato
     * @param idUtente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoPeriodoDiMobilitaStudente(codice_mobilita: string, idAllegato: string, idUtente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoPeriodoDiMobilitaStudente(codice_mobilita: string, idAllegato: string, idUtente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoPeriodoDiMobilitaStudente(codice_mobilita: string, idAllegato: string, idUtente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoPeriodoDiMobilitaStudente(codice_mobilita: string, idAllegato: string, idUtente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling getAllegatoPeriodoDiMobilitaStudente.');
        }

        if (idAllegato === null || idAllegato === undefined) {
            throw new Error('Required parameter idAllegato was null or undefined when calling getAllegatoPeriodoDiMobilitaStudente.');
        }

        if (idUtente === null || idUtente === undefined) {
            throw new Error('Required parameter idUtente was null or undefined when calling getAllegatoPeriodoDiMobilitaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idAllegato !== undefined && idAllegato !== null) {
            queryParameters = queryParameters.set('idAllegato', <any>idAllegato);
        }
        if (idUtente !== undefined && idUtente !== null) {
            queryParameters = queryParameters.set('idUtente', <any>idUtente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/allegati`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato presente in una richiesta di retribuzione di uno studente.
     * 
     * @param codice_mobilita 
     * @param id_richiesta_di_retribuzione_mobilita 
     * @param idAllegato id dell&#x27;allegato
     * @param idUtente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoRichiestaDiRetribuzioneMobilita(codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, idAllegato: string, idUtente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoRichiestaDiRetribuzioneMobilita(codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, idAllegato: string, idUtente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoRichiestaDiRetribuzioneMobilita(codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, idAllegato: string, idUtente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoRichiestaDiRetribuzioneMobilita(codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, idAllegato: string, idUtente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling getAllegatoRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling getAllegatoRichiestaDiRetribuzioneMobilita.');
        }

        if (idAllegato === null || idAllegato === undefined) {
            throw new Error('Required parameter idAllegato was null or undefined when calling getAllegatoRichiestaDiRetribuzioneMobilita.');
        }

        if (idUtente === null || idUtente === undefined) {
            throw new Error('Required parameter idUtente was null or undefined when calling getAllegatoRichiestaDiRetribuzioneMobilita.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idAllegato !== undefined && idAllegato !== null) {
            queryParameters = queryParameters.set('idAllegato', <any>idAllegato);
        }
        if (idUtente !== undefined && idUtente !== null) {
            queryParameters = queryParameters.set('idUtente', <any>idUtente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/allegati`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di periodi di mobilità di tipo &#x27;Ricerca&#x27; ordinati per data richiesta decrescente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodiDiRicercaStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public getPeriodiDiRicercaStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public getPeriodiDiRicercaStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public getPeriodiDiRicercaStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPeriodiDiRicercaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('get',`${this.basePath}/api/v1/studente-ciclo/mobilita/ricerca`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di periodi di mobilità di tipo &#x27;Soggiorno Estero&#x27; ordinati per data richiesta decrescente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodiDiSoggiornoEsteroStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public getPeriodiDiSoggiornoEsteroStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public getPeriodiDiSoggiornoEsteroStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public getPeriodiDiSoggiornoEsteroStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPeriodiDiSoggiornoEsteroStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('get',`${this.basePath}/api/v1/studente-ciclo/mobilita/soggiorno-estero`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli storici di autorizzazione alla mobilità per il dottorando.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStoriciAutorizzazioneMobilitaStudenteCiclo(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<StoricoAutorizzazioneMobilitaStudenteInfoView>>;
    public getStoriciAutorizzazioneMobilitaStudenteCiclo(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StoricoAutorizzazioneMobilitaStudenteInfoView>>>;
    public getStoriciAutorizzazioneMobilitaStudenteCiclo(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StoricoAutorizzazioneMobilitaStudenteInfoView>>>;
    public getStoriciAutorizzazioneMobilitaStudenteCiclo(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getStoriciAutorizzazioneMobilitaStudenteCiclo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StoricoAutorizzazioneMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/storici-autorizzazione-mobilita-dottorando`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Revoca l&#x27;autorizzazione alla mobilità per il dottorando.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokaAutorizzazioneMobilitaDottorando(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public revokaAutorizzazioneMobilitaDottorando(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public revokaAutorizzazioneMobilitaDottorando(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public revokaAutorizzazioneMobilitaDottorando(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling revokaAutorizzazioneMobilitaDottorando.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/revoca-autorizzazione-mobilita-dottorando`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al dottorando di richiedere la conclusione di un periodo di mobilità caricando gli attestati.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param fileAttestato 
     * @param richiestaDiConclusioneMobilitaDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public richiediConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, fileAttestato?: Array<Blob>, richiestaDiConclusioneMobilitaDTO?: RichiestaDiConclusioneMobilitaDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public richiediConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, fileAttestato?: Array<Blob>, richiestaDiConclusioneMobilitaDTO?: RichiestaDiConclusioneMobilitaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public richiediConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, fileAttestato?: Array<Blob>, richiestaDiConclusioneMobilitaDTO?: RichiestaDiConclusioneMobilitaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public richiediConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, fileAttestato?: Array<Blob>, richiestaDiConclusioneMobilitaDTO?: RichiestaDiConclusioneMobilitaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling richiediConclusionePeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling richiediConclusionePeriodoDiMobilita.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fileAttestato) {
            fileAttestato.forEach((element) => {
                formParams = formParams.append('fileAttestato', <any>element) as any || formParams;
            })
        }
        if (richiestaDiConclusioneMobilitaDTO !== undefined) {
            formParams = formParams.append('richiestaDiConclusioneMobilitaDTO', new Blob([JSON.stringify(richiestaDiConclusioneMobilitaDTO)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiedi-conclusione`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al dottorando di effettuare una richiesta di retribuzione su un periodo di mobilità caricando gli attestati.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param addRichiestaDiRetrubuzioneMobilitaDTO 
     * @param fileAttestato 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public richiediRetribuzionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, addRichiestaDiRetrubuzioneMobilitaDTO?: AddRichiestaDiRetrubuzioneMobilitaDTO, fileAttestato?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public richiediRetribuzionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, addRichiestaDiRetrubuzioneMobilitaDTO?: AddRichiestaDiRetrubuzioneMobilitaDTO, fileAttestato?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public richiediRetribuzionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, addRichiestaDiRetrubuzioneMobilitaDTO?: AddRichiestaDiRetrubuzioneMobilitaDTO, fileAttestato?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public richiediRetribuzionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, addRichiestaDiRetrubuzioneMobilitaDTO?: AddRichiestaDiRetrubuzioneMobilitaDTO, fileAttestato?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling richiediRetribuzionePeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling richiediRetribuzionePeriodoDiMobilita.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (addRichiestaDiRetrubuzioneMobilitaDTO !== undefined) {
            formParams = formParams.append('addRichiestaDiRetrubuzioneMobilitaDTO', new Blob([JSON.stringify(addRichiestaDiRetrubuzioneMobilitaDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (fileAttestato) {
            fileAttestato.forEach((element) => {
                formParams = formParams.append('fileAttestato', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiedi-retribuzione`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di aggiornare la delibera di approvazione associata ad un periodo di mobilità.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeliberaPeriodoDiMobilita(body: UpdateDeliberaPeriodoDiMobilitaRequest, id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public updateDeliberaPeriodoDiMobilita(body: UpdateDeliberaPeriodoDiMobilitaRequest, id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public updateDeliberaPeriodoDiMobilita(body: UpdateDeliberaPeriodoDiMobilitaRequest, id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public updateDeliberaPeriodoDiMobilita(body: UpdateDeliberaPeriodoDiMobilitaRequest, id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDeliberaPeriodoDiMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling updateDeliberaPeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling updateDeliberaPeriodoDiMobilita.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('put',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/update-delibera`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al PTA_ATENEO_MOBILITA di validare una richiesta di retribuzione su un periodo di mobilità.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param id_richiesta_di_retribuzione_mobilita codice univoco della richiesta di retribuzione mobilità
     * @param esito 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaPtaRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public validaPtaRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public validaPtaRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public validaPtaRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling validaPtaRichiestaDiRetribuzioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling validaPtaRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling validaPtaRichiestaDiRetribuzioneMobilita.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esito !== undefined) {
            formParams = formParams.append('esito', new Blob([JSON.stringify(esito)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/valida-pta`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al supervisore/cosupervisore di approvare o rifiutare una richiesta di conclusione periodo di mobilità.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param esitoRichiestaConclusioneMobilita 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaParzialeRichiestaDiConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, esitoRichiestaConclusioneMobilita?: EsitoRichiestaConclusioneMobilita, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public verificaParzialeRichiestaDiConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, esitoRichiestaConclusioneMobilita?: EsitoRichiestaConclusioneMobilita, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaParzialeRichiestaDiConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, esitoRichiestaConclusioneMobilita?: EsitoRichiestaConclusioneMobilita, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaParzialeRichiestaDiConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, esitoRichiestaConclusioneMobilita?: EsitoRichiestaConclusioneMobilita, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaParzialeRichiestaDiConclusionePeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling verificaParzialeRichiestaDiConclusionePeriodoDiMobilita.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esitoRichiestaConclusioneMobilita !== undefined) {
            formParams = formParams.append('esitoRichiestaConclusioneMobilita', new Blob([JSON.stringify(esitoRichiestaConclusioneMobilita)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/verifica-parziale-richiesta-conclusione`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al Supervisore o Cosupervisore di verificare una richiesta di retribuzione su un periodo di mobilità.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param id_richiesta_di_retribuzione_mobilita codice univoco della richiesta di retribuzione mobilità
     * @param esito 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaParzialeRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public verificaParzialeRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaParzialeRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaParzialeRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaParzialeRichiestaDiRetribuzioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling verificaParzialeRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling verificaParzialeRichiestaDiRetribuzioneMobilita.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esito !== undefined) {
            formParams = formParams.append('esito', new Blob([JSON.stringify(esito)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/verifica-parziale`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di approvare o rifiutare una richiesta di conclusione periodo di mobilità.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param esitoRichiestaConclusioneMobilita 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaRichiestaDiConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, esitoRichiestaConclusioneMobilita?: EsitoRichiestaConclusioneMobilita, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public verificaRichiestaDiConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, esitoRichiestaConclusioneMobilita?: EsitoRichiestaConclusioneMobilita, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaRichiestaDiConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, esitoRichiestaConclusioneMobilita?: EsitoRichiestaConclusioneMobilita, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaRichiestaDiConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, esitoRichiestaConclusioneMobilita?: EsitoRichiestaConclusioneMobilita, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaRichiestaDiConclusionePeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling verificaRichiestaDiConclusionePeriodoDiMobilita.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esitoRichiestaConclusioneMobilita !== undefined) {
            formParams = formParams.append('esitoRichiestaConclusioneMobilita', new Blob([JSON.stringify(esitoRichiestaConclusioneMobilita)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/verifica-richiesta-conclusione`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al Coordinatore di verificare una richiesta di retribuzione su un periodo di mobilità.
     * 
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param id_richiesta_di_retribuzione_mobilita codice univoco della richiesta di retribuzione mobilità
     * @param esito 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public verificaRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaRichiestaDiRetribuzioneMobilitaForm(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, esito?: EsitoRichiestaRetribuzioneMobilita, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaRichiestaDiRetribuzioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling verificaRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling verificaRichiestaDiRetribuzioneMobilita.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esito !== undefined) {
            formParams = formParams.append('esito', new Blob([JSON.stringify(esito)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/verifica`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
