import {Component, Inject, OnInit, Pipe} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogWrapI} from "../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    DettaglioCalcoloImportoMobilita, Nodo, PeriodoDiMobilitaStudenteInfoView
} from "../../../../../api-clients/generated/services";
import {isEmpty} from "lodash";
import {
    DialogBudgetRequestDetailsComponent
} from "../../student-details/budget/dialog-budget-request-details/dialog-budget-request-details.component";
import {PathEnum} from "../../../../app.routing";
import {ActivatedRoute, Router} from "@angular/router";
import {AppInitService} from "../../../../shared/service/app-init.service";


export enum TypeInfo {
    TEXTAREA = 'TEXTAREA',
    TEXT = 'TEXT',
    CHIP = 'CHIP',
    SECTION = 'SECTION',
    AMOUNT = 'AMOUNT'
}

export interface DialogMobilitaDashboardInfoI extends DialogWrapI {
    info?: Info[];
    readonlyData?: { key: string; value: any; style?: string ; hide?: boolean; pipe?: Pipe, showVisualizzaButton?: boolean, buttonAction?: () => void}[];
    dettaglioImporto?: DettaglioCalcoloImportoMobilita,
    flowTreeStep?: Nodo;
    periodo: PeriodoDiMobilitaStudenteInfoView;
}

export interface Info {
    nameGroup: string;
    children: { key: string; value?: any; flex?: string; type?: TypeInfo }[];

}

@Component({
    selector: 'app-dialog-mobilita-dashboard-info',
    templateUrl: './dialog-mobilita-dashboard-info.component.html',
    styleUrls: ['./dialog-mobilita-dashboard-info.component.scss']
})
export class DialogMobilitaDashboardInfoComponent implements OnInit {
    type = TypeInfo;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogMobilitaDashboardInfoI,
                public dialogRef: MatDialogRef<DialogMobilitaDashboardInfoComponent>,
                private router: Router,
                private appInitService: AppInitService) {

    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }

    goToLinkedMission(){
        const url = this.router.serializeUrl(
            this.router.createUrlTree([this.appInitService.isAreaAdministrator ? ('/'+PathEnum.ADMINISTRATOR) : '/',
                PathEnum.STUDENTS, this.data.periodo?.studenteCiclo?.utente?.id, PathEnum.MISSIONS, this.data.periodo?.missioneId])
        );
        window.open(url, '_blank');
        this.closeDialog();
    }


    protected readonly AuthorityType = AuthorityType;
    protected readonly isEmpty = isEmpty;
    stepexample2: Nodo =  {
        dataEsecuzione: new Date(),
        chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
        figli: [
            {
                dataEsecuzione: new Date(),
                chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
            },
            {
                dataEsecuzione: new Date(),
                chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                figli: [
                    {
                        dataEsecuzione: new Date(),

                        chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                    },
                    {
                        dataEsecuzione: new Date(),

                        chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                        figli: [
                            {
                                dataEsecuzione: new Date(),

                                chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                                figli: [
                                    {
                                        dataEsecuzione: new Date(),

                                        chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                                        figli: [
                                            {
                                                dataEsecuzione: new Date(),

                                                chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                                                figli: [
                                                    {
                                                        dataEsecuzione: new Date(),

                                                        chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                                                        figli: [
                                                            {
                                                                dataEsecuzione: new Date(),

                                                                chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                                                                figli: [
                                                                    {
                                                                        dataEsecuzione: new Date(),

                                                                        chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        dataEsecuzione: new Date(),
                        chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
                    },
                ]
            }
        ]
    };
    //
    // stepexample: FlowTreeStep =  {
    //     user: { nome: 'Marco', cognome: 'Rossi' },
    //     date: '21/06/98 24:345',
    //     chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
    //     childrenSteps: [
    //         {
    //             user: { nome: 'Marco', cognome: 'Rossi' },
    //             date: '21/06/98 24:34',
    //             chiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore,
    //             childrenSteps: [
    //                 {
    //                     user: { nome: 'Marco', cognome: 'Rossi' },
    //                     date: '21/06/98 24:34',
    //                     chiaveOperazione: ChiaveOperazioneEnum.PreautorizzazionePta,
    //                     childrenSteps: [
    //                         {
    //                             user: { nome: 'Marco', cognome: 'Rossi' },
    //                             date: '21/06/98 24:34',
    //                             chiaveOperazione: ChiaveOperazioneEnum.PreautorizzazionePta,
    //                             childrenSteps: [
    //                                 {
    //                                     chiaveOperazione: ChiaveOperazioneEnum.PreautorizzazionePta,
    //                                 },
    //                                 {
    //                                     chiaveOperazione: ChiaveOperazioneEnum.PreautorizzazionePta,
    //                                 }
    //                             ]
    //                         },
    //
    //                     ]
    //                 },
    //
    //             ]
    //         },
    //
    //     ]
    // };
    protected readonly ChiaveFlussoEnum = ChiaveFlussoEnum;
}
