/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi flusso
 */
export type ChiaveFlussoEnum = 'superamento_anno' | 'prima_presentazione_piano_formativo' | 'modifica_piano_formativo' | 'compilazione_registro_attivita' | 'preautorizzazione_richieste_mobilita' | 'richiesta_mobilita' | 'richiesta_validazione_sottoperiodi_mobilita' | 'validazione_periodo_registro_attivita' | 'richiesta_missione' | 'richiesta_acquisto' | 'richiesta_spesa_missione';

export const ChiaveFlussoEnum = {
    SuperamentoAnno: 'superamento_anno' as ChiaveFlussoEnum,
    PrimaPresentazionePianoFormativo: 'prima_presentazione_piano_formativo' as ChiaveFlussoEnum,
    ModificaPianoFormativo: 'modifica_piano_formativo' as ChiaveFlussoEnum,
    CompilazioneRegistroAttivita: 'compilazione_registro_attivita' as ChiaveFlussoEnum,
    PreautorizzazioneRichiesteMobilita: 'preautorizzazione_richieste_mobilita' as ChiaveFlussoEnum,
    RichiestaMobilita: 'richiesta_mobilita' as ChiaveFlussoEnum,
    RichiestaValidazioneSottoperiodiMobilita: 'richiesta_validazione_sottoperiodi_mobilita' as ChiaveFlussoEnum,
    ValidazionePeriodoRegistroAttivita: 'validazione_periodo_registro_attivita' as ChiaveFlussoEnum,
    RichiestaMissione: 'richiesta_missione' as ChiaveFlussoEnum,
    RichiestaAcquisto: 'richiesta_acquisto' as ChiaveFlussoEnum,
    RichiestaSpesaMissione: 'richiesta_spesa_missione' as ChiaveFlussoEnum
};