import {Pipe, PipeTransform} from '@angular/core';
import {AnnoRiferimentoValues, ChiaveFlussoEnum, StatoGenericoEnum} from '../../../api-clients/generated/services';
import {CicloConfigurationService} from "../service/ciclo-configuration.service";

export function flussoStatusToLabelFn(cicloConfigurationService: CicloConfigurationService, value: StatoGenericoEnum, chiaveFlusso: ChiaveFlussoEnum, annoRiferimento?: AnnoRiferimentoValues): string {
    return cicloConfigurationService.getTranslatedStateDescription(chiaveFlusso, value, annoRiferimento);
}

@Pipe({
  name: 'flussoStatusToLabel'
})
export class FlussoStatusToLabelPipe implements PipeTransform {

    constructor(private cicloConfigurationService: CicloConfigurationService) {
    }

    transform(value: StatoGenericoEnum, chiaveFlusso: ChiaveFlussoEnum, annoRiferimento?: AnnoRiferimentoValues): string {
        return flussoStatusToLabelFn(this.cicloConfigurationService, value, chiaveFlusso, annoRiferimento);
    }

}
