/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum di tutti le icone gestite
 */
export type IconaEnum = 'RICHIEDI' | 'ANNULLA' | 'RIFIUTA' | 'APPROVA' | 'AGGIUNGI';

export const IconaEnum = {
    RICHIEDI: 'RICHIEDI' as IconaEnum,
    ANNULLA: 'ANNULLA' as IconaEnum,
    RIFIUTA: 'RIFIUTA' as IconaEnum,
    APPROVA: 'APPROVA' as IconaEnum,
    AGGIUNGI: 'AGGIUNGI' as IconaEnum
};