import {Pipe, PipeTransform} from '@angular/core';
import {NuovaConfigurazioneOperazione} from "../../../../../api-clients/generated/services";
import {TranslocoService} from "@ngneat/transloco";

export function getOperationActionLabel(opConfig: NuovaConfigurazioneOperazione, translocoService: TranslocoService) {
    const activeLang = translocoService.getActiveLang();
    return opConfig
        ?.azione?.find(
            descriptionSpecification => descriptionSpecification?.lingua?.toLowerCase() === activeLang?.toLowerCase())?.valore;
}

@Pipe({
    name: 'operationActionLabel'
})
export class OperationActionLabelPipe implements PipeTransform {

    constructor(private translocoService: TranslocoService) {
    }

    transform(value: NuovaConfigurazioneOperazione): string {
        return getOperationActionLabel(value, this.translocoService);
    }

}
