<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-2">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t((someInfoNeeded || someDocNeeded) ? 'massive_operations.generic_operation.hint_compile_info' : 'massive_operations.generic_operation.hint_compile_no_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center" *ngIf="someInfoNeeded || someDocNeeded">

            <div class="flex flex-col gap-4 mt-6 w-full mb-2" *ngIf="requestsDetailsFormArray">
                <ng-container *ngFor="let request of requestsSelected; let i = index" class="w-full">

                    <div class="flex flex-col gap-3 bg-gray-100 rounded-xl px-4 py-3">
                        <div class="font-semibold text-lg mt-1">
                            {{getRequestTitle(request)}}
                        </div>
                        <div class="w-full flex flex-col gap-2" *ngIf="requestsDetailsFormArray.at(i) as form">

                            <div class="flex flex-col gap-2 w-full"
                                 [formGroup]="form"
                                 *ngIf="requestFormComponent">

                                <app-dynamic-host [data]="{form: form?.get('requestForm'), request: request, requestsSelected: requestsSelected, requestsFormValuesFormArray: requestsDetailsFormArray}"
                                                  [componentType]="requestFormComponent">
                                </app-dynamic-host>

                            </div>

                            <ng-container *ngIf="getDocumentsFormConfig(request.studenteCiclo?.utente?.id ?? request?.budgetStudente?.studenteCiclo?.utente?.id, request?.[getRequestIdentityFieldName]) as docsFormConfig">

                                <app-form class="w-full"
                                          [noPadding]="true"
                                          [formGroupConfig]="docsFormConfig"
                                          [valueForm]="getDocumentsFormValue(request.studenteCiclo?.utente?.id ?? request?.budgetStudente?.studenteCiclo?.utente?.id, request?.[getRequestIdentityFieldName])"
                                          [chiaveFlusso]="chiaveFlusso"
                                          [chiaveOperazione]="chiaveOperazione"
                                          (someDocumentSignedSuccessfully)="someDocumentSignedSuccessfully()"
                                ></app-form>

                                <div class="text-secondary"
                                     *ngIf="!(docsFormConfig?.length > 0) && isEmpty(form?.get('requestForm')?.getRawValue() ?? {})">
                                    {{t('massive_operations.generic_operation.no_info_to_fill_for_request')}}
                                </div>

                            </ng-container>

                        </div>
                    </div>


                </ng-container>

<!--                <ng-container *ngIf="allRequestsNeedADelibera">-->
<!--                    <button mat-flat-button-->
<!--                            (click)="selectDeliberaForAllRequest()"-->
<!--                            [color]="'accent'">-->
<!--                        <mat-icon class="mr-2">add</mat-icon>-->
<!--                        <span>{{t('massive_operations.generic_operation.select_delibera_for_all')}}</span>-->
<!--                    </button>-->
<!--                </ng-container>-->

<!--                <div *ngIf="someDocNeedSign" class="w-full flex flex-row justify-end items-center border-t-[1.5px] border-t-slate-200 border-solid pt-3">-->
<!--                    &lt;!&ndash; ONLY IF DOCUMENTS NEEDED USES SAME SIGN METHODS &ndash;&gt;-->
<!--                    <app-custom-button-->
<!--                        (clickHandler)="openSignAllRegistroDocumentsDialog()"-->
<!--                        [textButton]="'document_sign.sign_all_documents'"-->
<!--                        [type]="ButtonType.SIGN">-->
<!--                    </app-custom-button>-->
<!--                </div>-->

            </div>

        </div>



    </ng-template>



</ng-container>
