/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi documento
 */
export type ChiaveDocumentoEnum = 'attestato_conclusione_mobilita' | 'allegato_richiesta' | 'convenzione' | 'delibera_collegio' | 'giudizio_commissione' | 'lettera_di_invito' | 'liberatoria' | 'periodo_registro_firmato_dottorando' | 'periodo_registro_firmato_supervisore' | 'periodo_registro_firmato_coordinatore' | 'presentazione_fine_anno_dottorando' | 'relazione_fine_anno_dottorando' | 'relazione_fine_anno_supervisore' | 'registro_attivita' | 'registro_attivita_supervisore' | 'modulo_richiesta_di_missione' | 'modulo_richiesta_di_missione_firmato_supervisore' | 'modulo_richiesta_di_missione_firmato_coordinatore' | 'modulo_richiesta_di_missione_firmato_direttore_di_dipartimento' | 'attestato_partecipazione_evento' | 'decreto_rettorale' | 'nota_del_coordinatore' | 'allegato_richiesta_acquisto' | 'allegato_richiesta_rimborso' | 'scheda_prodotto' | 'modulo_richiesta_di_acquisto' | 'modulo_richiesta_di_acquisto_firmato_supervisore' | 'modulo_richiesta_di_acquisto_firmato_coordinatore' | 'modulo_richiesta_di_acquisto_firmato_direttore_di_dipartimento' | 'modulo_richiesta_di_rimborso' | 'modulo_richiesta_di_rimborso_firmato_supervisore' | 'modulo_richiesta_di_rimborso_firmato_coordinatore' | 'modulo_richiesta_di_rimborso_firmato_direttore_di_dipartimento' | 'allegato_richiesta_missione';

export const ChiaveDocumentoEnum = {
    AttestatoConclusioneMobilita: 'attestato_conclusione_mobilita' as ChiaveDocumentoEnum,
    AllegatoRichiesta: 'allegato_richiesta' as ChiaveDocumentoEnum,
    Convenzione: 'convenzione' as ChiaveDocumentoEnum,
    DeliberaCollegio: 'delibera_collegio' as ChiaveDocumentoEnum,
    GiudizioCommissione: 'giudizio_commissione' as ChiaveDocumentoEnum,
    LetteraDiInvito: 'lettera_di_invito' as ChiaveDocumentoEnum,
    Liberatoria: 'liberatoria' as ChiaveDocumentoEnum,
    PeriodoRegistroFirmatoDottorando: 'periodo_registro_firmato_dottorando' as ChiaveDocumentoEnum,
    PeriodoRegistroFirmatoSupervisore: 'periodo_registro_firmato_supervisore' as ChiaveDocumentoEnum,
    PeriodoRegistroFirmatoCoordinatore: 'periodo_registro_firmato_coordinatore' as ChiaveDocumentoEnum,
    PresentazioneFineAnnoDottorando: 'presentazione_fine_anno_dottorando' as ChiaveDocumentoEnum,
    RelazioneFineAnnoDottorando: 'relazione_fine_anno_dottorando' as ChiaveDocumentoEnum,
    RelazioneFineAnnoSupervisore: 'relazione_fine_anno_supervisore' as ChiaveDocumentoEnum,
    RegistroAttivita: 'registro_attivita' as ChiaveDocumentoEnum,
    RegistroAttivitaSupervisore: 'registro_attivita_supervisore' as ChiaveDocumentoEnum,
    ModuloRichiestaDiMissione: 'modulo_richiesta_di_missione' as ChiaveDocumentoEnum,
    ModuloRichiestaDiMissioneFirmatoSupervisore: 'modulo_richiesta_di_missione_firmato_supervisore' as ChiaveDocumentoEnum,
    ModuloRichiestaDiMissioneFirmatoCoordinatore: 'modulo_richiesta_di_missione_firmato_coordinatore' as ChiaveDocumentoEnum,
    ModuloRichiestaDiMissioneFirmatoDirettoreDiDipartimento: 'modulo_richiesta_di_missione_firmato_direttore_di_dipartimento' as ChiaveDocumentoEnum,
    AttestatoPartecipazioneEvento: 'attestato_partecipazione_evento' as ChiaveDocumentoEnum,
    DecretoRettorale: 'decreto_rettorale' as ChiaveDocumentoEnum,
    NotaDelCoordinatore: 'nota_del_coordinatore' as ChiaveDocumentoEnum,
    AllegatoRichiestaAcquisto: 'allegato_richiesta_acquisto' as ChiaveDocumentoEnum,
    AllegatoRichiestaRimborso: 'allegato_richiesta_rimborso' as ChiaveDocumentoEnum,
    SchedaProdotto: 'scheda_prodotto' as ChiaveDocumentoEnum,
    ModuloRichiestaDiAcquisto: 'modulo_richiesta_di_acquisto' as ChiaveDocumentoEnum,
    ModuloRichiestaDiAcquistoFirmatoSupervisore: 'modulo_richiesta_di_acquisto_firmato_supervisore' as ChiaveDocumentoEnum,
    ModuloRichiestaDiAcquistoFirmatoCoordinatore: 'modulo_richiesta_di_acquisto_firmato_coordinatore' as ChiaveDocumentoEnum,
    ModuloRichiestaDiAcquistoFirmatoDirettoreDiDipartimento: 'modulo_richiesta_di_acquisto_firmato_direttore_di_dipartimento' as ChiaveDocumentoEnum,
    ModuloRichiestaDiRimborso: 'modulo_richiesta_di_rimborso' as ChiaveDocumentoEnum,
    ModuloRichiestaDiRimborsoFirmatoSupervisore: 'modulo_richiesta_di_rimborso_firmato_supervisore' as ChiaveDocumentoEnum,
    ModuloRichiestaDiRimborsoFirmatoCoordinatore: 'modulo_richiesta_di_rimborso_firmato_coordinatore' as ChiaveDocumentoEnum,
    ModuloRichiestaDiRimborsoFirmatoDirettoreDiDipartimento: 'modulo_richiesta_di_rimborso_firmato_direttore_di_dipartimento' as ChiaveDocumentoEnum,
    AllegatoRichiestaMissione: 'allegato_richiesta_missione' as ChiaveDocumentoEnum
};