/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati della registrazione del superamento di anno studente nei sistemi di Ateneo. Valori previsti: *DA_REGISTRARE*, *REGISTRATO*
 */
export type SuperamentoAnnoRegistrazioneStatus = 'DA_REGISTRARE' | 'REGISTRATO';

export const SuperamentoAnnoRegistrazioneStatus = {
    DAREGISTRARE: 'DA_REGISTRARE' as SuperamentoAnnoRegistrazioneStatus,
    REGISTRATO: 'REGISTRATO' as SuperamentoAnnoRegistrazioneStatus
};