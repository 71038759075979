import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LocalStorageService} from './local-storage.service';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {
    AttivitaOffertaFormativaInfoView,
    AuthorityType,
    SottoruoloUtenteUserView,
    UserSottoruoloView
} from '../../../api-clients/generated/services';


@Injectable({
    providedIn: 'root'
})
export class StudentiBarFilterManagerService {

    mainFormGroup: FormGroup | undefined;
    codiceFiscaleSupervisoreList: UserSottoruoloView[] | undefined;
    codiceFiscaleCosupervisoreList: UserSottoruoloView[] | undefined;
    codiceFiscaleDocenteList: UserSottoruoloView[] | undefined;
    corsiDocenteCorso: AttivitaOffertaFormativaInfoView[] | undefined;
    checkApplyFilterClick$ = new BehaviorSubject(false);
    checkAggiornamentoSupervisori$ = new BehaviorSubject(undefined);
    checkAggiornamentoCosupervisori$ = new BehaviorSubject(undefined);
    checkAggiornamentoDocenti$ = new BehaviorSubject(undefined);
    checkCorsiDocenteCorso$ = new BehaviorSubject(undefined);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$= new BehaviorSubject(undefined);
    defaultFormPathList = [
        'nominativo',
        'ateneoProvenienza',
        'nazionalita',
        'codiceFiscale',
        'codiceFiscaleSupervisore', //select
        'ssdSupervisore',
        'afferenzaOrganizzativaSupervisore',
        'codiceFiscaleCosupervisore', //select
        'ssdCosupervisore',
        'afferenzaOrganizzativaCosupervisore',
        'codiceFiscaleDocenteCorso',
        'mobilitaDaApprovare',
        'codiceTipoBorsaPerCiclo',
        'statoCarriera',
        'hasNumGiorniMobilitaInferioreAlMinimoPrevisto',
        'hasNumGiorniMobilitaSuperioreAlPrevisto',
    ];
    docenteCorsoFormPathList = [
        'nominativo',
        'ateneoProvenienza',
        'nazionalita',
        'codiceFiscale',
        'codiceFiscaleSupervisore', //select
        'ssdSupervisore',
        'afferenzaOrganizzativaSupervisore',
        'codiceFiscaleCosupervisore', //select
        'ssdCosupervisore',
        'afferenzaOrganizzativaCosupervisore',
        'codiceTipoBorsaPerCiclo',
        'statoCarriera',
        'statoCarrieraMotivazione',
    ];
    coordinatoreFormPathList = [
        'nominativo',
        'ateneoProvenienza',
        'nazionalita',
        'codiceFiscale',
        'codiceFiscaleSupervisore', //select
        'ssdSupervisore',
        'afferenzaOrganizzativaSupervisore',
        'codiceFiscaleCosupervisore', //select
        'ssdCosupervisore',
        'afferenzaOrganizzativaCosupervisore',
        'codiceFiscaleDocenteCorso',
        'statoSpesaStudente', // checkbox
        'mobilitaDaApprovare', //checkbox
        'codiceTipoBorsaPerCiclo',
        'hasNotSupervisore',
        'hasNotCosupervisore',
        'hasSupervisoreButNotCosupervisore',
        'hasNotSupervisoreOrCosupervisoreInterno',
        'statoCarriera',
        'statoCarrieraMotivazione',
        'hasNumGiorniMobilitaInferioreAlMinimoPrevisto',
        'hasNumGiorniMobilitaSuperioreAlPrevisto',
        'hasMissioniDaApprovare',
    ];
    gruppoPTAFormPathList = [
        'nominativo',
        'ateneoProvenienza',
        'nazionalita',
        'codiceFiscale',
        'codiceFiscaleSupervisore', //select
        'ssdSupervisore',
        'afferenzaOrganizzativaSupervisore',
        'codiceFiscaleCosupervisore', //select
        'ssdCosupervisore',
        'afferenzaOrganizzativaCosupervisore',
        'codiceFiscaleDocenteCorso',
        'mobilitaDaApprovare',
        'codiceTipoBorsaPerCiclo',
        'hasNotDeliberaInizioAttivita',
        'hasNotSupervisore',
        'hasNotCosupervisore',
        'hasSupervisoreButNotCosupervisore',
        'hasNotSupervisoreOrCosupervisoreInterno',
        'statoCarriera',
        'statoCarrieraMotivazione',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['nominativo', 'menu_filter.nominative'],
        ['ateneoProvenienza', 'students_list.university_of_origin'],
        ['nazionalita', 'students_list.nationality'],
        ['codiceFiscaleSupervisore', 'menu_filter.supervisor'],
        ['ssdSupervisore', 'menu_filter.supervisor_ssd'],
        ['afferenzaOrganizzativaSupervisore', 'menu_filter.supervisor_organizational_affiliation'],
        ['codiceFiscaleCosupervisore', 'menu_filter.co_supervisor'],
        ['ssdCosupervisore', 'menu_filter.co_supervisor_ssd'],
        ['afferenzaOrganizzativaCosupervisore', 'menu_filter.co_supervisor_organizational_affiliation'],
        ['codiceFiscaleDocenteCorso', 'cycle_doctorate.professor'],
        ['statoSpesaStudente','student.expenses_to_be_approved'],
        ['mobilitaDaApprovare', 'mobility.period_to_approve'],
        ['codiceTipoBorsaPerCiclo', 'common.type_of_scholarship_code'],
        ['hasNotDeliberaInizioAttivita', 'students_list.has_not_delibera_inizio_attivita'],
        ['hasNotSupervisore', 'menu_filter.has_not_supervisor'],
        ['hasNotCosupervisore', 'menu_filter.has_not_cosupervisor'],
        ['hasSupervisoreButNotCosupervisore', 'menu_filter.has_supervisor_but_not_cosupervisor'],
        ['hasNotSupervisoreOrCosupervisoreInterno', 'menu_filter.has_not_supervisor_cosupervisor_internal'],
        ['codiceFiscale', 'common.fiscal_code'],
        ['statoCarriera', 'menu_filter.career_status'],
        ['statoCarrieraMotivazione', 'menu_filter.career_status_reason'],
        ['hasNumGiorniMobilitaInferioreAlMinimoPrevisto', 'menu_filter.days_mobility_below_minimum_expected'],
        ['hasNumGiorniMobilitaSuperioreAlPrevisto', 'menu_filter.days_mobility_above_expected'],
        ['hasMissioniDaApprovare', 'menu_filter.has_mission_to_approve'],
    ]);




    constructor(private localStorageService: LocalStorageService,
                private formBuilder: FormBuilder) {
    }

    init(forceHasNotDeliberaInizioAttivita?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            if(!!this.mainFormGroup) {
                this.mainFormGroup.reset();
            } else {
                this.mainFormGroup = this.createDefaultFormGroup();
            }
        }
        if(forceHasNotDeliberaInizioAttivita){
            this.mainFormGroup?.get('hasNotDeliberaInizioAttivita')?.patchValue(forceHasNotDeliberaInizioAttivita);
        }
    }

    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            nominativo: null,
            ateneoProvenienza: null,
            nazionalita: null,
            codiceFiscaleSupervisore: null, //select
            ssdSupervisore: null,
            afferenzaOrganizzativaSupervisore: null,
            codiceFiscaleCosupervisore: null, //select
            ssdCosupervisore: null,
            afferenzaOrganizzativaCosupervisore: null,
            codiceFiscaleDocenteCorso: null, //select
            statoSpesaStudente: null,  // check
            mobilitaDaApprovare: null,
            codiceTipoBorsaPerCiclo: null,
            hasNotDeliberaInizioAttivita: null,
            hasNotSupervisore: null,
            hasNotCosupervisore: null,
            hasSupervisoreButNotCosupervisore: null,
            hasNotSupervisoreOrCosupervisoreInterno: null,
            codiceFiscale: null,
            statoCarriera: null,
            statoCarrieraMotivazione: null,
            hasNumGiorniMobilitaInferioreAlMinimoPrevisto: null,
            hasNumGiorniMobilitaSuperioreAlPrevisto: null,
            hasMissioniDaApprovare: null,
        });
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    appliedFiltersObsevable$(): Observable<any> {
        return this.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            map(() => this.mainFormGroup?.value)
        );
    }

    getFieldsByRole(ruolo?: AuthorityType, sottoruolo?: AuthorityType): string[] {
        switch(ruolo) {
            case AuthorityType.SUPERADMIN:
            case AuthorityType.ADMIN:
                return this.defaultFormPathList;
            default:
                switch (sottoruolo) {
                    case AuthorityType.GRUPPODICOORDINAMENTO:
                    case AuthorityType.COLLEGIODOCENTI:
                    case AuthorityType.GRUPPOPTA:
                    case AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO:
                        return this.gruppoPTAFormPathList;
                    case AuthorityType.DOCENTECORSO:
                        return this.docenteCorsoFormPathList;
                    case AuthorityType.COORDINATORE:
                        return this.coordinatoreFormPathList;
                    default:
                        return null;
                }
        }


    }


    setCodiceFiscaleSupervisoreList(arraySupervisori?: Array<SottoruoloUtenteUserView>): void {
        this.codiceFiscaleSupervisoreList = arraySupervisori?.map(item => item?.user);
        this.checkAggiornamentoSupervisori$.next(this.codiceFiscaleSupervisoreList);
    }

    setCodiceFiscaleCosupervisoreList(arrayCosupervisori?: Array<SottoruoloUtenteUserView>): void {
        this.codiceFiscaleCosupervisoreList = arrayCosupervisori?.map(item => item?.user);
        this.checkAggiornamentoCosupervisori$.next(this.codiceFiscaleCosupervisoreList);
    }

    setCodiceFiscaleDocenteList(arrayDocenti: SottoruoloUtenteUserView[]): void {
        this.codiceFiscaleDocenteList = arrayDocenti?.map(item => item?.user);
        this.checkAggiornamentoDocenti$.next(this.codiceFiscaleDocenteList);
    }

}
