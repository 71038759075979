import {Component, Inject, OnInit} from '@angular/core';
import {
    EsitoOperazione,
    EsitoSingolaOperazioneInOperazioneMassiva,
    OperazioniMassiveService,
    StudenteCicloUtenteViewImpl,
    SuperamentoMassivoCorsoRequest,
    SuperamentoMassivoCorsoResponse,
    UserSuperamentoCorsoMassivo
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {SuperamentoCorsoMassivoDataI} from "../superamento-corso-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {get} from "lodash";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";


@Component({
    selector: 'app-confirm-superamento-corso-stepper',
    templateUrl: './confirm-superamento-corso.component.html',
    styleUrls: ['./confirm-superamento-corso.component.scss']
})
export class ConfirmSuperamentoCorsoComponent extends AbstractDefaultComponent implements OnInit {

    superamentoCorsoData: SuperamentoCorsoMassivoDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaOperazioneInOperazioneMassiva> | undefined;
    resultsStudentiSelected: StudenteCicloUtenteViewImpl[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                protected cicloConfigurationService: CicloConfigurationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<SuperamentoCorsoMassivoDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: SuperamentoCorsoMassivoDataI) => {
            this.superamentoCorsoData = inputOutputData;
            // when students are set reset succeed and error flags
            if(this.superamentoCorsoData?.studentiSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const superamentoCorsoBody: SuperamentoMassivoCorsoRequest = {
            data_superamento: this.superamentoCorsoData?.dataSuperamento,
            cfu_ottenuti: this.superamentoCorsoData?.cfuOttenuti as number,
            nota_superamento: this.superamentoCorsoData?.notaSuperamento,
            utenti_superamento: this.buildUtentiSuperamento(),
        }
        console.log(this.superamentoCorsoData)
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.superamentoCorsoMassivo(superamentoCorsoBody, this.superamentoCorsoData?.corsoSelected?.id)),
            takeUntil(this.destroy$),
            tap((superamentoMassivoCorsoResponse: SuperamentoMassivoCorsoResponse) => {
                this.resultsStudentiSelected  = cloneDeep(this.superamentoCorsoData?.studentiSelected);
                // removing from current operation the students for which the esito is success
                const succededStudentsIds = superamentoMassivoCorsoResponse?.esiti_operazioni
                    ?.filter(o => o.esito === EsitoOperazione.SUCCESS)
                    .map(o => o.id_utente);
                this.operazioneMassivaData$.next({
                    corsoSelected: this.superamentoCorsoData?.corsoSelected,
                    studentiSelected: this.superamentoCorsoData?.studentiSelected
                        ?.filter(s => !succededStudentsIds.includes(s.idUtente)),
                    dataSuperamento: this.superamentoCorsoData?.dataSuperamento,
                    cfuOttenuti: this.superamentoCorsoData?.cfuOttenuti,
                    notaSuperamento: this.superamentoCorsoData?.notaSuperamento,
                    noteSuperamentoCustom: this.superamentoCorsoData
                        ?.noteSuperamentoCustom?.filter(s => !succededStudentsIds.includes(s.id)),
                    refreshStudents: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (superamentoMassivoCorsoResponse: SuperamentoMassivoCorsoResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = superamentoMassivoCorsoResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    private buildUtentiSuperamento(): UserSuperamentoCorsoMassivo[] {
        return this.superamentoCorsoData?.studentiSelected?.map(s => ({
            id_utente: s.idUtente,
            nota_superamento: this.superamentoCorsoData?.noteSuperamentoCustom?.find(sWithNotaCustom => s.idUtente === sWithNotaCustom.id)?.nota || this.superamentoCorsoData?.notaSuperamento
        }))
    }

    getNotaSuperamento(studente: StudenteCicloUtenteViewImpl): string {
        return this.superamentoCorsoData?.noteSuperamentoCustom?.find(sWithNotaCustom => studente.idUtente === sWithNotaCustom.id)?.nota || this.superamentoCorsoData?.notaSuperamento;
    }

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente = this.resultsStudentiSelected?.find(s => s.idUtente === id_utente);
        return studente?.cognome + ' ' + studente?.nome;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

}
