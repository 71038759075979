<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t((hasSomeDocToFill()) ?
                'massive_operations.approvazione_conclusione_ricerca.hint_compile_info' : 'massive_operations.approvazione_conclusione_ricerca.hint_compile_no_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center">


            <!-- TABELLA STUDENTI -->
            <div class="lg:w-[95%] flex flex-col items-center gap-5 bg-gray-50 rounded-xl p-3"
                 *ngIf="(hasSomeDocToFill())">
                <div class="flex flex-col gap-0 w-full items-center">
                    <div class="w-full rounded-lg bg-slate-200 text-slate-600 px-4 py-2 grid grid-cols-6 font-semibold items-center justify-center text-center text-sm">
                        <div>{{t('mobility.mobility_code')}}</div>
                        <div>{{t('mobility.student')}}</div>
                        <div class="col-span-4">{{t(currentProfile === AuthorityType.COORDINATORE ? 'mobility.approve_coordinator_request_documents' : 'mobility.approve_supervisor_request_documents' )}}</div>
                    </div>
                    <div class="w-full grid grid-cols-6 px-4 py-2 min-h-18 text-center items-center gap-2"
                         *ngFor="let compileInfo of periodiCompileInfos">
                        <div>{{compileInfo.codiceMobilita}}</div>
                        <div class="font-semibold">{{t(compileInfo.studenteUIMapped?.utenteCognome)}} {{t(compileInfo.studenteUIMapped?.utenteNome)}}</div>
                        <div class="col-span-4">
                            <app-form class="w-full"
                                      [noPadding]="true"
                                      [formGroupConfig]="getDocumentsFormConfig(compileInfo.utenteId, compileInfo.codiceMobilita)"
                                      [valueForm]="getDocumentsFormValue(compileInfo.utenteId, compileInfo.codiceMobilita)"
                                      [chiaveFlusso]="ChiaveFlussoEnum.RichiestaMobilita"
                                      [chiaveOperazione]="currentProfile === AuthorityType.COORDINATORE ?
                                        ChiaveOperazioneEnum.ApprovazioneConclusioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneConclusioneSupervisore"
                            ></app-form>
                        </div>
                    </div>
                    <!--  to implement according specific document -->
<!--                    <div *ngIf="someDocNeedSign" class="w-full flex flex-row justify-end items-center border-t-[1.5px] border-t-slate-200 border-solid pt-3">-->
<!--                        &lt;!&ndash; ONLY IF DOCUMENTS NEEDED USES SAME SIGN METHODS &ndash;&gt;-->
<!--                        <app-custom-button-->
<!--                            (clickHandler)="openSignAllRegistroDocumentsDialog()"-->
<!--                            [textButton]="'document_sign.sign_all_documents'"-->
<!--                            [type]="ButtonType.SIGN">-->
<!--                        </app-custom-button>-->
<!--                    </div>-->
                </div>
            </div>


        </div>



    </ng-template>



</ng-container>
