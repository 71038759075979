/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Ruoli degli utenti di piattaforma. Ruoli utente previsti: *ADMIN*, *SUPERADMIN*, *DOCENTE*, *PERSONALE_ATA*, *STUDENTE*, *ESTERNO*. Ruoli amministrativi previsti: *SUPERADMIN*, *PTA_ATENEO*, *PTA_ATENEO_CSV_ANS*, *PTA_ATENEO_MOBILITA*, *PTA_ATENEO_MOBILITA_RICHIESTE*. Sottoruoli previsti sul corso: *GRUPPO_PTA*, *COORDINATORE_PTA*, *COLLEGIO_DOCENTI*, *GRUPPO_DI_COORDINAMENTO*, *DOCENTE_CORSO*. Sottoruoli previsti sullo studente: *SUPERVISORE*, *COSUPERVISORE*, *RELATORE*.
 */
export type AuthorityType = 'ADMIN' | 'SUPERADMIN' | 'DOCENTE' | 'PERSONALE_PTA' | 'STUDENTE' | 'ESTERNO' | 'COMMISSIONE_DI_AUTOVALUTAZIONE' | 'COORDINATORE' | 'GRUPPO_DI_COORDINAMENTO' | 'COLLEGIO_DOCENTI' | 'DIRETTORE_DI_DIPARTIMENTO' | 'TITOLARE_FONDO' | 'DOCENTE_CORSO' | 'GRUPPO_PTA' | 'SEGRETARIO_AMMINISTRATIVO_DI_DIPARTIMENTO' | 'SUPERVISORE' | 'COSUPERVISORE' | 'RELATORE' | 'PTA_ATENEO' | 'PTA_ATENEO_CSV_ANS' | 'PTA_ATENEO_MOBILITA' | 'PTA_ATENEO_MOBILITA_RICHIESTE' | 'PTA_ATENEO_DOTT_NO_MATRICOLA' | 'PTA_ATENEO_SYNC_ESTERNI' | 'PTA_ATENEO_SYNC_DOTTORANDI' | 'PTA_ATENEO_SYNC_SUPERAMENTI_ANNO';

export const AuthorityType = {
    ADMIN: 'ADMIN' as AuthorityType,
    SUPERADMIN: 'SUPERADMIN' as AuthorityType,
    DOCENTE: 'DOCENTE' as AuthorityType,
    PERSONALEPTA: 'PERSONALE_PTA' as AuthorityType,
    STUDENTE: 'STUDENTE' as AuthorityType,
    ESTERNO: 'ESTERNO' as AuthorityType,
    COMMISSIONEDIAUTOVALUTAZIONE: 'COMMISSIONE_DI_AUTOVALUTAZIONE' as AuthorityType,
    COORDINATORE: 'COORDINATORE' as AuthorityType,
    GRUPPODICOORDINAMENTO: 'GRUPPO_DI_COORDINAMENTO' as AuthorityType,
    COLLEGIODOCENTI: 'COLLEGIO_DOCENTI' as AuthorityType,
    DIRETTOREDIDIPARTIMENTO: 'DIRETTORE_DI_DIPARTIMENTO' as AuthorityType,
    TITOLAREFONDO: 'TITOLARE_FONDO' as AuthorityType,
    DOCENTECORSO: 'DOCENTE_CORSO' as AuthorityType,
    GRUPPOPTA: 'GRUPPO_PTA' as AuthorityType,
    SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO: 'SEGRETARIO_AMMINISTRATIVO_DI_DIPARTIMENTO' as AuthorityType,
    SUPERVISORE: 'SUPERVISORE' as AuthorityType,
    COSUPERVISORE: 'COSUPERVISORE' as AuthorityType,
    RELATORE: 'RELATORE' as AuthorityType,
    PTAATENEO: 'PTA_ATENEO' as AuthorityType,
    PTAATENEOCSVANS: 'PTA_ATENEO_CSV_ANS' as AuthorityType,
    PTAATENEOMOBILITA: 'PTA_ATENEO_MOBILITA' as AuthorityType,
    PTAATENEOMOBILITARICHIESTE: 'PTA_ATENEO_MOBILITA_RICHIESTE' as AuthorityType,
    PTAATENEODOTTNOMATRICOLA: 'PTA_ATENEO_DOTT_NO_MATRICOLA' as AuthorityType,
    PTAATENEOSYNCESTERNI: 'PTA_ATENEO_SYNC_ESTERNI' as AuthorityType,
    PTAATENEOSYNCDOTTORANDI: 'PTA_ATENEO_SYNC_DOTTORANDI' as AuthorityType,
    PTAATENEOSYNCSUPERAMENTIANNO: 'PTA_ATENEO_SYNC_SUPERAMENTI_ANNO' as AuthorityType
};