import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogInfoComponent} from './dialog-info.component';

import {MatDialogModule} from '@angular/material/dialog';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {TranslocoModule} from '@ngneat/transloco';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatChipsModule} from '@angular/material/chips';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared.module';
import {DialogWrapModule} from '../../../layout/common/dialog-wrap/dialog-wrap.module';
import {InfoWrapModule} from '../../../layout/common/info-dialog/info-wrap.module';
import {ImageHeaderModule} from "../../../modules/header/image-header.module";
import {MatIconModule} from "@angular/material/icon";




@NgModule({
    declarations: [DialogInfoComponent],
    imports: [
        CommonModule,
        DialogWrapModule,
        MatDialogModule,
        FlexModule,
        MatButtonModule,
        TranslocoModule,
        SharedModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        MatChipsModule,
        ReactiveFormsModule,
        InfoWrapModule,
        ImageHeaderModule,
        MatIconModule,
    ],
    exports: [
        DialogInfoComponent
    ]
})
export class DialogInfoModule { }
