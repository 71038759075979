import {ThemePalette} from "@angular/material/core";
import {PathEnum} from "../../../../app.routing";
import {formGroupConfigInterface} from "../../../../layout/common/generic-components/generic-components.interface";
import {FormGroup} from "@angular/forms";
import {
    AnnoRiferimentoValues,
    CategoriaInPianoDiStudiInfoView,
    ChiaveFlussoEnum, ChiaveOperazioneEnum
} from "../../../../../api-clients/generated/services";
import {
    TrainingOfferCategoryDataUI
} from "../../../../modules/landing/training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component";

export interface GenericTableConfigurationModel {
    configuration?: ConfigurazioneTabella;
}

export interface ConfigurazioneTabella {
    configurazioneTabella?: ConfigurazioneColonna[];
    data?: any[];
    pageSize?: number;
    pageSizeOptions?: number[];
    sticky?: boolean;
    css?: { [p: string]: any } | null;
    customButton?: boolean,  // i tasti azione non hanon padding e sono più piccoli
    messaggioDatiAssenti?: string;
    cssMatTable?: { [p: string]: any } | null;
    cssMatPaginator?: { [p: string]: any } | null;
    isPaginatedBE?: boolean;
    pageBE?: number;
    selection?: OpzioniSelezione;
    singleChoiceSelection?: boolean;
    totalElements?: number;
    hidePaginator?: boolean;
    highlightRowWhen?: (row) => string | undefined;
    disabledRowTooltip?: (row) => string | undefined;
    expandRow?: ExpandedGenericTable;
    groups?: GroupsGenericTable; // configurazione per raggruppamento delle righe con un'intestazione
    disabledCssClass?: string; // class for disabled rows
    checkboxColumnFormGroup?: FormGroup;
    classTable?: string;
}

export interface GroupsGenericTable {
    // sulla riga che ha valorizzato questo campo viene mostrata un'intestazione con denominazione e descrizione in elemento[firstRowOfGroupWithTitleKey]
    firstRowOfGroupKey?: string;
}

export interface GroupGenericTable {
    denominazione?: string;
    descrizione?: string;
    id: string;
}

export interface ExpandedGenericTable {
    expand: boolean;
    dataKey: string;
    parentIdKey: string
    configurazioneTabella?: ConfigurazioneColonna[];
    pageSize?: number;
    pageSizeOptions?: number[];
    sticky?: boolean;
    css?: { [p: string]: any } | null;
    customButton?: boolean,  // i tasti azione non hanon padding e sono più piccoli
    messaggioDatiAssenti?: string;
    cssMatTable?: { [p: string]: any } | null;
    cssMatPaginator?: { [p: string]: any } | null;
    selection?: OpzioniSelezione;
    singleChoiceSelection?: boolean;
    hidePaginator?: boolean;
    highlightRowWhen?: (row, parentRow) => string | undefined;
    disabledRowTooltip?: (row, parentRow) => string | undefined;
    data?: any; // viene popolato in automatico tramite dataKey
    tableTitle?: string;
    disabledCssClass?: string;
}

export interface OpzioniSelezione {
    isSelectable: boolean;
    selectedColumnKeyToShow: string;
    selectedColumnKeyPrimaryKey: string;
    selectedAndDisableByDefaultIf?: (value: any) => string;
    selectedByDefaultIfAndCallbackOnUnselect?: (value: any) => boolean;
}

export enum TipoColonnaEnum {
    SELECTION = 'selection',
    ICON = 'icon',
    STRING = 'string',
    ACTION = 'action',
    ACTION_MENU = 'action_menu',
    BOOLEAN = 'boolean',
    NUMBER = 'number',
    TEXT_BUTTON = 'text_button',
    AMOUNT = 'amount',
    HREF = 'href',
    CHIP_USER = 'chip_user',
    CHIP_STATUS = 'chip_status',
    CHECKBOX = 'checkbox',
    INPUT_NUMBER = 'input_number',
    INPUT_TEXT = 'input_text',
    INPUT_AMOUNT = 'input_amount',
    EXPAND = 'expand',
    INPUT_DATE = 'input_date',
    INPUT_SELECT = 'input_select',
    INPUT_BOOLEAN = 'input_boolean',
    CHIP_STATUS_FROM_CONFIG = 'chip_status_from_config',
}

export class IconInterface {
    nameIcon: string;
    colorIcon: ThemePalette;
    tootlip?: string;
    classes?: string;
    showIf?: (element: any) => boolean;
    onClick?: (element: any) => void;
}

export enum TipoClickEnum {
    CONFERMA = 'conferma',
    DISABILITA = 'disabilita',
    ABILITA = 'abilita',
    MENU = 'menu',
    REGISTRAZIONE = 'registrazione',
    ANNULLA_REGISTRAZIONE = 'ANNULLA_REGISTRAZIONE',
    EDIT = 'edit',
    DELETE = 'delete',
    INFO = 'info',
    DOCENZA = 'docenza_sindacale',
    DOWNLOAD = 'download',
    EVIDENZA_DOTTORANDO_NO_MATRICOLA = 'evidenza_dottorando_no_matricola',
    RIMUOVI_EVIDENZA_DOTTORANDO_NO_MATRICOLA = 'rimuovi_evidenza_dottorando_no_matricola',
    ABILITA_FONDO = 'abilita_fondo',
    DISABILITA_FONDO = 'disabilita_fondo',
    SHOW_STORICO_FONDO = 'show_storico_fondo',
    SHOW_ANTEPRIMA_FILE_FONDO = 'show_anteprima_file_fondo',
    DOWNLOAD_HEADER_FONDO = 'download_header_fondo',
    DOWNLOAD_FOOTER_FONDO = 'download_footer_fondo',
    DOWNLOAD2 = 'download2',
    ADD = 'add',
    NOTA = 'nota',
    SHOW = 'show',
    SHOW_CALENDAR = 'show_calendar',
    SHOW_PORTAFOGLIO = 'show_portafoglio',
    CHIP_DETAIL = "chip_detail",
    SUPERAMENTO_CORSO = 'superamento_corso',
    REFUSE_SUPERVISOR_CO_SUPERVISOR = 'refuse_supervisor_co_supervisor',
    APPROVE_SUPERVISOR_CO_SUPERVISOR = 'approve_supervisor_co_supervisor',
    REFUSE_PTA = 'refuse_pta',
    APPROVE_PTA = 'approve_pta',
    REFUSE_COORDINATOR = 'refuse_coordinator',
    APPROVE_COORDINATOR = 'approve_coordinator',
    SHOW_NOTES = "show_notes",
    SHOW_REFUSE_REASON = "show_refuse_reason",
    DOWNLOAD_APPROVED_FILE = "download_approved_file",
    CARICA_ATTESTATO = 'carica_attestato',
    SHOW_ATTESTATO_ATTIVITA_EXTRA = 'show_attestato_attivita_extra',
    SHOW_DELIBERA_ATTIVITA_EXTRA = 'show_delibera_attivita_extra',
    RIFIUTA_ATTIVITA_EXTRA = 'rifiuta_attivita_extra',
    APPROVA_ATTIVITA_EXTRA = 'approva_attivita_extra',
    OPEN_SURVEY = 'open_survey',
    MODIFICA_ATTIVITA_STUDENTE = 'modifica_attivita_studente',
    RIMUOVI_ATTIVITA_STUDENTE = 'rimuovi_attivita_studente',
    MODIFICA_FORM = 'MODIFICA_FORM',
    SALVA_FORM = 'SALVA_FORM',
    RESET_FORM = 'RESET_FORM',
    CARICA_ATTESTATO_ATTIVITA_STUDENTE = 'CARICA_ATTESTATO_ATTIVITA_STUDENTE', // disponibile per ruollo studente
    CARICA_DELIBERA_ATTIVITA_STUDENTE = 'CARICA_DELIBERA_ATTIVITA_STUDENTE', // disponibile per sottoruolo supervisore
    RIFIUTA_ATTIVITA_STUDENTE = 'RIMUOVI_ATTIVITA_STUDENTE', // disponibile per sottoruolo supervisore
    SUPERAMENTO_ATTIVITA_STUDENTE = 'SUPERAMENTO_ATTIVITA_STUDENTE', // disponibile per sottoruolo supervisore
    SCARICA_DELIBERA = 'SCARICA_DELIBERA',
    SCARICA_ATTESTATO = 'SCARICA_ATTESTATO',
    OPEN_TICKET_DETAIL = "APRI_DETTAGLIO_TICKET",
    RIMUOVI_ATTIVITA_TRASVERSALE = "RIMUOVI_ATTIVITA_TRASVERSALE",
    CARICA_DELIBERA_ATTIVITA_TRASVERSALE = "CARICA_DELIBERA_ATTIVITA_TRASVERSALE",
    CARICA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE = "CARICA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE",
    RIFIUTA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE = "RIFIUTA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE",
    ACCETTA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE = "ACCETTA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE",
    RICHIEDI_SUPERAMENTO_ATTIVITA_TRASVERSALE = "RICHIEDI_SUPERAMENTO_ATTIVITA_TRASVERSALE",
    REGISTRA_SUPERAMENTO_ATTIVITA_TRASVERSALE = "REGISTRA_SUPERAMENTO_ATTIVITA_TRASVERSALE",
    REGISTRA_SUPERAMENTO_CON_DOCUMENTO_ATTIVITA_TRASVERSALE = "REGISTRA_SUPERAMENTO_CON_DOCUMENTO_ATTIVITA_TRASVERSALE",
    SCARICA_DELIBERA_ATTIVITA_TRASVERSALE = "SCARICA_DELIBERA_ATTIVITA_TRASVERSALE",
    SCARICA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE = "SCARICA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE",
    SCARICA_DOCUMENTO_SUPERVISORE_ATTIVITA_TRASVERSALE = "SCARICA_DOCUMENTO_SUPERVISORE_ATTIVITA_TRASVERSALE",
    MODIFICA_ATTIVITA_TRASVERSALE = "MODIFICA_ATTIVITA_TRASVERSALE",
    RIMUOVI_ATTIVITA_OFFERTA_FORMATIVA = "RIMUOVI_ATTIVITA_OFFERTA_FORMATIVA",
    MODIFICA_ATTIVITA_OFFERTA_FORMATIVA = "MODIFICA_ATTIVITA_OFFERTA_FORMATIVA",
    REQUIRE_COMPLETION_MOBILITY_REQUEST = "REQUIRE_COMPLETION_MOBILITY_REQUEST",
    CONFIRM_COMPLETION_MOBILITY_REQUEST = "CONFIRM_COMPLETION_MOBILITY_REQUEST",
    REFUSE_COMPLETION_MOBILITY_REQUEST = "REFUSE_COMPLETION_MOBILITY_REQUEST",
    SHOW_REASONS_APPROVER = 'SHOW_REASONS_APPROVER',
    SHOW_REASONS = 'SHOW_REASONS',
    SEND_FOR_VALIDATION = 'SEND_FOR_VALIDATION',
    SHOW_ACTIVITY_LOG_STUDENT_FILE = 'SHOW_ACTIVITY_LOG_STUDENT_FILE',
    APPROVE_MOBILITY_REQUEST_SUPERVISOR_CO_SUPERVISOR="APPROVE_MOBILITY_REQUEST_SUPERVISOR_CO_SUPERVISOR",
    REFUSE_MOBILITY_REQUEST_SUPERVISOR_CO_SUPERVISOR="REFUSE_MOBILITY_REQUEST_SUPERVISOR_CO_SUPERVISOR",
    APPROVE_MOBILITY_REQUEST_COORDINATOR="APPROVE_MOBILITY_REQUEST_COORDINATOR",
    REFUSE_MOBILITY_REQUEST_COORDINATOR="REFUSE_MOBILITY_REQUEST_COORDINATOR",
    SHOW_MOBILITY_REQUEST_DELIBERA="SHOW_MOBILITY_REQUEST_DELIBERA",
    GO_TO_MOBILITA_STUDENTE="GO_TO_MOBILITA_STUDENTE",
    CANCEL_MOBILITY_REQUEST_COORDINATOR="CANCEL_MOBILITY_REQUEST_COORDINATOR",
    REQUIRE_CONCLUSION_MOBILITY_REQUEST="REQUIRE_CONCLUSION_MOBILITY_REQUEST",
    APPROVE_CONCLUSION_MOBILITY_REQUEST_SUPCOSUP="APPROVE_CONCLUSION_MOBILITY_REQUEST_SUPCOSUP",
    REFUSE_CONCLUSION_MOBILITY_REQUEST_SUPCOSUP="REFUSE_CONCLUSION_MOBILITY_REQUEST_SUPCOSUP",
    APPROVE_CONCLUSION_MOBILITY_REQUEST_COORDINATOR="APPROVE_CONCLUSION_MOBILITY_REQUEST_COORDINATOR",
    REFUSE_CONCLUSION_MOBILITY_REQUEST_COORDINATOR="REFUSE_CONCLUSION_MOBILITY_REQUEST_COORDINATOR",
    UPDATE_DELIBERA_MOBILITY_REQUEST="UPDATE_DELIBERA_MOBILITY_REQUEST",
    SHOW_MOBILITY_REQUEST_ATTESTATO="SHOW_MOBILITY_REQUEST_ATTESTATO",
    SHOW_ALLEGATO_BUDGET_REQUEST="SHOW_ALLEGATO_BUDGET_REQUEST",
    GO_TO = 'GO_TO',
    SHOW_ACTIVITY_LOG_STUDENT="SHOW_ACTIVITY_LOG_STUDENT",
    SHOW_ACTIVITY_LOG_SUPERVISOR="SHOW_ACTIVITY_LOG_SUPERVISOR",
    SHOW_ACTIVITY_LOG_COORDINATOR="SHOW_ACTIVITY_LOG_COORDINATOR",
    APPROVE_ACTIVITY_LOG_SUPERVISOR="APPROVE_ACTIVITY_LOG_SUPERVISOR",
    APPROVE_ACTIVITY_LOG_COORDINATOR="APPROVE_ACTIVITY_LOG_COORDINATOR",
    REFUSE_ACTIVITY_LOG_SUPERVISOR="REFUSE_ACTIVITY_LOG_SUPERVISOR",
    REFUSE_ACTIVITY_LOG_COORDINATOR="REFUSE_ACTIVITY_LOG_COORDINATOR",
    EXTERN_RESEND_INVITE="EXTERN_RESEND_INVITE",
    ADD_RETRIBUTION_MOBILITY_REQUEST="ADD_RETRIBUTION_MOBILITY_REQUEST",
    SHOW_DELIBERA_MOBILITA_DETAIL="SHOW_DELIBERA_MOBILITA_DETAIL",
    REFUSE_RETRIBUTION_MOBILITY_SUPCOSUP="REFUSE_RETRIBUTION_MOBILITY_SUPCOSUP",
    REFUSE_RETRIBUTION_MOBILITY_COORDINATOR="REFUSE_RETRIBUTION_MOBILITY_COORDINATOR",
    REFUSE_RETRIBUTION_MOBILITY_PTAATENEO="REFUSE_RETRIBUTION_MOBILITY_PTAATENEO",
    APPROVE_RETRIBUTION_MOBILITY_SUPCOSUP="APPROVE_RETRIBUTION_MOBILITY_SUPCOSUP",
    APPROVE_RETRIBUTION_MOBILITY_COORDINATOR="APPROVE_RETRIBUTION_MOBILITY_COORDINATOR",
    APPROVE_RETRIBUTION_MOBILITY_PTAATENEO="APPROVE_RETRIBUTION_MOBILITY_PTAATENEO",
    DOWNLOAD_CONCLUSION_MOBILITY_REQUEST_ATTACHMENTS="DOWNLOAD_CONCLUSION_MOBILITY_REQUEST_ATTACHMENTS",
    DOWNLOAD_RETRIBUTION_MOBILITY_ATTACHMENTS="DOWNLOAD_RETRIBUTION_MOBILITY_ATTACHMENTS",
    SHOW_RETRIBUTION_MOBILITA_DETAILS="SHOW_RETRIBUTION_MOBILITA_DETAILS",
    SHOW_CANCEL_REASON="SHOW_CANCEL_REASON",
    DOWNLOAD_NOTA_TRASMISSIONE="DOWNLOAD_NOTA_TRASMISSIONE",
    REVOKE_MOBILITA_AUTORIZZAZIONE="REVOKE_MOBILITA_AUTORIZZAZIONE",
    EXTERN_CANCEL_INVITE="EXTERN_CANCEL_INVITE",
    SYNC_EXTERN="SYNC_EXTERN",
    DESYNC_EXTERN="DESYNC_EXTERN",
    SHOW_DETAILS="SHOW_DETAILS",
    APPROVE_DEPARTMENT_DIRECTOR="APPROVE_DEPARTMENT_DIRECTOR",
    REFUSE_DEPARTMENT_DIRECTOR="REFUSE_DEPARTMENT_DIRECTOR",
    GO_TO_QUICKLY = 'GO_TO_QUICKLY',
    SPOSTA_ATTIVITA_OFFERTA_FORMATIVA = 'SPOSTA_ATTIVITA_OFFERTA_FORMATIVA',
    ADD_TRANSVERSAL_TO_PLAN = 'ADD_TRANSVERSAL_TO_PLAN',
    DOWNLOAD_MOBILITY_INVITATION_LETTER = 'DOWNLOAD_MOBILITY_INVITATION_LETTER',
    GO_TO_MISSION = 'GO_TO_MISSION',
    SHOW_REFUSE_REASON_COORDINATOR = 'SHOW_REFUSE_REASON_COORDINATOR',
    SHOW_REFUSE_REASON_SUPERVISOR = 'SHOW_REFUSE_REASON_SUPERVISOR',
    REFUSE_REQUEST = 'REFUSE_REQUEST',
    CONFERMA_IN_APPROVED_PLAN = 'CONFERMA_IN_APPROVED_PLAN',
    SHOW_REQUEST_ATTACHMENTS = 'SHOW_REQUEST_ATTACHMENTS',
    SHOW_RETRIBUTION_REQUEST_ATTACHMENTS = 'SHOW_RETRIBUTION_REQUEST_ATTACHMENTS',
    ENABLE = 'ENABLE',
    DISABLE = 'DISABLE',
    ESEGUI_OPERAZIONE_FLUSSO = 'ESEGUI_OPERAZIONE_FLUSSO',
    SHOW_DOCENTE = 'SHOW_DOCENTE',
    SHOW_EXTERN = 'SHOW_EXTERN',
    SHOW_PERSONALE_PTA = 'SHOW_PERSONALE_PTA',
    GO_TO_REQUEST_PAGE = 'GO_TO_REQUEST_PAGE',
}

export class ButtonInterface {
    nameIconButton?: string;
    click?: TipoClickEnum;
    tooltip?: string;
    color?: 'accent' | 'warn' | 'primary';
    show?: (value: any, element?: any) => boolean;
    showEvery?: (value: any) => boolean;
    valueShow?: string | string[];
    iconTooltip?: string;
    isIcon?: boolean;
    hide?: boolean;
    data?: any;
    chiaveOperazione?: ChiaveOperazioneEnum;
}

export interface ConfigurazioneColonna {
    tipo?: TipoColonnaEnum;
    colonnaKey?: string;
    nomeColonna?: string;
    hideColonna?: boolean;
    icon?: IconInterface[];
    valueKeyForFuction?: string;
    function?: (value: any) => boolean;
    function2?: (value: any) => boolean;
    button?: ButtonInterface[];
    dynamicButton?: (element: any) => ButtonInterface[];
    button2?: ButtonInterface[];
    flex?: number;
    goTo?: {
        path: PathEnum[],
        value: string
    },
    onlyInfo?: (value: any) => boolean;
    show?: (value: any, element?: any) => boolean;
    convertiValoreBoolean?: (value: any) => string;
    convertiValoreBooleanInIcon?: (value: any) => IconInterface;
    enableSort?: boolean;
    valueCheck?: string;
    getValue?: (value: any) => string;
    sortByNumber?: boolean;
    uppercase?: boolean;
    dialog?: {
        openDialog: (value: any) => void;
        fieldValueDialog: any;
    };
    isChipDisabled?: boolean;
    isCheckboxDisabled?: boolean;
    showOnlyWhenSelected?: boolean;
    formControl?: formGroupConfigInterface;
    tooltip?: (tipoModifica: string, element?: any) => string;
    chipWidthAuto?: boolean;
    statusType?: any;
    statusTypeChiaveFlusso?: ChiaveFlussoEnum;
    statusTypeChiaveFlussoFn?: (element: any) => ChiaveFlussoEnum;
    statusTypeAnnoRiferimento?: AnnoRiferimentoValues;
    onlyPositiveWithOneDecimal?: boolean;
    sottoruoloKey?: string;
    chipIconTooltip?: string;
    css?: any;
    alsoShowChipIf?: (value: any) => {
        iconName: string;
        chipText: string;
        classes?: string;
    };
    showAlsoIconIf?: (value: any) => {
        name: string;
        tooltipText: string;
        color: ThemePalette;
    };
    showIconInChipStatus?: (value: any) => {
        icon: string;
        classes: string;
    } | undefined;
    selectOptions?: {
        value: string;
        display: string;
    }[];
}

export interface GenericTableConfigurationInterface {
    configurazioneTabella: ConfigurazioneTabella;
}

export interface ClickEvent {
    category?: TrainingOfferCategoryDataUI;
    tipoClick: TipoClickEnum;
    index?: number;
    value?: any;
    rowValue?: any;
    parentValue?: any;
    data?: any;
    chiaveOperazione?: ChiaveOperazioneEnum;
}

export interface TableData {
    selectedRows: Array<TableDataRows>;
    removedRows: Array<any>;
    addedRows: Array<any>;
}

export interface TableDataRows {
    key: string;
    data?: TableDataRowsDataObj;
}

export interface TableDataRowsDataObj {
    id?: string;
    studente?: string;
    codiceFiscale?: string;
    nomeCognome?: string;
    idUtente?: string;
    codiceSpesa?: string;
}


export enum BooleanStatus {
    FALSE_0,
    TRUE_1,
}
export enum BooleanStatusNotNull {
    BOOLEAN_FALSE="BOOLEAN_FALSE",
    BOOLEAN_TRUE="BOOLEAN_TRUE",
}
