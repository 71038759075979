<ng-container *transloco="let t">
    <ng-container [ngSwitch]="directive">
        <ng-container *ngSwitchCase="'matStepperPrevious'">
            <button [disabled]="loading || disabled"
                    matStepperPrevious
                    mat-raised-button
                    [color]="colorButton"
                    (click)="onClick()">
                <ng-container *ngTemplateOutlet="buttonContentTmpl"></ng-container>
            </button>
        </ng-container>
        <ng-container *ngSwitchCase="'matStepperNext'">
            <button [disabled]="loading || disabled"
                    matStepperNext
                    mat-raised-button
                    [color]="colorButton"
                    (click)="onClick()">
                <ng-container *ngTemplateOutlet="buttonContentTmpl"></ng-container>
            </button>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <button [class.button-small]="smallMode"
                    class="multi-line-button"
                    [disabled]="loading || disabled"
                    mat-raised-button
                    type='button'
                    [color]="colorButton"
                    (click)="onClick()">
                <ng-container *ngTemplateOutlet="buttonContentTmpl"></ng-container>
            </button>
        </ng-container>
    </ng-container>

    <ng-template #buttonContentTmpl>
        <ng-container *ngIf="iconSide === ICONSIDE.LEFT">
            <mat-spinner *ngIf="loading"
                         [diameter]="20" [color]="'primary'"
                         class="mr-2"></mat-spinner>
            <mat-icon class="mr-2" *ngIf="!loading && icon">{{ icon }}</mat-icon>
        </ng-container>
        <span [class.text-sm]="smallMode">{{ t(textButton) }}</span>
        <ng-container *ngIf="iconSide === ICONSIDE.RIGHT">
            <mat-spinner *ngIf="loading"
                         [diameter]="20" [color]="'primary'"
                         class="ml-2"></mat-spinner>
            <mat-icon class="ml-2" *ngIf="!loading && icon">{{ icon }}</mat-icon>
        </ng-container>
    </ng-template>

</ng-container>
