/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Valori relativi allo stato del pagamento delle tasse. Valori previsti: *IN_REGOLA*, *NON_IN_REGOLA*, *ISCRIZIONE_NON_PRESENTE*
 */
export type StatoTasseValues = 'IN_REGOLA' | 'NON_IN_REGOLA' | 'ISCRIZIONE_NON_PRESENTE';

export const StatoTasseValues = {
    INREGOLA: 'IN_REGOLA' as StatoTasseValues,
    NONINREGOLA: 'NON_IN_REGOLA' as StatoTasseValues,
    ISCRIZIONENONPRESENTE: 'ISCRIZIONE_NON_PRESENTE' as StatoTasseValues
};