<ng-container *transloco="let t">

    <div class="h-full w-full flex flex-col">
        <!--HEADER-->
        <app-header [title]="'cycle_doctorate.training_offer'">

            <!-- SELECT SELECT SOTTORUOLO ON DESKTOP -->
            <ng-container *ngIf="!loading && showSelect">
                <div class="flex flex-col xl:flex-row gap-3 xl:gap-5 items-end xl:items-center justify-end">
                    <fuse-alert *ngIf="ctrlSelectFormControl.value && sottoruoli?.length > 1"
                                [showIcon]="true"
                                class="mt-2 lg:mt-0 w-full fuse-alert-modify-subrole"
                                [appearance]="'outline'"
                    >{{t('students_list.modify_subrole')}}</fuse-alert>
                    <mat-form-field  class="hidden md:block mat-form-field-select-sottoruolo" style="width:50%; min-width: 300px; max-width: 500px" appearance="outline">
                        <mat-label>{{t('students_list.subrole')}}</mat-label>
                        <mat-select [formControl]="ctrlSelectFormControl">
                            <mat-option *ngFor="let sottoruolo of sottoruoli" [value]="sottoruolo">
                                {{sottoruolo | removeUnderscores}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- SELECT SELECT SOTTORUOLO ON MOBILE -->
                <div class="w-full flex flex-row justify-center items-center md:hidden" *ngIf="showSelect">
                    <mat-form-field style="width:50%; min-width: 300px; max-width: 500px" appearance="outline" class="md:hidden mat-form-field-select-sottoruolo">
                        <mat-label>{{t('students_list.subrole')}}</mat-label>
                        <mat-select [formControl]="ctrlSelectFormControl">
                            <mat-option *ngFor="let sottoruolo of sottoruoli" [value]="sottoruolo">
                                {{sottoruolo | removeUnderscores}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>



            </ng-container>
            <ng-container *ngIf="appInitService.isAreaAdministrator">
                <div
                    class="flex flex-col xl:flex-row gap-3 xl:gap-5 xl:items-center md:items-end items-center justify-center md:justify-end">
                    <fuse-alert *ngIf="ctrlCorso.value && corsi.length > 1"
                                class="w-full fuse-alert-modify-subrole"
                                [showIcon]="true"
                                [appearance]="'outline'"
                    >{{ t('administration.modify_course') }}
                    </fuse-alert>
                    <mat-form-field style="width:50%; min-width: 300px; max-width: 500px"
                                    [ngClass]="{'mat-form-field-border-red': appInitService.isAreaAdministrator && !ctrlCorso.value}"
                                    appearance="outline" class="mat-form-field-select-sottoruolo">
                        <mat-label>{{ t('common.course') }}</mat-label>
                        <mat-select [disabled]="loading"
                                    (valueChange)="setCurrentCorso($event)"
                                    [formControl]="ctrlCorso">
                            <mat-option *ngFor="let corso of corsi" [value]="corso.codiceEsse3">
                                {{ (corso.codiceEsse3 || '') + ((corso.cicliCorsiDiStudi[0]?.denominazione) ? ' - ' : '') + (corso.cicliCorsiDiStudi[0]?.denominazione || '') }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </app-header>

        <app-loading-screen *ngIf="loading; else trainingOfferTemplate" class="w-full h-full">
        </app-loading-screen>

        <ng-template #trainingOfferTemplate>
            <ng-container *ngIf="appInitService.isAreaAdministrator && !ctrlCorso.value; else infoTmp">
                <fuse-alert
                    class="mt-2 w-full"
                    [showIcon]="true"
                    [appearance]="'outline'"
                >{{ t('administration.corse_not_selected') }}
                </fuse-alert>
            </ng-container>
            <ng-template #infoTmp>
                <!-- CONTENT -->
                <div class="h-full w-full flex flex-col gap-2.5">
                    <div style="padding: 20px 10px 10px 10px"
                         class="flex flex-col flex-auto bg-card shadow overflow-hidden w-full justify-start items-center gap-5" *ngIf="!!this.categoriesByYearConfigurations">

                        <!-- CLONE FUNCTIONALITY -->
                        <div class="w-full px-4"
                             *ngIf="ctrlSelectFormControl.value === AuthorityType.COORDINATORE && canClone">
                            <fuse-alert [showIcon]="true"
                                        class="w-full fuse-alert-content-full-width"
                                        [appearance]="'outline'">
                                <div class="flex flex-col w-full gap-4 justify-between items-center">
                                    <span class="w-full text-start">{{ t('training_offer.clone_offer_info_1') }}
                                        {{(appInitService.cicloCorsoRuoloSelected$ | async)?.ciclo}} {{ t('training_offer.clone_offer_info_2') }}</span>
                                    <app-custom-button
                                        [type]="ButtonType.SAVE"
                                        icon="content_copy"
                                        [textButton]="'training_offer.clone_offer'"
                                        [disableDirective]="true"
                                        (clickHandler)="openCloneDialog()">
                                    </app-custom-button>
                                </div>
                            </fuse-alert>
                        </div>

                        <div class="w-full flex flex-row justify-end px-6 gap-4" *ngIf="!appInitService.isAreaAdministrator &&
                        ((ctrlSelectFormControl.value === AuthorityType.COORDINATORE || currentRuolo === AuthorityType.SUPERADMIN) || ctrlSelectFormControl.value === AuthorityType.GRUPPODICOORDINAMENTO)">
                            <button mat-flat-button color="primary"
                                    class="multi-line-button"
                                    (click)="openModaleDownloadPdf()">
                                <mat-icon class="mr-2" >download</mat-icon>
                                {{t('training_offer.export_pdf')}}
                            </button>
                            <button mat-flat-button
                                    class="multi-line-button"
                                    type="submit"
                                    (click)="openExportExcelDialog(ExportType.XLS)"
                                    [color]="'primary'">
                                <mat-icon class="mr-2">download</mat-icon>
                                <span>{{ t('training_offer.export_excel_dialog') }}</span>
                            </button>
                            <button mat-flat-button
                                    class="multi-line-button"
                                    type="submit"
                                    (click)="openExportExcelDialog(ExportType.CSV)"
                                    [color]="'primary'">
                                <mat-icon class="mr-2">download</mat-icon>
                                <span>{{ t('training_offer.export_csv_dialog') }}</span>
                            </button>
                        </div>

                        <!-- PUBBLICAZIONE OFFERTA -->
                        <div class="px-6 w-full">
                            <app-training-offer-configuration [offertaFormativa]="offertaFormativa"
                                                              (onOffertaFormativaUpdated)="updateOffertaFormativaByConfiguration($event)"
                                                              [currentSottoruolo]="ctrlSelectFormControl.value"
                                                              [currentRuolo]="currentRuolo">
                            </app-training-offer-configuration>
                        </div>

                        <!-- GUIDA COORD e GUIDA ATENEO -->
                        <div class="px-6 w-full" *ngIf="!!this.guidaCoordinatore">
                            <app-guide-compilazione [guidaAteneo]="guidaAteneo"
                                                    [guidaCoordinatore]="guidaCoordinatore"
                                                    [accordion]="true"
                                                    [showEditGuidaCoordinatoreButton]="(!appInitService.isAreaAdministrator && (ctrlSelectFormControl.value === AuthorityType.COORDINATORE || currentRuolo === AuthorityType.SUPERADMIN))"
                                                    (onEditGuidaCoordinatore)="openEditGuidaCoordinatoreDialog($event)">
                            </app-guide-compilazione>
                        </div>

                        <!-- SUPERAMENTO D'ANNO CONFIG -->
                        <div class="px-6 w-full"
                             *ngIf="cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.SuperamentoAnno)?.stato !== FunzionalitaStatus.NASCOSTA">
                            <app-year-passing-configuration [currentProfile]="ctrlSelectFormControl.value"
                                                            [currentRuolo]="currentRuolo"
                                                            [currentCorso]="ctrlCorso.value">
                            </app-year-passing-configuration>
                        </div>

<!--                        &lt;!&ndash; AMBITI ATTIVITA &ndash;&gt;
                        <div class="px-6 w-full">
                            <app-training-offer-ambiti [offertaFormativa]="offertaFormativa"
                                                       [currentRuolo]="currentRuolo"
                                                       [currentSottoruolo]="ctrlSelectFormControl?.value"
                                                       (onOffertaFormativaUpdated)="updateOffertaFormativaByAmbitiChanged($event)">
                            </app-training-offer-ambiti>
                        </div>-->


                        <div class="w-full px-6 pb-6">
                            <div class="w-full flex flex-col rounded-xl items-center gap-4 pt-6"
                                 [ngClass]="{'bg-slate-100 shadow-md': this.showArchived}">

                                <div class="w-full flex flex-row px-6 items-center justify-center remove-padding-form-field pb-2 gap-4" *ngIf="this.categoriesByYearConfigurations">
                                    <mat-form-field class="min-w-40">
                                        <mat-select  [(ngModel)]="offerYearSelected">
                                            <mat-select-trigger>
                                                <ng-container *ngIf="offerYearSelected === AnnoRiferimentoWithAllValues.ALL">
                                                    {{t('common.all_years')}}
                                                </ng-container>
                                                <ng-container *ngIf="offerYearSelected !== AnnoRiferimentoWithAllValues.ALL">
                                                    {{ annoRiferimentoToRomanNumeral(offerYearSelected) }} {{t('common.year')}}
                                                </ng-container>
                                            </mat-select-trigger>
                                            <mat-option [value]="AnnoRiferimentoWithAllValues.ALL">{{t('common.all_years')}}</mat-option>
                                            <mat-option *ngFor="let anno of anniRiferimento"
                                                        [value]="anno">
                                                {{ annoRiferimentoToRomanNumeral(anno) }} {{t('common.year')}}
                                                <mat-icon *ngIf="!showArchived && !this.categoriesByYearConfigurations[configurationIndexByYear(anno)].activitiesCompliant"
                                                          class="icon-size-5 text-amber-500 cursor-pointer mr-2"
                                                          [matTooltip]="t('training_offer.year_not_valid')"
                                                          (click)="openYearNotValidDialog(); $event.stopPropagation()">
                                                    warning_amber
                                                </mat-icon>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-slide-toggle [checked]="false"
                                                      color="primary"
                                                      (change)="onArchivedToggleChange($event)">
                                        <span [class]="this.showArchived ? 'font-semibold text-lg' : undefined"
                                              class="text-slate-600">
                                            {{ t('training_offer.archived_activities')}}
                                        </span>
                                    </mat-slide-toggle>
                                </div>

                                <div class="w-full px-6 "
                                     *ngIf="this.showArchived">
                                    <fuse-alert
                                        class="w-full"
                                        [showIcon]="true"
                                        [appearance]="'outline'"
                                        [type]="'basic'">
                                        {{ t('training_offer.archived_explained') }}
                                    </fuse-alert>
                                </div>

                                <div class="w-full px-6 "
                                     *ngIf="!this.showArchived && offerYearSelected === AnnoRiferimentoWithAllValues.ALL && isSomeYearNotCompliant">
                                    <fuse-alert
                                        class="w-full"
                                        [showIcon]="true"
                                        [appearance]="'outline'"
                                        [type]="'warning'">
                                        {{ t('training_offer.some_year_not_compliant', { activityUnitOfMeasure: t(this.cicloConfigurationService.activityUnitOfMeasureLabel) }) }}
                                    </fuse-alert>
                                </div>


                                <div class="w-full" *ngIf="this.categoriesByYearConfigurations[configurationIndexByYear(offerYearSelected)] as configurazione">

                                    <!-- FAR and FR HEADERS -->
                                    <div class="custom-breakpoint-1:grid grid-cols-4 min-h-8 w-full pl-6 pr-6 text-secondary mb-2 hidden gap-1 opacity-70">
                                        <div class="font-medium col-span-2 rounded-md bg-gray-200 h-full flex flex-row items-center justify-center px-1 gap-1">
                                            <div class="py-1 text-center">{{t('common.FR_label')}}</div>
                                        </div>
                                        <div class="font-medium col-span-2 rounded-md bg-gray-200 h-full flex flex-row items-center justify-center px-1 gap-1">
                                            <div class="py-1 text-center">{{t('common.FAR_label')}}</div>
                                        </div>
                                    </div>

                                    <!-- ACTIVITY TYPES TABS -->
                                    <mat-tab-group class="w-full activities-tab-group class-hide-tab4"
                                                   animationDuration="0ms"
                                                   #activityTypesTab
                                                   [(selectedIndex)]="activityTabSelectedIndex">

                                        <ng-container *ngFor="let activityTypeConfiguration of configurazione.categoriesByActivityType; trackBy: trackByIndex">

                                            <mat-tab [aria-label]="activityTypeConfiguration.fragmentLabel">
                                                <ng-template matTabLabel>
                                                    <div class="flex flex-row items-center">
                                                        <mat-icon *ngIf="!showArchived && !activityTypeConfiguration.activitiesCompliant"
                                                                  class="icon-size-5 text-amber-500 cursor-pointer mr-2"
                                                                  [matTooltip]="t('training_offer.type_not_valid')"
                                                                  (click)="openTypeNotValidDialog(); $event.stopPropagation()">
                                                            warning_amber
                                                        </mat-icon>
                                                        <span>{{t(activityTypeConfiguration.activityLabel) | titlecase}}</span>
                                                    </div>
                                                </ng-template>
                                                <ng-template matTabContent>
                                                    <div class="w-full flex flex-col gap-6 items-end">
                                                        <fuse-alert
                                                            *ngIf="!showArchived && !activityTypeConfiguration.compliantVincoloOfferta"
                                                            [showIcon]="true"
                                                            [appearance]="'outline'"
                                                            class="w-full"
                                                            [type]="'warning'">
                                                            {{ t('training_offer.vincolo_cfu_offerta_not_compliant_1', { activityUnitOfMeasure: t(this.cicloConfigurationService.activityUnitOfMeasureLabel) }) }}: <span class="font-semibold">{{activityTypeConfiguration.totaleCfu}}</span>
                                                            {{ t('training_offer.vincolo_cfu_offerta_not_compliant_2', { activityUnitOfMeasure: t(this.cicloConfigurationService.activityUnitOfMeasureLabel) }) }}: <span class="font-semibold">{{activityTypeConfiguration.vincoloCfu.min}}</span>
                                                        </fuse-alert>
                                                        <button *ngIf="showEditCategoriesAndActivities && !showArchived"
                                                                mat-flat-button
                                                                class="multi-line-button"
                                                                (click)="openAddEditCategoryDialog(undefined, configurazione.year, undefined, activityTypeConfiguration.activityType)"
                                                                color="primary">
                                                            <mat-icon class="mr-2">add</mat-icon>
                                                            <span>{{t('training_offer.add_category')}}</span>
                                                        </button>
                                                        <app-activities-categories
                                                            class="w-full"
                                                            [categoriesConfiguration]="activityTypeConfiguration.categoriesConfiguration"
                                                            (tableClickAction)="tableClickAction($event)"
                                                            [showEditCategoriesAndActivities]="showEditCategoriesAndActivities"
                                                            [showArchived]="showArchived"
                                                            (onEditCategory)="openAddEditCategoryDialog($event, configurazione.year)"
                                                            (onEditConfiguration)="openAddEditCategoryDialog($event, configurazione.year, true)"
                                                            (onAddActivity)="manageActivity($event, activityTypeConfiguration.activityType, undefined)"
                                                            (onRemoveCategory)="openRemoveCategoryDialog($event)"
                                                            [isShowingAllYears]="offerYearSelected === AnnoRiferimentoWithAllValues.ALL"
                                                            [isInTrainingOffer]="true">
                                                        </app-activities-categories>
                                                    </div>
                                                </ng-template>
                                            </mat-tab>

                                        </ng-container>

                                        <mat-tab [label]="' '"
                                                 [disabled]="true">
                                        </mat-tab>

                                    </mat-tab-group>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-template>


        </ng-template>
    </div>


</ng-container>
