import {Component, Inject, Injector, OnDestroy, OnInit, Type} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    DeliberaInfoView,
    MediaService,
    MissioneStatus, MissioneStudentiCicloService, MissioniService,
    NuovaConfigurazioneOperazione,
    PeriodoDiMobilitaStudenteStatus,
    SpeseStudentiCicloService,
    UserCodiceFiscaleNomeCognomeView
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {
    BehaviorSubject,
    catchError,
    finalize,
    map,
    Observable,
    ObservedValueOf,
    of,
    OperatorFunction, Subject,
    take,
    takeUntil
} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {Translation, TranslocoService} from "@ngneat/transloco";
import {get} from "lodash";
import {PageEvent} from "@angular/material/paginator";
import {FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {
    CAN_GO_AHEAD$,
    CHIAVE_FLUSSO$,
    CHIAVE_OPERAZIONE$,
    CURRENT_PROFILE,
    DOWNLOAD_REQUEST_DOCUMENT$,
    GET_APPLIED_FILTERS_LABELS$,
    GET_DATA_SERVICE$, GET_FILTER_SERVICE_TYPE$,
    GET_REFUSE_REQUEST$,
    GET_REQUEST_ATTACHMENTS_FIELD_NAME$,
    GET_REQUEST_IDENTITY_FIELD_NAME$,
    GET_REQUEST_PAGE_URL$, GET_REQUEST_TITLE$,
    GET_TABLE_CONFIGURATION$,
    OPERAZIONE_MASSIVA_DATA$
} from "../../operazioni-massive.component";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoManagerService} from "../../../../../shared/service/dialog-info-manager.service";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";
import {GenericOperationMassivaDataI} from "../generic-operation-steps-interface";
import {Router} from "@angular/router";
import {AttachmentsInfoDialogService} from "../../../../../shared/service/attachments-info-dialog.service";
import {HttpResponse} from "@angular/common/http";
import {openFileInBlankWindow} from "../../../../../shared/utils/utils";
import {StudentDetailsService} from "../../../student-details/student-details.service";
import {
    BudgetFilterService
} from "../../../requests/approvazione-budget/budget-sidebar-filter-container-request/budget-filter.service";
import {CompilaInfoRichiesteComponent} from "../compila-info-richieste/compila-info-richieste.component";


@Component({
    selector: 'app-scegli-richieste-stepper',
    templateUrl: './scegli-richieste.component.html',
    styleUrls: ['./scegli-richieste.component.scss']
})
export class ScegliRichiesteComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    loading: boolean;
    tableConfiguration: GenericTableConfigurationModel;
    fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    showTopbar: boolean;
    idRigheSolaLettura: string[] = [];
    public operationConfig: NuovaConfigurazioneOperazione;

    filterManagerService: any; // todo understand type
    pageData: any;
    size: number;
    page: number;

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private logOutService: LogoutService,
                private appInitService: AppInitService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private cicloConfigurationService: CicloConfigurationService,
                private dialog: MatDialog,
                private dialogInfoManagerService: DialogInfoManagerService,
                private localStorageService: LocalStorageService,
                private router: Router,
                private attachmentsInfoDialogService: AttachmentsInfoDialogService,
                private studentDetailsService: StudentDetailsService,
                private injector: Injector,
                // services for get data
                public speseStudentiCicloService: SpeseStudentiCicloService, //update point: AGGIUNTA NUOVO FLUSSO
                public missioneStudentiCicloService: MissioneStudentiCicloService,
                public missioniService: MissioniService,
                // injected tokens
                @Inject(CHIAVE_FLUSSO$) protected chiaveFlusso: ChiaveFlussoEnum,
                @Inject(CHIAVE_OPERAZIONE$) protected chiaveOperazione: ChiaveOperazioneEnum,
                @Inject(GET_DATA_SERVICE$) protected getDataService$: (filters: any, size: number, page: number, opMassivaComp: ScegliRichiesteComponent | CompilaInfoRichiesteComponent, requestsId?: string[]) => Observable<any>,
                @Inject(GET_TABLE_CONFIGURATION$) protected getTableConfiguration: (pageData: any, cicloConfigurationService: CicloConfigurationService, size?: number, page?: number, idRigheDaDisabilitare?: string[]) => GenericTableConfigurationModel,
                @Inject(GET_REQUEST_PAGE_URL$) protected getRequestPageUrl: (request: any, router: Router) => string,
                @Inject(DOWNLOAD_REQUEST_DOCUMENT$) protected downloadRequestDocument: (filename: string, idAllegato: string, request: any, opMassivaComp: any) => Observable<any>,
                @Inject(GET_REQUEST_IDENTITY_FIELD_NAME$) protected getRequestIdentityFieldName: string,
                @Inject(GET_REFUSE_REQUEST$) protected getRefuseRequest$: (request: any, opMassivaComp: ScegliRichiesteComponent, cicloConfigurationService: CicloConfigurationService, form: FormGroup) => Observable<any>,
                @Inject(GET_APPLIED_FILTERS_LABELS$) protected getAppliedFiltersLabels: (filterManagerService: any, fieldsLabelMap: Map<string, string>, translation: Translation) => Array<string>,
                @Inject(GET_REQUEST_ATTACHMENTS_FIELD_NAME$) protected getRequestAttachmentsFieldName: string,
                @Inject(GET_REQUEST_TITLE$) protected getRequestTitle: (request?: any) => string,
                @Inject(GET_FILTER_SERVICE_TYPE$) protected getFilterServiceType: Type<any>,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<GenericOperationMassivaDataI>,) {
        super();
        this.filterManagerService = this.injector.get(this.getFilterServiceType);
    }

    ngOnInit(): void {
        this.operationConfig = this.cicloConfigurationService.getOperationCorsoConfig(
            this.chiaveFlusso,
            this.chiaveOperazione,
            undefined,
            true,
        ) as NuovaConfigurazioneOperazione;
        this.isFirstTime = true;
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.getDataAndFilterSubscribe();
        this.operazioneMassivaData$?.subscribe(inputOutputData => {
            // when confirm step forces refresh get the data and subscribe to filters
            if (inputOutputData.refreshRequests) {
                console.log('Refreshing requests')
                this.tableConfiguration = undefined;
                this.idRigheSolaLettura = [];
                this.resetRefreshRequests();
                this.canGoNext$.next(false);
                this.getDataRequest$(false, this.getMainFormGroup()?.getRawValue());
            }
            if(inputOutputData.refreshPage){
                this.resetRefreshPage();
                // refresh the selected page
                this.getDataRequest$(false, this.getMainFormGroup()?.value);
            }
        });
    }


    private resetRefreshPage() {
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshPage: false
        })
    }

    getDataRequest$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = this.localStorageService.pageSize): void {
        this.getDataObs$(isFirstTime, filters, page, size).subscribe({
            next: (value) => {
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => this.logOutService.goToHome(),
                    () => this.getDataRequest$(isFirstTime, filters, page, size),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getDataObs$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = this.localStorageService.pageSize): Observable<any> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.getDataService$(filters, size, page, this).pipe(
            tap((pageData) => {
                this.pageData = pageData;
                this.size = size;
                this.page = page;
                this.tableConfiguration = this.getTableConfiguration(pageData, this.cicloConfigurationService, size, page, this.idRigheSolaLettura);
                this.currentFilterListChipLabels = this.getListChipsLabel();
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }

    tableClickAction($event: ClickEvent) {
        const request = $event?.value;
        switch ($event?.tipoClick) {
            case TipoClickEnum.REFUSE_REQUEST:
                this.tryOpenRefuseDialog($event);
                break;
            case TipoClickEnum.CHIP_DETAIL:
                this.openInfoStudent($event.value);
                break;
            case TipoClickEnum.GO_TO_REQUEST_PAGE:
                const url = this.getRequestPageUrl(request, this.router);
                window.open(url, '_blank');
                break;
            case TipoClickEnum.SHOW_REQUEST_ATTACHMENTS:
                this.attachmentsInfoDialogService.openRequestAttachmentsDialog(
                    $event.value,
                    this.getRequestAttachmentsFieldName,
                    this.chiaveFlusso,
                    this.getRequestTitle($event.value),
                    (idAllegato, nomeFile, delibera) =>
                        this.getRequestDocument(nomeFile, idAllegato, delibera, $event.value));
                break;
            // todo need other???
        }
    }


    private openInfoStudent(studente: UserCodiceFiscaleNomeCognomeView) {
        if (!!studente.urlImmagineProfilo && !!studente.codiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente.codiceFiscale, studente.urlImmagineProfilo)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.dialogInfoManagerService.openStudenteInfoDialogUCFNCAV(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.dialogInfoManagerService.openStudenteInfoDialogUCFNCAV(studente);
        }
    }

    private tryOpenRefuseDialog(event: ClickEvent) {
        if (this.operazioneMassivaData$.getValue().requestsSelected?.find(
            s => s?.[this.getRequestIdentityFieldName] === event.value?.[this.getRequestIdentityFieldName])) {
            this.openDeselectFirstDialog();
        } else {
            this.openRefuseDialog(event.value)
        }
    }

    private openDeselectFirstDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'dialog.attention', null),
            message: get(translation, 'common.deselect_first', null),
            icon: {
                name: 'mat_solid:error_outline',
                color: 'error'
            },
            onBackdrop: {
                show: true,
                backdrop: false
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
            ]
        });
    }

    private openRefuseDialog(request: any) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'mobility.refuse_request',
            message: get(translation, 'common.refuse_request_message', null) + ' ' + this.getRequestTitle(request),
            icon: {
                show: true,
                name: 'mat_outline:close',
                color: 'error'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.refuseRequest(form, request, form.getRawValue().motivo, dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'motivo',
                    transloco: 'budget.refuse_reason',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
            ],
            valueForm: {
                motivo: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private refuseRequest(form: FormGroup, requestData: any, motivazioneRifiuto: string, dialogRef: MatDialogRef<GenericDialogComponent>) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let request= this.getRefuseRequest$(requestData, this, this.cicloConfigurationService, form);
        this.fuseConfirmationService.showLoader();
        form.disable();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => request),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
                form.enable();
            })
        ).subscribe({
            next: (requests: any[]) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'mobility.op_saved', null),
                    type: SnackbarTypes.Success,
                });
                this.idRigheSolaLettura = [...(this.idRigheSolaLettura ?? []), requestData?.[this.getRequestIdentityFieldName]];
                this.tableConfiguration = this.getTableConfiguration(this.pageData, this.cicloConfigurationService, this.size, this.page, this.idRigheSolaLettura);
                dialogRef.close();
            },
            error: (err) => {
                console.log(err)
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'mobility.error_during_op', null),
                    error: err, type: SnackbarTypes.Error,
                });
            }
        });
    }


    onRequestsSelectedTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            requestsSelected: $event.selectedRows.length > 0 ?
                $event.selectedRows.map(row => row.data) as any[] : undefined,
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.localStorageService.pageSize = $event.pageSize;
        this.getDataRequest$(false, this.getMainFormGroup()?.value, page, this.localStorageService.pageSize);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    catchErrorInRequestsList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        return this.getAppliedFiltersLabels(this.filterManagerService, this.fieldsLabelMap, translation);
    }


    private resetRefreshRequests() {
        console.log(this.operazioneMassivaData$.getValue())
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshRequests: false
        })
    }

    private getDataAndFilterSubscribe() {
        if (this.isFirstTime) {
            this.getDataRequest$(this.isFirstTime, this.getMainFormGroup()?.getRawValue());
        }
        this.filterManagerService.checkApplyFilterClick$.pipe(
            filter(Boolean),
            switchMap(value => {
                if (!this.isFirstTime) {
                    return this.getDataObs$(this.isFirstTime, this.getMainFormGroup()?.getRawValue(), 0, this.localStorageService.pageSize).pipe(
                        this.catchErrorInRequestsList$(this.getDataObs$(this.isFirstTime, this.filterManagerService.filterApplied, 0, this.localStorageService.pageSize))
                    );
                }
                return of(null);
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.filterManagerService.filterApplied$.next(this.getMainFormGroup()?.getRawValue());
                    this.filterManagerService.filterApplied = this.getMainFormGroup()?.getRawValue();
                }
            }
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    openFilters() {
        this.filterManagerService.openDrawer.next(true)
    }

    resetFilters(): void {
        this.getMainFormGroup()?.reset();
        this.filterManagerService.filterApplied = undefined;
        this.filterManagerService?.checkApplyFilterClick$.next(true);
    }

    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;
    protected readonly MissioneStatus = MissioneStatus;
    protected readonly AuthorityType = AuthorityType;
    protected readonly ChiaveFlussoEnum = ChiaveFlussoEnum;

    private getRequestDocument(nomeFile?: string, idAllegato?: string, deliberaData?: DeliberaInfoView, request?: any) {
        if (deliberaData) {
            this.fuseConfirmationService.openDialogDelibera({
                delibera: deliberaData,
                codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
            });
        } else {
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.showLoader();
            this.appInitService.selectedInService.pipe(
                filter(Boolean),
                take(1),
                switchMap(() => this.downloadRequestDocument(nomeFile, idAllegato, request, this)),
                takeUntil(this.destroy$),
                finalize(() => {
                    this.fuseConfirmationService.hideLoader();
                })
            ).subscribe({
                next: (fileResponse: HttpResponse<Blob>) => {
                    const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                    //const fileNameToDownload = makeFilename(fileName);
                    openFileInBlankWindow(fileResponse?.body, fileName);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'student.file_download_error', null),
                        type: SnackbarTypes.Warning,
                    });
                }
            });
        }
    }
}
