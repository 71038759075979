import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Pipe, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {get, isNil, orderBy} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {optionFile} from "../../costants/app-constants";
import {
    AddDeliberaDTO, AnnoRiferimentoValues,
    AuthorityType, ChiaveDocumentoEnum, ChiaveFlussoEnum, ChiaveOperazioneEnum,
    DeliberaInfoView, DeliberaInfoViewImpl,
    DelibereService, DocumentoInfoView, ModalitaDiFirmaType, PageDeliberaInfoViewImpl
} from "../../../../api-clients/generated/services";
import * as moment from "moment";
import {
    formGroupConfigInterface,
    GenericComponentDialogConfig
} from "../../../layout/common/generic-components/generic-components.interface";
import {buildFormConfigForAddNewDelibera} from "../../../modules/landing/gestione-documenti/gestione-documenti-utils";
import {
    GenericDialogComponent
} from "../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {finalize, switchMap, take, takeUntil} from "rxjs";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {AppInitService} from "../../service/app-init.service";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";
import {ButtonType} from "../custom-button/custom-button.component";
import {DialogSignDocumentComponent, DialogSignDocumentI} from "../dialog-sign-document/dialog-sign-document.component";
import {DocumentNameFromUrlPipe} from "./pipes/document-name-from-url.pipe";
import {CicloConfigurationService, isDocumentSigned} from "../../service/ciclo-configuration.service";
import {upperCase} from "lodash-es";
import {getSignedWithFromKey} from "../dialog-attachments-info/dialog-attachments-info.component";


export interface SignableDocumentsData{
    documentoInfo: DocumentoInfoView;
    utenteId: string;
    signRequired?: boolean;
}

export function getFormattedDocumentName(document: File | string) {
    return typeof document === 'string' ? document : document?.name;
}

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent extends AbstractDefaultComponent implements OnInit {
    @Input() label: string;
    @Input() labelNoTranslate: string;
    @Input() hint: string;
    @Input() form: FormArray;
    @Input() verificaId: boolean;
    @Input() idCampo: string;
    @Input() hideDeleteButton: boolean;
    @Input() customFileLabel: boolean;
    @Input() numeroMinimoDocumenti?: number;
    @Input() numeroMassimoDocumenti?: number;
    @Input() modificabileSeGiaPresente?: boolean;
    @Input() isDelibera?: boolean;
    @Input() signModes?: ModalitaDiFirmaType[];

    @Output() downloadEmitter = new EventEmitter<string>();
    @Output() saveBeforeUpload = new EventEmitter<void>();
    @Output() documentSignedSuccessfully = new EventEmitter<void>();
    @ViewChild('inputFile') inputRef: ElementRef;
    @Input() sizeByte = optionFile.sizeMb * 1000000;
    // files showed in the window
    @Input() listFileAccepted = ['pdf', 'gz', 'rar', 'tar', 'gz', 'tgz', 'zip', '7z']
    // files excluded
    @Input() listFileExcluded = ['exe', 'bat', 'sh', 'com', 'wsh', 'run', 'osx']
    acceptedFileList: string;
    @Input() customNotSupportedFileMessageLabel: string;
    @Input() forceAcceptedFiles: boolean;
    @Input() pipe?: Pipe;
    @Input() chiaveFlusso?: ChiaveFlussoEnum;
    @Input() chiaveOperazione?: ChiaveOperazioneEnum;
    @Input() chiaveDocumento?: ChiaveDocumentoEnum;
    @Input() annoRiferimento?: AnnoRiferimentoValues;
    @Input() useNewConfig?: boolean;
    @Input() signableDocumentsData?: SignableDocumentsData;
    @Input() formTouched?: boolean;
    @Input() initialValue?: (string | File)[];
    @Input() formConfig: formGroupConfigInterface;
    @Input() formInstance: FormGroup;

    protected delibere?: DeliberaInfoViewImpl[];

    outputFormatData = 'DD/MM/YYYY';


    constructor(private _translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private dialog: MatDialog,
                private delibereService: DelibereService,
                private appInitService: AppInitService,
                protected cicloConfigurationService: CicloConfigurationService,) {
        super();
    }

    ngOnInit(): void {
        console.log('FORM INPUT FILE', this.form);
        this.acceptedFileList = this.listFileAccepted.map(extension => '.' + extension).join(', ');
        if(this.isDelibera){
            this.getDelibereRequest();
        }
    }


    onFileSelected(event: any, index?: number) {
        if (event?.target?.files?.length === 0) {
            return;
        }
        const fileInput = event?.target?.files[0];
        const fileInputType = fileInput.name.substring(fileInput.name.lastIndexOf('.'))?.toLowerCase();

        const isFileTypeNotExcluded = !this.forceAcceptedFiles && !this.listFileExcluded.some(file => fileInputType.includes(file.toLowerCase()));
        const isFileTypeIncluded = this.forceAcceptedFiles && this.listFileAccepted.some(file => fileInputType.includes(file.toLowerCase()));

        if (isFileTypeNotExcluded || isFileTypeIncluded) {
            if (fileInput.size > this.sizeByte) {
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                this.fuseConfirmationService.open({
                    title: get(translation, 'dialog.attention', null),
                    message: get(translation, 'upload.size_exceeded_file_number', null) + ' ' + this.sizeByte / 1000000  + ' Mb',
                    icon: {
                        name: 'mat_solid:error_outline',
                        color: 'error'
                    },
                    onBackdrop: {
                        show: true,
                        backdrop: false
                    }
                });
            } else {
                if(isNil(index)){
                    this.form.push(new FormControl(fileInput));
                } else {
                    this.form.at(index).patchValue(fileInput);
                }
            }
        } else {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, this.customNotSupportedFileMessageLabel || 'upload.not_supported_file_document', null) + ' ' + this.acceptedFileList?.toLowerCase(),
                icon: {
                    name: 'mat_solid:error_outline',
                    color: 'error'
                },
                onBackdrop: {
                    show: true,
                    backdrop: false
                }
            });
        }

        this.inputRef.nativeElement.value = '';
    }

    download(index) {
        this.downloadEmitter.emit(this.form.at(index)?.value)
    }

    open(element: HTMLInputElement, docIndex?: number) {
        if(!this.alternativeDocumentAlreadyPresent() && !this.alreadyPresentAndNotModifiableForNewConfig(docIndex)) {
            if (this.verificaId) {
                if (this.idCampo) {
                    element.click()
                } else {
                    this.saveBeforeUpload.emit();
                }
            } else {
                element.click()
            }
        }

    }

    delete(index: number) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'common.delete', null),
                message: get(translation, 'common.delete_message', null),
                icon: {
                    name: 'mat_outline:delete',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.delete', null),
                        function: () => this.form.removeAt(index)
                    }]
            }
        );
    }

    protected readonly Object = Object;

    trackByFn(index: number, item: any): any
    {
        return index;
    }

    addFile(element: HTMLInputElement) {
        if(!this.form?.disabled) {
            element.click();
        }
    }


    isFile(document: File | string) {
        return typeof document !== 'string';
    }

    addDocument() {
        this.form.push(new FormControl());
    }

    deleteDocument(index: number) {
        this.form.removeAt(index);
    }

    clear(index: number){
        this.form.at(index).patchValue(undefined);
    }

    protected readonly AuthorityType = AuthorityType;

    openAddNewDeliberaDialog(ctrl: FormControl) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'common.add_delibera',
            //message: get(translation, 'mobility.approve_message', null),
            icon: {
                show: true,
                name: 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    icon: 'check',
                    function: (form, dialogRef) => this.addNewDeliberaRequest(form, form.getRawValue(), dialogRef, ctrl)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: buildFormConfigForAddNewDelibera(),
            valueForm: {
                fileDelibera: undefined,
                dataDelibera: undefined,
                numeroDelibera: undefined,
                fileNotaTrasmissione: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private addNewDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>, ctrl: FormControl) {
        console.log(rawValue)
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera, rawValue.fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_added', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                this.delibere.push(addedDelibera);
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
                ctrl?.patchValue(addedDelibera.id);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                    () => {},() => this.addNewDeliberaRequest(form, rawValue, dialogRef, ctrl), 'dialog.cancel',
                    err?.error?.message);
            }
        });

    }

    formatDeliberaDate(dateFromBE: string): string {
        return moment(dateFromBE).format(this.outputFormatData);
    }

    private getDelibereRequest() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.searchDelibereForm()),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (delibere: PageDeliberaInfoViewImpl) => {
                this.delibere = delibere?.content || [];
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                    () => {},() => this.getDelibereRequest(), 'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    protected readonly ButtonType = ButtonType;
    protected readonly undefined = undefined;

    openSignDocumentDialog(index: number, ctrl: FormControl, signModes: ModalitaDiFirmaType[]) {
        const data: DialogSignDocumentI = {
            title: 'document_sign.sign_document',
            icon: {
                name: 'mat_solid:draw',
                color: 'info'
            },
            availableSignMethods: signModes,
            chiaveDocumento: this.chiaveDocumento,
            chiaveFlusso: this.chiaveFlusso,
            chiaveOperazione: this.chiaveOperazione,
            documentsSignedSuccessfullyCallback: () => this.documentSignedSuccessfully.emit(),
            documents: [{
                labelNoTranslate: this.labelNoTranslate,
                label: this.label,
                ctrl: ctrl,
                download: () => this.download(index),
                documentoInfo: this.signableDocumentsData?.documentoInfo,
                userId: this.signableDocumentsData?.utenteId,
                formattedDocName: new DocumentNameFromUrlPipe().transform(ctrl.value) as string
            }]

        };
        this.dialog.open(DialogSignDocumentComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
        });
    }


    protected readonly getFormattedDocumentName = getFormattedDocumentName;
    protected readonly isDocumentSigned = isDocumentSigned;
    protected readonly upperCase = upperCase;
    protected readonly getSignedWithFromKey = getSignedWithFromKey;

    alreadyPresentAndNotModifiableForNewConfig(index: number) {
        if(this.useNewConfig) {
            return typeof this.initialValue?.[index] === 'string' && !this.modificabileSeGiaPresente;
        } else {
            return false; // for old config can modify
        }
    }

    alternativeDocumentAlreadyPresent(): ChiaveDocumentoEnum {
        return this.formConfig.chiaviDocumentiAlternativi?.find(alternativeDocKey =>
            !!this.formInstance?.value?.[alternativeDocKey]
                ?.find(alternativeDoc => !!alternativeDoc)
        )
    }

}
