import {ScegliCorsoComponent} from "./superamento-corso-steps/scegli-corso/scegli-corso.component";
import {Injector, Type} from "@angular/core";
import {BehaviorSubject, Observable, of} from "rxjs";
import {get, isEmpty} from "lodash";
import {SuperamentoCorsoMassivoDataI} from "./superamento-corso-steps/superamento-corso-steps-interface";
import {ScegliStudentiComponent} from "./superamento-corso-steps/scegli-studenti/scegli-studenti.component";
import {
    CompilaInfoSuperamentoComponent
} from "./superamento-corso-steps/compila-info-superamento/compila-info-superamento.component";
import {
    ConfirmSuperamentoCorsoComponent
} from "./superamento-corso-steps/confirm-superamento-corso/confirm-superamento-corso.component";
import {
    ApprovazioneMassivaMissioniStudenteRequest,
    ApprovazioneMassivaSpeseStudentiByGruppoPTARequest,
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveFunzionalitaEnum,
    ChiaveOperazioneEnum,
    DeliberaApprovaSpeseStudentiRequest,
    EsitoRichiestaApprovazione, EsitoSingolaApprovazioneMissioneStudente,
    EsitoSingolaApprovazioneSpesa,
    FunzionalitaStatus, MissioneAndStudenteInfoView, MissioneStatus, PageMissioneAndStudenteInfoView,
    PageSpesaStudentePerApprovMassivaInfoView,
    SpesaStudentePerApprovMassivaInfoView,
    SpesaStudenteStatus, SpeseStudentiCicloService,
    TipoPeriodoEnum
} from "../../../../api-clients/generated/services";
import {ApprovazioneSpeseMassivaDataI} from "./approvazione-spesa-steps/approvazione-spese-steps-interface";
import {ScegliSpeseComponent} from "./approvazione-spesa-steps/scegli-spese/scegli-spese.component";
import {
    ConfirmApprovazioneSpeseComponent
} from "./approvazione-spesa-steps/confirm-approvazione-spese/confirm-approvazione-spese.component";
import {
    CompilaInfoApprovazioneComponent
} from "./approvazione-spesa-steps/compila-info-approvazione/compila-info-approvazione.component";
import {
    ApprovazioneTrasversaliMassivaDataI
} from "./approvazione-trasversale-steps/approvazione-trasversale-steps-interface";
import {
    ScegliTrasversaliComponent
} from "./approvazione-trasversale-steps/scegli-trasversali/scegli-trasversali.component";
import {
    CompilaInfoApprovazioneTrasversaliComponent
} from "./approvazione-trasversale-steps/compila-info-approvazione-trasversali/compila-info-approvazione-trasversali.component";
import {
    ConfirmApprovazioneTrasversaliComponent
} from "./approvazione-trasversale-steps/confirm-approvazione-trasversali/confirm-approvazione-trasversali.component";
import {ApprovazionePianiMassivaDataI} from "./approvazione-piani-steps/approvazione-piani-steps-interface";
import {
    ScegliStudentiPianiComponent
} from "./approvazione-piani-steps/scegli-studenti-piani/scegli-studenti-piani.component";
import {
    CompilaInfoApprovazionePianiComponent
} from "./approvazione-piani-steps/compila-info-approvazione-piani/compila-info-approvazione-piani.component";
import {
    ConfirmApprovazionePianiComponent
} from "./approvazione-piani-steps/confirm-approvazione-piani/confirm-approvazione-piani.component";
import {
    ScegliPeriodiRicercaComponent
} from "./approvazione-ricerca-steps/scegli-periodi-ricerca/scegli-periodi-ricerca.component";
import {ApprovazioneRicercaMassivaDataI} from "./approvazione-ricerca-steps/approvazione-ricerca-steps-interface";
import {
    CompilaInfoApprovazioneRicercaComponent
} from "./approvazione-ricerca-steps/compila-info-approvazione-ricerca/compila-info-approvazione-ricerca.component";
import {
    ConfirmApprovazioneRicercaComponent
} from "./approvazione-ricerca-steps/confirm-approvazione-ricerca/confirm-approvazione-ricerca.component";
import {
    ScegliPeriodiMobilitaComponent
} from "./approvazione-mobilita-steps/scegli-periodi-mobilita/scegli-periodi-mobilita.component";
import {
    CompilaInfoApprovazioneMobilitaComponent
} from "./approvazione-mobilita-steps/compila-info-approvazione-mobilita/compila-info-approvazione-mobilita.component";
import {ApprovazioneMobilitaMassivaDataI} from "./approvazione-mobilita-steps/approvazione-mobilita-steps-interface";
import {
    ConfirmApprovazioneMobilitaComponent
} from "./approvazione-mobilita-steps/confirm-approvazione-mobilita/confirm-approvazione-mobilita.component";
import {
    CAN_GO_AHEAD$,
    CHIAVE_FLUSSO$,
    CHIAVE_OPERAZIONE$,
    CURRENT_PROFILE,
    DOWNLOAD_REQUEST_DOCUMENT$,
    EXECUTE_OPERATION_SERVICE$,
    GET_APPLIED_FILTERS_LABELS$,
    GET_DATA_SERVICE$,
    GET_ESITO_TITLE$,
    GET_FILTER_SERVICE_TYPE$,
    GET_REFUSE_REQUEST$,
    GET_REQUEST_ATTACHMENTS_FIELD_NAME$,
    GET_REQUEST_FORMGROUP$,
    GET_REQUEST_IDENTITY_FIELD_NAME$,
    GET_REQUEST_PAGE_URL$,
    GET_REQUEST_TITLE$,
    GET_TABLE_CONFIGURATION$,
    OP_TYPE_CHOICE_ENABLED,
    OPERAZIONE_MASSIVA_DATA$,
    PERIOD_TYPE$,
    REQUEST_FORM_COMPONENT$
} from "./operazioni-massive.component";
import {ShowWithDeliberaStepComponent} from "./common-steps/show-with-delibera-step/show-with-delibera-step.component";
import {
    AutorizzazioneMobilitaMassivaDataI
} from "./autorizzazione-mobilita-steps/autorizzazione-mobilita-steps-interface";
import {
    ConfirmAutorizzazioneMobilitaComponent
} from "./autorizzazione-mobilita-steps/confirm-autorizzazione-mobilita/confirm-autorizzazione-mobilita.component";
import {
    ScegliStudentiAutorizzazioneMobilitaComponent
} from "./autorizzazione-mobilita-steps/scegli-studenti-autorizzazione-mobilita/scegli-studenti-autorizzazione-mobilita.component";
import {
    ApprovazioneRegistroAttivitaMassivaDataI
} from "./approvazione-registro-attivita-steps/approvazione-registro-attivita-steps-interface";
import {
    ScegliRegistriAttivitaComponent
} from "./approvazione-registro-attivita-steps/scegli-registri-attivita/scegli-registri-attivita.component";
import {
    CompilaInfoApprovazioneRegistroComponent
} from "./approvazione-registro-attivita-steps/compila-info-approvazione/compila-info-approvazione-registro.component";
import {
    ConfirmApprovazioneRegistroAttivitaComponent
} from "./approvazione-registro-attivita-steps/confirm-approvazione-registro-attivita/confirm-approvazione-registro-attivita.component";
import {
    PlanOpType,
    ShowWithDeliberaForPlanStepComponent
} from "./common-steps/show-with-delibera-for-plan-step/show-with-delibera-for-plan-step.component";
import {ApprovazioneMissioniMassivaDataI} from "./approvazione-missioni-steps/approvazione-missioni-steps-interface";
import {ScegliMissioniComponent} from "./approvazione-missioni-steps/scegli-missioni/scegli-missioni.component";
import {
    ConfirmApprovazioneMissioniComponent
} from "./approvazione-missioni-steps/confirm-approvazione-missioni/confirm-approvazione-missioni.component";
import {
    ApprovazioneValidazioneMobilitaMassivaDataI
} from "./approvazione-validazione-mobilita-steps/approvazione-validazione-mobilita-steps-interface";
import {
    ScegliRichiesteValidazioneComponent
} from "./approvazione-validazione-mobilita-steps/scegli-richieste-validazione-mobilita/scegli-richieste-validazione.component";
import {
    CompilaInfoApprovazioneRichiesteValidazioneComponent
} from "./approvazione-validazione-mobilita-steps/compila-info-approvazione-richieste-validazione/compila-info-approvazione-richieste-validazione.component";
import {
    ConfirmApprovazioneRichiesteValidazioneComponent
} from "./approvazione-validazione-mobilita-steps/confirm-approvazione-richieste-validazione/confirm-approvazione-richieste-validazione.component";
import {
    ApprovazioneConclusioneMobilitaMassivaDataI
} from "./approvazione-conclusione-mobilita-steps/approvazione-conclusione-mobilita-steps-interface";
import {
    ScegliConclusioneMobilitaComponent
} from "./approvazione-conclusione-mobilita-steps/scegli-conclusione-mobilita/scegli-conclusione-mobilita.component";
import {
    ConfirmApprovazioneConclusioneMobilitaComponent
} from "./approvazione-conclusione-mobilita-steps/confirm-approvazione-conclusione-mobilita/confirm-approvazione-conclusione-mobilita.component";
import {
    ApprovazioneModificheBudgetMassivaDataI
} from "./approvazione-modifiche-budget-steps/approvazione-modifiche-budget-steps-interface";
import {
    ScegliModificheBudgetComponent
} from "./approvazione-modifiche-budget-steps/scegli-modifiche-budget/scegli-modifiche-budget.component";
import {
    CompilaInfoApprovazioneModificheBudgetComponent
} from "./approvazione-modifiche-budget-steps/compila-info-approvazione-modifiche-budget/compila-info-approvazione-modifiche-budget.component";
import {
    ConfirmApprovazioneModificheBudgetComponent
} from "./approvazione-modifiche-budget-steps/confirm-approvazione-modifiche-budget/confirm-approvazione-modifiche-budget.component";
import {ApprovazioneSpesaPTAMassivaDataI} from "./approvazione-spesa-pta-steps/approvazione-spesa-pta-steps-interface";
import {ScegliSpesaPtaComponent} from "./approvazione-spesa-pta-steps/scegli-spesa-pta/scegli-spesa-pta.component";
import {
    CompilaInfoApprovazioneSpesaPtaComponent
} from "./approvazione-spesa-pta-steps/compila-info-approvazione-spesa-pta/compila-info-approvazione-spesa-pta.component";
import {
    ConfirmApprovazioneSpesaPtaComponent
} from "./approvazione-spesa-pta-steps/confirm-approvazione-spesa-pta/confirm-approvazione-spesa-pta.component";
import {ApprovazioneExtraMassivaDataI} from "./approvazione-extra-steps/approvazione-extra-steps-interface";
import {ScegliExtraComponent} from "./approvazione-extra-steps/scegli-extra/scegli-extra.component";
import {CompilaInfoExtraComponent} from "./approvazione-extra-steps/compila-info-extra/compila-info-extra.component";
import {
    ConfirmSuperamentoExtraComponent
} from "./approvazione-extra-steps/confirm-superamento-extra/confirm-superamento-extra.component";
import {
    CicloConfigurationService,
    getMatchingRefuseOperation
} from "../../../shared/service/ciclo-configuration.service";
import {
    CompilaInfoApprovazioneConclusioneMobilitaComponent
} from "./approvazione-conclusione-mobilita-steps/compila-info-approvazione-conclusione-mobilita/compila-info-approvazione-conclusione-mobilita.component";
import {
    CompilaInfoAutorizzazioneMobilitaComponent
} from "./autorizzazione-mobilita-steps/compila-info-autorizzazione-mobilita/compila-info-autorizzazione-mobilita.component";
import {ScegliRichiesteComponent} from "./generic-operation-steps/scegli-richieste/scegli-richieste.component";
import {Translation} from "@ngneat/transloco";
import {GenericOperationMassivaDataI} from "./generic-operation-steps/generic-operation-steps-interface";
import {
    BudgetFilters,
    BudgetFilterService
} from "../requests/approvazione-budget/budget-sidebar-filter-container-request/budget-filter.service";
import {PathEnum} from "../../../app.routing";
import {removeUnderscore} from "../../../shared/utils/utils";
import {Router} from "@angular/router";
import {buildConfigurationForSpeseRequest, buildMissioniConfiguration} from "../requests/requests-utils";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {GenericTableConfigurationModel} from "../../../shared/components/table/model/generic-table-model";
import {ConfirmOperationComponent} from "./generic-operation-steps/confirm-operation/confirm-operation.component";
import {
    CompilaInfoRichiesteComponent
} from "./generic-operation-steps/compila-info-richieste/compila-info-richieste.component";
import {
    ApprovazioneSpesaFormComponent,
    getRequestFormGroupForApprovazioneSpesa
} from "./approvazione-spesa-steps/approvazione-spesa-form/approvazione-spesa-form.component";
import {ComponentType} from "@angular/cdk/overlay";
import {
    BudgetPtaFilterService
} from "../pta-requests/approvazione-budget/budget-pta-sidebar-filter-container-request/budget-pta-filter.service";
import {
    ApprovazioneSpesaPtaFormComponent,
    getRequestFormGroupForApprovazioneSpesaPta
} from "./approvazione-spesa-pta-steps/approvazione-spesa-pta-form/approvazione-spesa-pta-form.component";
import {
    MissioniFilters, MissioniFilterService
} from "../../../shared/components/missioni-filter/missioni-filter.service";

// models

export enum OperazioneMassivaTipo {
    SUPERAMENTO_CORSO = "SUPERAMENTO_CORSO",
    APPROVAZIONE_SPESE = "APPROVAZIONE_SPESE",
    APPROVAZIONE_TRASVERSALI = "APPROVAZIONE_TRASVERSALI",
    APPROVAZIONE_PIANI = "APPROVAZIONE_PIANI",
    APPROVAZIONE_RICERCA = "APPROVAZIONE_RICERCA",
    APPROVAZIONE_MOBILITA = "APPROVAZIONE_MOBILITA",
    AUTORIZZAZIONE_MOBILITA = "AUTORIZZAZIONE_MOBILITA",
    AUTORIZZAZIONE_REGISTRO_ATTIVITA = "AUTORIZZAZIONE_REGISTRO_ATTIVITA",
    APPROVAZIONE_MISSIONI = 'APPROVAZIONE_MISSIONI',
    APPROVAZIONE_VALIDAZIONE_MOBILITA = 'APPROVAZIONE_VALIDAZIONE_MOBILITA',
    APPROVAZIONE_VALIDAZIONE_RICERCA = 'APPROVAZIONE_VALIDAZIONE_RICERCA',
    APPROVAZIONE_CONCLUSIONE_MOBILITA = 'APPROVAZIONE_CONCLUSIONE_MOBILITA',
    SUPERAMENTO_ATTIVITA_EXTRA = 'SUPERAMENTO_ATTIVITA_EXTRA',
    APPROVAZIONE_CONCLUSIONE_RICERCA = 'APPROVAZIONE_CONCLUSIONE_RICERCA',
    APPROVAZIONE_RICHIESTA_ACQUISTO = 'APPROVAZIONE_RICHIESTA_ACQUISTO',
    APPROVAZIONE_SPESA_MISSIONE = 'APPROVAZIONE_SPESA_MISSIONE',
    // PTA
    APPROVAZIONE_SPESE_PTA = 'APPROVAZIONE_SPESE_PTA',
    APPROVAZIONE_MODIFICA_BUDGET = 'APPROVAZIONE_MODIFICA_BUDGET',
}

export interface OperazioneMassivaI {
    descrizione: string;
    tipo: OperazioneMassivaTipo;
}

export interface OperazioneMassivaStepConfig {
    stepName: string;
    stepState: string;
    component: Type<any>;
    dataInjector: Injector;
    previewStringFn: (value: any) => string;
    hideNavButtons?: boolean;
    hideBack?: boolean;
}

export interface OperazioneMassivaDataI {
    showWithDelibera?: boolean;
    opType?: PlanOpType;
    showWithDeliberaTranslatedLabel?: string;
    refreshData?: boolean;
    refreshPage?: boolean;
}


// get operations and steps utils

export function getOperazioniDisponibiliBySottoruolo(sottoruolo: AuthorityType, cicloConfigurationService: CicloConfigurationService): OperazioneMassivaI[] {
    let ops = [];
    switch (sottoruolo) {
        case AuthorityType.DOCENTECORSO:
            ops = [
                {
                    descrizione: "massive_operations.superamento_corso.op_title",
                    tipo: OperazioneMassivaTipo.SUPERAMENTO_CORSO
                }
            ];
        case AuthorityType.COORDINATORE:
            ops = [
                {
                    descrizione: "massive_operations.approvazione_trasversali.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_TRASVERSALI
                },
                {
                    descrizione: "massive_operations.approvazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_ricerca.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_RICERCA
                },
                {
                    descrizione: "massive_operations.approvazione_piani.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_PIANI
                },
                {
                    descrizione: "massive_operations.approvazione_spese.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_SPESE
                },
                {
                    descrizione: "massive_operations.autorizzazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_registro_attivita.op_title",
                    tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_REGISTRO_ATTIVITA
                },
                {
                    descrizione: "massive_operations.approvazione_missioni.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MISSIONI
                },
                { descrizione: "massive_operations.approvazione_validazione_mobilita.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_MOBILITA},
                { descrizione: "massive_operations.approvazione_validazione_ricerca.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_RICERCA},
                { descrizione: "massive_operations.approvazione_conclusione_mobilita.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_MOBILITA},
                { descrizione: "massive_operations.approvazione_conclusione_ricerca.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_RICERCA},
                {
                    descrizione: "massive_operations.approvazione_modifiche_budget.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MODIFICA_BUDGET
                },
                {
                    descrizione: "massive_operations.approvazione_spese_pta.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_SPESE_PTA
                },
            ];
        case AuthorityType.SUPERVISORE:
        case AuthorityType.COSUPERVISORE:
            ops = [
                {
                    descrizione: "massive_operations.approvazione_trasversali.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_TRASVERSALI
                },
                {
                    descrizione: "massive_operations.approvazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_ricerca.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_RICERCA
                },
                {
                    descrizione: "massive_operations.approvazione_piani.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_PIANI
                },
                {
                    descrizione: "massive_operations.approvazione_spese.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_SPESE
                },
                {
                    descrizione: "massive_operations.autorizzazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_registro_attivita.op_title",
                    tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_REGISTRO_ATTIVITA
                },
                {
                    descrizione: "massive_operations.approvazione_missioni.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MISSIONI
                },
                {
                    descrizione: "massive_operations.approvazione_validazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_conclusione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.superamento_extra.op_title",
                    tipo: OperazioneMassivaTipo.SUPERAMENTO_ATTIVITA_EXTRA
                },
            ];
        case AuthorityType.GRUPPOPTA:
            ops = [
                {
                    descrizione: "massive_operations.approvazione_modifiche_budget.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MODIFICA_BUDGET
                },
                {
                    descrizione: "massive_operations.approvazione_spese_pta.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_SPESE_PTA
                },
            ];
        default:
            ops = [];
    }
    // filtering by activation state
    return ops.filter(op => {
        if (op.tipo === OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA)
            return cicloConfigurationService.getSubFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita, ChiaveFunzionalitaEnum.PreautorizzazioneRichiestaMobilita)?.stato === FunzionalitaStatus.ABILITATA;
        else
            return true;
    })
}

export function getStepsByOperazione(tipo: OperazioneMassivaTipo,
                                     injector: Injector,
                                     profilo: AuthorityType,
                                     cicloConfigurationService: CicloConfigurationService,
                                     chiaveOperazione?: ChiaveOperazioneEnum): OperazioneMassivaStepConfig[] {
    switch (tipo) {
        case OperazioneMassivaTipo.SUPERAMENTO_CORSO:
            return getSuperamentoCorsoMassivoConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_RICHIESTA_ACQUISTO:
            return getApprovazioneRichiestaAcquistoMassivaConfig(injector, profilo, chiaveOperazione);
        case OperazioneMassivaTipo.APPROVAZIONE_SPESA_MISSIONE:
            return getApprovazioneSpesaMissioneMassivaConfig(injector, profilo, chiaveOperazione);
        case OperazioneMassivaTipo.APPROVAZIONE_TRASVERSALI:
            return getApprovazioneTrasversaliMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.APPROVAZIONE_PIANI:
            return getApprovazionePianiMassivaConfig(injector, profilo, cicloConfigurationService);
        case OperazioneMassivaTipo.APPROVAZIONE_RICERCA:
            return getApprovazioneRicercaMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.APPROVAZIONE_MOBILITA:
            return getApprovazioneMobilitaMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA:
            return getAutorizzazioneMobilitaMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.AUTORIZZAZIONE_REGISTRO_ATTIVITA:
            return getApprovazioneRegistroAttivitaMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.APPROVAZIONE_MISSIONI:
            return getApprovazioneMissioniMassivaConfig(injector, profilo, chiaveOperazione);
        case OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_MOBILITA:
            return getApprovazioneValidazioneMobilitaMassivaConfig(injector, profilo, TipoPeriodoEnum.SOGGIORNOESTERO);
        case OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_MOBILITA:
            return getApprovazioneConclusioneMobilitaMassivaConfig(injector, profilo, TipoPeriodoEnum.SOGGIORNOESTERO);
        case OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_RICERCA:
            return getApprovazioneValidazioneMobilitaMassivaConfig(injector, profilo, TipoPeriodoEnum.RICERCA);
        case OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_RICERCA:
            return getApprovazioneConclusioneMobilitaMassivaConfig(injector, profilo, TipoPeriodoEnum.RICERCA);
        case OperazioneMassivaTipo.APPROVAZIONE_MODIFICA_BUDGET:
            return getApprovazioneModificheBudgetMassivaConfig(injector);
        case OperazioneMassivaTipo.SUPERAMENTO_ATTIVITA_EXTRA:
            return getSuperamentoExtraMassivaConfig(injector, profilo);
        default:
            return [];
    }
}


// configs creator methods

export function getSuperamentoCorsoMassivoConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<SuperamentoCorsoMassivoDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.superamento_corso.steps.choose_course',
            component: ScegliCorsoComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                /*if(stepperDataSuperamento.corsoSelected){
                    return '- ' + stepperDataSuperamento.corsoSelected?.denominazione;
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_corso.steps.choose_students',
            component: ScegliStudentiComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                /* if(stepperDataSuperamento.studentiSelected?.length > 0){
                     return '- ' + stepperDataSuperamento.studentiSelected.map(s => s.nome + ' ' + s.cognome).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_corso.steps.add_compile_info',
            component: CompilaInfoSuperamentoComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_corso.steps.confirm',
            component: ConfirmSuperamentoCorsoComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneSpeseMassivaConfigOld(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneSpeseMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_spese.steps.choose_budgets',
            component: ScegliSpeseComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpeseMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese.steps.add_compile_info',
            component: CompilaInfoApprovazioneComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpeseMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese.steps.confirm',
            component: ConfirmApprovazioneSpeseComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpeseMassivaDataI) => {
                return '';
            }
        },
    ];
}


function getGenericOperationStepsConfig(profilo: AuthorityType,
                                        chiaveFlusso: ChiaveFlussoEnum,
                                        chiaveOperazione: ChiaveOperazioneEnum,
                                        getDataService$: (filters: any, size: number, page: number, opMassivaComp: ScegliRichiesteComponent | CompilaInfoRichiesteComponent, requestsId?: string[]) => Observable<any>,
                                        getTableConfiguration: (pageData: any, size: number, page: number, idRigheDaDisabilitare: string[], cicloConfigurationService: CicloConfigurationService) => GenericTableConfigurationModel,
                                        getRequestPageUrl: (request: any, router: Router) => string,
                                        downloadDocumentObs: (filename, idAllegato, request, opMassivaComp: any) => Observable<any>,
                                        getRefuseRequest$: (request: any, opMassivaComp: ScegliRichiesteComponent, cicloConfigurationService: CicloConfigurationService, form: FormGroup) => any,
                                        getAppliedFiltersLabels: (filterManagerService: any, fieldsLabelMap: Map<string, string>, translation: Translation) => (string[]),
                                        injector: Injector,
                                        getRequestFormGroup: (fb: FormBuilder, request: any, requestsSelected: any[], formArray: FormArray) => FormGroup,
                                        executeOperationService$: (opMassivaData: GenericOperationMassivaDataI, opMassivaComp: ConfirmOperationComponent, cicloConfigurationService: CicloConfigurationService) => Observable<any>,
                                        requestIdentityFieldName: string,
                                        requestAttachmentsFieldName: string,
                                        getRequestTitle: (request: any) => string,
                                        getRequestEsito: (esito: any, resultRequest: any[]) => string,
                                        requestFormComponent: ComponentType<any> | undefined,
                                        filterServiceType: Type<any>,
) {
    const stepFlowData = new BehaviorSubject<GenericOperationMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.generic_operation.steps.choose_requests',
            component: ScegliRichiesteComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: CHIAVE_FLUSSO$, useValue: chiaveFlusso},
                    {provide: CHIAVE_OPERAZIONE$, useValue: chiaveOperazione},
                    {
                        provide: GET_DATA_SERVICE$,
                        useValue: (filters, size, page, opMassivaComp, requestsId) => getDataService$(filters, size, page, opMassivaComp, requestsId)
                    },
                    {
                        provide: GET_TABLE_CONFIGURATION$,
                        useValue: (pageData, cicloConfigService,  size, page, idRigheDaDisabilitare) => getTableConfiguration(pageData, size, page, idRigheDaDisabilitare, cicloConfigService)
                    },
                    {provide: GET_REQUEST_PAGE_URL$, useValue: (request, router) => getRequestPageUrl(request, router)},
                    {
                        provide: DOWNLOAD_REQUEST_DOCUMENT$,
                        useValue: (filename, idAllegato, request, opMassivaComp) => downloadDocumentObs(filename, idAllegato, request, opMassivaComp)
                    },
                    {provide: GET_REQUEST_IDENTITY_FIELD_NAME$, useValue: requestIdentityFieldName},
                    {
                        provide: GET_REFUSE_REQUEST$,
                        useValue: (request, opMassivaComp, ccs, form) => getRefuseRequest$(request, opMassivaComp, ccs, form)
                    },
                    {provide: GET_REQUEST_ATTACHMENTS_FIELD_NAME$, useValue: requestAttachmentsFieldName},
                    {
                        provide: GET_APPLIED_FILTERS_LABELS$,
                        useValue: (filterManagerService, fieldsLabelMap, translation) => getAppliedFiltersLabels(filterManagerService, fieldsLabelMap, translation)
                    },
                    {provide: GET_REQUEST_TITLE$, useValue: (request) => getRequestTitle(request)},
                    {provide: GET_FILTER_SERVICE_TYPE$, useValue: filterServiceType},
                ], parent: injector
            }),
            previewStringFn: (stepperData: GenericOperationMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.generic_operation.steps.add_compile_info',
            component: CompilaInfoRichiesteComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: CHIAVE_FLUSSO$, useValue: chiaveFlusso},
                    {provide: CHIAVE_OPERAZIONE$, useValue: chiaveOperazione},
                    {
                        provide: DOWNLOAD_REQUEST_DOCUMENT$,
                        useValue: (filename, idAllegato, request, opMassivaComp) => downloadDocumentObs(filename, idAllegato, request, opMassivaComp)
                    },
                    {provide: GET_REQUEST_IDENTITY_FIELD_NAME$, useValue: requestIdentityFieldName},
                    {provide: GET_REQUEST_ATTACHMENTS_FIELD_NAME$, useValue: requestAttachmentsFieldName},
                    {provide: GET_REQUEST_FORMGROUP$, useValue: (fb, requestsSelected, formArray, request) => getRequestFormGroup(fb, request, requestsSelected, formArray)},
                    {provide: GET_REQUEST_TITLE$, useValue: (request) => getRequestTitle(request)},
                    {provide: REQUEST_FORM_COMPONENT$, useValue: requestFormComponent},
                    {
                        provide: GET_DATA_SERVICE$,
                        useValue: (filters, size, page, opMassivaComp, requestsId) => getDataService$(filters, size, page, opMassivaComp, requestsId)
                    },
                ], parent: injector
            }),
            previewStringFn: (stepperData: GenericOperationMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.generic_operation.steps.confirm',
            component: ConfirmOperationComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: CHIAVE_FLUSSO$, useValue: chiaveFlusso},
                    {provide: CHIAVE_OPERAZIONE$, useValue: chiaveOperazione},
                    {provide: GET_REQUEST_IDENTITY_FIELD_NAME$, useValue: requestIdentityFieldName},
                    {
                        provide: EXECUTE_OPERATION_SERVICE$,
                        useValue: (opMassivaData, opMassivaComp, ccs) => executeOperationService$(opMassivaData, opMassivaComp, ccs)
                    },
                    {provide: GET_ESITO_TITLE$, useValue: (esito, requestsResults) => getRequestEsito(esito, requestsResults)},
                    {provide: GET_REQUEST_TITLE$, useValue: (request) => getRequestTitle(request)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: GenericOperationMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneSpesaMissioneMassivaConfig(injector: Injector,
                                                          profilo: AuthorityType,
                                                          chiaveOperazione: ChiaveOperazioneEnum): OperazioneMassivaStepConfig[] {
    // config part
    const chiaveFlusso = ChiaveFlussoEnum.RichiestaSpesaMissione;
    const getDataService$ = (filters: BudgetFilters, size: number, page: number, opMassivaComp: ScegliRichiesteComponent, requestsId?: string[]) =>
        opMassivaComp.speseStudentiCicloService?.searchSpeseStudentiCicloForm(
            (!!filters?.cfStudente && !requestsId) ? filters?.cfStudente : undefined,
            chiaveFlusso,
            (!!filters?.codiceSpesa && !requestsId) ? filters?.codiceSpesa : undefined,
            requestsId,
            (!!filters?.descrizioneFondoRiferimento && !requestsId) ? filters?.descrizioneFondoRiferimento : undefined,
            (!!filters?.descrizioneNoteSpesa && !requestsId) ? filters?.descrizioneNoteSpesa : undefined,
            (!!filters?.emailStudente && !requestsId) ? filters?.emailStudente : undefined,
            profilo === AuthorityType.COSUPERVISORE ? true : undefined,
            (!!filters?.matricolaStudente && !requestsId) ? filters?.matricolaStudente : undefined,
            (!!filters?.nominativoStudente && !requestsId) ? filters?.nominativoStudente : undefined,
            (!requestsId ? opMassivaComp.operationConfig.stati_in?.map(si => si.stato) as SpesaStudenteStatus[] : undefined),
            (!!filters?.tipoFondoRiferimento && !requestsId) ? filters?.tipoFondoRiferimento : undefined,
            (!!filters?.tipologiaSpesa && !requestsId) ? filters?.tipologiaSpesa : undefined,
            (!requestsId ? page : undefined),
            (!requestsId ? size : undefined),
            undefined
        );
    const getTableConfiguration =
        (pageData: PageSpesaStudentePerApprovMassivaInfoView, size: number, page: number, idRigheDaDisabilitare: string[], cicloConfigService: CicloConfigurationService) =>
            buildConfigurationForSpeseRequest(pageData, size, page, true, idRigheDaDisabilitare);
    const getRequestPageUrl = (request: SpesaStudentePerApprovMassivaInfoView, router: Router) =>
        router.serializeUrl(router.createUrlTree([PathEnum.STUDENTS, request.budgetStudente?.studenteCiclo?.utente?.id, PathEnum.BUDGET, request.codiceSpesa]));
    const downloadDocumentObs = (filename, idAllegato, request, opMassivaComp: ScegliRichiesteComponent | CompilaInfoRichiesteComponent) =>
        opMassivaComp.speseStudentiCicloService.getAllegatoSpesaStudente(request.codiceSpesa, idAllegato, request?.budgetStudente?.studenteCiclo?.utente?.id, 'response');
    const getRefuseRequest$ = (request: SpesaStudentePerApprovMassivaInfoView, opMassivaComp: ScegliRichiesteComponent, cicloConfigurationService: CicloConfigurationService, form: FormGroup) => {
        const documents = cicloConfigurationService.prepareDocsSubmitObjectFromNuoveConfig(chiaveFlusso, chiaveOperazione, form.getRawValue());
        let esito: EsitoRichiestaApprovazione = { approvato: false,  motivazione_rifiuto: form.getRawValue()?.motivo };
        switch (getMatchingRefuseOperation(chiaveOperazione)){
            case ChiaveOperazioneEnum.RifiutoSupervisore:
                return opMassivaComp.speseStudentiCicloService.accettaRifiutaSpesaStudenteRequestBySupervisoreForm(
                    request.budgetStudente?.studenteCiclo?.utente?.id, request.codiceSpesa, { dettagli_documenti_allegati: documents?.specifications }, esito, documents?.files)
            case ChiaveOperazioneEnum.RifiutoCoordinatore:
                return opMassivaComp.speseStudentiCicloService.accettaRifiutaSpesaStudenteRequestByCoordinatoreForm(
                    request.budgetStudente?.studenteCiclo?.utente?.id, request.codiceSpesa, { dettagli_documenti_allegati: documents?.specifications }, esito, documents?.files)
            case ChiaveOperazioneEnum.RifiutoPta:
                return opMassivaComp.speseStudentiCicloService.accettaRifiutaSpesaStudenteRequestByGruppoPTAForm(
                    request.budgetStudente?.studenteCiclo?.utente?.id, request.codiceSpesa, { dettagli_documenti_allegati: documents?.specifications }, esito, documents?.files)
        }
    }
    const getAppliedFiltersLabels = (filterManagerService: BudgetFilterService, fieldsLabelMap: Map<string, string>, translation: Translation,)=> {
        if (!isEmpty(filterManagerService?.mainFormGroup?.value)) {
            const entries: [string, any][] = Object.entries(filterManagerService?.mainFormGroup?.value)?.filter(item =>
                item[0] !== 'statiSpesa' && item[0] !== 'showOnlyApprovableRequests');
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!fieldsLabelMap.get(element)) {
                            return get(translation, fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }
    const getRequestFormGroup = (fb: FormBuilder, request: SpesaStudentePerApprovMassivaInfoView, requestsSelected: SpesaStudentePerApprovMassivaInfoView[], formArray: FormArray) => {
        if(chiaveOperazione === ChiaveOperazioneEnum.ApprovazionePta) {
            return getRequestFormGroupForApprovazioneSpesaPta(fb, request, requestsSelected, formArray);
        } else if(chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore) {
            return getRequestFormGroupForApprovazioneSpesa(fb, request, requestsSelected, formArray);
        } else {
            return fb.group({});
        }
    };
    const executeOperationService$ = (opMassivaData: GenericOperationMassivaDataI, opMassivaComp: ConfirmOperationComponent, cicloConfigurationService: CicloConfigurationService) => {
        switch (chiaveOperazione){
            case ChiaveOperazioneEnum.ApprovazioneSupervisore:
            case ChiaveOperazioneEnum.ApprovazioneCoordinatore:
                const requestBody: DeliberaApprovaSpeseStudentiRequest = {
                    specifiche: opMassivaData?.requestsSelected?.map((req: SpesaStudentePerApprovMassivaInfoView, index) => ({
                        id_utente: req.budgetStudente?.studenteCiclo?.utente?.id,
                        codice_spesa: req.codiceSpesa,
                        importo_approvato: opMassivaData?.requestsFormValues?.find(c =>
                            c.requestId === req.codiceSpesa && c.userId === req.budgetStudente?.studenteCiclo?.utente?.id)
                            ?.formValue
                            ?.importoApprovato || 0,
                        dettagli_documenti_allegati: {
                            dettagli_documenti_allegati: opMassivaData?.requestsDocumentsValues
                                ?.find(docs => docs.requestId === req.codiceSpesa
                                    && docs.userId === req.budgetStudente?.studenteCiclo?.utente?.id)?.specifications,
                        }
                    }))
                };
                return opMassivaComp.operazioniMassiveService.deliberaApprovaSpeseStudentiForm(requestBody, opMassivaData?.requestsDocumentsValues?.flatMap(docs => docs.files))
            case ChiaveOperazioneEnum.ApprovazionePta:
                const requestBodyPta: ApprovazioneMassivaSpeseStudentiByGruppoPTARequest = {
                    specifiche: opMassivaData?.requestsFormValues?.map((fv, index) => ({
                        id_utente: fv.userId,
                        codice_spesa: fv.requestId,
                        importo_approvato: fv?.formValue?.importoSpesa,
                        importo_approvato_fondo_primo_anno: fv?.formValue?.importoSpesaPrimoAnno,
                        importo_approvato_fondo_secondo_anno: fv?.formValue?.importoSpesaSecondoAnno,
                        importo_approvato_fondo_terzo_anno: fv?.formValue?.importoSpesaTerzoAnno,
                        importo_approvato_altro_fondo: fv?.formValue?.importoSpesaAltriFondi,
                        descrizione_fondo_effettivo: fv?.formValue?.descrizioneFondo,
                        tipo_fondo_effettivo: fv?.formValue?.tipoFondoRiferimento,
                        dettagli_documenti_allegati: {
                            dettagli_documenti_allegati: opMassivaData?.requestsDocumentsValues
                                ?.find(docs => docs.requestId === fv.requestId
                                    && docs.userId === fv.userId)?.specifications,
                        }
                    }))
                };
                return opMassivaComp.operazioniMassiveService.approvazioneMassivaSpeseStudentiByGruppoPTAForm(requestBodyPta, opMassivaData?.requestsDocumentsValues?.flatMap(docs => docs.files))
        }
    }
    const requestTitle = (request: SpesaStudentePerApprovMassivaInfoView) => {
        return `${request.codiceSpesa?.toUpperCase()} - ${request.budgetStudente?.studenteCiclo?.utente?.cognome?.toUpperCase()} ${request.budgetStudente?.studenteCiclo?.utente?.nome?.toUpperCase()}`
    };
    const requestEsito = (esito: EsitoSingolaApprovazioneSpesa, requestResults: SpesaStudentePerApprovMassivaInfoView[]) => {
        const request = requestResults.find(rr => rr.codiceSpesa === esito.codice_spesa && rr.budgetStudente?.studenteCiclo?.utente?.id === esito.id_utente);
        return `${request?.codiceSpesa?.toUpperCase()} - ${request?.budgetStudente?.studenteCiclo?.utente?.cognome?.toUpperCase()} ${request?.budgetStudente?.studenteCiclo?.utente?.nome?.toUpperCase()}`
    };
    return getGenericOperationStepsConfig(
        profilo,
        chiaveFlusso,
        chiaveOperazione,
        getDataService$,
        getTableConfiguration,
        getRequestPageUrl,
        downloadDocumentObs,
        getRefuseRequest$,
        getAppliedFiltersLabels,
        injector,
        getRequestFormGroup,
        executeOperationService$,
        'codiceSpesa',
        'allegatiSpesaStudente',
        requestTitle,
        requestEsito,
        chiaveOperazione === ChiaveOperazioneEnum.ApprovazionePta ? ApprovazioneSpesaPtaFormComponent : ApprovazioneSpesaFormComponent,
        chiaveOperazione === ChiaveOperazioneEnum.ApprovazionePta ? BudgetPtaFilterService : BudgetFilterService,
    );
}



export function getApprovazioneRichiestaAcquistoMassivaConfig(injector: Injector,
                                                              profilo: AuthorityType,
                                                              chiaveOperazione: ChiaveOperazioneEnum): OperazioneMassivaStepConfig[] {
    // config part
    const chiaveFlusso = ChiaveFlussoEnum.RichiestaAcquisto;
    const getDataService$ = (filters: BudgetFilters, size: number, page: number, opMassivaComp: ScegliRichiesteComponent, requestsId?: string[]) =>
        opMassivaComp.speseStudentiCicloService?.searchSpeseStudentiCicloForm(
            (!!filters?.cfStudente && !requestsId) ? filters?.cfStudente : undefined,
            chiaveFlusso,
            (!!filters?.codiceSpesa && !requestsId) ? filters?.codiceSpesa : undefined,
            requestsId,
            (!!filters?.descrizioneFondoRiferimento && !requestsId) ? filters?.descrizioneFondoRiferimento : undefined,
            (!!filters?.descrizioneNoteSpesa && !requestsId) ? filters?.descrizioneNoteSpesa : undefined,
            (!!filters?.emailStudente && !requestsId) ? filters?.emailStudente : undefined,
            profilo === AuthorityType.COSUPERVISORE ? true : undefined,
            (!!filters?.matricolaStudente && !requestsId) ? filters?.matricolaStudente : undefined,
            (!!filters?.nominativoStudente && !requestsId) ? filters?.nominativoStudente : undefined,
            (!requestsId ? opMassivaComp.operationConfig.stati_in?.map(si => si.stato) as SpesaStudenteStatus[] : undefined),
            (!!filters?.tipoFondoRiferimento && !requestsId) ? filters?.tipoFondoRiferimento : undefined,
            (!!filters?.tipologiaSpesa && !requestsId) ? filters?.tipologiaSpesa : undefined,
            (!requestsId ? page : undefined),
            (!requestsId ? size : undefined),
            undefined
        );
    const getTableConfiguration =
        (pageData: PageSpesaStudentePerApprovMassivaInfoView, size: number, page: number, idRigheDaDisabilitare: string[], cicloConfigService: CicloConfigurationService) =>
            buildConfigurationForSpeseRequest(pageData, size, page, true, idRigheDaDisabilitare);
    const getRequestPageUrl = (request: SpesaStudentePerApprovMassivaInfoView, router: Router) =>
        router.serializeUrl(router.createUrlTree([PathEnum.STUDENTS, request.budgetStudente?.studenteCiclo?.utente?.id, PathEnum.BUDGET, request.codiceSpesa]));
    const downloadDocumentObs = (filename, idAllegato, request, opMassivaComp: ScegliRichiesteComponent | CompilaInfoRichiesteComponent) =>
        opMassivaComp.speseStudentiCicloService.getAllegatoSpesaStudente(request.codiceSpesa, idAllegato, request?.budgetStudente?.studenteCiclo?.utente?.id, 'response');
    const getRefuseRequest$ = (request: SpesaStudentePerApprovMassivaInfoView, opMassivaComp: ScegliRichiesteComponent, cicloConfigurationService: CicloConfigurationService, form: FormGroup) => {
        const documents = cicloConfigurationService.prepareDocsSubmitObjectFromNuoveConfig(chiaveFlusso, chiaveOperazione, form.getRawValue());
        let esito: EsitoRichiestaApprovazione = { approvato: false,  motivazione_rifiuto: form.getRawValue()?.motivo };
        switch (getMatchingRefuseOperation(chiaveOperazione)){
            case ChiaveOperazioneEnum.RifiutoSupervisore:
                return opMassivaComp.speseStudentiCicloService.accettaRifiutaSpesaStudenteRequestBySupervisoreForm(
                    request.budgetStudente?.studenteCiclo?.utente?.id, request.codiceSpesa, { dettagli_documenti_allegati: documents?.specifications }, esito, documents?.files)
            case ChiaveOperazioneEnum.RifiutoCoordinatore:
                return opMassivaComp.speseStudentiCicloService.accettaRifiutaSpesaStudenteRequestByCoordinatoreForm(
                    request.budgetStudente?.studenteCiclo?.utente?.id, request.codiceSpesa, { dettagli_documenti_allegati: documents?.specifications }, esito, documents?.files)
            case ChiaveOperazioneEnum.RifiutoPta:
                return opMassivaComp.speseStudentiCicloService.accettaRifiutaSpesaStudenteRequestByGruppoPTAForm(
                    request.budgetStudente?.studenteCiclo?.utente?.id, request.codiceSpesa, { dettagli_documenti_allegati: documents?.specifications }, esito, documents?.files)
        }
    }
    const getAppliedFiltersLabels = (filterManagerService: BudgetFilterService, fieldsLabelMap: Map<string, string>, translation: Translation,)=> {
        if (!isEmpty(filterManagerService?.mainFormGroup?.value)) {
            const entries: [string, any][] = Object.entries(filterManagerService?.mainFormGroup?.value)?.filter(item =>
                item[0] !== 'statiSpesa' && item[0] !== 'showOnlyApprovableRequests');
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!fieldsLabelMap.get(element)) {
                            return get(translation, fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }
    const getRequestFormGroup = (fb: FormBuilder, request: SpesaStudentePerApprovMassivaInfoView, requestsSelected: SpesaStudentePerApprovMassivaInfoView[], formArray: FormArray) => {
        if(chiaveOperazione === ChiaveOperazioneEnum.ApprovazionePta) {
            return getRequestFormGroupForApprovazioneSpesaPta(fb, request, requestsSelected, formArray);
        } else if(chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore) {
            return getRequestFormGroupForApprovazioneSpesa(fb, request, requestsSelected, formArray);
        } else {
            return fb.group({});
        }
    };
    const executeOperationService$ = (opMassivaData: GenericOperationMassivaDataI, opMassivaComp: ConfirmOperationComponent, cicloConfigurationService: CicloConfigurationService) => {
        switch (chiaveOperazione) {
            case ChiaveOperazioneEnum.ApprovazioneSupervisore:
            case ChiaveOperazioneEnum.ApprovazioneCoordinatore:
                const requestBody: DeliberaApprovaSpeseStudentiRequest = {
                    specifiche: opMassivaData?.requestsSelected?.map((req: SpesaStudentePerApprovMassivaInfoView, index) => ({
                        id_utente: req.budgetStudente?.studenteCiclo?.utente?.id,
                        codice_spesa: req.codiceSpesa,
                        importo_approvato: opMassivaData?.requestsFormValues?.find(c =>
                            c.requestId === req.codiceSpesa && c.userId === req.budgetStudente?.studenteCiclo?.utente?.id)
                            ?.formValue
                            ?.importoApprovato || 0,
                        dettagli_documenti_allegati: {
                            dettagli_documenti_allegati: opMassivaData?.requestsDocumentsValues
                                ?.find(docs => docs.requestId === req.codiceSpesa
                                    && docs.userId === req.budgetStudente?.studenteCiclo?.utente?.id)?.specifications,
                        }
                    }))
                };
                return opMassivaComp.operazioniMassiveService.deliberaApprovaSpeseStudentiForm(requestBody, opMassivaData?.requestsDocumentsValues?.flatMap(docs => docs.files))
            case ChiaveOperazioneEnum.ApprovazionePta:
                const requestBodyPta: ApprovazioneMassivaSpeseStudentiByGruppoPTARequest = {
                    specifiche: opMassivaData?.requestsFormValues?.map((fv, index) => ({
                        id_utente: fv.userId,
                        codice_spesa: fv.requestId,
                        importo_approvato: fv?.formValue?.importoSpesa,
                        importo_approvato_fondo_primo_anno: fv?.formValue?.importoSpesaPrimoAnno,
                        importo_approvato_fondo_secondo_anno: fv?.formValue?.importoSpesaSecondoAnno,
                        importo_approvato_fondo_terzo_anno: fv?.formValue?.importoSpesaTerzoAnno,
                        importo_approvato_altro_fondo: fv?.formValue?.importoSpesaAltriFondi,
                        descrizione_fondo_effettivo: fv?.formValue?.descrizioneFondo,
                        tipo_fondo_effettivo: fv?.formValue?.tipoFondoRiferimento,
                        dettagli_documenti_allegati: {
                            dettagli_documenti_allegati: opMassivaData?.requestsDocumentsValues
                                ?.find(docs => docs.requestId === fv.requestId
                                    && docs.userId === fv.userId)?.specifications,
                        }
                    }))
                };
                return opMassivaComp.operazioniMassiveService.approvazioneMassivaSpeseStudentiByGruppoPTAForm(requestBodyPta, opMassivaData?.requestsDocumentsValues?.flatMap(docs => docs.files))
        }
    }
    const requestTitle = (request: SpesaStudentePerApprovMassivaInfoView) => {
        return `${request.codiceSpesa?.toUpperCase()} - ${request.budgetStudente?.studenteCiclo?.utente?.cognome?.toUpperCase()} ${request.budgetStudente?.studenteCiclo?.utente?.nome?.toUpperCase()}`
    };
    const requestEsito = (esito: EsitoSingolaApprovazioneSpesa, requestResults: SpesaStudentePerApprovMassivaInfoView[]) => {
        const request = requestResults.find(rr => rr.codiceSpesa === esito.codice_spesa && rr.budgetStudente?.studenteCiclo?.utente?.id === esito.id_utente);
        return `${request?.codiceSpesa?.toUpperCase()} - ${request?.budgetStudente?.studenteCiclo?.utente?.cognome?.toUpperCase()} ${request?.budgetStudente?.studenteCiclo?.utente?.nome?.toUpperCase()}`
    };
    return getGenericOperationStepsConfig(
        profilo,
        chiaveFlusso,
        chiaveOperazione,
        getDataService$,
        getTableConfiguration,
        getRequestPageUrl,
        downloadDocumentObs,
        getRefuseRequest$,
        getAppliedFiltersLabels,
        injector,
        getRequestFormGroup,
        executeOperationService$,
        'codiceSpesa',
        'allegatiSpesaStudente',
        requestTitle,
        requestEsito,
        chiaveOperazione === ChiaveOperazioneEnum.ApprovazionePta ? ApprovazioneSpesaPtaFormComponent : ApprovazioneSpesaFormComponent,
        chiaveOperazione === ChiaveOperazioneEnum.ApprovazionePta ? BudgetPtaFilterService : BudgetFilterService,
    );
}


export function getSuperamentoExtraMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneExtraMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.superamento_extra.steps.choose_extra',
            component: ScegliExtraComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneExtraMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_extra.steps.add_compile_info',
            component: CompilaInfoExtraComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneExtraMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_extra.steps.confirm',
            component: ConfirmSuperamentoExtraComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneExtraMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneSpesePTAMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneSpesaPTAMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_spese_pta.steps.choose_budgets',
            component: ScegliSpesaPtaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpesaPTAMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese_pta.steps.add_compile_info',
            component: CompilaInfoApprovazioneSpesaPtaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpesaPTAMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese_pta.steps.confirm',
            component: ConfirmApprovazioneSpesaPtaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpesaPTAMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneModificheBudgetMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneModificheBudgetMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_modifiche_budget.steps.choose_budgets',
            component: ScegliModificheBudgetComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},

                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneModificheBudgetMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_modifiche_budget.steps.add_compile_info',
            component: CompilaInfoApprovazioneModificheBudgetComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneModificheBudgetMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_modifiche_budget.steps.confirm',
            component: ConfirmApprovazioneModificheBudgetComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneModificheBudgetMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneValidazioneMobilitaMassivaConfig(injector: Injector, profilo: AuthorityType, tipo: TipoPeriodoEnum): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneValidazioneMobilitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_validazione_mobilita.steps.choose_requests',
            component: ScegliRichiesteValidazioneComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    { provide: PERIOD_TYPE$, useValue: tipo }
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_validazione_mobilita.steps.add_compile_info',
            component: CompilaInfoApprovazioneRichiesteValidazioneComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_validazione_mobilita.steps.confirm',
            component: ConfirmApprovazioneRichiesteValidazioneComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneConclusioneMobilitaMassivaConfig(injector: Injector, profilo: AuthorityType, tipo: TipoPeriodoEnum): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneConclusioneMobilitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_conclusione_mobilita.steps.choose_periods',
            component: ScegliConclusioneMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    { provide: PERIOD_TYPE$, useValue: tipo }
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_conclusione_mobilita.steps.add_compile_info',
            component: CompilaInfoApprovazioneConclusioneMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    { provide: PERIOD_TYPE$, useValue: tipo }
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_conclusione_mobilita.steps.confirm',
            component: ConfirmApprovazioneConclusioneMobilitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneMissioniMassivaConfig(injector: Injector,
                                                     profilo: AuthorityType,
                                                     chiaveOperazione: ChiaveOperazioneEnum): OperazioneMassivaStepConfig[] {
    // config part
    const chiaveFlusso = ChiaveFlussoEnum.RichiestaMissione;
    const getDataService$ = (filters: MissioniFilters, size: number, page: number, opMassivaComp: ScegliRichiesteComponent | CompilaInfoRichiesteComponent, requestsId?: string[]) =>
        opMassivaComp.missioniService.searchMissioniStudentiForm(
            (!!filters?.annoRiferimento && !requestsId) ? filters?.annoRiferimento : undefined,
            (!!filters?.codiceFiscaleStudente && !requestsId) ? filters?.codiceFiscaleStudente : undefined,
            undefined,
            (!!filters?.descrizione && !requestsId) ? filters?.descrizione : undefined,
            (!!filters?.descrizioneFondoRiferimento && !requestsId) ? filters?.descrizioneFondoRiferimento : undefined,
            undefined,
            profilo === AuthorityType.COSUPERVISORE ? true : undefined,
            (!!filters?.italiaEstero && !requestsId) ? filters?.italiaEstero : undefined,
            (!!filters?.luogo && !requestsId) ? filters?.luogo : undefined,
            (!!filters?.matricolaStudente && !requestsId) ? filters?.matricolaStudente : undefined,
            requestsId,
            (!!filters?.nominativoStudente && !requestsId) ? filters?.nominativoStudente : undefined,
            undefined,
            (!requestsId ? (opMassivaComp as ScegliRichiesteComponent).operationConfig.stati_in?.map(si => si.stato) as MissioneStatus[] : undefined),
            (!!filters?.tipoFondoRiferimento && !requestsId) ? filters?.tipoFondoRiferimento : undefined,
            (!!filters?.tipologiaMissione && !requestsId) ? filters?.tipologiaMissione : undefined,
            (!requestsId ? page : undefined),
            (!requestsId ? size : undefined),
            undefined
        )
    const getTableConfiguration =
        (pageData: PageMissioneAndStudenteInfoView, size: number, page: number, idRigheDaDisabilitare: string[], cicloConfigurationService: CicloConfigurationService) =>
            buildMissioniConfiguration(pageData, cicloConfigurationService, size, page, true, idRigheDaDisabilitare);
    const getRequestPageUrl = (request: MissioneAndStudenteInfoView, router: Router) =>
        router.serializeUrl(router.createUrlTree([PathEnum.STUDENTS, request.studenteCiclo?.utente?.id, PathEnum.MISSIONS, request.id]));
    const downloadDocumentObs = (filename, idAllegato, request: MissioneAndStudenteInfoView, opMassivaComp: ScegliRichiesteComponent | CompilaInfoRichiesteComponent) =>
        opMassivaComp.missioneStudentiCicloService.downloadAllegatoMissione({ id_utente: request?.studenteCiclo?.utente?.id, url: filename }, request.id, 'response');
    const getRefuseRequest$ = (request: MissioneAndStudenteInfoView, opMassivaComp: ScegliRichiesteComponent, cicloConfigurationService: CicloConfigurationService, form: FormGroup) => {
        const documents = cicloConfigurationService.prepareDocsSubmitObjectFromNuoveConfig(chiaveFlusso, chiaveOperazione, form.getRawValue());
        let esito: EsitoRichiestaApprovazione = { approvato: false,  motivazione_rifiuto: form.getRawValue()?.motivo };
        switch (getMatchingRefuseOperation(chiaveOperazione)){
            case ChiaveOperazioneEnum.RifiutoSupervisore:
                return opMassivaComp.missioniService.validaRichiestaMissioneStudenteBySupervisoreForm(
                    request.id, { dettagli_documenti_allegati: documents?.specifications }, esito, documents?.files)
            case ChiaveOperazioneEnum.RifiutoCoordinatore:
                return opMassivaComp.missioniService.validaRichiestaMissioneStudenteByCoordinatoreForm(
                    request.id, { dettagli_documenti_allegati: documents?.specifications }, esito, documents?.files)
            case ChiaveOperazioneEnum.RifiutoPta:
                return opMassivaComp.missioniService.validaRichiestaMissioneStudenteByDirettoreDiDipartimentoForm(
                    request.id,
                    { dettagli_documenti_allegati: documents?.specifications },
                    esito,
                    documents?.files
            )
        }
    }
    const getAppliedFiltersLabels = (filterManagerService: MissioniFilterService, fieldsLabelMap: Map<string, string>, translation: Translation,)=> {
        if (!isEmpty(filterManagerService?.mainFormGroup?.value)) {
            const entries: [string, any][] = Object.entries(filterManagerService?.mainFormGroup?.value)?.filter(item =>
                item[0] !== 'statiMissione' && item[0] !== 'showOnlyApprovableRequests');
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!fieldsLabelMap.get(element)) {

                            return get(translation, fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }
    const getRequestFormGroup = (fb: FormBuilder, request: MissioneAndStudenteInfoView, requestsSelected: MissioneAndStudenteInfoView[], formArray: FormArray) => {
        return fb.group({});
    };
    const executeOperationService$ = (opMassivaData: GenericOperationMassivaDataI, opMassivaComp: ConfirmOperationComponent, cicloConfigurationService: CicloConfigurationService) => {
        switch (chiaveOperazione) {
            case ChiaveOperazioneEnum.ApprovazioneSupervisore:
            case ChiaveOperazioneEnum.ApprovazioneCoordinatore:
            case ChiaveOperazioneEnum.ValidazioneDirettoreDiDipartimento:
                const requestBody: ApprovazioneMassivaMissioniStudenteRequest = {
                    specifiche: opMassivaData?.requestsSelected?.map((req: MissioneAndStudenteInfoView, index) => ({
                        id_missione: req.id,
                        dettagli_documenti_allegati: {
                            dettagli_documenti_allegati: opMassivaData?.requestsDocumentsValues
                                ?.find(docs => docs.requestId === req.id
                                    && docs.userId === req.studenteCiclo?.utente?.id)?.specifications,
                        }
                    }))
                };
                return opMassivaComp.operazioniMassiveService.approvazioneMassivaMissioniDottorandiForm(opMassivaData?.requestsDocumentsValues?.flatMap(docs => docs.files), requestBody)
        }
    }
    const requestTitle = (request: MissioneAndStudenteInfoView) => {
        return `${request.descrizione?.toUpperCase()} - ${request.studenteCiclo?.utente?.cognome?.toUpperCase()} ${request.studenteCiclo?.utente?.nome?.toUpperCase()}`
    };
    const requestEsito = (esito: EsitoSingolaApprovazioneMissioneStudente, requestResults: MissioneAndStudenteInfoView[]) => {
        const request = requestResults.find(rr => rr.id === esito.id_missione && rr.studenteCiclo?.utente?.id === esito.id_utente);
        return `${request.descrizione?.toUpperCase()} - ${request.studenteCiclo?.utente?.cognome?.toUpperCase()} ${request.studenteCiclo?.utente?.nome?.toUpperCase()}`
    };
    return getGenericOperationStepsConfig(
        profilo,
        chiaveFlusso,
        chiaveOperazione,
        getDataService$,
        getTableConfiguration,
        getRequestPageUrl,
        downloadDocumentObs,
        getRefuseRequest$,
        getAppliedFiltersLabels,
        injector,
        getRequestFormGroup,
        executeOperationService$,
        'id',
        'allegatiMissione',
        requestTitle,
        requestEsito,
        undefined,
        MissioniFilterService,
    );
}
export function getApprovazioneRegistroAttivitaMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneRegistroAttivitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_registro_attivita.steps.choose_registri',
            component: ScegliRegistriAttivitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRegistroAttivitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_registro_attivita.steps.add_compile_info',
            component: CompilaInfoApprovazioneRegistroComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRegistroAttivitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_registro_attivita.steps.confirm',
            component: ConfirmApprovazioneRegistroAttivitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRegistroAttivitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneTrasversaliMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneTrasversaliMassivaDataI>({});
    const deliberaStep = {
        stepState: 'phone',
        hideBack: true,
        stepName: 'massive_operations.approvazione_trasversali.steps.with_delibera',
        component: ShowWithDeliberaStepComponent,
        dataInjector: Injector.create({
            providers: [
                {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)}
            ], parent: injector
        }),
        previewStringFn: (stepperData: OperazioneMassivaDataI) => {
            /* if(!!stepperData.showWithDeliberaTranslatedLabel){
                 return ' - ' + stepperData.showWithDeliberaTranslatedLabel;
             } else {
                 return '';
             }*/
            return '';
        }
    };
    const steps = [
        {
            stepState: 'phone',
            stepName: 'massive_operations.approvazione_trasversali.steps.choose_trasversali',
            component: ScegliTrasversaliComponent,
            hideBack: profilo !== AuthorityType.COORDINATORE,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneTrasversaliMassivaDataI) => {
                // if (stepperData.attivitaSelected?.length > 0) {
                //     return '- ' + stepperData.attivitaSelected?.map(a => a.denominazione)?.join(', ');
                // } else {
                //     return '';
                // }
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: profilo === AuthorityType.COORDINATORE ?
                'massive_operations.approvazione_trasversali.steps.add_compile_info' :  'massive_operations.approvazione_trasversali.steps.add_compile_info_supervisor' ,
            component: CompilaInfoApprovazioneTrasversaliComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneTrasversaliMassivaDataI) => {
                /*if(stepperData.deliberaCollegio){
                    return '- ' + stepperData.deliberaCollegio?.numero + ' - ' + moment(stepperData?.deliberaCollegio?.data, 'YYYY-MM-DD').format('DD/MM/YYYY');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_trasversali.steps.confirm',
            component: ConfirmApprovazioneTrasversaliComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneTrasversaliMassivaDataI) => {
                return '';
            }
        },
    ];
    if (profilo === AuthorityType.COORDINATORE) {
        return [deliberaStep, ...steps];
    } else {
        return steps
    }
}

export function getApprovazionePianiMassivaConfig(injector: Injector,
                                                  profilo: AuthorityType,
                                                  cicloConfigurationService: CicloConfigurationService): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazionePianiMassivaDataI>({});
    const planOpTypeStep = {
        stepState: 'phone',
        hideBack: true,
        stepName: 'massive_operations.approvazione_piani.steps.with_delibera_title',
        component: ShowWithDeliberaForPlanStepComponent,
        dataInjector: Injector.create({
            providers: [
                {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)}
            ], parent: injector
        }),
        previewStringFn: (stepperData: OperazioneMassivaDataI) => {
            /* if(!!stepperData.showWithDeliberaTranslatedLabel){
                 return ' - ' + stepperData.showWithDeliberaTranslatedLabel;
             } else {
                 return '';
             }*/
            return '';
        }
    };
    const corsoConfiguration = cicloConfigurationService.corsoConfiguration;
    const opTypeChoiceEnabled = profilo === AuthorityType.COORDINATORE ?
        corsoConfiguration.mostra_scelta_tipo_flusso_in_oper_massiva_approvazione_piano_coordinatore :
        corsoConfiguration.mostra_scelta_tipo_flusso_in_oper_massiva_approvazione_piano_supervisore;
    const steps = [
        {
            stepState: 'phone',
            stepName: 'massive_operations.approvazione_piani.steps.choose_students',
            component: ScegliStudentiPianiComponent,
            hideBack: !opTypeChoiceEnabled,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: OP_TYPE_CHOICE_ENABLED, useValue: opTypeChoiceEnabled}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazionePianiMassivaDataI) => {
                /*if(stepperData.studentiSelected?.length > 0){
                    return '- ' + stepperData.studentiSelected?.map(s => s.cognome + ' ' + s.nome).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_piani.steps.add_compile_info',
            component: CompilaInfoApprovazionePianiComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: OP_TYPE_CHOICE_ENABLED, useValue: opTypeChoiceEnabled}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazionePianiMassivaDataI) => {
                /* if(stepperData.deliberaCollegio){
                     return '- ' + stepperData.deliberaCollegio?.numero + ' - ' + moment(stepperData?.deliberaCollegio?.data, 'YYYY-MM-DD').format('DD/MM/YYYY');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_piani.steps.confirm',
            component: ConfirmApprovazionePianiComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: OP_TYPE_CHOICE_ENABLED, useValue: opTypeChoiceEnabled}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazionePianiMassivaDataI) => {
                return '';
            }
        },
    ];
    if (opTypeChoiceEnabled) {
        return [planOpTypeStep, ...steps];
    } else {
        return steps;
    }
}

export function getApprovazioneRicercaMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneRicercaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_ricerca.steps.choose_periods',
            component: ScegliPeriodiRicercaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRicercaMassivaDataI) => {
                /*if(stepperData.periodiSelected?.length > 0){
                    return '- ' + stepperData.periodiSelected?.map(s =>
                        s?.codiceMobilita + ' (' + s?.studenteCiclo?.utente?.cognome + ' ' + s?.studenteCiclo?.utente?.nome + ')'
                    ).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_ricerca.steps.add_compile_info',
            component: CompilaInfoApprovazioneRicercaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRicercaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_ricerca.steps.confirm',
            component: ConfirmApprovazioneRicercaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRicercaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneMobilitaMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneRicercaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_mobilita.steps.choose_periods',
            component: ScegliPeriodiMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneMobilitaMassivaDataI) => {
                /* if(stepperData.periodiSelected?.length > 0){
                     return '- ' + stepperData.periodiSelected?.map(s =>
                         s?.codiceMobilita + ' (' + s?.studenteCiclo?.utente?.cognome + ' ' + s?.studenteCiclo?.utente?.nome + ')'
                     ).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_mobilita.steps.add_compile_info',
            component: CompilaInfoApprovazioneMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_mobilita.steps.confirm',
            component: ConfirmApprovazioneMobilitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getAutorizzazioneMobilitaMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<AutorizzazioneMobilitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.autorizzazione_mobilita.steps.choose_students',
            component: ScegliStudentiAutorizzazioneMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: AutorizzazioneMobilitaMassivaDataI) => {
                /*if(stepperData.studentiSelected?.length > 0){
                    return '- ' + stepperData.studentiSelected?.map(s => s.cognome + ' ' + s.nome).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.autorizzazione_mobilita.steps.add_compile_info',
            component: CompilaInfoAutorizzazioneMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: AutorizzazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.autorizzazione_mobilita.steps.confirm',
            component: ConfirmAutorizzazioneMobilitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: AutorizzazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}
