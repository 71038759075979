import {INFO_TOP_COMPONENT_DATA} from "../../../../common/info-dialog/info-wrap.component";
import {Component, Inject, Input, OnInit, Optional} from "@angular/core";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {AuthorityType} from "../../../../../../api-clients/generated/services";
import {FuseMediaWatcherService} from "../../../../../../@fuse/services/media-watcher";
import {takeUntil} from "rxjs";
import {get} from "lodash";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";


@Component({
    selector: 'app-admin-roles',
    templateUrl: './admin-roles.component.html',
    styleUrls: ['./admin-roles.component.scss']
})
export class AdminRolesComponent extends AbstractDefaultComponent implements OnInit {

    @Input() roles: AuthorityType[];

    isScreenSmall: boolean;

    constructor(@Optional() @Inject(INFO_TOP_COMPONENT_DATA) public injectedData: AuthorityType[],
                private _fuseMediaWatcherService: FuseMediaWatcherService,
                private _fuseConfirmationService: FuseConfirmationService,
                private _translocoService: TranslocoService) {
        super();
        this.roles = this.roles || this.injectedData;

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngOnInit(): void {

    }

    openAdministrationRoleInfoDialog(adminRole: AuthorityType) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this._fuseConfirmationService.open({
            title: get(translation, 'administration.' + adminRole + '_title', null),
            message: get(translation, 'administration.' + adminRole, null),
            icon: {
                name: 'mat_outline:info',
                color: 'info'
            },
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
            ]
        });
    }
}
