import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {
    AnnoRiferimentoValues,
    AuthorityType,
    ItaliaEsteroEnum,
    MissioneStatus, MissioneType,
    MobilitaRequestSelectFormValuesDTO, TipoFondoDiRiferimento
} from 'api-clients/generated/services';
import {LocalStorageService} from "../../service/local-storage.service";
import {ApplyFiltersQuicklyStudentsI} from "../../../modules/landing/students-list/students-list.component";




export interface MissioniFilters {
    nominativoStudente?: string;
    codiceFiscaleStudente?: string;
    matricolaStudente?: string;
    statiMissione?: MissioneStatus[];
    italiaEstero?: ItaliaEsteroEnum;
    descrizione?: string;
    luogo?: string;
    upb?: string;
    annoRiferimento?: AnnoRiferimentoValues;
    showOnlyApprovableRequests?: boolean;
    descrizioneFondoRiferimento?: string;
    tipoFondoRiferimento?: TipoFondoDiRiferimento[];
    tipologiaMissione?: MissioneType;
}

@Injectable({
    providedIn: "root"
})
export class MissioniFilterService {

    mainFormGroup: FormGroup | undefined;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    checkAggiornamentoSelectValues$ = new BehaviorSubject(undefined);
    reset$ = new BehaviorSubject(false);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$ = new BehaviorSubject(undefined);
    filterApplied = undefined;
    page = 0;
    defaultFormPathList = [
        'nominativoStudente',
        'codiceFiscaleStudente',
        'matricolaStudente',
        'statiMissione',
        'italiaEstero',
        'descrizione',
        'luogo',
        //'upb',
        'annoRiferimento',
        'showOnlyApprovableRequests',
        'tipoFondoRiferimento',
        //'fondoRiferimento', // include in get enabled filters by func
        'descrizioneFondoRiferimento',
        'tipologiaMissione',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['statiMissione', 'budget.state'],
        ['nominativoStudente', 'common.nominative'],
        ['codiceFiscaleStudente', 'common.fiscal_code'],
        ['matricolaStudente', 'common.enrollment_number'],
        ['italiaEstero', 'missions.nation'],
        ['descrizione', 'common.description'],
        ['luogo', 'missions.place'],
        ['upb', 'missions.upb'],
        ['annoRiferimento', 'common.year'],
        ['showOnlyApprovableRequests', 'menu_filter.only_approvable_by_me'],
        ['tipoFondoRiferimento', 'missions.type_fondo'],
        //['fondoRiferimento', 'missions.fondo_rif'],
        ['descrizioneFondoRiferimento', 'missions.fondo_description'],
        ['tipologiaMissione', 'common.tipology'],
    ]);

    openDrawer = new Subject();
    applyFiltersQuickly: ApplyFiltersQuicklyStudentsI;


    constructor(public localStorageService: LocalStorageService,
                public formBuilder: FormBuilder) {
    }


    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            nominativoStudente: null,
            codiceFiscaleStudente: null,
            matricolaStudente:  null,
            statiMissione: null,
            italiaEstero: null,
            descrizione: null,
            luogo: null,
            upb: null,
            annoRiferimento: null,
            showOnlyApprovableRequests: null,
            tipoFondoRiferimento: null,
            descrizioneFondoRiferimento: null,
            tipologiaMissione: null,
        });
    }


    getFieldsByRole(): string[] {
        return this.defaultFormPathList;
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    init(force?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if ((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            this.buildForm();
        } else if(force){
            this.buildForm();
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.statiRichiesta) {
            this.mainFormGroup.get('statiMissione').setValue(this.applyFiltersQuickly?.statiRichiesta);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.statiMissione) {
            this.mainFormGroup.get('statiMissione').setValue(this.applyFiltersQuickly?.statiMissione);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.showOnlyApprovableRequests) {
            this.mainFormGroup.get('showOnlyApprovableRequests').setValue(this.applyFiltersQuickly?.showOnlyApprovableRequests);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
    }

    private buildForm() {
        if (!!this.mainFormGroup) {
            this.mainFormGroup.reset();
        } else {
            this.mainFormGroup = this.createDefaultFormGroup();
        }
    }






    reset(): void {
        this.page = 0;

        this.mainFormGroup.reset();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }


    restore(): void {
        this.page = 0;

        this.mainFormGroup.reset();
        this.buildForm();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }
}
