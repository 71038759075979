import {Injectable} from '@angular/core';
import {formGroupConfigInterface} from "../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../layout/common/generic-components/generic-components-enum";
import {
    getCosupPermessoApprovazione,
    getProfiloOrRuolo,
    mapIconToIconName,
    openFileInBlankWindow
} from "../utils/utils";
import {TranslocoService} from "@ngneat/transloco";
import {BehaviorSubject, Subject, tap} from "rxjs";
import {
    AbilitazioneFunzionalita,
    AnnoRiferimentoValues,
    AuthorityType,
    ChiaveDocumentoEnum,
    ChiaveFlussoEnum,
    ChiaveFunzionalitaEnum,
    ChiaveOperazioneEnum, ChiaveWidgetEnum,
    CicliService, ColoreEnum,
    ConfigurazioneCorsoDiStudi,
    ConfigurazioneDocumento, ConfigurazioneDocumentoFlusso,
    ConfigurazioneFlussoCicloDottorato,
    ConfigurazioneFlussoCorsoDiStudi,
    ConfigurazioneFunzionalita,
    ConfigurazioneFunzionalitaExtended,
    ConfigurazioneOperazione,
    ConfigurazioneOperazioneFlussoCiclo,
    DettaglioDocumentiAllegato,
    DocumentoType,
    FunzionalitaStatus,
    GetConfigurazioneCicloDottoratoResponse,
    LinguaValore, MappaStatoDescrizione,
    ModalitaDiFirmaType,
    ModalitaGestioneUtentiEsterni, NuovaConfigurazioneFlussoCorsoDiStudi, NuovaConfigurazioneOperazione,
    PeriodoDiMobilitaStudenteInfoView,
    PeriodoDiMobilitaStudenteStatus, StatoGenericoEnum,
    StudenteCicloInfoView, TipoPeriodoEnum, UnitaDiMisuraAttivita
} from "../../../api-clients/generated/services";
import moment from "moment";
import {optionFile} from "../costants/app-constants";
import {DocumentNameFromUrlPipe} from "../components/document/pipes/document-name-from-url.pipe";
import {groupBy, max} from "lodash";
import {PathEnum} from "../../app.routing";
import {AppInitService} from "./app-init.service";
import {StudentDetailsService} from "../../modules/landing/student-details/student-details.service";
import {SignableDocumentsData} from "../components/document/document.component";
import {ButtonInterface, TipoClickEnum} from "../components/table/model/generic-table-model";
import {getOperationActionLabel} from "../../modules/landing/requests/wrap-request/operation-action-label.pipe";

export enum CicloConfigurationType {
    GESTIONALE="GESTIONALE",
    AMMINISTRAZIONE="AMMINISTRAZIONE"
}

export type OperationsSpec = (
    NuovaConfigurazioneOperazione & {
    chiaveFlusso: ChiaveFlussoEnum,
    chiaveFunzionalita: ChiaveFunzionalitaEnum,
    mappaStatiDescrizioniFlusso: MappaStatoDescrizione[],
})

export interface OperationsByWidgetGroupSpecs {
    chiaveFunzionalita: ChiaveFunzionalitaEnum,
    chiaveWidget: ChiaveWidgetEnum,
    functionalityStatus: FunzionalitaStatus,
    tipoPeriodoEnum?: TipoPeriodoEnum,
    operations: OperationsSpec[]
}

export type DocumentDataType = any & { signableDocumentsData?: SignableDocumentsData };


@Injectable({
    providedIn: 'root'
})
export class CicloConfigurationService {
    /**
     * Holds the right flows-operations configuration.
     * Provides methods to get the configuration according the given flow and operation.
     */

    private configurationInGestionale: GetConfigurazioneCicloDottoratoResponse;
    private configurationInAmministrazione: GetConfigurazioneCicloDottoratoResponse;
    private appInitService: AppInitService;
    private cicloConfigurationGestionaleLoaded$ = new BehaviorSubject<boolean>(false);
    private cicloConfigurationAmministrazioneLoaded$ = new BehaviorSubject<boolean>(false);

    // variables mantained in service for efficiency
    private _activityUnitOfMeasureLabel: string;
    private _activityUnitOfMeasure: UnitaDiMisuraAttivita;
    private _targetAttivitaPrimoAnno: number;
    private _targetAttivitaSecondoAnno: number;
    private _targetAttivitaTerzoAnno: number;
    private _targetAttivitaAbilitato: boolean;

    public fetchingConfigurationOf: CicloConfigurationType;


    constructor(private translocoService: TranslocoService,
                private cicliService: CicliService,
                private studentDetailsService: StudentDetailsService) {

    }

    public init(appInitService: AppInitService) {
        /**
         * USED TO INSTANTIATE SERVICE AT STARTUP
         * (to avoid circular dep)
         * it can be done since the methods that app init service needs are
         * getCicloCorsoConfigurationInGestionale and getCicloCorsoConfigurationInAmministrazione
         * all the other methods, which depends on corso amministrativo in app init, must be called AFTER init()
         */
        this.appInitService = appInitService;
    }


    public get corsoConfiguration(): ConfigurazioneCorsoDiStudi {
        /**
         * Get the corso configuration according the area (gestionale or admin) and the selected course
         */
        // in admin area, for student detail pages, use the course of the student (fetched by getCicloConfigurationInAmministrazione)
        if (window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR)
            && window.location.pathname.includes(PathEnum.STUDENTS)) {
            const studentCorso = this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3;
            return this.configurationInAmministrazione?.configurazioni_corso_di_studi
                ?.find(configCorso => configCorso.codici_corso_esse_3?.includes(studentCorso)); // get the right course
            // in admin area, for other pages, use the selected course (fetched by getCicloConfigurationInAmministrazione)
        } if (window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR)) {
            const currentAdminCorso = this.appInitService?.codiceCorsoStudiAmmSelected;
            return this.configurationInAmministrazione?.configurazioni_corso_di_studi
                ?.find(configCorso => configCorso.codici_corso_esse_3?.includes(currentAdminCorso)); // get the right course
            // in gestionale area, use the first (and the unique) course config (fetched by getCicloConfigurationInGestionale)
        } else {
            return this.configurationInGestionale?.configurazioni_corso_di_studi?.[0]; // get first course (the only one)
        }
    }


    public get corsoConfigurationOrFirstCorsoIfAdministrationNotDettaglioDottorando(): ConfigurazioneCorsoDiStudi {
        /**
         * Get the first available corso configuration
         */
        if (window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR)
            && window.location.pathname.includes(PathEnum.STUDENTS)) {
            const studentCorso = this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3;
            return this.configurationInAmministrazione?.configurazioni_corso_di_studi
                ?.find(configCorso => configCorso.codici_corso_esse_3?.includes(studentCorso)); // get the right course
            // in admin area, for other pages, use the selected course (fetched by getCicloConfigurationInAmministrazione)
        } if (window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR)) {
            const currentAdminCorso = this.appInitService?.codiceCorsoStudiAmmSelected;
            return this.configurationInAmministrazione?.configurazioni_corso_di_studi?.[0]; // get the first course
            // in gestionale area, use the first (and the unique) course config (fetched by getCicloConfigurationInGestionale)
        } else {
            return this.configurationInGestionale?.configurazioni_corso_di_studi?.[0]; // get first course (the only one)
        }
    }


    public get corsoFunzionalitaConfigurationExtended(): ConfigurazioneFunzionalitaExtended {
        /**
         * Get the corso configuration according the area (gestionale or admin) and the selected course
         */
        // in admin area, for student detail pages, use the course of the student (fetched by getCicloConfigurationInAmministrazione)
        if (window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR)
            && window.location.pathname.includes(PathEnum.STUDENTS)) {
            const studentCorso = this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3;
            return this.configurationInAmministrazione?.configurazioni_funzionalita_extended
                ?.find(configCorso => configCorso.codiceCorsoEsse3 === studentCorso); // get the right course
            // in admin area, for other pages, use the selected course (fetched by getCicloConfigurationInAmministrazione)
        } if (window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR)) {
            const currentAdminCorso = this.appInitService?.codiceCorsoStudiAmmSelected;
            return this.configurationInAmministrazione?.configurazioni_funzionalita_extended
                ?.find(configCorso => configCorso.codiceCorsoEsse3 === currentAdminCorso); // get the right course
            // in gestionale area, use the first (and the unique) course config (fetched by getCicloConfigurationInGestionale)
        } else {
            return this.configurationInGestionale?.configurazioni_funzionalita_extended?.[0]; // get first course (the only one)
        }
    }

    public get cicloConfiguration(): GetConfigurazioneCicloDottoratoResponse {
        /**
         * Get the ciclo configuration according the area (gestionale or admin)
         */
        // in admin area, for student detail pages, use the course of the student (fetched by getCicloConfigurationInAmministrazione)
        if (window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR)) {
            return this.configurationInAmministrazione;
        } else {
            return this.configurationInGestionale;
        }
    }

    public get cicloConfigurationLoaded$(): BehaviorSubject<boolean> {
        /**
         * Get a behaviours subject whoose value states if ciclo configuration has been fetched
         */
        if (window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR)) {
            return this.cicloConfigurationAmministrazioneLoaded$;
        } else {
            return this.cicloConfigurationGestionaleLoaded$;
        }
    }

    public setConfigurationInGestionale(cicloConfigurationUpdated: GetConfigurazioneCicloDottoratoResponse){
        /**
         * Update the gestionale ciclo configuration and also align the corresponding conf in admin area if exists
         */
        this.configurationInGestionale = cicloConfigurationUpdated;
        // align config in admin response
        const configIndex = this.configurationInAmministrazione?.configurazioni_corso_di_studi
            ?.findIndex(configCorso =>
                configCorso.codici_corso_esse_3?.includes(cicloConfigurationUpdated.configurazioni_corso_di_studi?.[0]?.codici_corso_esse_3?.[0]));
        if(configIndex != -1 && this.configurationInAmministrazione?.numero_ciclo === cicloConfigurationUpdated.numero_ciclo){
            // if the config in admin section is about the same ciclo and contains corso of gestionale, update it
            this.configurationInAmministrazione.configurazioni_corso_di_studi.splice(configIndex, 1);
            this.configurationInAmministrazione.configurazioni_corso_di_studi.push(cicloConfigurationUpdated.configurazioni_corso_di_studi?.[0]);
        }
    }

    public fetchAndSaveCicloConfigurationForGestionale$ = (ciclo: string, codiceCorsoStudi: string) => {
        console.log('Fetching app configuration (gest area) json for', ciclo, codiceCorsoStudi);
        this.fetchingConfigurationOf = CicloConfigurationType.GESTIONALE;
        return this.cicliService.getConfigurazioneCicloDottorato(+ciclo, codiceCorsoStudi).pipe(
            tap(cicloCorsoConfig => {
                this.configurationInGestionale = cicloCorsoConfig;
                this.cicloConfigurationGestionaleLoaded$.next(true);
                // update activity unit of measure label
                this.updateActivityUnitOfMeasure(cicloCorsoConfig);
                // update activity target
                this.updateTargetAttivita(cicloCorsoConfig);
                this.updateTargetAttivitaAbilitato(cicloCorsoConfig);
                // DEBUG LINE
                // this.configurationInGestionale.abilitazioni_funzionalita?.forEach(f => f.stato = FunzionalitaStatus.DISABILITATA)
            }),
            tap(_ => console.log('Configuration fetched successfully')),
        );
    }

    public fetchAndSaveCicloConfigurationForAmministrazione$ = (ciclo: number) => {
        console.log('Fetching app configuration (amm area) json for', ciclo);
        this.fetchingConfigurationOf = CicloConfigurationType.AMMINISTRAZIONE;
        return this.cicliService.getConfigurazioneCicloDottorato(ciclo).pipe(
            tap(cicloCorsoConfig => {
                this.configurationInAmministrazione = cicloCorsoConfig;
                this.cicloConfigurationAmministrazioneLoaded$.next(true);
                // update activity unit of measure label
                this.updateActivityUnitOfMeasure(cicloCorsoConfig);
                // update activity target
                this.updateTargetAttivita(cicloCorsoConfig);
                this.updateTargetAttivitaAbilitato(cicloCorsoConfig);
                // DEBUG LINE
                // this.configurationInAmministrazione.abilitazioni_funzionalita?.forEach(f => f.stato = FunzionalitaStatus.DISABILITATA)
            }),
            tap(_ => console.log('Configuration fetched successfully')),
        );
    }



    public getFunzionalitaCorsoConfig(chiaveFunzionalita: ChiaveFunzionalitaEnum,
                                      annoRiferimento?: AnnoRiferimentoValues): ConfigurazioneFunzionalita {
        /**
         * Get the functionality configuration for a given functionality, anno (optional)
         */
        return this.corsoFunzionalitaConfigurationExtended
            ?.configurazioniFunzionalita
            ?.find(funzionalita => funzionalita.chiaveFunzionalita === chiaveFunzionalita
                && (!annoRiferimento || annoRiferimento === funzionalita.annoRiferimento));
    }

    public getFlussoCorsoConfig(chiaveFlusso: ChiaveFlussoEnum,
                                annoRiferimento?: AnnoRiferimentoValues,
                                useNewConfig = false,
                                takeFirstCourse = false,): ConfigurazioneFlussoCorsoDiStudi | NuovaConfigurazioneFlussoCorsoDiStudi {
        /**
         * Get the flusso configuration for a given flusso, anno (optional)
         */
        const corsoConfig = takeFirstCourse ? this.corsoConfigurationOrFirstCorsoIfAdministrationNotDettaglioDottorando : this.corsoConfiguration;
        const configFlusso: (NuovaConfigurazioneFlussoCorsoDiStudi | ConfigurazioneFlussoCorsoDiStudi)[] = useNewConfig ?
            corsoConfig?.nuove_configurazioni_flussi_corsi_di_studi :
            corsoConfig?.configurazioni_flussi_corsi_di_studi;
        return configFlusso
            ?.find(flusso => flusso.chiave_flusso === chiaveFlusso
                && (!annoRiferimento || flusso.anni_riferimento?.includes(annoRiferimento)));
    }

    public getOperationCorsoConfig(chiaveFlusso: ChiaveFlussoEnum,
                                   chiaveOperazione: ChiaveOperazioneEnum,
                                   annoRiferimento?: AnnoRiferimentoValues,
                                   useNewConfig = false,
                                   takeFirstCourse = false,): ConfigurazioneOperazione | NuovaConfigurazioneOperazione {
        /**
         * Get the operation configuration for a given flusso, operazione, anno (optional)
         */
        const ops = this.getFlussoCorsoConfig(chiaveFlusso, annoRiferimento, useNewConfig, takeFirstCourse)?.configurazioni_operazioni;
        if(useNewConfig){
            return (ops as NuovaConfigurazioneOperazione[])?.find(op => op.chiave_operazione === chiaveOperazione);
        } else {
            return (ops as ConfigurazioneOperazione[])?.find(op => op.chiaveOperazione === chiaveOperazione);
        }
    }


    public getFlussoCicloConfig(chiaveFlusso: ChiaveFlussoEnum): ConfigurazioneFlussoCicloDottorato {
        /**
         * Get the flusso configuration for a given flusso, anno (optional)
         */
        return this.cicloConfiguration
            ?.configurazioni_flussi_ciclo_dottorato
            ?.find(flusso => flusso.chiave_flusso === chiaveFlusso);
    }

    public getOperationCicloConfig(chiaveFlusso: ChiaveFlussoEnum,
                                   chiaveOperazione: ChiaveOperazioneEnum): ConfigurazioneOperazioneFlussoCiclo {
        /**
         * Get the operation configuration for a given flusso, operazione, anno (optional)
         */
        return this.cicloConfiguration
            ?.configurazioni_flussi_ciclo_dottorato
            ?.find(flusso => flusso.chiave_flusso === chiaveFlusso)
            ?.configurazioni_operazioni?.find(op => op.chiaveOperazione === chiaveOperazione);
    }

    public getDocsFormConfig(chiaveFlusso: ChiaveFlussoEnum,
                             chiaveOperazione: ChiaveOperazioneEnum,
                             downloadFileCallback: (filename: string) => void,
                             annoRiferimento?: AnnoRiferimentoValues,
                             documentsData?: any,
    ): formGroupConfigInterface[] {
        /**
         * Get the documents configuration for a given flusso, operazione, anno (optional)
         */
        const operationConfig = this.getOperationCorsoConfig(chiaveFlusso, chiaveOperazione, annoRiferimento) as ConfigurazioneOperazione;
        const config = operationConfig?.documenti
            // skipping generated documents
            ?.filter((documentSpecification: ConfigurazioneDocumento) => !documentSpecification.generato_da_operazione_corrente)
            // mapping to form config interface
            ?.map((documentSpecification: ConfigurazioneDocumento) => {
                const isSignableDocument = documentSpecification.modalita_di_firma?.length > 0;
                let targetDocSpec, targetDocData;
                if(isSignableDocument){
                    const targetDoc = this.getSignTargetDocument(chiaveFlusso, chiaveOperazione, documentSpecification.chiave, documentsData);
                    targetDocSpec = targetDoc?.targetDocSpec;
                    targetDocData = targetDoc?.targetDocData;
                }
                return {
                    show: true,
                    name: documentSpecification.chiave,
                    isDelibera: documentSpecification.documento_type === DocumentoType.DELIBERA,
                    signModes: isSignableDocument ? documentSpecification.modalita_di_firma.sort() : undefined,
                    //DEBUG signModes: [ModalitaDiFirmaType.FIRMAOTP, ModalitaDiFirmaType.CARICAMENTOFILE, ModalitaDiFirmaType.FIRMADIGITALEUSIGN],
                    noTransloco: this.getTranslatedDescriptionFromDescSpec(
                        ((isSignableDocument && !targetDocData?.documentoInfo?.dataFirma) ? targetDocSpec : documentSpecification)?.descrizione
                    ),
                    numeroMinimoDocumenti: documentSpecification.numero_minimo_documenti,
                    numeroMassimoDocumenti: documentSpecification.numero_massimo_documenti,
                    caricabileARatifica: documentSpecification.caricabile_a_ratifica,
                    statiAmmessiPerCaricamento: documentSpecification.stati_ammessi_per_caricamento,
                    type: TypeDialogFormEnum.DOCUMENT,
                    pipe: DocumentNameFromUrlPipe,
                    downloadFileCallback: (fileOrUrl: File | string) => {
                        if(typeof fileOrUrl === 'string'){
                            downloadFileCallback(fileOrUrl);
                        } else {
                            openFileInBlankWindow(fileOrUrl, fileOrUrl.name);
                        }
                    },
                    optionFile: {
                        sizeByte: (documentSpecification.dimensione_massima_byte ?? optionFile.sizeMb * 1024),
                        sizeMb: 0, // unused for TypeDialogFormEnum.DOCUMENT
                        listaAccepted: documentSpecification.tipi_file_ammessi
                    },
                    hint: this.getTranslatedDescriptionFromDescSpec(documentSpecification?.hint),
                    chiaveFlusso: chiaveFlusso,
                    chiaveOperazione: chiaveOperazione,
                    chiaveDocumento: documentSpecification?.chiave,
                    annoRiferimento: annoRiferimento,
                    useNewConfig: false,
                    chiaviDocumentiAlternativi: documentSpecification?.chiavi_documenti_alternativi,
                } as formGroupConfigInterface
            });
        return config ?? [];
    }

    public getDocsFormConfigFromNuoveConfig(chiaveFlusso: ChiaveFlussoEnum,
                             chiaveOperazione: ChiaveOperazioneEnum,
                             downloadFileCallback: (filename: string, idAllegato?: string) => void,
                             annoRiferimento?: AnnoRiferimentoValues,
                             documentsData?: any[],
    ): formGroupConfigInterface[] {
        /**
         * Get the documents configuration for a given flusso, operazione, anno (optional)
         */
        const flussoConfig = this.getFlussoCorsoConfig(chiaveFlusso, annoRiferimento, true) as NuovaConfigurazioneFlussoCorsoDiStudi;
        const config = flussoConfig?.documenti
            ?.filter((documentSpecification: ConfigurazioneDocumentoFlusso) => documentSpecification.chiavi_operazioni?.includes(chiaveOperazione))
            // skipping generated documents
            ?.filter((documentSpecification: ConfigurazioneDocumentoFlusso) => !documentSpecification.generato_da_operazione_corrente)
            // mapping to form config interface
            ?.map((documentSpecification: ConfigurazioneDocumentoFlusso) => {
                const isSignableDocument = documentSpecification.modalita_di_firma?.length > 0;
                let targetDocSpec, targetDocData;
                if(isSignableDocument){
                    const targetDoc = this.getSignTargetDocumentFromNuoveConfig(chiaveFlusso, chiaveOperazione, documentSpecification.chiave, documentsData);
                    targetDocSpec = targetDoc?.targetDocSpec;
                    targetDocData = targetDoc?.targetDocData;
                }
                return {
                    show: true,
                    name: documentSpecification.chiave,
                    isDelibera: documentSpecification.documento_type === DocumentoType.DELIBERA,
                    signModes: isSignableDocument ? documentSpecification.modalita_di_firma.sort() : undefined,
                    //DEBUG signModes: [ModalitaDiFirmaType.FIRMAOTP, ModalitaDiFirmaType.CARICAMENTOFILE, ModalitaDiFirmaType.FIRMADIGITALEUSIGN],
                    noTransloco: this.getTranslatedDescriptionFromDescSpec(
                        ((isSignableDocument && !targetDocData?.documentoInfo?.dataFirma) ? targetDocSpec : documentSpecification)?.descrizione
                    ),
                    numeroMinimoDocumenti: documentSpecification.numero_minimo_documenti,
                    numeroMassimoDocumenti: documentSpecification.numero_massimo_documenti,
                    modificabileSeGiaPresente: documentSpecification.modificabile_se_gia_presente,
                    caricabileARatifica: documentSpecification.caricabile_a_ratifica,
                    statiAmmessiPerCaricamento: documentSpecification.stati_ammessi_per_caricamento,
                    type: TypeDialogFormEnum.DOCUMENT,
                    pipe: DocumentNameFromUrlPipe,
                    downloadFileCallback: (fileOrUrl: File | string) => {
                        if(typeof fileOrUrl === 'string'){
                            const idAllegato = documentsData?.find(d => d.url === fileOrUrl)?.id;
                            downloadFileCallback(fileOrUrl, idAllegato);
                        } else {
                            openFileInBlankWindow(fileOrUrl, fileOrUrl.name);
                        }
                    },
                    optionFile: {
                        sizeByte: (documentSpecification.dimensione_massima_byte ?? optionFile.sizeMb * 1024),
                        sizeMb: 0, // unused for TypeDialogFormEnum.DOCUMENT
                        listaAccepted: documentSpecification.tipi_file_ammessi
                    },
                    hint: this.getTranslatedDescriptionFromDescSpec(documentSpecification?.hint),
                    chiaveDocumento: documentSpecification?.chiave,
                    chiaveFlusso: chiaveFlusso,
                    chiaveOperazione: chiaveOperazione,
                    annoRiferimento: annoRiferimento,
                    useNewConfig: true,
                    chiaviDocumentiAlternativi: documentSpecification?.chiavi_documenti_alternativi,
                } as formGroupConfigInterface
            });
        return config ?? [];
    }

    public getDocFormConfig(chiaveFlusso: ChiaveFlussoEnum,
                            chiaveOperazione: ChiaveOperazioneEnum,
                            chiaveDocumento: ChiaveDocumentoEnum,
                            downloadFileCallback: (filename: string) => void,
                            annoRiferimento?: AnnoRiferimentoValues,
    ): formGroupConfigInterface {
        /**
         * Get the document configuration for a given flusso, operazione, chiave documento, anno (optional)
         */
        return this.getDocsFormConfig(chiaveFlusso, chiaveOperazione, downloadFileCallback, annoRiferimento)
            ?.find(docConfig => docConfig.name === chiaveDocumento);
    }

    public getDocFormConfigFromNuoveConfig(chiaveFlusso: ChiaveFlussoEnum,
                            chiaveOperazione: ChiaveOperazioneEnum,
                            chiaveDocumento: ChiaveDocumentoEnum,
                            downloadFileCallback: (filename: string) => void,
                            annoRiferimento?: AnnoRiferimentoValues,
    ): formGroupConfigInterface {
        /**
         * Get the document configuration for a given flusso, operazione, chiave documento, anno (optional)
         */
        return this.getDocsFormConfigFromNuoveConfig(chiaveFlusso, chiaveOperazione, downloadFileCallback, annoRiferimento)
            ?.find(docConfig => docConfig.name === chiaveDocumento);
    }

    public getDocsFormValues(chiaveFlusso: ChiaveFlussoEnum,
                             chiaveOperazione: ChiaveOperazioneEnum,
                             documentsData: any[],
                             annoRiferimento?: AnnoRiferimentoValues,
                             utenteId?: string,): DocumentDataType {
        /**
         * Get the documents configuration for a given flusso, operazione, anno (optional)
         */
        const valuesObj: DocumentDataType = {};
        valuesObj.signableDocumentsData = {};
        const operationConfig = this.getOperationCorsoConfig(chiaveFlusso, chiaveOperazione, annoRiferimento) as ConfigurazioneOperazione;
        operationConfig?.documenti?.forEach((documentSpecification: ConfigurazioneDocumento) => {
            const docsWithGivenKeyData = (documentsData?.filter?.(doc =>
                doc?.chiaveDocumento === documentSpecification.chiave &&
                doc?.chiaveFlusso === chiaveFlusso &&
                doc?.chiaveOperazione === chiaveOperazione
            ));
            // CASE: DOCUMENT TO SIGN
            if(documentSpecification.modalita_di_firma?.length > 0) {
                // getting target document to sign spec
                const { targetDocData } = this.getSignTargetDocument(chiaveFlusso, chiaveOperazione, documentSpecification.chiave, documentsData);
                // if document is already signed take the signed document
                if(docsWithGivenKeyData?.length >= 1){  // Signable documents are fixed at max 1
                    valuesObj[documentSpecification.chiave] = docsWithGivenKeyData?.map(d => d?.url) ?? [];
                } else {
                    // filling the url of the doc to sign
                    valuesObj[documentSpecification.chiave] = [targetDocData?.url];
                }
                // creating signable documents metadata
                valuesObj[documentSpecification.chiave].forEach(signableDocUrl => {
                    valuesObj.signableDocumentsData[signableDocUrl] = {
                        utenteId: utenteId,
                        documentoInfo: targetDocData?.documentoInfo,
                        signRequired: documentSpecification.numero_minimo_documenti == 1,
                    }
                })
            // CASE: DOCUMENT NOT TO SIGN
            } else {
                // mapping to url
                valuesObj[documentSpecification.chiave] = docsWithGivenKeyData?.map(d => d?.deliberaCollegioDocenti?.id ?? d?.url) ?? [];
                // preadding empty required values
                while (valuesObj[documentSpecification.chiave]?.length < (max([1, documentSpecification.numero_minimo_documenti ?? 0]))){
                    valuesObj[documentSpecification.chiave]?.push(undefined);
                }
            }
        });
        return valuesObj;
    }

    public getDocsFormValuesFromNuoveConfig(chiaveFlusso: ChiaveFlussoEnum,
                             chiaveOperazione: ChiaveOperazioneEnum,
                             documentsData: any[],
                             annoRiferimento?: AnnoRiferimentoValues,
                             utenteId?: string,): DocumentDataType {
        /**
         * Get the documents configuration for a given flusso, operazione, anno (optional)
         */
        const valuesObj: DocumentDataType = {};
        valuesObj.signableDocumentsData = {};
        const flussoConfig = this.getFlussoCorsoConfig(chiaveFlusso, annoRiferimento, true) as NuovaConfigurazioneFlussoCorsoDiStudi;
        flussoConfig
            ?.documenti
            ?.filter((documentSpecification: ConfigurazioneDocumentoFlusso) => documentSpecification.chiavi_operazioni?.includes(chiaveOperazione))
            ?.forEach((documentSpecification: ConfigurazioneDocumentoFlusso) => {
            const docsWithGivenKeyData = (documentsData?.filter?.(doc =>
                doc?.chiaveDocumento === documentSpecification.chiave &&
                doc?.chiaveFlusso === chiaveFlusso &&
                // take all documents with the given chiave
                // and check if in documentsData there is a doc with current chiave operazione as one of the chiavi operazioni in which it can be uploaded
                flussoConfig.documenti?.filter(dFlusso => dFlusso.chiave === documentSpecification.chiave)?.flatMap(dFlusso => dFlusso.chiavi_operazioni)?.includes(doc?.chiaveOperazione)
            ));
            // CASE: DOCUMENT TO SIGN
            if(documentSpecification.modalita_di_firma?.length > 0) {
                // getting target document to sign spec
                const { targetDocData } = this.getSignTargetDocumentFromNuoveConfig(chiaveFlusso, chiaveOperazione, documentSpecification.chiave, documentsData);
                // if document is already signed take the signed document
                if(docsWithGivenKeyData?.length >= 1){  // Signable documents are fixed at max 1
                    valuesObj[documentSpecification.chiave] = docsWithGivenKeyData?.map(d => d?.url) ?? [];
                } else {
                    // filling the url of the doc to sign
                    valuesObj[documentSpecification.chiave] = [targetDocData?.url];
                }
                // creating signable documents metadata
                valuesObj[documentSpecification.chiave].forEach(signableDocUrl => {
                    valuesObj.signableDocumentsData[signableDocUrl] = {
                        utenteId: utenteId,
                        documentoInfo: targetDocData?.documentoInfo,
                        signRequired: documentSpecification.numero_minimo_documenti == 1,
                    }
                })
            // CASE: DOCUMENT NOT TO SIGN
            } else {
                // mapping to url
                valuesObj[documentSpecification.chiave] = docsWithGivenKeyData?.map(d => d?.deliberaCollegioDocenti?.id ?? d?.url) ?? [];
                // preadding empty required values
                while (valuesObj[documentSpecification.chiave]?.length < (max([1, documentSpecification.numero_minimo_documenti ?? 0]))){
                    valuesObj[documentSpecification.chiave]?.push(undefined);
                }
            }
        });
        return valuesObj;
    }

    public prepareDocsSubmitObject(chiaveFlusso: ChiaveFlussoEnum,
                            chiaveOperazione: ChiaveOperazioneEnum,
                            formValues: any,
                            annoRiferimento?: AnnoRiferimentoValues,): {
        specifications: DettaglioDocumentiAllegato[];
        files: File[];
    } {
        /**
         * Get the documents body specifications for a given flusso, operazione, anno (optional)
         */
        const operationConfig = this.getOperationCorsoConfig(chiaveFlusso, chiaveOperazione, annoRiferimento) as ConfigurazioneOperazione;
        const fileByNames = new Map<string, File>();
        const specifications: DettaglioDocumentiAllegato[] = operationConfig
            ?.documenti
            ?.flatMap((documentSpecification: ConfigurazioneDocumento) => {
                return formValues?.[documentSpecification.chiave]
                    ?.filter(value => !!value)
                    ?.map((fileOrUrl: File | string) => {
                        const docSpec = {
                            chiave: documentSpecification.chiave,
                            //tipo_documento: documentSpecification.documento_type,
                            url: typeof fileOrUrl !== 'string' ? this.createNameForAddedFile(fileOrUrl.name, fileByNames.size) : fileOrUrl,
                            id_delibera: documentSpecification.documento_type === DocumentoType.DELIBERA ? fileOrUrl : undefined,
                        }  as DettaglioDocumentiAllegato;
                        // if it's a new added file
                        if(typeof fileOrUrl !== 'string') {
                            const file = fileOrUrl;
                            fileByNames.set(docSpec.url, new File([file], docSpec.url, {type: file.type}))
                        }
                        return docSpec;
                    });
            }) ?? [];
        const files: File[] = [...fileByNames.values()];
        return {
            specifications: specifications?.filter(s => !!s),
            files: files?.filter(f => !!f)
        };
    }

    public prepareDocsSubmitObjectFromNuoveConfig(chiaveFlusso: ChiaveFlussoEnum,
                            chiaveOperazione: ChiaveOperazioneEnum,
                            formValues: any,
                            annoRiferimento?: AnnoRiferimentoValues,): {
        specifications: DettaglioDocumentiAllegato[];
        files: File[];
    } {
        /**
         * Get the documents body specifications for a given flusso, operazione, anno (optional)
         */
        const flussoConfig = this.getFlussoCorsoConfig(chiaveFlusso, annoRiferimento, true) as NuovaConfigurazioneFlussoCorsoDiStudi;
        const fileByNames = new Map<string, File>();

        const specifications: DettaglioDocumentiAllegato[] = flussoConfig
            ?.documenti
            ?.filter(d => d.chiavi_operazioni.includes(chiaveOperazione))
            ?.flatMap((documentSpecification: ConfigurazioneDocumento) => {
                return formValues?.[documentSpecification.chiave]
                    ?.filter(value => !!value)
                    ?.map((fileOrUrl: File | string) => {
                        const docSpec = {
                            chiave: documentSpecification.chiave,
                            //tipo_documento: documentSpecification.documento_type,
                            url: typeof fileOrUrl !== 'string' ? this.createNameForAddedFile(fileOrUrl.name, fileByNames.size) : fileOrUrl,
                            id_delibera: documentSpecification.documento_type === DocumentoType.DELIBERA ? fileOrUrl : undefined,
                        }  as DettaglioDocumentiAllegato;
                        // if it's a new added file
                        if(typeof fileOrUrl !== 'string') {
                            const file = fileOrUrl;
                            fileByNames.set(docSpec.url, new File([file], docSpec.url, {type: file.type}))
                        }
                        return docSpec;
                    });
            }) ?? [];
        const files: File[] = [...fileByNames.values()];
        return {
            specifications: specifications?.filter(s => !!s),
            files: files?.filter(f => !!f)
        };
    }

    public getTranslatedDescription(chiaveFlusso: ChiaveFlussoEnum,
                                    chiaveOperazione: ChiaveOperazioneEnum,
                                    chiave_documento: string,
                                    annoRiferimento?: AnnoRiferimentoValues,
                                    useNewConfig = false,) {
        /**
         * Get the translated description (according current lang) for a given flusso, operation, year, document key
         */
        const activeLang = this.translocoService.getActiveLang();
        if(useNewConfig){
            const flussoConfig = this.getFlussoCorsoConfig(chiaveFlusso, annoRiferimento, true) as NuovaConfigurazioneFlussoCorsoDiStudi;
            return flussoConfig
                ?.documenti
                ?.find(config => config.chiave === chiave_documento && config.chiavi_operazioni?.includes(chiaveOperazione))
                ?.descrizione?.find(
                    descriptionSpecification => descriptionSpecification?.lingua?.toLowerCase() === activeLang?.toLowerCase())?.valore;
        } else {
            const operationConfig = this.getOperationCorsoConfig(chiaveFlusso, chiaveOperazione, annoRiferimento);
            return (operationConfig as ConfigurazioneOperazione)?.documenti
                ?.find(config => config.chiave === chiave_documento)
                ?.descrizione?.find(
                    descriptionSpecification => descriptionSpecification?.lingua?.toLowerCase() === activeLang?.toLowerCase())?.valore;
        }

    }

    public getFunctionalityEnablingState(chiaveFunzionalita: ChiaveFunzionalitaEnum): AbilitazioneFunzionalita {
        /**
         * Get the enabling state for a given functionality
         */
        return this.cicloConfiguration?.abilitazioni_funzionalita
            ?.find(enablingConfig => enablingConfig.chiave === chiaveFunzionalita);
    }


    public getSubFunctionalityEnablingState(chiaveFunzionalita: ChiaveFunzionalitaEnum, chiaveSottofunzionalita: ChiaveFunzionalitaEnum,): AbilitazioneFunzionalita {
        /**
         * Get the enabling state for a given subfunctionality of first level
         */
        return this.cicloConfiguration?.abilitazioni_funzionalita
            ?.find(enablingConfig => enablingConfig.chiave === chiaveFunzionalita)
            ?.abilitazioni_sottofunzionalita
            ?.find(enablingConfig => enablingConfig.chiave === chiaveSottofunzionalita);
    }










    private getTranslatedDescriptionFromDescSpec(descriptionSpecifications: LinguaValore[]): string {
        /**
         * Get the translated description (according current lang) from the specification
         */
        const activeLang = this.translocoService.getActiveLang();
        return descriptionSpecifications?.find(descriptionSpecification => descriptionSpecification?.lingua?.toLowerCase() === activeLang?.toLowerCase())?.valore;
    }

    private createNameForAddedFile(fileName: string, docIndex: number) {
        /**
         * Create a name based on file extension and current timestamp
         */
        const extensionDotIndex = fileName?.lastIndexOf('.');
        return moment().valueOf() + '_' + docIndex + fileName?.substring(extensionDotIndex);
    }

    get showMatricola(): boolean {
        /**
         * Return true if matricola field can be showed in app else false
         */
        return this.cicloConfiguration.mostrare_matricola;
    }

    get activityUnitOfMeasureLabel(): string {
        /**
         * Return the activity duration mode
         */
        if(!this._activityUnitOfMeasureLabel || !this._activityUnitOfMeasure){
            this.updateActivityUnitOfMeasure(this.cicloConfiguration);
        }
        return this._activityUnitOfMeasureLabel;
    }

    get activityUnitOfMeasure(): UnitaDiMisuraAttivita {
        return this._activityUnitOfMeasure;
    }

    private updateActivityUnitOfMeasure(cicloCorsoConfig: GetConfigurazioneCicloDottoratoResponse) {
        const mode = cicloCorsoConfig?.unita_di_misura_attivita;
        this._activityUnitOfMeasure = mode;
        switch (mode) {
            case UnitaDiMisuraAttivita.CFU:
                this._activityUnitOfMeasureLabel = 'common.cfu_unit_of_measure_plural';
                break;
            case UnitaDiMisuraAttivita.ORE:
                this._activityUnitOfMeasureLabel = 'common.ore_unit_of_measure_plural'
                break;
        }
    }

    getTargetAttivita(anno: AnnoRiferimentoValues): number {
        switch (anno){
            case AnnoRiferimentoValues.PRIMO:
                return this._targetAttivitaPrimoAnno;
            case AnnoRiferimentoValues.SECONDO:
                return this._targetAttivitaSecondoAnno;
            case AnnoRiferimentoValues.TERZO:
                return this._targetAttivitaTerzoAnno;
        }
    }

    get targetAttivitaAbilitato(): boolean {
        return this._targetAttivitaAbilitato;
    }


    private updateTargetAttivita(cicloCorsoConfig: GetConfigurazioneCicloDottoratoResponse) {
        this._targetAttivitaPrimoAnno = cicloCorsoConfig?.target_attivita_primo_anno ?? 0;
        this._targetAttivitaSecondoAnno = cicloCorsoConfig?.target_attivita_secondo_anno ?? 0;
        this._targetAttivitaTerzoAnno = cicloCorsoConfig?.target_attivita_terzo_anno ?? 0;
    }

    private updateTargetAttivitaAbilitato(cicloCorsoConfig: GetConfigurazioneCicloDottoratoResponse) {
        this._targetAttivitaAbilitato = cicloCorsoConfig?.check_target_attivita_abilitato;
    }

    public getEnabledFilterFields(currentFields?: string[]){
        /**
         * Return the enabled filter fields according configuration
         */
        return currentFields?.filter(field => {
            // CASES IN WHICH HIDE FILTER FIELD
            if ((field === 'matricolaStudente' || field === 'matricola') && !this.cicloConfiguration?.mostrare_matricola)
                return false;
            if ((field === 'idCorsoInOffertaFormativa') && this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato !== FunzionalitaStatus.ABILITATA)
                return false;
            // if ((field === 'statoUltimoAggiornamentoPianoDiStudi') && this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato !== FunzionalitaStatus.ABILITATA)
            //     return false;
            if ((field === 'statoSpesaStudente') && this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese)?.stato !== FunzionalitaStatus.ABILITATA)
                return false;
            if ((field === 'statoUltimoAggiornamentoBudgetStudente') && this.getSubFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese, ChiaveFunzionalitaEnum.ModificaBudgetDottorando)?.stato !== FunzionalitaStatus.ABILITATA)
                return false;
            if ((field === 'mobilitaDaApprovareSupervisore') && this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato !== FunzionalitaStatus.ABILITATA)
                return false;
            if ((field === 'mobilitaDaApprovareCoordinatore') && this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato !== FunzionalitaStatus.ABILITATA)
                return false;
            if ((field === 'activityLogDaApprovare') && this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.RegistroAttivita)?.stato !== FunzionalitaStatus.ABILITATA)
                return false;
            if ((field === 'hasNumGiorniMobilitaInferioreAlMinimoPrevisto') && this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato !== FunzionalitaStatus.ABILITATA)
                return false;
            if ((field === 'hasNumGiorniMobilitaSuperioreAlPrevisto') && this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato !== FunzionalitaStatus.ABILITATA)
                return false;
            if ((field === 'hasMissioniDaApprovare') && this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Missioni)?.stato !== FunzionalitaStatus.ABILITATA)
                return false;
            if ((field === 'tipologiaMissione') && !(this.getFlussoCicloConfig(ChiaveFlussoEnum.RichiestaMissione)?.tipologia_missione_values?.length > 0))
                return false;
            if ((field === 'hasNotSupervisoreOrCosupervisoreInterno') && (
                this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneEsterni)?.stato !== FunzionalitaStatus.ABILITATA ||
                this.getSubFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneEsterni, ChiaveFunzionalitaEnum.CheckSupervisoriInterni)?.stato !== FunzionalitaStatus.ABILITATA))
                return false;
            if ((field === 'hasSupervisoreButNotCosupervisore') && (
                this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneEsterni)?.stato !== FunzionalitaStatus.ABILITATA ||
                this.getSubFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneEsterni, ChiaveFunzionalitaEnum.CheckSupervisoriInterni)?.stato !== FunzionalitaStatus.ABILITATA))
                return false;
            if ((field === 'tasseInRegola') && (
                this.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneTasseInRegola)?.stato !== FunzionalitaStatus.ABILITATA))
                return false;
            if((field === 'enabled') && this.cicloConfiguration?.modalita_gestione_utenti_esterni !== ModalitaGestioneUtentiEsterni.KEYCLOAK){
                return false;
            }

            // filter field is ok in all the other cases
            return true;
        });
    }

    private getSignTargetDocument(chiaveFlusso: ChiaveFlussoEnum,
                                  chiaveOperazione: ChiaveOperazioneEnum,
                                  chiaveDocumento: ChiaveDocumentoEnum,
                                  documentsData: any,) {

        function getSignTargetDocumentKeysPriority(chiaveFlusso: ChiaveFlussoEnum, chiaveOperazione: ChiaveOperazioneEnum, chiaveDocumento: ChiaveDocumentoEnum){
            // find the document to sign.
            if(chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneDottorando
                && chiaveDocumento === ChiaveDocumentoEnum.PeriodoRegistroFirmatoDottorando)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.RegistroAttivita, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneSupervisore
                && chiaveDocumento === ChiaveDocumentoEnum.PeriodoRegistroFirmatoSupervisore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.PeriodoRegistroFirmatoDottorando, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneDottorando },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.RegistroAttivita, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore
                && chiaveDocumento === ChiaveDocumentoEnum.PeriodoRegistroFirmatoCoordinatore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.PeriodoRegistroFirmatoSupervisore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.PeriodoRegistroFirmatoDottorando, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneDottorando },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.RegistroAttivita, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
        }

        const targetPriorities = getSignTargetDocumentKeysPriority(chiaveFlusso, chiaveOperazione, chiaveDocumento);

        const { signTargetChiaveDocumento, signTargetChiaveOperazione} = targetPriorities.find(target =>
            documentsData?.filter(docToSignData => docToSignData?.documentoInfo?.chiave === target.signTargetChiaveDocumento && docToSignData?.chiaveOperazione === target.signTargetChiaveOperazione)
                ?.[0]);

        const targetDocSpec = (this.getOperationCorsoConfig(chiaveFlusso, signTargetChiaveOperazione, undefined) as ConfigurazioneOperazione)
            ?.documenti?.find(docSpec => docSpec.chiave === signTargetChiaveDocumento);
        const targetDocData = documentsData?.filter(docToSignData =>
            docToSignData?.documentoInfo?.chiave === signTargetChiaveDocumento && docToSignData?.chiaveOperazione === signTargetChiaveOperazione)?.[0]; // TARGET DOCU MUST ME ONE

        return { targetDocData, targetDocSpec };
    }

    private getSignTargetDocumentFromNuoveConfig(chiaveFlusso: ChiaveFlussoEnum,
                                  chiaveOperazione: ChiaveOperazioneEnum,
                                  chiaveDocumento: ChiaveDocumentoEnum,
                                  documentsData: any,) {

        function getSignTargetDocumentKeysPriority(chiaveFlusso: ChiaveFlussoEnum, chiaveOperazione: ChiaveOperazioneEnum, chiaveDocumento: ChiaveDocumentoEnum){
            // update point: NUOVI DOCUMENTI DA FIRMARE
            // find the document to sign.
            if(chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneDottorando
                && chiaveDocumento === ChiaveDocumentoEnum.PeriodoRegistroFirmatoDottorando)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.RegistroAttivita, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneSupervisore
                && chiaveDocumento === ChiaveDocumentoEnum.PeriodoRegistroFirmatoSupervisore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.PeriodoRegistroFirmatoDottorando, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneDottorando },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.RegistroAttivita, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore
                && chiaveDocumento === ChiaveDocumentoEnum.PeriodoRegistroFirmatoCoordinatore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.PeriodoRegistroFirmatoSupervisore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.PeriodoRegistroFirmatoDottorando, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneDottorando },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.RegistroAttivita, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            // RICHIESTA ACQUISTO
            else if(chiaveFlusso === ChiaveFlussoEnum.RichiestaAcquisto
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneSupervisore
                && chiaveDocumento === ChiaveDocumentoEnum.ModuloRichiestaDiAcquistoFirmatoSupervisore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiAcquisto, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.RichiestaAcquisto
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore
                && chiaveDocumento === ChiaveDocumentoEnum.ModuloRichiestaDiAcquistoFirmatoCoordinatore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiAcquistoFirmatoSupervisore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiAcquisto, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.RichiestaAcquisto
                && (chiaveOperazione === ChiaveOperazioneEnum.ValidazioneDirettoreDiDipartimento || chiaveOperazione === ChiaveOperazioneEnum.ApprovazionePta)
                && chiaveDocumento === ChiaveDocumentoEnum.ModuloRichiestaDiAcquistoFirmatoDirettoreDiDipartimento)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiAcquistoFirmatoCoordinatore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneCoordinatore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiAcquistoFirmatoSupervisore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiAcquisto, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            // RICHIESTA RIMBORSO
            else if(chiaveFlusso === ChiaveFlussoEnum.RichiestaSpesaMissione
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneSupervisore
                && chiaveDocumento === ChiaveDocumentoEnum.ModuloRichiestaDiRimborsoFirmatoSupervisore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiRimborso, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.RichiestaSpesaMissione
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore
                && chiaveDocumento === ChiaveDocumentoEnum.ModuloRichiestaDiRimborsoFirmatoCoordinatore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiRimborsoFirmatoSupervisore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiRimborso, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.RichiestaSpesaMissione
                && (chiaveOperazione === ChiaveOperazioneEnum.ValidazioneDirettoreDiDipartimento || chiaveOperazione === ChiaveOperazioneEnum.ApprovazionePta)
                && chiaveDocumento === ChiaveDocumentoEnum.ModuloRichiestaDiRimborsoFirmatoDirettoreDiDipartimento)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiRimborsoFirmatoCoordinatore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneCoordinatore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiRimborsoFirmatoSupervisore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiRimborso, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            // RICHIESTA MISSIONE
            else if(chiaveFlusso === ChiaveFlussoEnum.RichiestaMissione
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneSupervisore
                && chiaveDocumento === ChiaveDocumentoEnum.ModuloRichiestaDiMissioneFirmatoSupervisore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiMissione, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.RichiestaMissione
                && chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore
                && chiaveDocumento === ChiaveDocumentoEnum.ModuloRichiestaDiMissioneFirmatoCoordinatore)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiMissioneFirmatoSupervisore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiMissione, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
            else if(chiaveFlusso === ChiaveFlussoEnum.RichiestaMissione
                && (chiaveOperazione === ChiaveOperazioneEnum.ValidazioneDirettoreDiDipartimento)
                && chiaveDocumento === ChiaveDocumentoEnum.ModuloRichiestaDiMissioneFirmatoDirettoreDiDipartimento)
                return [
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiMissioneFirmatoCoordinatore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneCoordinatore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiMissioneFirmatoSupervisore, signTargetChiaveOperazione: ChiaveOperazioneEnum.ApprovazioneSupervisore },
                    { signTargetChiaveDocumento: ChiaveDocumentoEnum.ModuloRichiestaDiMissione, signTargetChiaveOperazione: ChiaveOperazioneEnum.RichiestaDottorando },
                ];
        }

        const targetPriorities = getSignTargetDocumentKeysPriority(chiaveFlusso, chiaveOperazione, chiaveDocumento);

        const { signTargetChiaveDocumento, signTargetChiaveOperazione} = targetPriorities.find(target =>
            documentsData?.filter(docToSignData => docToSignData?.documentoInfo?.chiave === target.signTargetChiaveDocumento && docToSignData?.chiaveOperazione === target.signTargetChiaveOperazione)
                ?.[0]);

        const targetDocSpec = (this.getFlussoCorsoConfig(chiaveFlusso, undefined, true) as NuovaConfigurazioneFlussoCorsoDiStudi)
            ?.documenti
            ?.filter(d => d.chiavi_operazioni.includes(signTargetChiaveOperazione))
            ?.find(docSpec => docSpec.chiave === signTargetChiaveDocumento);
        const targetDocData = documentsData?.filter(docToSignData =>
            docToSignData?.documentoInfo?.chiave === signTargetChiaveDocumento && docToSignData?.chiaveOperazione === signTargetChiaveOperazione)?.[0]; // TARGET DOCU MUST ME ONE

        return { targetDocData, targetDocSpec };
    }

    getTranslatedOperationDescription(chiaveFlusso: ChiaveFlussoEnum,
                                      chiaveOperazione: ChiaveOperazioneEnum,
                                      annoRiferimento?: AnnoRiferimentoValues,) {
        const operationConfig = this.getOperationCorsoConfig(
            chiaveFlusso, chiaveOperazione, annoRiferimento, true) as NuovaConfigurazioneOperazione;
        const activeLang = this.translocoService.getActiveLang();
        return operationConfig
            ?.descrizione_operazione?.find(
                descriptionSpecification => descriptionSpecification?.lingua?.toLowerCase() === activeLang?.toLowerCase())?.valore;
    }

    getTranslatedStateDescription(chiaveFlusso: ChiaveFlussoEnum,
                                  stato: StatoGenericoEnum,
                                  annoRiferimento?: AnnoRiferimentoValues) {
        const flussoConfig = this.getFlussoCorsoConfig(
            chiaveFlusso, annoRiferimento, true, this.appInitService.isAreaAdministrator) as NuovaConfigurazioneFlussoCorsoDiStudi;
        const activeLang = this.translocoService.getActiveLang();
        return flussoConfig
            ?.mappa_stati_descrizioni
            ?.find(s => s.stato === stato)
            ?.descrizione_stato
            ?.find(
                descriptionSpecification => descriptionSpecification?.lingua?.toLowerCase() === activeLang?.toLowerCase())?.valore;
    }

    getStateColor(chiaveFlusso: ChiaveFlussoEnum,
                  stato: StatoGenericoEnum,
                  annoRiferimento?: AnnoRiferimentoValues): ColoreEnum {
        const flussoConfig = this.getFlussoCorsoConfig(
            chiaveFlusso, annoRiferimento, true, this.appInitService.isAreaAdministrator) as NuovaConfigurazioneFlussoCorsoDiStudi;
        return flussoConfig
            ?.mappa_stati_descrizioni
            ?.find(s => s.stato === stato)
            ?.colore;
    }

    buildButtonOpConfigForFlussoAndUser(chiaveFlusso: ChiaveFlussoEnum,
                                        annoRiferimento?: AnnoRiferimentoValues,
                                        currentUserId?: string,
                                        currentRuolo?: AuthorityType,
                                        currentProfilo?: AuthorityType,
                                        student?: StudenteCicloInfoView,): ButtonInterface[] {
        const roleForOp = getProfiloOrRuolo(currentRuolo, currentProfilo);
        const flussoConfig = this.getFlussoCorsoConfig(chiaveFlusso, annoRiferimento, true) as NuovaConfigurazioneFlussoCorsoDiStudi;
        return flussoConfig?.configurazioni_operazioni?.map(opConfig => {
            const actionLabel = getOperationActionLabel(opConfig, this.translocoService);
            return {
                nameIconButton: mapIconToIconName(opConfig.icona),
                click: TipoClickEnum.ESEGUI_OPERAZIONE_FLUSSO,
                chiaveOperazione: opConfig.chiave_operazione,
                tooltip: actionLabel,
                color: 'accent',
                show: (stato, richiesta: any & { stato: StatoGenericoEnum}) =>
                    opConfig?.stati_in?.map(si => si.stato)?.includes(richiesta.stato)
                    && opConfig?.stati_out?.find(so =>
                        (this.appInitService.isAreaAdministrator && so.ruoli?.find(sor => this.appInitService.userAmministrazioneInfoCicli?.roles?.includes(sor)))
                        || (!this.appInitService.isAreaAdministrator && so.ruoli?.includes(roleForOp)))
                    //if cosupervisor check permessi approvativi
                    && (this.appInitService.isAmministratore || roleForOp !== AuthorityType.COSUPERVISORE || (roleForOp === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(student, currentUserId)))
                    // specific checks on mobilita
                    && !(opConfig.chiave_operazione === ChiaveOperazioneEnum.RichiestaConclusioneDottorando && richiesta.isRichiestaConclusione),
                valueShow: 'stato'
            }
        }) ?? [];
    }

    getExecutableOperationsByGroupForRole(currentRuolo?: AuthorityType,
                                          currentProfile?: AuthorityType,
                                          takeFirstCourse = false,
                                          onlyOneWidgetForMobilita = false,
                                          ): OperationsByWidgetGroupSpecs[]{
        const courseConfig = takeFirstCourse ? this.corsoConfigurationOrFirstCorsoIfAdministrationNotDettaglioDottorando : this.corsoConfiguration;
        const roleForOp = getProfiloOrRuolo(currentRuolo, currentProfile);
        const executableOperations = courseConfig?.nuove_configurazioni_flussi_corsi_di_studi
            ?.filter(flusso => this.getFunctionalityEnablingState(flusso.chiave_funzionalita).stato !== FunzionalitaStatus.NASCOSTA)
            ?.flatMap(flusso =>
                flusso.configurazioni_operazioni
                    ?.map(op =>({
                        ...op,
                        chiaveFunzionalita: flusso.chiave_funzionalita,
                        mappaStatiDescrizioniFlusso: flusso.mappa_stati_descrizioni,
                        chiaveFlusso: flusso.chiave_flusso,
                        chiaveWidget: flusso.chiave_widget,
                    } as OperationsSpec))
            )
            ?.filter(op => op.is_standard)
            ?.filter(op => op?.stati_out?.find(so =>
                (this.appInitService.isAreaAdministrator && so.ruoli?.find(sor => this.appInitService.userAmministrazioneInfoCicli?.roles?.includes(sor)))
                || (!this.appInitService.isAreaAdministrator && so.ruoli?.includes(roleForOp))));
        const opsByGroup = Object.entries(groupBy(executableOperations, 'chiaveWidget'))
            .map(([chiaveWidget, operations]) => ({
                chiaveFunzionalita: operations?.[0]?.chiaveFunzionalita as ChiaveFunzionalitaEnum,
                chiaveWidget: chiaveWidget as ChiaveWidgetEnum,
                functionalityStatus: this.getFunctionalityEnablingState(operations?.[0]?.chiaveFunzionalita as ChiaveFunzionalitaEnum)?.stato,
                operations
            } as OperationsByWidgetGroupSpecs));
        const mobilityWidgetGroup = opsByGroup.find(o => o.chiaveFunzionalita === ChiaveFunzionalitaEnum.Mobilita);
        if(!onlyOneWidgetForMobilita && mobilityWidgetGroup){
            mobilityWidgetGroup.tipoPeriodoEnum = TipoPeriodoEnum.SOGGIORNOESTERO;
            const researchWidgetGroup = {
                ...mobilityWidgetGroup,
                tipoPeriodoEnum: TipoPeriodoEnum.RICERCA
            }
            opsByGroup.push(researchWidgetGroup)
        }

        //todo sort widgets
        return opsByGroup;
    }

    getExecutableOperationsForFunctionalityForGroupForRole(chiaveFunzionalita: ChiaveFunzionalitaEnum,
                                                           chiaveWidget: ChiaveWidgetEnum,
                                                           currentRuolo?: AuthorityType,
                                                           currentProfile?: AuthorityType,
                                                           chiaveFlusso?: ChiaveFlussoEnum,
                                                           tipoPeriodo?: TipoPeriodoEnum,
                                                           takeFirstCourse = false,): OperationsSpec[] {
        return this.getExecutableOperationsByGroupForRole(currentRuolo, currentProfile, takeFirstCourse)
            ?.filter(op => op.chiaveFunzionalita === chiaveFunzionalita && op.chiaveWidget === chiaveWidget)
            ?.filter(op => !tipoPeriodo || op.tipoPeriodoEnum === tipoPeriodo)
            ?.flatMap(conf => conf.operations)
            ?.filter(op => !chiaveFlusso || op.chiaveFlusso === chiaveFlusso)
    }

}

export const isDocumentSigned = (docSignData: SignableDocumentsData) =>
    docSignData?.documentoInfo?.dataFirma;

export const isTargetDocumentSigned = (docSignData: SignableDocumentsData) =>
    docSignData?.documentoInfo?.dataFirmaDocumentoPadre;

export const isDocumentNotSignedWithSignRequiredCondition = (docSignData: SignableDocumentsData) =>
    docSignData?.signRequired && !isDocumentSigned(docSignData)

export function getMatchingRefuseOperation(chiaveOperazione: ChiaveOperazioneEnum) {
    switch (chiaveOperazione){
        case ChiaveOperazioneEnum.ApprovazioneCoordinatore:
            return ChiaveOperazioneEnum.RifiutoCoordinatore;
        case ChiaveOperazioneEnum.ApprovazionePta:
            return ChiaveOperazioneEnum.RifiutoPta;
        case ChiaveOperazioneEnum.ApprovazioneSupervisore:
            return ChiaveOperazioneEnum.RifiutoSupervisore;
        case ChiaveOperazioneEnum.ApprovazionePtaAteneoMobilitaRichieste:
            return ChiaveOperazioneEnum.RifiutoPtaAteneoMobilitaRichieste;
        case ChiaveOperazioneEnum.ValidazioneDirettoreDiDipartimento:
            return ChiaveOperazioneEnum.RifiutoValidazioneDirettoreDiDipartimento;
        case ChiaveOperazioneEnum.AutorizzazioneDirettoreDiDipartimento:
            return ChiaveOperazioneEnum.RifiutoAutorizzazioneDirettoreDiDipartimento;
    }
}
