<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6" *ngIf="currentProfile !== AuthorityType.COSUPERVISORE && currentProfile !== AuthorityType.SUPERVISORE">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t(!withDelibera ? 'massive_operations.approvazione_trasversali.hint_compile_info' : 'massive_operations.approvazione_trasversali.hint_compile_no_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center">

            <div class="w-full flex flex-col items-center mb-2">

                <!-- DELIBERA -->
                <div class="w-full flex flex-col md:flex-row gap-3 items-start mt-2"
                     *ngIf="!withDelibera && currentProfile !== AuthorityType.COSUPERVISORE && currentProfile !== AuthorityType.SUPERVISORE">

                    <form [formGroup]="form" class="w-full">

                        <mat-form-field appearance="outline"
                                        class="w-full add-mobilita-mat-form-field"
                                        *ngIf="form?.get('deliberaCollegio') as fieldCtrl">
                            <mat-label><strong>{{t('mobility.collegio_approval')}}</strong></mat-label>
                            <mat-select formControlName='deliberaCollegio'>
                                <mat-option [value]="undefined">-</mat-option>
                                <mat-option *ngFor="let delibera of delibere" [value]="delibera.id">
                                    {{t('mobility.delibera_name')}} {{delibera.numero}} - {{formatDeliberaDate(delibera?.data)}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="fieldCtrl.hasError('required')">
                                {{t('form.required')}}
                            </mat-error>

                        </mat-form-field>

                    </form>

                    <button [color]="'primary'"
                            mat-raised-button
                            type="button"
                            class="add-delibera-button"
                            [disabled]="loading"
                            (click)="this.openAddNewDeliberaDialog()">
                        <mat-icon class="mr-2">add</mat-icon>
                        {{t('common.add_delibera')}}
                    </button>

                </div>


                <div class="w-full flex flex-col items-center" *ngIf="currentProfile === AuthorityType.COSUPERVISORE || currentProfile === AuthorityType.SUPERVISORE">

                    <div class="mt-2 mb-4 w-full">
                        <fuse-alert class="w-full"
                                    [showIcon]="true"
                                    [appearance]="'outline'"
                        >{{t(trasversaliCompileInfos?.length > 0 ? 'massive_operations.approvazione_trasversali.hint_compile_info_sup' : 'massive_operations.approvazione_trasversali.hint_compile_no_info')}}</fuse-alert>
                    </div>

                    <!-- TABELLA ATTIVITA -->

                    <div class="flex flex-col gap-4 w-full mb-2" *ngIf="trasversaliCompileInfos">
                        <ng-container *ngFor="let attivita of trasversaliCompileInfos; let i = index" class="w-full">

                            <div class="flex flex-col gap-3 bg-gray-100 rounded-xl px-4 pb-4 pt-2"
                                 *ngIf="getFormGroupByAttId(attivita.id) as formGroup">
                                <div class="mt-1 w-full flex flex-col items-center gap-1">
                                    <span class="font-semibold">
                                        {{attivita?.denominazione}}
                                    </span>
                                    <span class="font-medium text-sm" *ngIf="attivita?.dettaglioAttivitaTrasversale">
                                        {{attivita?.dettaglioAttivitaTrasversale}}
                                    </span>
                                    <span>
                                        {{attivita?.studenteUIMapped?.utenteCognome + ' ' + attivita?.studenteUIMapped?.utenteNome}}
                                    </span>
                                </div>

                                    <div class="flex flex-col pt-2 pb-1 gap-2 w-full" [formGroup]="formGroup">

                                        <!-- ORE -->
                                        <ng-container *ngIf="cicloConfigurationService.activityUnitOfMeasure === UnitaDiMisuraAttivita.CFU">
                                            <mat-form-field appearance="outline" class="w-full add-course-mat-form-field"
                                                            *ngIf="formGroup?.get('ore') as fieldCtrl">
                                                <mat-label><strong>{{t('common.hours')}}</strong></mat-label>
                                                <input matInput
                                                       appOnlyPositiveNumbers
                                                       type="number"
                                                       [min]="1"
                                                       [formControlName]="'ore'">
                                                <mat-error  *ngIf="fieldCtrl.hasError('required') && (formGroup?.dirty || formGroup?.touched)" class="pb-2">
                                                    {{t('form.required')}}
                                                </mat-error>
                                                <mat-error  *ngIf="fieldCtrl.hasError('min') && (formGroup?.dirty || formGroup?.touched)" class="pb-2">
                                                    {{t('form.must_be_greater_than_zero')}}
                                                </mat-error>
                                            </mat-form-field>
                                        </ng-container>

                                        <!-- CFU -->
                                        <mat-form-field appearance="outline" class="w-full add-course-mat-form-field"
                                                        *ngIf="formGroup?.get('cfu') as fieldCtrl">
                                            <mat-label><strong>{{t(cicloConfigurationService.activityUnitOfMeasureLabel)}}</strong></mat-label>
                                            <input matInput
                                                   required
                                                   appOnlyDecimalNumbers
                                                   type="number"
                                                   [min]="0.1"
                                                   [numOfDecimal]="1"
                                                   [formControlName]="'cfu'">
                                            <mat-error  *ngIf="fieldCtrl.hasError('required') && (formGroup?.dirty || formGroup?.touched)" class="pb-2">
                                                {{t('form.required')}}
                                            </mat-error>
                                            <mat-error  *ngIf="fieldCtrl.hasError('min') && (formGroup?.dirty || formGroup?.touched)" class="pb-2">
                                                {{t('form.must_be_greater_than_zero')}}
                                            </mat-error>
                                        </mat-form-field>

                                        <!-- DATA -->
                                        <mat-form-field class="w-full mat-button-datapicker add-mobilita-mat-form-field" appearance="outline"
                                                        *ngIf="formGroup?.get('data') as fieldCtrl">
                                            <mat-label>{{t('student.activity_executed_date')}}</mat-label>
                                            <input matInput
                                                   [placeholder]="t('student.activity_executed_date')"
                                                   required
                                                   readonly
                                                   [max]="formGroup?.get('data')?.value"
                                                   formControlName='data'
                                                   [matDatepicker]="picker1"
                                            >
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                            <mat-error *ngIf="fieldCtrl.hasError('required')">
                                                {{t('form.required')}}
                                            </mat-error>
                                        </mat-form-field>

                                        <!-- NOTA -->
                                        <mat-form-field appearance="outline" class="w-full add-course-mat-form-field"
                                                        *ngIf="formGroup?.get('nota') as fieldCtrl">
                                            <mat-label><strong>{{t('student.activity_executed_note')}}</strong></mat-label>
                                            <input matInput
                                                   type="text"
                                                   [formControlName]="'nota'">
                                        </mat-form-field>

                                        <!-- FILE -->
                                        <ng-container *ngIf="attivita.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESADOCUMENTOSUPERVISORE
                                            || attivita.statoAttivitaTrasversale === AttivitaTrasversaleStatus.RICHIESTASUPERAMENTOCONDOCUMENTOSUPERVISORE
                                            || (attivita.statoAttivitaTrasversale === AttivitaTrasversaleStatus.DOCUMENTODOTTORANDOCARICATO && attivita.presenzaDocumentoSupervisore)">
                                            <div *ngIf="formGroup.get('file') as formControlFile">
                                                <app-input-upload class="w-full"
                                                                  [labelNoTranslate]="t('common.supervisor_document')+'*'"
                                                                  [form]="formControlFile"
                                                                  (downloadEmitter)="downloadFile(formControlFile.value, attivita.id)"
                                                                  (uploadImgEmitter)="uploadFile($event, attivita.id)"
                                                                  (deleteEmitter)="deleteFile(attivita.id)"
                                                                  [forceAcceptedFiles]="true"
                                                                  [sizeMB]="optionFile.sizeMb"
                                                                  [customFileLabel]="t('common.file_selected')"
                                                                  [customNotSupportedFileMessageLabel]="'common.no_file_selected'">
                                                </app-input-upload>
                                            </div>
                                        </ng-container>

                                    </div>

                            </div>


                        </ng-container>

                    </div>


                </div>


            </div>

        </div>



    </ng-template>



</ng-container>
