<ng-container *transloco="let t">
    <div class="flex-row gap-2 mr-2 flex flex-wrap" *ngIf="roles?.length > 0">
            <mat-chip
                *ngFor="let administrationRole of roles"
                class="chip-administration-roles bg-gray-200 text-gray-600 justify-between flex flex-row gap-2 cursor-pointer text-sm"
                (click)="openAdministrationRoleInfoDialog(administrationRole)">
                    <span
                        class="line-clamp-1">{{ t('administration.' + administrationRole + '_title') }}
                    </span>
                <mat-icon class="icon-size-5">
                    info
                </mat-icon>
            </mat-chip>
    </div>
</ng-container>
