/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CicloAbilitatoViewImpl } from '../model/cicloAbilitatoViewImpl';
import { CicloCorsoDiStudiInfoDTO } from '../model/cicloCorsoDiStudiInfoDTO';
import { EsitoRichiestaApprovazione } from '../model/esitoRichiestaApprovazione';
import { Esterno } from '../model/esterno';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { ExportType } from '../model/exportType';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { NazionalitaType } from '../model/nazionalitaType';
import { OnboardingEsternoStatus } from '../model/onboardingEsternoStatus';
import { PageBackOfficeUserView } from '../model/pageBackOfficeUserView';
import { PageUserEsternoViewImpl } from '../model/pageUserEsternoViewImpl';
import { ResetPasswordRequest } from '../model/resetPasswordRequest';
import { SessoType } from '../model/sessoType';
import { SetNewPasswordRequest } from '../model/setNewPasswordRequest';
import { StudenteFilterForm } from '../model/studenteFilterForm';
import { UserAdminRoleUpdateDTORequest } from '../model/userAdminRoleUpdateDTORequest';
import { UserAmministrazioneInfoCicliDTO } from '../model/userAmministrazioneInfoCicliDTO';
import { UserEsternoFilterForm } from '../model/userEsternoFilterForm';
import { UserEsternoViewImpl } from '../model/userEsternoViewImpl';
import { UserProfileUpdateDTO } from '../model/userProfileUpdateDTO';
import { UserRoleBasedInfoView } from '../model/userRoleBasedInfoView';
import { UserSottoruoliInfoDTO } from '../model/userSottoruoliInfoDTO';
import { UserView } from '../model/userView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UsersService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Abilita/disabilita un utente a partire dal suo identificativo.
     * 
     * @param userId id dell&#x27;utente
     * @param enable Valore di abilitazione dell&#x27;utente. Se settato a true, l&#x27;utente viene abilitato. Se settato a false, l&#x27;utente viene bloccato. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public abilitaDisabilitaUtente(userId: string, enable: boolean, observe?: 'body', reportProgress?: boolean): Observable<UserView>;
    public abilitaDisabilitaUtente(userId: string, enable: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserView>>;
    public abilitaDisabilitaUtente(userId: string, enable: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserView>>;
    public abilitaDisabilitaUtente(userId: string, enable: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling abilitaDisabilitaUtente.');
        }

        if (enable === null || enable === undefined) {
            throw new Error('Required parameter enable was null or undefined when calling abilitaDisabilitaUtente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (enable !== undefined && enable !== null) {
            queryParameters = queryParameters.set('enable', <any>enable);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserView>('put',`${this.basePath}/api/v1/user/${encodeURIComponent(String(userId))}/enabling`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il profilo dell&#x27;utente e verifica se importarlo o aggiornarlo.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkAndGetUser(observe?: 'body', reportProgress?: boolean): Observable<UserView>;
    public checkAndGetUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserView>>;
    public checkAndGetUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserView>>;
    public checkAndGetUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserView>('get',`${this.basePath}/api/v1/user/check-and-get`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verifica la validità del token di reset password.
     * 
     * @param userId identificivo dell&#x27;utente
     * @param token token di reset password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkResetPwdToken(userId: string, token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public checkResetPwdToken(userId: string, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public checkResetPwdToken(userId: string, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public checkResetPwdToken(userId: string, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling checkResetPwdToken.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling checkResetPwdToken.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('get',`${this.basePath}/api/v1/user/check-reset-pwd`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette all&#x27;utente di eliminare il profilo di un utente ESTERNO non ancora registrato.
     * 
     * @param esternoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUtenteEsternoForm(esternoId?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteUtenteEsternoForm(esternoId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteUtenteEsternoForm(esternoId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteUtenteEsternoForm(esternoId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esternoId !== undefined) {
            formParams = formParams.append('esternoId', <any>esternoId) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/user/esterno`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;export excel dei dottorandi.
     * 
     * @param body 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param annoSolareMobilita 
     * @param exportType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportExcelDottorandi(body: StudenteFilterForm, page?: number, size?: number, sort?: string, annoSolareMobilita?: number, exportType?: ExportType, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportExcelDottorandi(body: StudenteFilterForm, page?: number, size?: number, sort?: string, annoSolareMobilita?: number, exportType?: ExportType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportExcelDottorandi(body: StudenteFilterForm, page?: number, size?: number, sort?: string, annoSolareMobilita?: number, exportType?: ExportType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportExcelDottorandi(body: StudenteFilterForm, page?: number, size?: number, sort?: string, annoSolareMobilita?: number, exportType?: ExportType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling exportExcelDottorandi.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }
        if (annoSolareMobilita !== undefined && annoSolareMobilita !== null) {
            queryParameters = queryParameters.set('annoSolareMobilita', <any>annoSolareMobilita);
        }
        if (exportType !== undefined && exportType !== null) {
            queryParameters = queryParameters.set('exportType', <any>exportType);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/user/dottorandi/export-excel`,
            {
             responseType: "blob" as "json",
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;export excel degli utenti ESTERNO per lo specifico ciclo.
     * 
     * @param body 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param exportType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportExcelEsterni(body: UserEsternoFilterForm, page?: number, size?: number, sort?: string, exportType?: ExportType, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportExcelEsterni(body: UserEsternoFilterForm, page?: number, size?: number, sort?: string, exportType?: ExportType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportExcelEsterni(body: UserEsternoFilterForm, page?: number, size?: number, sort?: string, exportType?: ExportType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportExcelEsterni(body: UserEsternoFilterForm, page?: number, size?: number, sort?: string, exportType?: ExportType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling exportExcelEsterni.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }
        if (exportType !== undefined && exportType !== null) {
            queryParameters = queryParameters.set('exportType', <any>exportType);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/user/esterni/export-excel`,
            {
             responseType: "blob" as "json",
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i profili di tutti gli utenti con i filtri applicati.
     * 
     * @param codiceCorsoDiStudiEsse3 
     * @param codiceFiscale 
     * @param cognome 
     * @param email 
     * @param nome 
     * @param nominativo 
     * @param numeroCiclo 
     * @param ruolo 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBackofficeUsersForm(codiceCorsoDiStudiEsse3?: string, codiceFiscale?: string, cognome?: string, email?: string, nome?: string, nominativo?: string, numeroCiclo?: number, ruolo?: Array<string>, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageBackOfficeUserView>;
    public getBackofficeUsersForm(codiceCorsoDiStudiEsse3?: string, codiceFiscale?: string, cognome?: string, email?: string, nome?: string, nominativo?: string, numeroCiclo?: number, ruolo?: Array<string>, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageBackOfficeUserView>>;
    public getBackofficeUsersForm(codiceCorsoDiStudiEsse3?: string, codiceFiscale?: string, cognome?: string, email?: string, nome?: string, nominativo?: string, numeroCiclo?: number, ruolo?: Array<string>, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageBackOfficeUserView>>;
    public getBackofficeUsersForm(codiceCorsoDiStudiEsse3?: string, codiceFiscale?: string, cognome?: string, email?: string, nome?: string, nominativo?: string, numeroCiclo?: number, ruolo?: Array<string>, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (codiceCorsoDiStudiEsse3 !== undefined) {
            formParams = formParams.append('codiceCorsoDiStudiEsse3', <any>codiceCorsoDiStudiEsse3) as any || formParams;
        }
        if (codiceFiscale !== undefined) {
            formParams = formParams.append('codiceFiscale', <any>codiceFiscale) as any || formParams;
        }
        if (cognome !== undefined) {
            formParams = formParams.append('cognome', <any>cognome) as any || formParams;
        }
        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }
        if (nome !== undefined) {
            formParams = formParams.append('nome', <any>nome) as any || formParams;
        }
        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (numeroCiclo !== undefined) {
            formParams = formParams.append('numeroCiclo', <any>numeroCiclo) as any || formParams;
        }
        if (ruolo) {
            ruolo.forEach((element) => {
                formParams = formParams.append('ruolo', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<PageBackOfficeUserView>('post',`${this.basePath}/api/v1/user/backoffice`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i ruoli associati all&#x27;utente amministrativo e tutte le associazioni ciclo-corsoDiStudi.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCicliCorsoDiStudiAmministrazione(observe?: 'body', reportProgress?: boolean): Observable<UserAmministrazioneInfoCicliDTO>;
    public getCicliCorsoDiStudiAmministrazione(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAmministrazioneInfoCicliDTO>>;
    public getCicliCorsoDiStudiAmministrazione(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAmministrazioneInfoCicliDTO>>;
    public getCicliCorsoDiStudiAmministrazione(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserAmministrazioneInfoCicliDTO>('get',`${this.basePath}/api/v1/user/cicli-ruoli-amministrativi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce tutte le associazioni ciclo-ruolo-corsoDiStudi dell&#x27;utente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCicliRuoliCorsoDiStudiAssociations(observe?: 'body', reportProgress?: boolean): Observable<Array<CicloAbilitatoViewImpl>>;
    public getCicliRuoliCorsoDiStudiAssociations(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CicloAbilitatoViewImpl>>>;
    public getCicliRuoliCorsoDiStudiAssociations(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CicloAbilitatoViewImpl>>>;
    public getCicliRuoliCorsoDiStudiAssociations(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CicloAbilitatoViewImpl>>('get',`${this.basePath}/api/v1/user/cicli-ruoli-corsi-di-studi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le informazioni degli esterni associati al ciclo di cui l&#x27;utente è COORDINATORE.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsterniCiclo(observe?: 'body', reportProgress?: boolean): Observable<Array<Esterno>>;
    public getEsterniCiclo(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Esterno>>>;
    public getEsterniCiclo(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Esterno>>>;
    public getEsterniCiclo(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Esterno>>('get',`${this.basePath}/api/v1/user/esterni`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i campi pre-settati dell&#x27;utente ESTERNO invitato.
     * 
     * @param userId identificivo dell&#x27;utente
     * @param token token di onboarding dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrefilledUtenteEsternoInvitato(userId: string, token: string, observe?: 'body', reportProgress?: boolean): Observable<UserEsternoViewImpl>;
    public getPrefilledUtenteEsternoInvitato(userId: string, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserEsternoViewImpl>>;
    public getPrefilledUtenteEsternoInvitato(userId: string, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserEsternoViewImpl>>;
    public getPrefilledUtenteEsternoInvitato(userId: string, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getPrefilledUtenteEsternoInvitato.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getPrefilledUtenteEsternoInvitato.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserEsternoViewImpl>('get',`${this.basePath}/api/v1/user/esterni-prefilled`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il profilo dell&#x27;utente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfiloUtente(observe?: 'body', reportProgress?: boolean): Observable<UserView>;
    public getProfiloUtente(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserView>>;
    public getProfiloUtente(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserView>>;
    public getProfiloUtente(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserView>('get',`${this.basePath}/api/v1/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i sottoruoli dell&#x27;utente loggato.
     * Se viene passato il parametro opzionale &#x27;idStudente&#x27;, i sottoruoli studente restituiti saranno filtrati rispetto allo studente indicato
     * @param idStudente 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSottoruoliUtente(idStudente?: string, observe?: 'body', reportProgress?: boolean): Observable<UserSottoruoliInfoDTO>;
    public getSottoruoliUtente(idStudente?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSottoruoliInfoDTO>>;
    public getSottoruoliUtente(idStudente?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSottoruoliInfoDTO>>;
    public getSottoruoliUtente(idStudente?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idStudente !== undefined && idStudente !== null) {
            queryParameters = queryParameters.set('idStudente', <any>idStudente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserSottoruoliInfoDTO>('get',`${this.basePath}/api/v1/user/sottoruoli`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di stati esteri supportati.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatiEsteri(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getStatiEsteri(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getStatiEsteri(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getStatiEsteri(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/v1/user/stati-esteri`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le informazioni di un utente di tipo Docente/Personale-PTA/Esterno.
     * 
     * @param codiceFiscale 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserInfoRoleBased(codiceFiscale: string, observe?: 'body', reportProgress?: boolean): Observable<UserRoleBasedInfoView>;
    public getUserInfoRoleBased(codiceFiscale: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserRoleBasedInfoView>>;
    public getUserInfoRoleBased(codiceFiscale: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserRoleBasedInfoView>>;
    public getUserInfoRoleBased(codiceFiscale: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (codiceFiscale === null || codiceFiscale === undefined) {
            throw new Error('Required parameter codiceFiscale was null or undefined when calling getUserInfoRoleBased.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (codiceFiscale !== undefined && codiceFiscale !== null) {
            queryParameters = queryParameters.set('codiceFiscale', <any>codiceFiscale);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserRoleBasedInfoView>('get',`${this.basePath}/api/v1/user/role-based`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i profili di tutti gli utenti ESTERNO per lo specifico ciclo.
     * 
     * @param abilitato 
     * @param codiceFiscale 
     * @param loggato 
     * @param mail 
     * @param nazionalita 
     * @param nominativo 
     * @param stati 
     * @param statiNon 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersEsterniForm(abilitato?: boolean, codiceFiscale?: string, loggato?: boolean, mail?: string, nazionalita?: NazionalitaType, nominativo?: string, stati?: Array<OnboardingEsternoStatus>, statiNon?: Array<OnboardingEsternoStatus>, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageUserEsternoViewImpl>;
    public getUsersEsterniForm(abilitato?: boolean, codiceFiscale?: string, loggato?: boolean, mail?: string, nazionalita?: NazionalitaType, nominativo?: string, stati?: Array<OnboardingEsternoStatus>, statiNon?: Array<OnboardingEsternoStatus>, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageUserEsternoViewImpl>>;
    public getUsersEsterniForm(abilitato?: boolean, codiceFiscale?: string, loggato?: boolean, mail?: string, nazionalita?: NazionalitaType, nominativo?: string, stati?: Array<OnboardingEsternoStatus>, statiNon?: Array<OnboardingEsternoStatus>, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageUserEsternoViewImpl>>;
    public getUsersEsterniForm(abilitato?: boolean, codiceFiscale?: string, loggato?: boolean, mail?: string, nazionalita?: NazionalitaType, nominativo?: string, stati?: Array<OnboardingEsternoStatus>, statiNon?: Array<OnboardingEsternoStatus>, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (abilitato !== undefined) {
            formParams = formParams.append('abilitato', <any>abilitato) as any || formParams;
        }
        if (codiceFiscale !== undefined) {
            formParams = formParams.append('codiceFiscale', <any>codiceFiscale) as any || formParams;
        }
        if (loggato !== undefined) {
            formParams = formParams.append('loggato', <any>loggato) as any || formParams;
        }
        if (mail !== undefined) {
            formParams = formParams.append('mail', <any>mail) as any || formParams;
        }
        if (nazionalita !== undefined) {
            formParams = formParams.append('nazionalita', <any>nazionalita) as any || formParams;
        }
        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (stati) {
            stati.forEach((element) => {
                formParams = formParams.append('stati', <any>element) as any || formParams;
            })
        }
        if (statiNon) {
            statiNon.forEach((element) => {
                formParams = formParams.append('statiNon', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<PageUserEsternoViewImpl>('post',`${this.basePath}/api/v1/user/esterni`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette all&#x27;utente di aggiungere un nuovo utente con ruolo ESTERNO.
     * 
     * @param email 
     * @param firstName 
     * @param idCorsoStudi 
     * @param idDocenteExtra 
     * @param lastName 
     * @param note 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invitaUtenteEsternoForm(email?: string, firstName?: string, idCorsoStudi?: string, idDocenteExtra?: string, lastName?: string, note?: string, observe?: 'body', reportProgress?: boolean): Observable<UserView>;
    public invitaUtenteEsternoForm(email?: string, firstName?: string, idCorsoStudi?: string, idDocenteExtra?: string, lastName?: string, note?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserView>>;
    public invitaUtenteEsternoForm(email?: string, firstName?: string, idCorsoStudi?: string, idDocenteExtra?: string, lastName?: string, note?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserView>>;
    public invitaUtenteEsternoForm(email?: string, firstName?: string, idCorsoStudi?: string, idDocenteExtra?: string, lastName?: string, note?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }
        if (firstName !== undefined) {
            formParams = formParams.append('firstName', <any>firstName) as any || formParams;
        }
        if (idCorsoStudi !== undefined) {
            formParams = formParams.append('idCorsoStudi', <any>idCorsoStudi) as any || formParams;
        }
        if (idDocenteExtra !== undefined) {
            formParams = formParams.append('idDocenteExtra', <any>idDocenteExtra) as any || formParams;
        }
        if (lastName !== undefined) {
            formParams = formParams.append('lastName', <any>lastName) as any || formParams;
        }
        if (note !== undefined) {
            formParams = formParams.append('note', <any>note) as any || formParams;
        }

        return this.httpClient.request<UserView>('post',`${this.basePath}/api/v1/user/esterno`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette all&#x27;utente invitato di richiedere il reset della password, ricevendo il link per il set della nuova password via email
     * 
     * @param body 
     * @param Dottorandi_Recaptcha_Token Token reCAPTCHA
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordUtenteRequest(body: ResetPasswordRequest, Dottorandi_Recaptcha_Token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public resetPasswordUtenteRequest(body: ResetPasswordRequest, Dottorandi_Recaptcha_Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public resetPasswordUtenteRequest(body: ResetPasswordRequest, Dottorandi_Recaptcha_Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public resetPasswordUtenteRequest(body: ResetPasswordRequest, Dottorandi_Recaptcha_Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling resetPasswordUtenteRequest.');
        }

        if (Dottorandi_Recaptcha_Token === null || Dottorandi_Recaptcha_Token === undefined) {
            throw new Error('Required parameter Dottorandi_Recaptcha_Token was null or undefined when calling resetPasswordUtenteRequest.');
        }

        let headers = this.defaultHeaders;
        if (Dottorandi_Recaptcha_Token !== undefined && Dottorandi_Recaptcha_Token !== null) {
            headers = headers.set('Dottorandi-Recaptcha-Token', String(Dottorandi_Recaptcha_Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/user/reset-password-request`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette all&#x27;utente invitato con ruolo ESTERNO di confermare e/o integrare i dati forniti.
     * 
     * @param Dottorandi_Recaptcha_Token Token reCAPTCHA
     * @param birthday 
     * @param codiceFiscale 
     * @param nazionalita 
     * @param note 
     * @param password 
     * @param sesso 
     * @param statoEstero 
     * @param token 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rispostaInvitoUtenteEsternoForm(Dottorandi_Recaptcha_Token: string, birthday?: string, codiceFiscale?: string, nazionalita?: NazionalitaType, note?: string, password?: string, sesso?: SessoType, statoEstero?: string, token?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public rispostaInvitoUtenteEsternoForm(Dottorandi_Recaptcha_Token: string, birthday?: string, codiceFiscale?: string, nazionalita?: NazionalitaType, note?: string, password?: string, sesso?: SessoType, statoEstero?: string, token?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public rispostaInvitoUtenteEsternoForm(Dottorandi_Recaptcha_Token: string, birthday?: string, codiceFiscale?: string, nazionalita?: NazionalitaType, note?: string, password?: string, sesso?: SessoType, statoEstero?: string, token?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public rispostaInvitoUtenteEsternoForm(Dottorandi_Recaptcha_Token: string, birthday?: string, codiceFiscale?: string, nazionalita?: NazionalitaType, note?: string, password?: string, sesso?: SessoType, statoEstero?: string, token?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Dottorandi_Recaptcha_Token === null || Dottorandi_Recaptcha_Token === undefined) {
            throw new Error('Required parameter Dottorandi_Recaptcha_Token was null or undefined when calling rispostaInvitoUtenteEsterno.');
        }










        let headers = this.defaultHeaders;
        if (Dottorandi_Recaptcha_Token !== undefined && Dottorandi_Recaptcha_Token !== null) {
            headers = headers.set('Dottorandi-Recaptcha-Token', String(Dottorandi_Recaptcha_Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (birthday !== undefined) {
            formParams = formParams.append('birthday', <any>birthday) as any || formParams;
        }
        if (codiceFiscale !== undefined) {
            formParams = formParams.append('codiceFiscale', <any>codiceFiscale) as any || formParams;
        }
        if (nazionalita !== undefined) {
            formParams = formParams.append('nazionalita', <any>nazionalita) as any || formParams;
        }
        if (note !== undefined) {
            formParams = formParams.append('note', <any>note) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }
        if (sesso !== undefined) {
            formParams = formParams.append('sesso', <any>sesso) as any || formParams;
        }
        if (statoEstero !== undefined) {
            formParams = formParams.append('statoEstero', <any>statoEstero) as any || formParams;
        }
        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) as any || formParams;
        }
        if (userId !== undefined) {
            formParams = formParams.append('userId', <any>userId) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/user/esterno/invito`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette all&#x27;utente che ha richiesto il reset della password di settare la nuova password.
     * 
     * @param body 
     * @param Dottorandi_Recaptcha_Token Token reCAPTCHA
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setNewPasswordUtente(body: SetNewPasswordRequest, Dottorandi_Recaptcha_Token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public setNewPasswordUtente(body: SetNewPasswordRequest, Dottorandi_Recaptcha_Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public setNewPasswordUtente(body: SetNewPasswordRequest, Dottorandi_Recaptcha_Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public setNewPasswordUtente(body: SetNewPasswordRequest, Dottorandi_Recaptcha_Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setNewPasswordUtente.');
        }

        if (Dottorandi_Recaptcha_Token === null || Dottorandi_Recaptcha_Token === undefined) {
            throw new Error('Required parameter Dottorandi_Recaptcha_Token was null or undefined when calling setNewPasswordUtente.');
        }

        let headers = this.defaultHeaders;
        if (Dottorandi_Recaptcha_Token !== undefined && Dottorandi_Recaptcha_Token !== null) {
            headers = headers.set('Dottorandi-Recaptcha-Token', String(Dottorandi_Recaptcha_Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/user/set-new-password`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette all&#x27;utente di aggiornare il proprio profilo.
     * Aggiorna il numero di telefono o la foto nel profilo utente.
     * @param file 
     * @param fileLetteraDiAmmissione 
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfiloUtenteForm(file?: Blob, fileLetteraDiAmmissione?: Blob, user?: UserProfileUpdateDTO, observe?: 'body', reportProgress?: boolean): Observable<UserView>;
    public updateProfiloUtenteForm(file?: Blob, fileLetteraDiAmmissione?: Blob, user?: UserProfileUpdateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserView>>;
    public updateProfiloUtenteForm(file?: Blob, fileLetteraDiAmmissione?: Blob, user?: UserProfileUpdateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserView>>;
    public updateProfiloUtenteForm(file?: Blob, fileLetteraDiAmmissione?: Blob, user?: UserProfileUpdateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (fileLetteraDiAmmissione !== undefined) {
            formParams = formParams.append('fileLetteraDiAmmissione', <any>fileLetteraDiAmmissione) as any || formParams;
        }
        if (user !== undefined) {
            formParams = formParams.append('user', new Blob([JSON.stringify(user)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<UserView>('patch',`${this.basePath}/api/v1/user/profilo`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette all&#x27;utente di modificare lo stato di un utente ESTERNO da &#x27;DA_SINCRONIZZARE&#x27; A &#x27;SINCRONIZZATO&#x27; e viceversa
     * 
     * @param esternoId 
     * @param statoNew 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatoUtenteEsterno(esternoId: string, statoNew: OnboardingEsternoStatus, observe?: 'body', reportProgress?: boolean): Observable<UserView>;
    public updateStatoUtenteEsterno(esternoId: string, statoNew: OnboardingEsternoStatus, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserView>>;
    public updateStatoUtenteEsterno(esternoId: string, statoNew: OnboardingEsternoStatus, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserView>>;
    public updateStatoUtenteEsterno(esternoId: string, statoNew: OnboardingEsternoStatus, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (esternoId === null || esternoId === undefined) {
            throw new Error('Required parameter esternoId was null or undefined when calling updateStatoUtenteEsterno.');
        }

        if (statoNew === null || statoNew === undefined) {
            throw new Error('Required parameter statoNew was null or undefined when calling updateStatoUtenteEsterno.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statoNew !== undefined && statoNew !== null) {
            queryParameters = queryParameters.set('statoNew', <any>statoNew);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserView>('post',`${this.basePath}/api/v1/user/esterno/${encodeURIComponent(String(esternoId))}/stato`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette all&#x27;utente di eliminare il profilo di un utente ESTERNO incompleto se associato ad un altro utente ESTERNO già censito.
     * 
     * @param collisionId 
     * @param esternoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUtenteEsternoCollisionForm(collisionId?: string, esternoId?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public updateUtenteEsternoCollisionForm(collisionId?: string, esternoId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public updateUtenteEsternoCollisionForm(collisionId?: string, esternoId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public updateUtenteEsternoCollisionForm(collisionId?: string, esternoId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (collisionId !== undefined) {
            formParams = formParams.append('collisionId', <any>collisionId) as any || formParams;
        }
        if (esternoId !== undefined) {
            formParams = formParams.append('esternoId', <any>esternoId) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('patch',`${this.basePath}/api/v1/user/esterno/collision`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette all&#x27;utente di modificare l&#x27;email di un utente ESTERNO e/o reinviare l&#x27;email di invito in piattaforma.
     * 
     * @param email 
     * @param esternoId 
     * @param resend 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUtenteEsternoNonSincronizzatoForm(email?: string, esternoId?: string, resend?: boolean, observe?: 'body', reportProgress?: boolean): Observable<UserView>;
    public updateUtenteEsternoNonSincronizzatoForm(email?: string, esternoId?: string, resend?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserView>>;
    public updateUtenteEsternoNonSincronizzatoForm(email?: string, esternoId?: string, resend?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserView>>;
    public updateUtenteEsternoNonSincronizzatoForm(email?: string, esternoId?: string, resend?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }
        if (esternoId !== undefined) {
            formParams = formParams.append('esternoId', <any>esternoId) as any || formParams;
        }
        if (resend !== undefined) {
            formParams = formParams.append('resend', <any>resend) as any || formParams;
        }

        return this.httpClient.request<UserView>('patch',`${this.basePath}/api/v1/user/esterno/profilo`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di assegnare o rimuovere il ruolo ADMIN ad una lista di utenti del corso di studi e ciclo presenti nel contesto.
     * 
     * @param body Payload della richiesta.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userAdminRoleUpdate(body: UserAdminRoleUpdateDTORequest, observe?: 'body', reportProgress?: boolean): Observable<CicloCorsoDiStudiInfoDTO>;
    public userAdminRoleUpdate(body: UserAdminRoleUpdateDTORequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloCorsoDiStudiInfoDTO>>;
    public userAdminRoleUpdate(body: UserAdminRoleUpdateDTORequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloCorsoDiStudiInfoDTO>>;
    public userAdminRoleUpdate(body: UserAdminRoleUpdateDTORequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling userAdminRoleUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CicloCorsoDiStudiInfoDTO>('post',`${this.basePath}/api/v1/user/admin-role`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di validare la registrazione eseguita da un utente esterno invitato.
     * 
     * @param body 
     * @param esternoId id dell&#x27;utente esterno
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaRegistrazioneUtenteEsterno(body: EsitoRichiestaApprovazione, esternoId: string, observe?: 'body', reportProgress?: boolean): Observable<UserView>;
    public validaRegistrazioneUtenteEsterno(body: EsitoRichiestaApprovazione, esternoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserView>>;
    public validaRegistrazioneUtenteEsterno(body: EsitoRichiestaApprovazione, esternoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserView>>;
    public validaRegistrazioneUtenteEsterno(body: EsitoRichiestaApprovazione, esternoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling validaRegistrazioneUtenteEsterno.');
        }

        if (esternoId === null || esternoId === undefined) {
            throw new Error('Required parameter esternoId was null or undefined when calling validaRegistrazioneUtenteEsterno.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserView>('post',`${this.basePath}/api/v1/user/esterno/${encodeURIComponent(String(esternoId))}/valida-registrazione`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
