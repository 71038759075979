import {AfterViewInit, Component, Inject, Injector, OnInit, QueryList, Type, ViewChildren} from '@angular/core';
import {
    AuthorityType,
    ChiaveDocumentoEnum,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum, MissioneStudentiCicloService, MissioniService,
    ModalitaDiFirmaType,
    PagePeriodoRegistroAttivitaInfoView,
    SpesaStudentePerApprovMassivaInfoView,
    SpeseStudentiCicloService,
    TipoPeriodoEnum
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, debounceTime, finalize, Observable, startWith, Subscription, take, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {AbstractControl, FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {
    CAN_GO_AHEAD$,
    CHIAVE_FLUSSO$,
    CHIAVE_OPERAZIONE$,
    CURRENT_PROFILE,
    DOWNLOAD_REQUEST_DOCUMENT$, GET_DATA_SERVICE$,
    GET_REQUEST_ATTACHMENTS_FIELD_NAME$,
    GET_REQUEST_FORMGROUP$,
    GET_REQUEST_IDENTITY_FIELD_NAME$, GET_REQUEST_TITLE$,
    OPERAZIONE_MASSIVA_DATA$, REQUEST_FORM_COMPONENT$
} from "../../operazioni-massive.component";
import {FormComponent} from "../../../../../layout/common/generic-components/form/form.component";
import {
    CicloConfigurationService,
    DocumentDataType,
    isDocumentNotSignedWithSignRequiredCondition,
    isDocumentSigned
} from "../../../../../shared/service/ciclo-configuration.service";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {SignableDocumentsData} from "../../../../../shared/components/document/document.component";
import {GenericOperationMassivaDataI} from "../generic-operation-steps-interface";
import {filter, switchMap} from "rxjs/operators";
import {HttpResponse} from "@angular/common/http";
import {makeFilename, openFileInBlankWindow} from "../../../../../shared/utils/utils";
import {get, isEmpty} from "lodash";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {StudentDetailsService} from "../../../student-details/student-details.service";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {ComponentPortal} from "@angular/cdk/portal";
import {INFO_BOTTOM_COMPONENT_DATA} from "../../../../../layout/common/info-dialog/info-wrap.component";
import {ComponentType} from "@angular/cdk/overlay";
import {ButtonType} from "../../../../../shared/components/custom-button/custom-button.component";
import {ScegliRichiesteComponent} from "../scegli-richieste/scegli-richieste.component";
import {LogoutService} from "../../../../../shared/service/logout.service";


@Component({
    selector: 'app-info-richieste-stepper',
    templateUrl: './compila-info-richieste.component.html',
    styleUrls: ['./compila-info-richieste.component.scss']
})
export class CompilaInfoRichiesteComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit {

    outputFormatData = 'DD/MM/YYYY';
    loading: boolean;
    requestsSelected: any[];
    requestsDetailsFormArray: FormArray;
    documentsFormValues: DocumentDataType[];
    _docsFormComponents: FormComponent[];
    docsFormComponentSubs: Subscription[] = [];
    @ViewChildren(FormComponent) formComponentsQL: QueryList<FormComponent>;
    documentsFormConfigs: { formConfig: formGroupConfigInterface[], userId: string, requestId: string }[];
    someDocNeedSign: boolean;
    allRequestsNeedADelibera: boolean;
    someInfoNeeded: boolean;
    someDocNeeded: boolean;

    constructor(private fb: FormBuilder,
                private cicloConfigurationService: CicloConfigurationService,
                private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private appInitService: AppInitService,
                private logOutService: LogoutService,
                // requests specific controllers
                public speseStudentiCicloService: SpeseStudentiCicloService,
                public missioneStudentiCicloService: MissioneStudentiCicloService,
                public missioniService: MissioniService,
                @Inject(CHIAVE_FLUSSO$) protected chiaveFlusso: ChiaveFlussoEnum,
                @Inject(CHIAVE_OPERAZIONE$) protected chiaveOperazione: ChiaveOperazioneEnum,
                @Inject(GET_REQUEST_IDENTITY_FIELD_NAME$) protected getRequestIdentityFieldName: string,
                @Inject(DOWNLOAD_REQUEST_DOCUMENT$) protected downloadRequestDocument: (filename: string, idAllegato: string, request: any, opMassivaComp: any) => Observable<any>,
                @Inject(GET_REQUEST_ATTACHMENTS_FIELD_NAME$) protected getRequestAttachmentsFieldName: string,
                @Inject(GET_REQUEST_FORMGROUP$) protected getRequestFormGroup: (fb: FormBuilder, requestsSelected: any[], formArray: FormArray, request?: any) => FormGroup | undefined,
                @Inject(GET_REQUEST_TITLE$) protected getRequestTitle: (request?: any) => string,
                @Inject(REQUEST_FORM_COMPONENT$) protected requestFormComponent: ComponentType<any> | undefined,
                @Inject(GET_DATA_SERVICE$) protected getDataService$: (filters: any, size: number, page: number, opMassivaComp: CompilaInfoRichiesteComponent | ScegliRichiesteComponent, requestsId?: string[]) => Observable<any>,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<GenericOperationMassivaDataI>,) {
        super();
    }

    ngAfterViewInit() {
        this.formComponentsQL.changes
            ?.pipe(startWith(this.formComponentsQL))
            ?.subscribe((ql: QueryList<FormComponent>) => {
                this._docsFormComponents = ql.toArray();
                this.subscribeToDocsFormsValueChange();
            });
    }


    private subscribeToDocsFormsValueChange() {
        const someNotSignedDocExists = this.documentsFormValues
            ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
        this.canGoNext$.next(this._docsFormComponents?.every(fc => fc.formGroup?.valid && this.requestsDetailsFormArray.valid && !someNotSignedDocExists));
        this.docsFormComponentSubs?.forEach(fcs => fcs.unsubscribe());
        const documentiAllegati = [];
        this.docsFormComponentSubs = this._docsFormComponents?.map(fc =>
            (fc?.formGroup?.valueChanges
                ?.pipe(startWith(fc?.formGroup?.value), debounceTime(50))
                .subscribe(docsFormValue => {
                    const someNotSignedDocExists = this.documentsFormValues
                        ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
                    this.canGoNext$.next(this._docsFormComponents?.every(fc => fc.formGroup?.valid && this.requestsDetailsFormArray.valid && !someNotSignedDocExists));
                    const documents = this.cicloConfigurationService.prepareDocsSubmitObjectFromNuoveConfig(
                        this.chiaveFlusso,
                        this.chiaveOperazione,
                        docsFormValue,
                    );
                    let existingDocs = documentiAllegati?.find(d =>
                        d?.requestId === fc.formGroup?.value?.requestId
                        && d.userId === fc.formGroup?.value?.userId);
                    if(existingDocs){
                        existingDocs.documents = (documents ?? {});
                    } else {
                        documentiAllegati.push({
                            requestId: fc.formGroup?.value?.requestId,
                            userId: fc.formGroup?.value?.userId,
                            documents: (documents ?? {}),
                        });
                    }
                    this.operazioneMassivaData$.next({
                        ...this.operazioneMassivaData$?.getValue(),
                        requestsDocumentsValues: documentiAllegati?.map(da => ({
                            requestId: da?.requestId,
                            userId: da?.userId,
                            ...(da?.documents ?? {})
                        }))
                    });
                })));


    }


    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: GenericOperationMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshRequests){
                this.canGoNext$.next(false);
                this.requestsDetailsFormArray = undefined;
            }
            if(inputOutputData.requestsSelected !== this.requestsSelected){
                this.requestsSelected = inputOutputData?.requestsSelected;
                this._docsFormComponents?.forEach(fc => fc?.formGroup?.reset({emitEvent: false}));
                this.buildRequestDetailsFormGroup(this.requestsSelected);
                this.buildDocsFormGroup();
            }
        });
    }





    private buildDocsFormGroup() {
        this.documentsFormConfigs = this.requestsSelected?.map(request => ({
            formConfig: [
                {
                    show: false,
                    name: 'requestId',
                    transloco: 'requestId',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
                {
                    show: false,
                    name: 'userId',
                    transloco: 'userId',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
                ...this.cicloConfigurationService.getDocsFormConfigFromNuoveConfig(
                    this.chiaveFlusso,
                    this.chiaveOperazione,
                    (filename, idAllegato) => this.getRequestDocument(filename, idAllegato, request),
                    undefined,
                    request?.[this.getRequestAttachmentsFieldName],
                )
            ],
            userId: request?.studenteCiclo?.utente?.id ?? request?.budgetStudente?.studenteCiclo?.utente?.id,
            requestId: request?.[this.getRequestIdentityFieldName],
        }));
        this.allRequestsNeedADelibera = this.documentsFormConfigs?.every(c =>
            c.formConfig?.find(fc => fc.chiaveDocumento === ChiaveDocumentoEnum.DeliberaCollegio));
        this.someDocNeeded = !!this.documentsFormConfigs?.find(dfc => dfc.formConfig?.length > 2);
        this.documentsFormValues = this.requestsSelected?.map(request => ({
            requestId: request?.[this.getRequestIdentityFieldName],
            userId: request?.studenteCiclo?.utente?.id ?? request?.budgetStudente?.studenteCiclo?.utente?.id,
            ...this.cicloConfigurationService.getDocsFormValuesFromNuoveConfig(
                this.chiaveFlusso,
                this.chiaveOperazione,
                request?.[this.getRequestAttachmentsFieldName],
                undefined,
                request?.studenteCiclo?.utente?.id ?? request?.budgetStudente?.studenteCiclo?.utente?.id,
            )
        }));

        this.someDocNeedSign =
            !!this.documentsFormConfigs?.find(fc =>
                fc?.formConfig?.find(fci =>
                    fci.signModes?.filter(mode => mode !== ModalitaDiFirmaType.CARICAMENTOFILE)?.length > 0)
            ) && !!this.documentsFormValues?.find(fv =>
                Object.values(fv?.signableDocumentsData ?? {})
                    ?.find((docSignData: SignableDocumentsData) => !isDocumentSigned(docSignData))
            );
    }

    private getRequestDocument(nomeFile?: string, idAllegato?: string, request?: any) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.downloadRequestDocument(nomeFile, idAllegato, request, this)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                //const fileNameToDownload = makeFilename(fileName);
                openFileInBlankWindow(fileResponse?.body, fileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });
    }

    private buildRequestDetailsFormGroup(selectedRequests: any[]) {
        this.requestsDetailsFormArray = this.fb.array(selectedRequests?.map(request =>
            this.getRequestDetailsForm(request))?? []);
        this.someInfoNeeded = !!(this.requestsDetailsFormArray?.value as any[])?.find(fv => !isEmpty(fv.requestForm));
        // subscribe to form group change
        this.requestsDetailsFormArray.valueChanges?.pipe(startWith(this.requestsDetailsFormArray.value), debounceTime(50)).subscribe(fa => {
            console.log('details value changed', fa)
            const someNotSignedDocExists = this.documentsFormValues
                ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
            this.canGoNext$.next(this.requestsDetailsFormArray.valid && this._docsFormComponents?.every(fc => fc.formGroup?.valid) && !someNotSignedDocExists);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                requestsFormValues: fa?.map(value => ({
                    requestId: value.requestId,
                    userId: value.userId,
                    formValue: value.requestForm
                }))
            });
        })
    }

    private getRequestDetailsForm(request: any) {
        return this.fb.group({
            requestId: request?.[this.getRequestIdentityFieldName],
            userId: request?.studenteCiclo?.utente?.id ?? request?.budgetStudente?.studenteCiclo?.utente?.id,
            requestForm: this.getRequestFormGroup(this.fb, this.requestsSelected, this.requestsDetailsFormArray, request),
        });
    }

    clearDateField(fieldCtrl: AbstractControl) {
        fieldCtrl.setValue(undefined);
        fieldCtrl.setErrors(null);
    }


    getDocumentsFormValue(userId: string, requestId: string) {
        return this.documentsFormValues?.find(dfv => dfv.requestId === requestId && dfv.userId === userId);
    }

    getDocumentsFormConfig(userId: string, requestId: string) {
        return this.documentsFormConfigs?.find(dfc => dfc.requestId === requestId && dfc.userId === userId)?.formConfig;
    }

    protected readonly AuthorityType = AuthorityType;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;

    protected readonly ChiaveFlussoEnum = ChiaveFlussoEnum;
    protected readonly ChiaveOperazioneEnum = ChiaveOperazioneEnum;

    protected readonly isEmpty = isEmpty;

    // selectDeliberaForAllRequest() {
    //
    // }
    protected readonly ButtonType = ButtonType;


    someDocumentSignedSuccessfully() {
        this.fuseConfirmationService.showLoader();
        this.getDataService$({}, 0, 0, this, this.requestsSelected?.map(r => r?.[this.getRequestIdentityFieldName]))
            .pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                        this.fuseConfirmationService.hideLoader();
                    }
                )
        ).subscribe({
            next: (requestsSelectedPage: any) => {
                // replacing selected requests
                this.operazioneMassivaData$.next({
                    ...this.operazioneMassivaData$?.getValue(),
                    requestsSelected: requestsSelectedPage.content,
                    refreshPage: true,
                })
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => { this.logOutService.goToHome() },
                    () => this.someDocumentSignedSuccessfully(),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });

    }
}
