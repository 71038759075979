<ng-container *transloco="let t">
    <app-dialog-wrap class="w-full" [data]="data">
        <mat-dialog-content>

            <div class="flex flex-col gap-1 w-full" *ngIf="data.documentSections && !data.allegatiKey">
                <ng-container *ngFor="let documentsSection of data.documentSections">
                    <ng-container *ngIf="data.requestData[documentsSection.requestAttachmentsListKey] as docs">
                        <div class="flex flex-col w-full items-center justify-center justify-items-center" *ngIf="docs.length > 0">
                            <div class="w-full text-center text-lg">{{t(documentsSection.sectionTitle)}}</div>
                            <div class="mt-1 w-full mb-2 flex flex-wrap rounded-2xl bg-slate-50">
                                <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-4 m-4 mb-4">
                                    <app-input-value-key *ngFor="let doc of docs"
                                                         [label]="getLabelForDoc(documentsSection.operation, doc.documentoInfo?.chiave)"
                                                         [labelClasses]="'text-sm'"
                                                         [showVisualizzaButton]="(appInitService.cicloCorsoRuoloSelected$ | async)?.ruolo !== AuthorityType.STUDENTE
                                                                                        || ![DocumentoType.DELIBERA, DocumentoType.NOTADELCOORDINATORE].includes(doc.documentoInfo?.tipo)"
                                                         [useLargerLabel]="true"
                                                         (onDownloadButtonPressed)="data.downloadDocumentFn(doc?.id, doc?.url, doc.deliberaCollegioDocenti)"
                                                         [value]="((appInitService.cicloCorsoRuoloSelected$ | async)?.ruolo !== AuthorityType.STUDENTE
                                                                     || ![DocumentoType.DELIBERA, DocumentoType.NOTADELCOORDINATORE].includes(doc.documentoInfo?.tipo)) ? (doc?.url | documentNameFromUrl) : ''">
                                        <!--signed doc data-->
                                        <div class="flex flex-row justify-start" *ngIf="isTargetDocumentSigned(doc)">
                                            <div class="flex flex-row p-2 pr-4 bg-slate-200 rounded-xl items-center gap-2 text-sm">
                                                <mat-icon class="text-slate-700">verified</mat-icon>
                                                <div class="flex flex-row min-w-36 text-start items-center gap-1">
                                                    <ng-container *ngIf="doc?.documentoInfo?.utenteFirmatarioDocumentoPadre as utenteFirmatario">
                                                        <span class="text-start">
                                                            {{t(getSignedWithFromKey(doc?.documentoInfo?.modalitaDiFirmaDocumentoPadre))}}
                                                        </span>
                                                        <span class="font-semibold text-start">
                                                            {{utenteFirmatario.cognome | uppercase}} {{utenteFirmatario.nome | uppercase}}
                                                        </span>
                                                    </ng-container>
                                                    <span class="text-start" *ngIf="doc?.documentoInfo?.dataFirmaDocumentoPadre as dataFirma">
                                                        {{t('document_sign.document_signed_on')}} {{dataFirma | date: 'dd/MM/yyyy HH:mm': 'UTC+2'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </app-input-value-key>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </ng-container>
                <ng-container *ngIf="!documentExists">
                    <div class="text-secondary w-full text-center italic text-lg py-6">{{t('common.no_attachment')}}</div>
                </ng-container>
            </div>


            <!-- new mode -->
            <div class="flex flex-col gap-1 w-full" *ngIf="data.allegatiKey">
                <ng-container *ngIf="data.requestData[data.allegatiKey] as docs">
                    <ng-container *ngFor="let operation of executableOperationsOfFlow">
                        <ng-container *ngIf="getDocsForOp(operation.chiave_operazione, docs) as opDocs">
                            <div class="flex flex-col w-full items-center justify-center justify-items-center" *ngIf="opDocs.length > 0">
                            <div class="w-full text-center text-lg">{{operation | operationDescriptionLabel}}</div>
                            <div class="mt-1 w-full mb-2 flex flex-wrap rounded-2xl bg-slate-50">
                                <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-4 m-4 mb-4">
                                    <app-input-value-key *ngFor="let doc of opDocs"
                                                         [label]="getLabelForDoc(operation.chiave_operazione, doc.documentoInfo?.chiave)"
                                                         [labelClasses]="'text-sm'"
                                                         [showVisualizzaButton]="(appInitService.cicloCorsoRuoloSelected$ | async)?.ruolo !== AuthorityType.STUDENTE
                                                                                        || ![DocumentoType.DELIBERA, DocumentoType.NOTADELCOORDINATORE].includes(doc.documentoInfo?.tipo)"
                                                         [useLargerLabel]="true"
                                                         (onDownloadButtonPressed)="data.downloadDocumentFn(doc?.id, doc?.url, doc.deliberaCollegioDocenti)"
                                                         [value]="((appInitService.cicloCorsoRuoloSelected$ | async)?.ruolo !== AuthorityType.STUDENTE
                                                                     || ![DocumentoType.DELIBERA, DocumentoType.NOTADELCOORDINATORE].includes(doc.documentoInfo?.tipo)) ? (doc?.url | documentNameFromUrl) : ''">
                                        <!--signed doc data-->
                                        <div class="flex flex-row justify-start" *ngIf="isTargetDocumentSigned(doc)">
                                            <div class="flex flex-row p-2 pr-4 bg-slate-200 rounded-xl items-center gap-2 text-sm">
                                                <mat-icon class="text-slate-700">verified</mat-icon>
                                                <div class="flex flex-row min-w-36 text-start items-center gap-1">
                                                    <ng-container *ngIf="doc?.documentoInfo?.utenteFirmatarioDocumentoPadre as utenteFirmatario">
                                                        <span class="text-start">
                                                            {{t(getSignedWithFromKey(doc?.documentoInfo?.modalitaDiFirmaDocumentoPadre))}}
                                                        </span>
                                                        <span class="font-semibold text-start">
                                                            {{utenteFirmatario.cognome | uppercase}} {{utenteFirmatario.nome | uppercase}}
                                                        </span>
                                                    </ng-container>
                                                    <span class="text-start" *ngIf="doc?.documentoInfo?.dataFirmaDocumentoPadre as dataFirma">
                                                        {{t('document_sign.document_signed_on')}} {{dataFirma | date: 'dd/MM/yyyy HH:mm': 'UTC+2'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </app-input-value-key>
                                </div>
                            </div>
                        </div>
                        </ng-container>
                    </ng-container>

                </ng-container>
                <ng-container *ngIf="!documentExists">
                    <div class="text-secondary w-full text-center italic text-lg py-6">{{t('common.no_attachment')}}</div>
                </ng-container>
            </div>


        </mat-dialog-content>
    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
    </div>

</ng-container>
