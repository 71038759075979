// eslint-disable-next-line max-len
import {
    AnnoRiferimentoValues,
    AuthorityType, ChiaveFlussoEnum, DeliberaInfoView, FunzionalitaStatus,
    SpesaStudenteInfoView,
    SpesaStudenteInfoViewImpl, SpesaStudentePerApprovMassivaInfoView,
    SpesaStudenteStatus, SpesaStudenteType, SpeseStudentiCicloService, StudenteCicloInfoView, TipoLavoroSvolto,
    TipoModificaCorsoPianoDiStudi
} from '../../../../../api-clients/generated/services';
import {
    ButtonInterface,
    GenericTableConfigurationModel, TipoClickEnum,
    TipoColonnaEnum
} from '../../../../shared/components/table/model/generic-table-model';
import {get, isEmpty, orderBy} from 'lodash';
import moment from "moment/moment";
import {getCosupPermessoApprovazione, openFileInBlankWindow} from "../../../../shared/utils/utils";
import {CicloConfigurationService} from "../../../../shared/service/ciclo-configuration.service";
import {BehaviorSubject, finalize, of, Subject, takeUntil} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {SnackbarTypes} from "../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";

const addColumnPtaBySubrole = (config: GenericTableConfigurationModel,
                               currentSottoruolo: AuthorityType,
                               currentRuolo: AuthorityType,
                               cicloConfigurationService: CicloConfigurationService,
                               currentUserId: string,
                               student: StudenteCicloInfoView) => {
    switch (currentSottoruolo) {
        case AuthorityType.GRUPPOPTA:
        case AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO:
            config.configuration.configurazioneTabella.push({
                nomeColonna: ' ',
                colonnaKey: 'action',
                flex: 8,
                tipo: TipoColonnaEnum.ACTION_MENU,
                valueCheck: 'stato',
                dynamicButton: (richiesta: SpesaStudenteInfoViewImpl) => [
                    {
                        nameIconButton: 'description',
                        click: TipoClickEnum.SHOW_DETAILS,
                        tooltip: 'budget.show_spesa_details',
                        color: 'accent',
                    },
                    // {
                    //     nameIconButton: 'done',
                    //     click: TipoClickEnum.APPROVE_PTA,
                    //     tooltip: 'budget.approve',
                    //     color: 'accent',
                    //     show: (status: SpesaStudenteStatus, spesa: SpesaStudenteInfoView) => status === SpesaStudenteStatus.APPROVATO,
                    //     valueShow: 'stato'
                    // },
                    // {
                    //     nameIconButton: 'close',
                    //     click: TipoClickEnum.REFUSE_PTA,
                    //     tooltip: 'budget.refuse',
                    //     color: 'warn',
                    //     show: (status: SpesaStudenteStatus, spesa: SpesaStudenteInfoView) => status === SpesaStudenteStatus.APPROVATO,
                    //     valueShow: 'stato'
                    // },
                    {
                        nameIconButton: 'visibility',
                        click: TipoClickEnum.SHOW_REQUEST_ATTACHMENTS,
                        tooltip: 'mobility.show_request_attachments',
                        color: 'accent',
                        valueShow: 'stato'
                    },
                    ...cicloConfigurationService.buildButtonOpConfigForFlussoAndUser(
                        richiesta?.dettaglioLiquidazioneMissione ? ChiaveFlussoEnum.RichiestaSpesaMissione : ChiaveFlussoEnum.RichiestaAcquisto,
                        undefined,
                        currentUserId,
                        currentRuolo,
                        currentSottoruolo,
                        student,
                    ),
                ]
            });
    }

}

const addColumnDocenteBySubRole = (config: GenericTableConfigurationModel,
                                   currentSottoruolo: AuthorityType,
                                   currentRuolo: AuthorityType,
                                   cicloConfigurationService: CicloConfigurationService,
                                   currentUserId: string,
                                   student: StudenteCicloInfoView) => {
     switch (currentSottoruolo) {
         case AuthorityType.SUPERVISORE:
         case AuthorityType.COSUPERVISORE:
             config.configuration.configurazioneTabella.push({
                 nomeColonna: ' ',
                 colonnaKey: 'action',
                 flex: 8,
                 tipo: TipoColonnaEnum.ACTION_MENU,
                 dynamicButton: (richiesta: SpesaStudenteInfoViewImpl) => [
                     {
                         nameIconButton: 'description',
                         click: TipoClickEnum.SHOW_DETAILS,
                         tooltip: 'budget.show_spesa_details',
                         color: 'accent',
                     },
                     // {
                     //     nameIconButton: 'done',
                     //     click: TipoClickEnum.APPROVE_SUPERVISOR_CO_SUPERVISOR,
                     //     tooltip: 'budget.approve',
                     //     color: 'accent',
                     //     show: (status: SpesaStudenteStatus, spesa: SpesaStudenteInfoView) => (status === SpesaStudenteStatus.DAAPPROVARE &&
                     //         (currentSottoruolo === AuthorityType.SUPERVISORE ||
                     //             (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(studente, currentUserId)) && !spesa.dettaglioLiquidazioneMissione))
                     //         && !spesa.dettaglioLiquidazioneMissione,
                     //     valueShow: 'stato'
                     // },
                     // {
                     //     nameIconButton: 'close',
                     //     click: TipoClickEnum.REFUSE_SUPERVISOR_CO_SUPERVISOR,
                     //     tooltip: 'budget.refuse',
                     //     color: 'warn',
                     //     show: (status: SpesaStudenteStatus, spesa: SpesaStudenteInfoView) => status === SpesaStudenteStatus.DAAPPROVARE && (currentSottoruolo === AuthorityType.SUPERVISORE ||
                     //         (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(studente, currentUserId))) && !spesa.dettaglioLiquidazioneMissione,
                     //     valueShow: 'stato'
                     // },
                     {
                         nameIconButton: 'visibility',
                         click: TipoClickEnum.SHOW_REQUEST_ATTACHMENTS,
                         tooltip: 'mobility.show_request_attachments',
                         color: 'accent',
                         valueShow: 'stato'
                     },
                     ...cicloConfigurationService.buildButtonOpConfigForFlussoAndUser(
                         richiesta?.dettaglioLiquidazioneMissione ? ChiaveFlussoEnum.RichiestaSpesaMissione : ChiaveFlussoEnum.RichiestaAcquisto,
                         undefined,
                         currentUserId,
                         currentRuolo,
                         currentSottoruolo,
                         student,
                     ),
                 ]
             });
             break;
         case AuthorityType.COORDINATORE:
             config.configuration.configurazioneTabella.push({
                 nomeColonna: ' ',
                 colonnaKey: 'action',
                 flex: 8,
                 tipo: TipoColonnaEnum.ACTION_MENU,
                 dynamicButton: (richiesta: SpesaStudenteInfoViewImpl) => [
                     {
                         nameIconButton: 'description',
                         click: TipoClickEnum.SHOW_DETAILS,
                         tooltip: 'budget.show_spesa_details',
                         color: 'accent',
                     },
                     // {
                     //     nameIconButton: 'done',
                     //     click: TipoClickEnum.APPROVE_COORDINATOR,
                     //     tooltip: 'budget.approve',
                     //     color: 'accent',
                     //     show: (status: SpesaStudenteStatus, spesa: SpesaStudenteInfoView) => status === SpesaStudenteStatus.APPROVATOPARZIALE && !spesa.dettaglioLiquidazioneMissione,
                     //     valueShow: 'stato'
                     // },
                     // {
                     //     nameIconButton: 'close',
                     //     click: TipoClickEnum.REFUSE_COORDINATOR,
                     //     tooltip: 'budget.refuse',
                     //     color: 'warn',
                     //     show: (status: SpesaStudenteStatus, spesa: SpesaStudenteInfoView) => status === SpesaStudenteStatus.APPROVATOPARZIALE && !spesa.dettaglioLiquidazioneMissione,
                     //     valueShow: 'stato'
                     // },
                     {
                         nameIconButton: 'visibility',
                         click: TipoClickEnum.SHOW_REQUEST_ATTACHMENTS,
                         tooltip: 'mobility.show_request_attachments',
                         color: 'accent',
                         valueShow: 'stato'
                     },
                     ...cicloConfigurationService.buildButtonOpConfigForFlussoAndUser(
                         richiesta?.dettaglioLiquidazioneMissione ? ChiaveFlussoEnum.RichiestaSpesaMissione : ChiaveFlussoEnum.RichiestaAcquisto,
                         undefined,
                         currentUserId,
                         currentRuolo,
                         currentSottoruolo,
                         student,
                     ),
                 ]
             });
             break;

         case AuthorityType.GRUPPODICOORDINAMENTO:
         case AuthorityType.DIRETTOREDIDIPARTIMENTO:
             config.configuration.configurazioneTabella.push({
                 nomeColonna: ' ',
                 colonnaKey: 'action',
                 flex: 8,
                 tipo: TipoColonnaEnum.ACTION_MENU,
                 valueCheck: 'stato',
                 dynamicButton: (richiesta: SpesaStudenteInfoViewImpl) => [
                     {
                         nameIconButton: 'description',
                         click: TipoClickEnum.SHOW_DETAILS,
                         tooltip: 'budget.show_spesa_details',
                         color: 'accent',
                     },
                     {
                         nameIconButton: 'visibility',
                         click: TipoClickEnum.SHOW_REQUEST_ATTACHMENTS,
                         tooltip: 'mobility.show_request_attachments',
                         color: 'accent',
                         valueShow: 'stato'
                     },
                     ...cicloConfigurationService.buildButtonOpConfigForFlussoAndUser(
                         richiesta?.dettaglioLiquidazioneMissione ? ChiaveFlussoEnum.RichiestaSpesaMissione : ChiaveFlussoEnum.RichiestaAcquisto,
                         undefined,
                         currentUserId,
                         currentRuolo,
                         currentSottoruolo,
                         student,
                     ),
                 ]
             });
             break;
     }
}

const addColumnEsternoBySubRole = (config: GenericTableConfigurationModel,
                                   currentSottoruolo: AuthorityType,
                                   currentRuolo: AuthorityType,
                                   cicloConfigurationService: CicloConfigurationService,
                                   currentUserId: string,
                                   student: StudenteCicloInfoView) => {
    switch (currentSottoruolo) {
        case AuthorityType.SUPERVISORE:
        case AuthorityType.COSUPERVISORE:
            config.configuration.configurazioneTabella.push({
                nomeColonna: ' ',
                colonnaKey: 'action',
                flex: 8,
                tipo: TipoColonnaEnum.ACTION_MENU,
                valueCheck: 'stato',
                dynamicButton: (richiesta: SpesaStudenteInfoViewImpl) => [
                    {
                        nameIconButton: 'description',
                        click: TipoClickEnum.SHOW_DETAILS,
                        tooltip: 'budget.show_spesa_details',
                        color: 'accent',
                    },
                    // {
                    //     nameIconButton: 'done',
                    //     click: TipoClickEnum.APPROVE_SUPERVISOR_CO_SUPERVISOR,
                    //     tooltip: 'budget.approve',
                    //     color: 'accent',
                    //     show: (status: SpesaStudenteStatus) => status === SpesaStudenteStatus.DAAPPROVARE && (currentSottoruolo === AuthorityType.SUPERVISORE ||
                    //         (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(studente, currentUserId))),
                    //     valueShow: 'stato'
                    // },
                    // {
                    //     nameIconButton: 'close',
                    //     click: TipoClickEnum.REFUSE_SUPERVISOR_CO_SUPERVISOR,
                    //     tooltip: 'budget.refuse',
                    //     color: 'warn',
                    //     show: (status: SpesaStudenteStatus) => status === SpesaStudenteStatus.DAAPPROVARE && (currentSottoruolo === AuthorityType.SUPERVISORE ||
                    //         (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(studente, currentUserId))),
                    //     valueShow: 'stato'
                    // },
                    {
                        nameIconButton: 'visibility',
                        click: TipoClickEnum.SHOW_REQUEST_ATTACHMENTS,
                        tooltip: 'mobility.show_request_attachments',
                        color: 'accent',
                        valueShow: 'stato'
                    },
                    ...cicloConfigurationService.buildButtonOpConfigForFlussoAndUser(
                        richiesta?.dettaglioLiquidazioneMissione ? ChiaveFlussoEnum.RichiestaSpesaMissione : ChiaveFlussoEnum.RichiestaAcquisto,
                        undefined,
                        currentUserId,
                        currentRuolo,
                        currentSottoruolo,
                        student,
                    ),
                ]
            });
            break;
    }
}


export function buildConfigurationForSpese(spese: Array<SpesaStudenteInfoViewImpl>,
                                           studente: StudenteCicloInfoView,
                                           currentUserId: string,
                                           annoInsegnamentoInput: AnnoRiferimentoValues,
                                           currentRuolo: AuthorityType,
                                           currentSottoruolo: AuthorityType,
                                           cicloConfigurationService: CicloConfigurationService,
                                           isAreaAmministrazione?: boolean,
): GenericTableConfigurationModel {
    let speseForThisYear: any[] = spese.filter(s => s.annoRiferimento === annoInsegnamentoInput)
        ?.map(spesa => ({
        ...spesa,
        dataRichiestaFormatted: moment(spesa?.dataRichiesta).format('DD/MM/YYYY'),
    }));
    speseForThisYear = orderBy(
        speseForThisYear,
            spesa => spesa.dataRichiesta,
        'desc'
    );
    let config: GenericTableConfigurationModel = {
        configuration: {
            data: speseForThisYear,
            totalElements: speseForThisYear?.length,
            isPaginatedBE: false,
            sticky: true,
            pageSize: speseForThisYear?.length,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.request_date',
                    colonnaKey: 'dataRichiestaFormatted',
                    flex: 9,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.description',
                    colonnaKey: 'descrizione',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.codice_spesa',
                    colonnaKey: 'codiceSpesa',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.type',
                    colonnaKey: 'tipologia',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'budget.req_amount',
                    colonnaKey: 'importoRichiesto',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'budget.appr_amount',
                    colonnaKey: 'importoApprovato',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS_FROM_CONFIG,
                    nomeColonna: 'budget.state',
                    colonnaKey: 'stato',
                    flex: 18,
                    statusTypeChiaveFlussoFn: (element: SpesaStudenteInfoViewImpl) => !!element.dettaglioLiquidazioneMissione ? ChiaveFlussoEnum.RichiestaSpesaMissione : ChiaveFlussoEnum.RichiestaAcquisto,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 8,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    dynamicButton: (richiesta: SpesaStudenteInfoViewImpl) =>  {
                        const btn = [
                            {
                                nameIconButton: 'description',
                                click: TipoClickEnum.SHOW_DETAILS,
                                tooltip: 'budget.show_spesa_details',
                                color: 'accent',
                            },
                            {
                                nameIconButton: 'visibility',
                                click: TipoClickEnum.SHOW_REQUEST_ATTACHMENTS,
                                tooltip: 'mobility.show_request_attachments',
                                color: 'accent',
                                valueShow: 'stato'
                            },
                            ...cicloConfigurationService.buildButtonOpConfigForFlussoAndUser(
                                richiesta?.dettaglioLiquidazioneMissione ? ChiaveFlussoEnum.RichiestaSpesaMissione : ChiaveFlussoEnum.RichiestaAcquisto,
                                undefined,
                                currentUserId,
                                currentRuolo,
                                currentSottoruolo,
                                studente,
                            ),
                        ] as ButtonInterface[];
                        return btn;
                    }
                }
            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_budget'
        }
    };
    return config;
}

export function  downloadSpesaDocument(idAllegato: string,
                                       richiesta: SpesaStudentePerApprovMassivaInfoView,
                                       fuseConfirmationService: FuseConfirmationService,
                                       translocoService: TranslocoService,
                                       speseStudentiService: SpeseStudentiCicloService,
                                       destroy$: Subject<boolean>,
                                       delibera?: DeliberaInfoView,
                                       idUtente?: string) {
    if (delibera) {
        fuseConfirmationService.openDialogDelibera({
            delibera: delibera,
            codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
        });
    } else {
        const activeLang = translocoService.getActiveLang();
        const translation = translocoService.getTranslation().get(activeLang);
        fuseConfirmationService.showLoader();
        speseStudentiService.getAllegatoSpesaStudente(richiesta.codiceSpesa, idAllegato, richiesta?.budgetStudente?.studenteCiclo?.utente?.id ?? idUtente, 'response').pipe(
            takeUntil(destroy$),
            finalize(() => {
                fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                //const fileNameToDownload = makeFilename(fileName);
                openFileInBlankWindow(fileResponse?.body, fileName);
                fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });
    }
}

export function sortTipologieSpesa(types: SpesaStudenteType[]) {
    const typesWithoutAltro = types?.filter(type => type !== SpesaStudenteType.ALTRO);
    typesWithoutAltro.sort();
    return [...typesWithoutAltro, SpesaStudenteType.ALTRO];
}
