<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.superamento_extra.hint_compile_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center">

            <div class="w-full flex flex-col items-center mb-2">

                <div class="w-full flex flex-col items-center" >

                    <!-- TABELLA ATTIVITA -->

                    <div class="flex flex-col gap-4 w-full mb-2" *ngIf="extraCompileInfos">
                        <ng-container *ngFor="let attivita of extraCompileInfos; let i = index" class="w-full">

                            <div class="flex flex-col gap-3 bg-gray-100 rounded-xl px-4 pb-4 pt-2"
                                 *ngIf="getFormGroupByAttId(attivita.id, attivita.idCategoria) as formGroup">
                                <div class="mt-1 w-full flex flex-col items-center gap-1">
                                    <span class="font-semibold">
                                        {{attivita?.denominazione}}
                                    </span>
                                    <span>
                                        {{attivita?.studenteUIMapped?.utenteCognome + ' ' + attivita?.studenteUIMapped?.utenteNome}}
                                    </span>
                                </div>

                                <div class="flex flex-col pt-2 pb-1 gap-2 w-full" [formGroup]="formGroup">

                                    <!-- CFU -->
                                    <mat-form-field appearance="outline" class="w-full add-course-mat-form-field"
                                                    *ngIf="formGroup?.get('cfu') as fieldCtrl">
                                        <mat-label><strong>{{t(cicloConfigurationService.activityUnitOfMeasureLabel)}}</strong></mat-label>
                                        <input matInput
                                               required
                                               appOnlyDecimalNumbers
                                               type="number"
                                               [min]="0.1"
                                               [numOfDecimal]="1"
                                               [formControlName]="'cfu'">
                                        <mat-error  *ngIf="fieldCtrl.hasError('required') && (formGroup?.dirty || formGroup?.touched)" class="pb-2">
                                            {{t('form.required')}}
                                        </mat-error>
                                        <mat-error  *ngIf="fieldCtrl.hasError('min') && (formGroup?.dirty || formGroup?.touched)" class="pb-2">
                                            {{t('form.must_be_greater_than_zero')}}
                                        </mat-error>
                                    </mat-form-field>

                                    <!-- DATA -->
                                    <mat-form-field class="w-full mat-button-datapicker add-mobilita-mat-form-field" appearance="outline"
                                                    *ngIf="formGroup?.get('data') as fieldCtrl">
                                        <mat-label>{{t('student.activity_executed_date')}}</mat-label>
                                        <input matInput
                                               [placeholder]="t('student.activity_executed_date')"
                                               required
                                               readonly
                                               [max]="formGroup?.get('data')?.value"
                                               formControlName='data'
                                               [matDatepicker]="picker1"
                                        >
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                        <mat-error *ngIf="fieldCtrl.hasError('required')">
                                            {{t('form.required')}}
                                        </mat-error>
                                    </mat-form-field>

                                    <!-- NOTA -->
                                    <mat-form-field appearance="outline" class="w-full add-course-mat-form-field"
                                                    *ngIf="formGroup?.get('nota') as fieldCtrl">
                                        <mat-label><strong>{{t('student.activity_executed_note')}}</strong></mat-label>
                                        <input matInput
                                               type="text"
                                               [formControlName]="'nota'">
                                    </mat-form-field>

                                    <!-- FILE -->
                                    <ng-container>
                                        <div *ngIf="formGroup.get('file') as formControlFile">
                                            <app-input-upload class="w-full"
                                                              [labelNoTranslate]="t('common.supervisor_document')"
                                                              [form]="formControlFile"
                                                              (downloadEmitter)="downloadFile(formControlFile.value, attivita.id)"
                                                              (uploadImgEmitter)="uploadFile($event, attivita.id)"
                                                              (deleteEmitter)="deleteFile(attivita.id)"
                                                              [forceAcceptedFiles]="true"
                                                              [sizeMB]="optionFile.sizeMb"
                                                              [customFileLabel]="t('common.file_selected')"
                                                              [customNotSupportedFileMessageLabel]="'common.no_file_selected'">
                                            </app-input-upload>
                                        </div>
                                    </ng-container>

                                </div>

                            </div>


                        </ng-container>

                    </div>


                </div>


            </div>

        </div>



    </ng-template>



</ng-container>
