/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AnnoRiferimentoValues } from '../model/annoRiferimentoValues';
import { EditStatoRegistrazioneSuperamentoAnno } from '../model/editStatoRegistrazioneSuperamentoAnno';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { PageSuperamentoAnnoStudenteAndStudenteInfoView } from '../model/pageSuperamentoAnnoStudenteAndStudenteInfoView';
import { SuperamentoAnnoRegistrazioneStatus } from '../model/superamentoAnnoRegistrazioneStatus';
import { SuperamentoAnnoStudenteAndStudenteInfoView } from '../model/superamentoAnnoStudenteAndStudenteInfoView';
import { SuperamentoAnnoStudenteStatus } from '../model/superamentoAnnoStudenteStatus';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SuperamentiAnnoService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette di modificare il valore del flag che indica se il superamento d&#x27;anno è stato registrato nei sistemi di ateneo.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editStatoRegistrazione(body: EditStatoRegistrazioneSuperamentoAnno, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteAndStudenteInfoView>>;
    public editStatoRegistrazione(body: EditStatoRegistrazioneSuperamentoAnno, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteAndStudenteInfoView>>>;
    public editStatoRegistrazione(body: EditStatoRegistrazioneSuperamentoAnno, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteAndStudenteInfoView>>>;
    public editStatoRegistrazione(body: EditStatoRegistrazioneSuperamentoAnno, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling editStatoRegistrazione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SuperamentoAnnoStudenteAndStudenteInfoView>>('put',`${this.basePath}/api/v1/superamento-anno/edit-stato-registrazione`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i superamenti degli studenti con i filtri applicati.
     * 
     * @param annoRiferimento 
     * @param codiceFiscaleStudente 
     * @param corsoDiStudiCodiceEsse3 
     * @param hasPermessiApprovativiCosupervisore 
     * @param matricolaStudente 
     * @param nominativoStudente 
     * @param statiSuperamentoAnno 
     * @param statoRegistrazione 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSuperamentiAnnoStudentiForm(annoRiferimento?: AnnoRiferimentoValues, codiceFiscaleStudente?: string, corsoDiStudiCodiceEsse3?: string, hasPermessiApprovativiCosupervisore?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiSuperamentoAnno?: Array<SuperamentoAnnoStudenteStatus>, statoRegistrazione?: SuperamentoAnnoRegistrazioneStatus, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageSuperamentoAnnoStudenteAndStudenteInfoView>;
    public searchSuperamentiAnnoStudentiForm(annoRiferimento?: AnnoRiferimentoValues, codiceFiscaleStudente?: string, corsoDiStudiCodiceEsse3?: string, hasPermessiApprovativiCosupervisore?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiSuperamentoAnno?: Array<SuperamentoAnnoStudenteStatus>, statoRegistrazione?: SuperamentoAnnoRegistrazioneStatus, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSuperamentoAnnoStudenteAndStudenteInfoView>>;
    public searchSuperamentiAnnoStudentiForm(annoRiferimento?: AnnoRiferimentoValues, codiceFiscaleStudente?: string, corsoDiStudiCodiceEsse3?: string, hasPermessiApprovativiCosupervisore?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiSuperamentoAnno?: Array<SuperamentoAnnoStudenteStatus>, statoRegistrazione?: SuperamentoAnnoRegistrazioneStatus, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSuperamentoAnnoStudenteAndStudenteInfoView>>;
    public searchSuperamentiAnnoStudentiForm(annoRiferimento?: AnnoRiferimentoValues, codiceFiscaleStudente?: string, corsoDiStudiCodiceEsse3?: string, hasPermessiApprovativiCosupervisore?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiSuperamentoAnno?: Array<SuperamentoAnnoStudenteStatus>, statoRegistrazione?: SuperamentoAnnoRegistrazioneStatus, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (annoRiferimento !== undefined) {
            formParams = formParams.append('annoRiferimento', <any>annoRiferimento) as any || formParams;
        }
        if (codiceFiscaleStudente !== undefined) {
            formParams = formParams.append('codiceFiscaleStudente', <any>codiceFiscaleStudente) as any || formParams;
        }
        if (corsoDiStudiCodiceEsse3 !== undefined) {
            formParams = formParams.append('corsoDiStudiCodiceEsse3', <any>corsoDiStudiCodiceEsse3) as any || formParams;
        }
        if (hasPermessiApprovativiCosupervisore !== undefined) {
            formParams = formParams.append('hasPermessiApprovativiCosupervisore', <any>hasPermessiApprovativiCosupervisore) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }
        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (statiSuperamentoAnno) {
            statiSuperamentoAnno.forEach((element) => {
                formParams = formParams.append('statiSuperamentoAnno', <any>element) as any || formParams;
            })
        }
        if (statoRegistrazione !== undefined) {
            formParams = formParams.append('statoRegistrazione', <any>statoRegistrazione) as any || formParams;
        }

        return this.httpClient.request<PageSuperamentoAnnoStudenteAndStudenteInfoView>('post',`${this.basePath}/api/v1/superamento-anno/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
