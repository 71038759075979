/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di una richiesta di spesa di uno studente. Valori previsti: *DA_APPROVARE*, *APPROVATO_PARZIALE*, *APPROVATO*, *APPROVATO_PTA*, *RIFIUTATO*
 */
export type SpesaStudenteStatus = 'DA_APPROVARE' | 'APPROVATO_PARZIALE' | 'APPROVATO' | 'AUTORIZZATO_DIRETTORE_DI_DIPARTIMENTO' | 'APPROVATO_PTA' | 'VALIDATO_DIRETTORE_DI_DIPARTIMENTO' | 'RIFIUTATO';

export const SpesaStudenteStatus = {
    DAAPPROVARE: 'DA_APPROVARE' as SpesaStudenteStatus,
    APPROVATOPARZIALE: 'APPROVATO_PARZIALE' as SpesaStudenteStatus,
    APPROVATO: 'APPROVATO' as SpesaStudenteStatus,
    AUTORIZZATODIRETTOREDIDIPARTIMENTO: 'AUTORIZZATO_DIRETTORE_DI_DIPARTIMENTO' as SpesaStudenteStatus,
    APPROVATOPTA: 'APPROVATO_PTA' as SpesaStudenteStatus,
    VALIDATODIRETTOREDIDIPARTIMENTO: 'VALIDATO_DIRETTORE_DI_DIPARTIMENTO' as SpesaStudenteStatus,
    RIFIUTATO: 'RIFIUTATO' as SpesaStudenteStatus
};