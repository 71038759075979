import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    AnnoRiferimentoValues,
    SpesaStudentePerApprovMassivaInfoView
} from "../../../../../../api-clients/generated/services";
import {REQUEST_FORM_COMPONENT_DATA} from "../../generic-operation-steps/dynamic-host-component/dynamic-host.component";
import {max} from "lodash";
import {round} from "lodash-es";
import {CustomValidators} from "../../../../../shared/validators/custom-validators";
import {
    getRipartizioneImportoSpesaPta,
    getSumImportiForOtherRequestsOfSameStudentSpesaPta, setEnableStatesSpesaPta
} from "../../approvazione-spesa-pta-steps/approvazione-spesa-pta-form/approvazione-spesa-pta-form.component";

@Component({
    selector: 'app-approvazione-spesa-form',
    templateUrl: './approvazione-spesa-form.component.html',
    styleUrls: ['./approvazione-spesa-form.component.scss']
})
export class ApprovazioneSpesaFormComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() request: SpesaStudentePerApprovMassivaInfoView;

    budgetResiduo: number;

    constructor(@Optional() @Inject(REQUEST_FORM_COMPONENT_DATA) public injectedData:
                {  form: FormGroup, request: SpesaStudentePerApprovMassivaInfoView }) {
        this.form = this.form || this.injectedData?.form;
        this.request = this.request || this.injectedData?.request;

    }

    ngOnInit(): void {
        this.budgetResiduo = this.request.annoRiferimento === AnnoRiferimentoValues.PRIMO ? this.request.budgetStudente.budgetResiduoPrimoAnno :
            (this.request.annoRiferimento === AnnoRiferimentoValues.SECONDO ? this.request.budgetStudente.budgetSecondoAnno :
                this.request.budgetStudente?.budgetResiduoTerzoAnno);

    }

}

export function getRequestFormGroupForApprovazioneSpesa(fb: FormBuilder, request: SpesaStudentePerApprovMassivaInfoView, requestsSelected: SpesaStudentePerApprovMassivaInfoView[], formArray: FormArray) {
    // const maxResidualBudget = request.annoRiferimento === AnnoRiferimentoValues.PRIMO ? request.budgetStudente.budgetResiduoPrimoAnno :
    //     (request.annoRiferimento === AnnoRiferimentoValues.SECONDO ? request.budgetStudente.budgetSecondoAnno :
    //         request.budgetStudente?.budgetResiduoTerzoAnno);
    return fb.group({
        importoApprovato: [request.importoRichiesto, [Validators.min(0.1), /*Validators.max(maxResidualBudget)*/ Validators.required]]
    });
}
