/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AnnoRiferimentoValues } from '../model/annoRiferimentoValues';
import { ChiaveDocumentoEnum } from '../model/chiaveDocumentoEnum';
import { DettagliDocumentiAllegatiRequestDTO } from '../model/dettagliDocumentiAllegatiRequestDTO';
import { EsitoRichiestaApprovazione } from '../model/esitoRichiestaApprovazione';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { ItaliaEsteroEnum } from '../model/italiaEsteroEnum';
import { MissioneInfoView } from '../model/missioneInfoView';
import { MissioneStatus } from '../model/missioneStatus';
import { MissioneType } from '../model/missioneType';
import { PageMissioneAndStudenteInfoView } from '../model/pageMissioneAndStudenteInfoView';
import { TipoFondoDiRiferimento } from '../model/tipoFondoDiRiferimento';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MissioniService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Restituisce le richieste di missione degli studenti con i filtri applicati.
     * 
     * @param annoRiferimento 
     * @param codiceFiscaleStudente 
     * @param corsoDiStudiCodiceEsse3 
     * @param descrizione 
     * @param descrizioneFondoDiRiferimento 
     * @param hasChiaveDocumentoAllegato 
     * @param hasPermessiApprovativiCosupervisore 
     * @param italiaEstero 
     * @param luogo 
     * @param matricolaStudente 
     * @param missioneIds 
     * @param nominativoStudente 
     * @param notHasChiaveDocumentoAllegato 
     * @param statiMissione 
     * @param tipiFondoDiRiferimento 
     * @param tipologiaMissione 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchMissioniStudentiForm(annoRiferimento?: AnnoRiferimentoValues, codiceFiscaleStudente?: string, corsoDiStudiCodiceEsse3?: string, descrizione?: string, descrizioneFondoDiRiferimento?: string, hasChiaveDocumentoAllegato?: ChiaveDocumentoEnum, hasPermessiApprovativiCosupervisore?: boolean, italiaEstero?: ItaliaEsteroEnum, luogo?: string, matricolaStudente?: string, missioneIds?: Array<string>, nominativoStudente?: string, notHasChiaveDocumentoAllegato?: ChiaveDocumentoEnum, statiMissione?: Array<MissioneStatus>, tipiFondoDiRiferimento?: Array<TipoFondoDiRiferimento>, tipologiaMissione?: MissioneType, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageMissioneAndStudenteInfoView>;
    public searchMissioniStudentiForm(annoRiferimento?: AnnoRiferimentoValues, codiceFiscaleStudente?: string, corsoDiStudiCodiceEsse3?: string, descrizione?: string, descrizioneFondoDiRiferimento?: string, hasChiaveDocumentoAllegato?: ChiaveDocumentoEnum, hasPermessiApprovativiCosupervisore?: boolean, italiaEstero?: ItaliaEsteroEnum, luogo?: string, matricolaStudente?: string, missioneIds?: Array<string>, nominativoStudente?: string, notHasChiaveDocumentoAllegato?: ChiaveDocumentoEnum, statiMissione?: Array<MissioneStatus>, tipiFondoDiRiferimento?: Array<TipoFondoDiRiferimento>, tipologiaMissione?: MissioneType, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMissioneAndStudenteInfoView>>;
    public searchMissioniStudentiForm(annoRiferimento?: AnnoRiferimentoValues, codiceFiscaleStudente?: string, corsoDiStudiCodiceEsse3?: string, descrizione?: string, descrizioneFondoDiRiferimento?: string, hasChiaveDocumentoAllegato?: ChiaveDocumentoEnum, hasPermessiApprovativiCosupervisore?: boolean, italiaEstero?: ItaliaEsteroEnum, luogo?: string, matricolaStudente?: string, missioneIds?: Array<string>, nominativoStudente?: string, notHasChiaveDocumentoAllegato?: ChiaveDocumentoEnum, statiMissione?: Array<MissioneStatus>, tipiFondoDiRiferimento?: Array<TipoFondoDiRiferimento>, tipologiaMissione?: MissioneType, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMissioneAndStudenteInfoView>>;
    public searchMissioniStudentiForm(annoRiferimento?: AnnoRiferimentoValues, codiceFiscaleStudente?: string, corsoDiStudiCodiceEsse3?: string, descrizione?: string, descrizioneFondoDiRiferimento?: string, hasChiaveDocumentoAllegato?: ChiaveDocumentoEnum, hasPermessiApprovativiCosupervisore?: boolean, italiaEstero?: ItaliaEsteroEnum, luogo?: string, matricolaStudente?: string, missioneIds?: Array<string>, nominativoStudente?: string, notHasChiaveDocumentoAllegato?: ChiaveDocumentoEnum, statiMissione?: Array<MissioneStatus>, tipiFondoDiRiferimento?: Array<TipoFondoDiRiferimento>, tipologiaMissione?: MissioneType, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (annoRiferimento !== undefined) {
            formParams = formParams.append('annoRiferimento', <any>annoRiferimento) as any || formParams;
        }
        if (codiceFiscaleStudente !== undefined) {
            formParams = formParams.append('codiceFiscaleStudente', <any>codiceFiscaleStudente) as any || formParams;
        }
        if (corsoDiStudiCodiceEsse3 !== undefined) {
            formParams = formParams.append('corsoDiStudiCodiceEsse3', <any>corsoDiStudiCodiceEsse3) as any || formParams;
        }
        if (descrizione !== undefined) {
            formParams = formParams.append('descrizione', <any>descrizione) as any || formParams;
        }
        if (descrizioneFondoDiRiferimento !== undefined) {
            formParams = formParams.append('descrizioneFondoDiRiferimento', <any>descrizioneFondoDiRiferimento) as any || formParams;
        }
        if (hasChiaveDocumentoAllegato !== undefined) {
            formParams = formParams.append('hasChiaveDocumentoAllegato', <any>hasChiaveDocumentoAllegato) as any || formParams;
        }
        if (hasPermessiApprovativiCosupervisore !== undefined) {
            formParams = formParams.append('hasPermessiApprovativiCosupervisore', <any>hasPermessiApprovativiCosupervisore) as any || formParams;
        }
        if (italiaEstero !== undefined) {
            formParams = formParams.append('italiaEstero', <any>italiaEstero) as any || formParams;
        }
        if (luogo !== undefined) {
            formParams = formParams.append('luogo', <any>luogo) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }
        if (missioneIds) {
            missioneIds.forEach((element) => {
                formParams = formParams.append('missioneIds', <any>element) as any || formParams;
            })
        }
        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (notHasChiaveDocumentoAllegato !== undefined) {
            formParams = formParams.append('notHasChiaveDocumentoAllegato', <any>notHasChiaveDocumentoAllegato) as any || formParams;
        }
        if (statiMissione) {
            statiMissione.forEach((element) => {
                formParams = formParams.append('statiMissione', <any>element) as any || formParams;
            })
        }
        if (tipiFondoDiRiferimento) {
            tipiFondoDiRiferimento.forEach((element) => {
                formParams = formParams.append('tipiFondoDiRiferimento', <any>element) as any || formParams;
            })
        }
        if (tipologiaMissione !== undefined) {
            formParams = formParams.append('tipologiaMissione', <any>tipologiaMissione) as any || formParams;
        }

        return this.httpClient.request<PageMissioneAndStudenteInfoView>('post',`${this.basePath}/api/v1/missione/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un coordinatore di validare una richiesta di missione di uno studente.
     * 
     * @param id_missione id missione
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaRichiestaMissioneStudenteByCoordinatoreForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<MissioneInfoView>>;
    public validaRichiestaMissioneStudenteByCoordinatoreForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MissioneInfoView>>>;
    public validaRichiestaMissioneStudenteByCoordinatoreForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MissioneInfoView>>>;
    public validaRichiestaMissioneStudenteByCoordinatoreForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_missione === null || id_missione === undefined) {
            throw new Error('Required parameter id_missione was null or undefined when calling validaRichiestaMissioneStudenteByCoordinatore.');
        }




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<MissioneInfoView>>('post',`${this.basePath}/api/v1/missione/${encodeURIComponent(String(id_missione))}/coordinatore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al Direttore di Dipartimento di validare una richiesta di missione di uno studente.
     * 
     * @param id_missione id missione
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaRichiestaMissioneStudenteByDirettoreDiDipartimentoForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<MissioneInfoView>>;
    public validaRichiestaMissioneStudenteByDirettoreDiDipartimentoForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MissioneInfoView>>>;
    public validaRichiestaMissioneStudenteByDirettoreDiDipartimentoForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MissioneInfoView>>>;
    public validaRichiestaMissioneStudenteByDirettoreDiDipartimentoForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_missione === null || id_missione === undefined) {
            throw new Error('Required parameter id_missione was null or undefined when calling validaRichiestaMissioneStudenteByDirettoreDiDipartimento.');
        }




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<MissioneInfoView>>('post',`${this.basePath}/api/v1/missione/${encodeURIComponent(String(id_missione))}/direttore-di-dipartimento`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore/cosupervisore di validare una richiesta di missione di uno studente.
     * 
     * @param id_missione id missione
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaRichiestaMissioneStudenteBySupervisoreForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<MissioneInfoView>>;
    public validaRichiestaMissioneStudenteBySupervisoreForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MissioneInfoView>>>;
    public validaRichiestaMissioneStudenteBySupervisoreForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MissioneInfoView>>>;
    public validaRichiestaMissioneStudenteBySupervisoreForm(id_missione: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_missione === null || id_missione === undefined) {
            throw new Error('Required parameter id_missione was null or undefined when calling validaRichiestaMissioneStudenteBySupervisore.');
        }




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<MissioneInfoView>>('post',`${this.basePath}/api/v1/missione/${encodeURIComponent(String(id_missione))}/supervisore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
