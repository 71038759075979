import {Pipe, PipeTransform} from '@angular/core';
import {AnnoRiferimentoValues, ChiaveFlussoEnum, StatoGenericoEnum} from '../../../api-clients/generated/services';
import {mapColorToClass} from '../utils/utils';
import {CicloConfigurationService} from "../service/ciclo-configuration.service";

@Pipe({
  name: 'flussoStatusToColorClass'
})
export class FlussoStatusToColorPipe implements PipeTransform {

    constructor(private cicloConfigurationService: CicloConfigurationService) {
    }

    transform(value: StatoGenericoEnum, chiaveFlusso: ChiaveFlussoEnum, annoRiferimento?: AnnoRiferimentoValues): string {
        const color = this.cicloConfigurationService.getStateColor(chiaveFlusso, value, annoRiferimento);
        return mapColorToClass(color);
    }

}
