import {Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ClickEvent} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    DelibereService,
    MobilitaRequestSelectFormValuesDTO,
    MobilitaStudentiCicloService, ModalitaDiFirmaType,
    PeriodiDiMobilitaService,
    PeriodoDiMobilitaStudenteInfoView,
    TipoPeriodoEnum
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, startWith, Subscription, switchMap, take, takeUntil, tap} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {AbstractControl, FormArray, FormBuilder, Validators} from "@angular/forms";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {MatDialog} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {CustomValidators} from "../../../../../shared/validators/custom-validators";
import {FormComponent} from "../../../../../layout/common/generic-components/form/form.component";
import {
    CicloConfigurationService,
    DocumentDataType,
    isDocumentNotSignedWithSignRequiredCondition, isDocumentSigned
} from "../../../../../shared/service/ciclo-configuration.service";
import {HttpResponse} from "@angular/common/http";
import {openFileInBlankWindow} from "../../../../../shared/utils/utils";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {SignableDocumentsData} from "../../../../../shared/components/document/document.component";
import {ApprovazioneMobilitaMassivaDataI} from "../approvazione-mobilita-steps-interface";
import {emailRegexString} from "../../../../../shared/costants/regex";


@Component({
    selector: 'app-info-approvazione-mobilita-stepper',
    templateUrl: './compila-info-approvazione-mobilita.component.html',
    styleUrls: ['./compila-info-approvazione-mobilita.component.scss']
})
export class CompilaInfoApprovazioneMobilitaComponent extends AbstractDefaultComponent implements OnInit {

    outputFormatData = 'DD/MM/YYYY';
    loading: boolean;
    periodiSelected: PeriodoDiMobilitaStudenteInfoView[];
    periodiDetailsFormArray: FormArray;
    formSelectValues: MobilitaRequestSelectFormValuesDTO;
    documentsFormValues: DocumentDataType[];
    _docsFormComponents: FormComponent[];
    docsFormComponentSubs: Subscription[] = [];
    @ViewChildren(FormComponent) formComponentsQL: QueryList<FormComponent>;
    documentsFormConfigs: { formConfig: formGroupConfigInterface[], utenteId: string, codiceMobilita: string }[];
    someDocNeedSign: boolean;



    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private cicloConfigurationService: CicloConfigurationService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                private mobilitaStudentiCicloService: MobilitaStudentiCicloService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneMobilitaMassivaDataI>,) {
        super();
    }

    ngAfterViewInit() {
        this.formComponentsQL.changes
            ?.pipe(startWith(this.formComponentsQL))
            ?.subscribe((ql: QueryList<FormComponent>) => {
                this._docsFormComponents = ql.toArray();
                this.subscribeToDocsFormsValueChange();
            });
    }

    private subscribeToDocsFormsValueChange() {
        const someNotSignedDocExists = this.documentsFormValues
            ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
        this.canGoNext$.next(this._docsFormComponents?.every(fc => fc.formGroup?.valid) && !someNotSignedDocExists);
        this.docsFormComponentSubs?.forEach(fcs => fcs.unsubscribe());
        const documentiAllegati = [];
        this.docsFormComponentSubs = this._docsFormComponents?.map(fc =>
            (fc?.formGroup?.valueChanges
                ?.pipe(startWith(fc?.formGroup?.value))
                .subscribe(docsFormValue => {
                    const someNotSignedDocExists = this.documentsFormValues
                        ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
                    this.canGoNext$.next(this._docsFormComponents?.every(fc => fc.formGroup?.valid && this.periodiDetailsFormArray.valid)/* && !someNotSignedDocExists*/);
                    const documents = this.cicloConfigurationService.prepareDocsSubmitObjectFromNuoveConfig(
                        ChiaveFlussoEnum.RichiestaMobilita,
                        this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                        docsFormValue,
                    );
                    let existingDocs = documentiAllegati?.find(d => d.codiceMobilita === fc.formGroup?.value?.codiceMobilita && d.id_utente === fc.formGroup?.value?.id_utente);
                    if(existingDocs){
                        existingDocs.documents = (documents ?? {});
                    } else {
                        documentiAllegati.push({
                            codiceMobilita: fc.formGroup?.value?.codiceMobilita,
                            id_utente: fc.formGroup?.value?.id_utente,
                            documents: (documents ?? {}),
                        });
                    }
                    this.operazioneMassivaData$.next({
                        ...this.operazioneMassivaData$?.getValue(),
                        documentiAllegati: documentiAllegati?.map(da => ({
                            codiceMobilita: da?.codiceMobilita,
                            id_utente: da?.id_utente,
                            ...(da?.documents ?? {})
                        }))
                    });
                })));


    }


    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneMobilitaMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshPeriodi){
                this.canGoNext$.next(false);
                this.periodiDetailsFormArray = undefined;
            }
            // if studenti selected changes reset delibera form and get plans for selected students
            if(inputOutputData.periodiSelected !== this.periodiSelected){
                this.periodiSelected = inputOutputData?.periodiSelected;
                //this.form?.get('deliberaCollegio')?.setValue(inputOutputData.deliberaCollegio?.id, {emitEvent: false});
                this._docsFormComponents?.forEach(fc => fc?.formGroup?.reset({emitEvent: false}));
                this.buildPeriodiDetailsFormGroup(this.periodiSelected);
                this.buildDocsFormGroup();
            }
        });
        // get form select values
        this.getFormSelectValues();
    }




    private buildDocsFormGroup() {
        this.documentsFormConfigs = this.periodiSelected?.map(period => ({
            formConfig: [
                {
                    show: false,
                    name: 'codiceMobilita',
                    transloco: 'codiceMobilita',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
                {
                    show: false,
                    name: 'id_utente',
                    transloco: 'id_utente',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
                ...this.cicloConfigurationService.getDocsFormConfigFromNuoveConfig(
                    ChiaveFlussoEnum.RichiestaMobilita,
                    this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                    (filename, idAllegato) => this.downloadDocument(period.codiceMobilita, idAllegato, period.studenteCiclo?.utente?.id),
                    undefined,
                    period.allegatiPeriodoDiMobilita,
                )
            ],
            utenteId: period?.studenteCiclo?.utente?.id,
            codiceMobilita: period.codiceMobilita,
        }));

        this.documentsFormValues = this.periodiSelected?.map(period => ({
            codiceMobilita: period.codiceMobilita,
            id_utente: period?.studenteCiclo?.utente?.id,
            ...this.cicloConfigurationService.getDocsFormValuesFromNuoveConfig(
                ChiaveFlussoEnum.RichiestaMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                period.allegatiPeriodoDiMobilita,
                undefined,
                period?.studenteCiclo?.utente?.id,
            )
        }));

        this.someDocNeedSign =
            !!this.documentsFormConfigs?.find(fc =>
                fc?.formConfig?.find(fci =>
                    fci.signModes?.filter(mode => mode !== ModalitaDiFirmaType.CARICAMENTOFILE)?.length > 0)
            ) && !!this.documentsFormValues?.find(fv =>
                Object.values(fv?.signableDocumentsData ?? {})
                    ?.find((docSignData: SignableDocumentsData) => !isDocumentSigned(docSignData))
            );
    }

    private getFormSelectValues() {
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.getMobilitaRequestSelectFormValues()),
            tap(selectValues => this.formSelectValues = selectValues),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: () => {
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.getFormSelectValues(),
                    'dialog.cancel',
                    err?.error?.message);
            }
        });
    }


    tableClickAction($event: ClickEvent) {

    }


    private buildPeriodiDetailsFormGroup(selectedPeriods: PeriodoDiMobilitaStudenteInfoView[]) {
        this.periodiDetailsFormArray = this.fb.array(selectedPeriods?.map(period =>
            this.getPeriodoDetailsForm(period))?? []);
        // subscribe to form group change
        this.periodiDetailsFormArray.valueChanges?.pipe(startWith(this.periodiDetailsFormArray.value)).subscribe(fa => {
            console.log('details value changed', fa)
            const someNotSignedDocExists = this.documentsFormValues
                ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
            this.canGoNext$.next(this.periodiDetailsFormArray.valid && this._docsFormComponents?.every(fc => fc.formGroup?.valid) && !someNotSignedDocExists);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                compileInfo: fa?.map(value => ({
                    codiceMobilitaPeriodo: value.codiceMobilita,
                    idUtente: value.idUtente,
                    esito: {
                        approvato: true,
                        codice_tipologia_mobilita: value.tipologiaMobilita,
                        //numero_tipologia_bonus: formRawValue.tipologiaBonus,
                        doctor_europeaus: value.doctorEuropeaus.doctorEuropeausFlag || false,
                        sede_doctor_europeaus: value.doctorEuropeaus.sedeDoctorEuropeaus,
                        codice_tipo_intervento_supporto: value.tipoInterventoSupporto,
                        data_inizio: moment(value.dataInizio).format(this.outputFormatData),
                        data_fine: value.dataFine ? moment(value.dataFine).format(this.outputFormatData) : undefined,
                        erasmus: value.erasmus || false,
                        nome_referente_estero: value?.referenteEstero?.nome,
                        cognome_referente_estero: value?.referenteEstero?.cognome,
                        email_referente_estero: value?.referenteEstero?.email,
                    }
                }))
            });
        })
    }

    private getPeriodoDetailsForm(period: PeriodoDiMobilitaStudenteInfoView) {
        console.log(period)
        return this.fb.group({
            codiceMobilita: period?.codiceMobilita,
            idUtente: period?.studenteCiclo?.utente?.id,
            erasmus: period?.erasmus,
            dataInizio: this.fb.control(period?.dataInizio ? moment(period?.dataInizio, 'DD/MM/YYYY').toDate() : undefined),
            dataFine: this.fb.control(period?.dataFine ? moment(period?.dataFine, 'DD/MM/YYYY').toDate() : undefined),
            tipologiaMobilita: [period?.tipologiaMobilita?.codice, Validators.required],
            tipoInterventoSupporto: period?.tipoInterventoSupporto?.codice,
            doctorEuropeaus: this.fb.group({
                doctorEuropeausFlag: period?.doctorEuropeaus,
                sedeDoctorEuropeaus: period?.sedeDoctorEuropeaus,
            }, {validators: CustomValidators.DoctorEuropeausValidator()}),
            deliberaCollegio: period?.delibera?.id,
            referenteEstero: this.fb.group(
                {
                    nome: [period?.nomeReferenteEstero, [Validators.required, CustomValidators.noWhitespaceValidator]],
                    cognome: [period?.cognomeReferenteEstero, [Validators.required, CustomValidators.noWhitespaceValidator]],
                    email: [period?.emailReferenteEstero, [Validators.required, CustomValidators.noWhitespaceValidator, Validators.pattern(new RegExp(emailRegexString))]],
                }
            ),
            coordinatorAck: [undefined, this.currentProfile === AuthorityType.COORDINATORE ? Validators.requiredTrue : undefined],
        }, {validators: CustomValidators.dataAMaggioreDiDataA('dataInizio', 'dataFine', this.outputFormatData)});
    }

    clearDateField(fieldCtrl: AbstractControl) {
        fieldCtrl.setValue(undefined);
        fieldCtrl.setErrors(null);
    }

    downloadDocument(codiceMobilita: string, idAllegato: string, idStudente: string) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.mobilitaStudentiCicloService.getAllegatoPeriodoDiMobilitaStudente(codiceMobilita, idAllegato, idStudente, 'response').pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                //const fileNameToDownload = makeFilename(fileName);
                openFileInBlankWindow(fileResponse?.body, fileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });

    }





    getDocumentsFormValue(utenteId: string, codiceMobilita: string) {
        return this.documentsFormValues?.find(dfv => dfv.codiceMobilita === codiceMobilita && dfv.id_utente === utenteId);
    }

    getDocumentsFormConfig(utenteId: string, codiceMobilita: string) {
        return this.documentsFormConfigs?.find(dfc => dfc.codiceMobilita === codiceMobilita && dfc.utenteId === utenteId)?.formConfig;
    }

    protected readonly AuthorityType = AuthorityType;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;

    protected readonly ChiaveFlussoEnum = ChiaveFlussoEnum;
    protected readonly ChiaveOperazioneEnum = ChiaveOperazioneEnum;

}
