import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {get, isEmpty} from "lodash";
import {DialogWrapI} from "../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum, ConfigurazioneDocumentoFlusso,
    DeliberaInfoView, DocumentoType, ModalitaDiFirmaType, NuovaConfigurazioneOperazione,
    SuperamentoAnnoStudenteStatus
} from "../../../../api-clients/generated/services";
import {
    CicloConfigurationService,
    isDocumentSigned,
    isTargetDocumentSigned
} from "../../service/ciclo-configuration.service";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";
import {AppInitService} from "../../service/app-init.service";

export interface DialogAttachmentsDocumentsSection {
    sectionTitle: string;
    requestAttachmentsListKey: string;
    operation: ChiaveOperazioneEnum;
}

export interface DialogAttachmentsInfoI extends DialogWrapI {
    documentSections?: DialogAttachmentsDocumentsSection[];
    requestData: any;
    flusso: ChiaveFlussoEnum;
    allegatiKey?: string;
    downloadDocumentFn: (idAllegato: string, nomeFile: string, delibera: DeliberaInfoView) => void;
}

@Component({
    selector: 'app-dialog-attachments-info',
    templateUrl: './dialog-attachments-info.component.html',
    styleUrls: ['./dialog-attachments-info.component.scss']
})
export class DialogAttachmentsInfoComponent extends AbstractDefaultComponent implements OnInit {

    documentExists: boolean;
    documentsOfFlow: Array<ConfigurazioneDocumentoFlusso> | undefined;
    executableOperationsOfFlow: Array<NuovaConfigurazioneOperazione> | undefined;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogAttachmentsInfoI,
                public dialogRef: MatDialogRef<DialogAttachmentsInfoComponent>,
                public cicloConfigurationService: CicloConfigurationService,
                protected appInitService: AppInitService,
                ) {
        super();
        if(this.data.allegatiKey){
            this.documentExists = this.data.requestData?.[data.allegatiKey]?.length > 0;
        } else {
            this.documentExists = this.data.documentSections?.map(section =>
                this.data.requestData[section.requestAttachmentsListKey])
                ?.some(docs => docs?.length > 0);
        }


    }

    ngOnInit(): void {
        if(this.data.allegatiKey){
            this.executableOperationsOfFlow = this.cicloConfigurationService.corsoConfiguration
                ?.nuove_configurazioni_flussi_corsi_di_studi
                ?.find(config => config.chiave_flusso === this.data.flusso)
                ?.configurazioni_operazioni;
            this.documentsOfFlow = this.cicloConfigurationService.corsoConfiguration
                ?.nuove_configurazioni_flussi_corsi_di_studi
                ?.find(config => config.chiave_flusso === this.data.flusso)
                ?.documenti;
        }

    }

    closeDialog() {
        this.dialogRef.close();
    }


    protected readonly isEmpty = isEmpty;
    protected readonly SuperamentoAnnoStudenteStatus = SuperamentoAnnoStudenteStatus;
    protected readonly ChiaveOperazioneEnum = ChiaveOperazioneEnum;

    getLabelForDoc(operation: ChiaveOperazioneEnum, chiave: string) {
        const a = this.cicloConfigurationService.getTranslatedDescription(
            this.data.flusso,
            operation,
            chiave,
            undefined,
            !!this.data.allegatiKey
        );
        return a;
    }


    protected readonly isDocumentSigned = isDocumentSigned;

    protected readonly get = get;
    protected readonly getSignedWithFromKey = getSignedWithFromKey;
    protected readonly isTargetDocumentSigned = isTargetDocumentSigned;

    getDocsForOp(chiave_operazione: ChiaveOperazioneEnum, docs: any[]) {
        return docs?.filter(doc => doc.chiaveOperazione === chiave_operazione);
    }

    protected readonly DocumentoType = DocumentoType;
    protected readonly AuthorityType = AuthorityType;
}

export function getSignedWithFromKey(modalitaDiFirma: ModalitaDiFirmaType) {
    switch (modalitaDiFirma){
        case ModalitaDiFirmaType.FIRMAOTP:
            return 'document_sign.document_signed_otp_from';
        case ModalitaDiFirmaType.CARICAMENTOFILE:
            return 'document_sign.document_signed_file_from';
    }
}
