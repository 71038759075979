import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {AuthorityType, UserSottoruoliInfoDTO, UsersService} from "../../../api-clients/generated/services";
import {LocalStorageService} from "../../shared/service/local-storage.service";
import {PathEnum} from "../../app.routing";
import {map, Observable, of, switchMap} from "rxjs";
import {AppInitService} from "../../shared/service/app-init.service";
import {filter, first, skip} from "rxjs/operators";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";


@Injectable({
    providedIn: 'root'
})
export class DocentiEsterniListGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private localStorageService: LocalStorageService,
        private appInitService: AppInitService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const cicloCorsoRuolo = this.localStorageService.getCicloCorsoRuolo(true);
        console.log('cicloCorsoRuolo from docenti esterni list guard', cicloCorsoRuolo)
        if (cicloCorsoRuolo?.ruolo === AuthorityType.DOCENTE || cicloCorsoRuolo?.ruolo === AuthorityType.PERSONALEPTA || cicloCorsoRuolo?.ruolo === AuthorityType.ESTERNO) {
            return this.appInitService.currentSottoruoliUtente$.pipe(
                first(),
                map(sottoruoli => {
                    if(!!sottoruoli) {
                        // condition for users with subroles
                        const canActivate = DocentiEsterniListGuard.accessConditionForSubroles(sottoruoli);
                        if(!canActivate) {
                            this._router.navigate([PathEnum.HOME]);
                        }
                        return canActivate;
                    }
                })
            );
        } else {
            // condition for users without subroles
            const canActivate = DocentiEsterniListGuard.accessConditionForRoles(cicloCorsoRuolo);
            if(!canActivate) {
                this._router.navigate([PathEnum.HOME]);
            }
            return of(canActivate);
        }
    }

    static accessConditionForRoles(cicloCorsoRuolo: CicloCorsoRuoloInterface): boolean {
        return cicloCorsoRuolo?.ruolo === AuthorityType.SUPERADMIN || cicloCorsoRuolo?.ruolo === AuthorityType.ADMIN;
    }

    static accessConditionForSubroles(sottoruoli: AuthorityType[]): boolean {
        return !!sottoruoli?.find(s =>
            s === AuthorityType.COORDINATORE ||
            s === AuthorityType.GRUPPODICOORDINAMENTO ||
            s === AuthorityType.DIRETTOREDIDIPARTIMENTO ||
            s === AuthorityType.COLLEGIODOCENTI ||
            s === AuthorityType.GRUPPOPTA ||
            s === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO
        );
    }

    static accessCondition(cicloCorsoRuolo: CicloCorsoRuoloInterface, sottoruoli: AuthorityType[]) {
        return DocentiEsterniListGuard.accessConditionForRoles(cicloCorsoRuolo) || DocentiEsterniListGuard.accessConditionForSubroles(sottoruoli);
    }
}
